import React, { useEffect } from 'react';

import './page.scss'
import { setTheme } from '../utils/store';
import { useSelector } from 'react-redux';

export function Page({theme="index light",title="",styles="",sectionStyles="",children}){
    let {inMeeting}=useSelector(state => state.game);
    let th=theme;
    if (inMeeting) th+=" meeting";
/*
    useEffect(function(){
        setTheme(theme);
    },[theme])
*/
    console.log("Page is setting clsList",th)
    let html=document.querySelector("html");
    html.classList=th;

    //setTheme(theme);

    return <div className={styles+" page"}>
        {title ? <h2>{title}</h2> : <></>}
        <div className={sectionStyles}>
            {children}
        </div>
    </div>
}