import { guid } from "../utils/utils";
import { drawAsset } from "./assets/assetrenderer";
import { hitTestContours } from "./contours/contourhittest";
import { drawContour } from "./contours/contourrenderer";
import { hitTestLines, testForSharedPoints } from "./lines/linehittest";
import { drawLine, findOpeningEndpoints } from "./lines/linerenderer";
import { pointFromPixels, pointNearLine, pointOnLine, distance, lineInfo, pointOptions, point, calculateOptionValues, pointInShape, pointNear, pointInRect } from "./points/pointutils";
import { drawAvatars, drawClicks,  drawPointer, hiliteClicked } from "./renderer";
import { Room } from "./rooms/room";

class Door{
    constructor(points){
        this.rowKey=guid('dr');
        this.points=points;
    }    
}

function createOpening(type,room1,room2,start,end,isDoorOpen=false){
    let obj={type,room1,room2,start,end,isDoorOpen}
    obj.toJSON=function(){
        let ret={...obj};
        ret.room1RowKey=obj.room1.rowKey;
        ret.room2RowKey=obj.room2.rowKey;
        delete ret.room1;
        delete ret.room2;
        return ret;
    }
    return obj;
}


export class Map{

    constructor(sizeInFeet=200){
        this.points=[];
        this.openings=[];
        this.assets=[];
        this.selectedRoom=null;
        this.objects=[];
        this.clicked={};
        this.clicks=[];
        this.notes=[];
        this.playerAvatars=[];
        this.options=pointOptions(sizeInFeet);
        this.activeEncounter=null;
    }

    toJSON(){
        return {
            points:this.points,
            objects:this.objects,
            openings:this.openings,
            options:this.options,
            assets:this.assets,
            selectedRoom:this.selectedRoom,
            playerAvatars:this.playerAvatars,
            activeEncounter:this.activeEncounter,
        }
    }


    prepareForDraw(player){
        if (!player) return;
        if (!this.options.onlyShowVisited) return;
        let selected=null;
        for(let i=0;i<this.objects.length;i++){
            let obj=this.objects[i];
            obj.visible=obj.visited;
            if (obj.rowKey==this.selectedRoom) selected=obj;
        }
        if (selected) this.testConnectedRooms(selected);
    }

    testConnectedRooms(room){
        for(let i=0;i<room.points.length;i++){
            let opening=this.openings.find(o => o.rowKey==room.points[i].rowKey);
            if (!opening) continue;
            if (!opening.isOpen) continue;
            let connected=this.objects.find(l => {
                if (l.rowKey==room.rowKey) return false;
                return l.points.find(p => p.rowKey==opening.rowKey);
            })
            if (connected) connected.visible=true;
            console.log("Found connected",opening,connected)
        }
    }

    clearLatest(){
        let {canvasRef,image}=this.latest;
        let canvas=canvasRef.current;
        if (canvas){
            let ctx=canvas.getContext("2d");
            ctx.clearRect(0,0,this.options.canvasWidth,this.options.canvasHeight);
        }
    }

    async drawLatest(obj=null,hover=false,hiliteLine=true){
        if (!this.latest?.image) {
            console.log("No latest")
            return;
        }
        console.log("Map drawing latest",obj,hiliteLine,this)
        let {canvasRef,image}=this.latest;
        let canvas=canvasRef.current;
        if (canvas){
            let ctx=canvas.getContext("2d");
            ctx.clearRect(0,0,this.options.canvasWidth,10000,10000);
            ctx.drawImage(image,0,0);
            if (obj?.itemType=="asset"){
                await drawAsset(ctx,this,obj);
            }
            if (obj?.itemType=="line"){
                drawLine(ctx,this,obj,hover).then(() => hiliteClicked(ctx,this,hiliteLine))
            }
            await drawAvatars(ctx,this,this.avatars);
            if (!obj && !this.clicked.line){
                drawClicks(ctx,this,!obj);
            }
            hiliteClicked(ctx,this,hiliteLine);
            drawPointer(ctx,this,this.mapPointer);
        }
    }

    addObject(obj){
        obj.points=obj.points.map(p => this.addPoint(p));
        obj.rowKey=guid(obj.itemType);
        this.objects.push(obj);
        //testForSharedPoints(this,obj);
        return obj;
    }

    createOpening(type,width=5,isOpen=true,isLocked=false){
        let {line,segment}=this.clicked;
        let point=this.addCorner();
        if (point){
            if (type=="arc"){
                isOpen=false;
                width=10;
            }
            if (type=="window"){
                isOpen=false;
                width=2;
            }
            let opening={type,width,isOpen,isLocked,rowKey:point.rowKey,owningLine:line.rowKey};
            this.openings.push(opening);
        }
    }

    initOpening(type='open'){
        console.log("Opening",this)
        let {start,end,length,fromBeginning:pos}=this.clicked.wall;
        let {room,wallIndex,wall}=this.clicked;
        wall={...wall};
        let p1=start;
        let p2=end;
        if (type.startsWith('door')){
            let w=5;
            if (type=='door2') w=10;
            w+=2*this.options.wallThickness;
            pos-=w/2;
            if (pos<0) pos=0;
            p1=pointOnLine(start,end,pos,this.options);
            p2=pointOnLine(p1,end,w,this.options);
            p1.rooms=[room];
            p2.rooms=[room];
            wall.start=p1;
            wall.end=p2;
        }
        //click.wall.index++;
        wall.index=wallIndex+1;
        return {room, wall ,points:[p1,p2],type};
    }

    addCorner(){
        console.log("HAVENT TESTED");
        let {segment,line}=this.clicked;
        let clickedObject=line;
        if (!clickedObject || !segment) return;
        let object=this.objects.find(o => o.rowKey==clickedObject.rowKey);
        let {index,fromBeginning}=segment;
        if (!fromBeginning) return;
        let points=object.points;
        let start=points[index];
        let end=points[index<points.length-1 ? index+1 : 0];
        let point=pointOnLine(start,end,fromBeginning,this.options);
        this.addPoint(point);
        index++;
        if (index>=points.length) points.push(point);
        else points.splice(index,0,point);
        console.log("Added point?",object,points)
        return point;
    }

    createAdjacentRoom(doorInfo){
        let [dp2,dp1]=this.clicks;
        let room=this.createRoom(this.clicks);
        if (doorInfo){
            let opening=createOpening(doorInfo.type,doorInfo.room,room,dp1,dp2,false);
            this.openings.push(opening)
        }
        this.clearClicks(true);
        return room;
    }

    joinAdjacentRooms(type='door'){
        let [room1,room2]=click.wall.sharedBy;
        let doorInfo=this.initOpening(click,type);
        let [dp1,dp2]=doorInfo.points;
        this.addPoint(dp1);
        this.addPoint(dp2);
        let opening=createOpening(doorInfo.type,room1,room2,dp1,dp2,false);
        this.rooms.forEach(r => r.testNewPoints([dp1,dp2]))
        this.openings.push(opening)
        this.clearClicks(true);
    }

    createDoor(doorPoints,room){
        doorPoints.forEach(dp => dp.rooms.push(room));
        let door=new Door(doorPoints);
        this.doors.push(door);
        this.rooms.forEach(r => r.testNewPoints(doorPoints))
    }


    /*
    createDecorator(room,point,itemTemplate){
        let item={...itemTemplate};
        //item.room=room;
        item.point=point;
        item.rotation=0;
        item.toJSON=() => decoratorToJson(item);
        console.log("Create item",item);
        return item;
    }
    */

    addAsset(item,room=null){
        let point=this.addPoint(item.point);
        let asset={...item,point};
        asset.room=room?.rowKey;
        asset.rowKey=guid("ast");
        this.assets.push(asset);
        /*
        let realRoom=null;
        if (room) realRoom=this.rooms.find(r => r.rowKey==room.rowKey);
        if (!realRoom) this.assets.push(asset);
        else realRoom.assets.push(asset);
        */
        console.log("Added",asset,this.points);
        return asset;
    }

    getPoint(mouseX,mouseY){
        return pointFromPixels(mouseX,mouseY,this.options);
    }

    drawPoint(ctx,point){
        let {dx,dy}=this.displacement;
        point.translate(dx,dy);
        let {x,y}=point.pixels;
        ctx.fillRect(x-2,y-2,4,4);        
    }

    addPoint(point){
        point={...point};
        if (!point.rowKey) {
            point.rowKey=guid("pnt");
            console.log("Addpoint, generated rowkey")
        }
        let existing=this.points.find(p => p.rowKey==point.rowKey);
        if (!existing) {
            console.log("Add new point")
            let pt={...point};
            delete pt.mouse;
            this.points.push(pt);
            return pt;
        }
        console.log("Add existing point")
        return existing;
    }

    createRoom(points){
        let room=new Room(points,this.options);
        points.forEach(c => {
            /*
            let point=this.points.find(p => p.rowKey==c.rowKey);
            if (point) point.rooms.push(room);
            else{
                c.rooms=[room];
                this.addPoint(c);
            }
            */
           this.addPoint(c);
        })
        this.rooms.push(room);
        this.selectedRoom=room;
        this.selectedRoomRowKey=room.rowKey;
        //console.log("MyPoints",this.points,room);
        this.rooms.forEach(r => r.testNewPoints(this.points));
        return room;
    }

    addRoom(room,clicked=null){
        if(!room.rowKey) room.rowKey=guid('rm');
        this.rooms.push(room);
        this.selectedRoom=room;
        if (clicked && clicked.wall){
            let p1=clicked.room.points[clicked.wallIndex].feet;
            let p2=room.points[1].feet;
            let room1={
                rowKey:clicked.room.rowKey,
                wallIndex:clicked.wallIndex,
                translate:{
                    dx:p1.x-p2.x,
                    dy:p1.y-p2.y
                }
            };
            let room2={
                rowKey:room.rowKey,
                wallIndex:0,
                translate:{
                    dx:-p1.x+p2.x,
                    dy:-p1.y+p2.y
                }
            }
        }
    }

    get dimensions(){
        let minX=0,minY=0,maxX=0,maxY=0;
        this.rooms.forEach(room => {
            let dim=room.dimensions;
            if (dim.minX<minX) minX=dim.minX;
            if (dim.minY<minY) minY=dim.minY;
            if (dim.maxX<maxX) maxX=dim.maxX;
            if (dim.minX<maxX) minX=dim.maxX;
        })
        return {minX,minY,maxX,maxY}
    }


    getPlayerMap(){
        let playerMap={options:this.options,rooms:[],selectedRoom:null};
        if (!this.selectedRoom) return {};
        playerMap.selectedRoom=this.selectedRoom;
        playerMap.rooms=[this.selectedRoom];
        let connections=this.openings.filter(o => o.room1==this.selectedRoom || o.room2==this.selectedRoom);
        connections.forEach(c => {
            let otherRoom=c.room1==this.selectedRoom ? c.room2 : c.room1;
            playerMap.rooms.push(otherRoom);            
        })
    }


    isWallShared(wall){
        let {start,end}=wall;
        let rooms=[];
        this.rooms.forEach(room => {
            for(let i=0;i<room.wallCount;i++){
                let rw=room.getWall(i);
                if (((rw.start==start) && (rw.end==end)) || ((rw.end==start)  && (rw.start==end))){
                    rooms.push({room,wallIndex:i});
                    break
                }
            }
        })
        if (rooms.length<2) return null
        return rooms;
    }

    hitTestNotes(point,notes){
        if (!notes) return;
        for(let i=0;i<notes.length;i++){
            let note=notes[i];
            let {x,y}=note;
            let x1=x+10;
            let y1=y-10;
            let inRect=pointInRect(point,x,y,x1,y1);
            if (inRect) return {note,noteIndex:i}
        }
    }

    hitTestAvatars(point,player,role){
        let avatars=this.avatars;
        //console.log("Avatarhittest",point);
        for(let i=0;i<avatars.length;i++){
            if (role!="Master" && avatars[i].player!=player) continue;
            let {x,y}=avatars[i];
            x*=this.options.feetToPixelsRatio;
            y*=this.options.feetToPixelsRatio;
            let mx=point.mouse.x;
            let my=point.mouse.y;
            let dist=Math.sqrt((mx-x)*(mx-x)+(my-y)*(my-y));
            //console.log("Avatar player",avatars[i].rowKey,dist)
            if (dist<10) return {avatar:avatars[i],avatarIndex:i}
        }
        return null;
    }


    hitTestRooms(point){
        for(let i=0;i<this.rooms.length;i++){
            let ht=pointInShape(point,this.rooms[i].points,this.options);
            if (ht){
                ht.roomIndex=i;
                ht.room=this.rooms[i];
                return ht;
            }
        }
    }

    hitTestMapAssets(point){
        for(let i=0;i<this.assets.length;i++){
            let asset=this.assets[i];
            //console.log("HTass",asset.point,point)
            if (pointNear(asset.point,point)){
                if (asset.room){
                    let room=this.objects.find(r => r.rowKey==asset.room);
                    return {assetIndex:i,asset,room};
                }
                return {assetIndex:i,asset};
            } 
        }
    }

    hitTest(point,player="",role=""){
        this.clicked={point};
        let aht=this.hitTestAvatars(point,player,role);
        if (aht){
            Object.assign(this.clicked,aht);
            //this.clicked.avatarClick=aht;
            return;
        }
        
        let line=hitTestLines(this,point);
        if (line){
            Object.assign(this.clicked,line);
        }
        let asset=this.hitTestMapAssets(point);
        if (asset) {
            Object.assign(this.clicked,asset);
        }
        let note=this.hitTestNotes(point,this.notes);
        if (note){
            Object.assign(this.clicked,note);
        }
        for(let i=0;i<this.points.length;i++){
            if (pointNear(this.points[i],point)) {
                this.clicked.point=this.points[i];
            }
        }
        for(let i=0;i<this.openings.length;i++){
            let {start,end}=this.openings[i];
            if (pointNearLine(start,end,point,this.options)){
                //console.log("Clicked opening");
                this.clicked.opening=this.openings[i];
                this.clicked.openingIndex=i;
                //return {opening:this.openings[i],openingIndex:i};
            }
        }
        return this.clicked;
    }

    addClick(point){
        this.clicks.push(point);
        //this.drawLatest();
    }

    clearClicks(alsoClicked=false){
        this.clicks=[];
        if (alsoClicked) this.clicked={};
        //this.drawLatest();
    }

    pointFromRowKey(rowKey){
        return this.points.find(p => p.rowKey==rowKey);
    }
}

Map.fromJson=function(jsonMap){
    //console.log("jsonmap",jsonMap);
    let map=new Map();
    let options={...jsonMap.options};
    if (!Number(options.mapWidth)) options.mapWidth=200;
    if (!Number(options.mapHeight)) options.mapHeight=200;
    calculateOptionValues(options);
    map.selectedRoom=jsonMap.selectedRoom;
    map.activeEncounter=jsonMap.activeEncounter;
    map.options=options;
    map.points=jsonMap.points.map(p => point(p.feet ? p.feet.x : p.x,p.feet ? p.feet.y : p.y,map.options,p.rowKey))
    let objects=jsonMap.objects?.filter(o => o.itemType=="line");
    map.objects=objects?.map(l => {
        let obj={...l};
        obj.points=l.points.map(p => map.pointFromRowKey(p.rowKey));
        return obj;
    })
    if (!map.objects) map.objects=[];
    let avs=jsonMap.playerAvatars || [];
    map.playerAvatars=avs.map(a => ({...a,map:options.rowKey}));
    map.openings=jsonMap.openings?.map(o => ({...o}));
    if (!map.openings) map.openings=[];
    map.assets=jsonMap.assets?.map(d => {
        let asset={...d};
        asset.point=map.pointFromRowKey(d.point.rowKey);
        return asset;
    })||[];

    return map;
}


