import React, { useEffect, useState } from 'react';
import { Dice } from '../../dice/dicebox';
import { Ranks } from '../../utils/ranks';
import { createCharacter, saveCharacter } from '../character';

import "./characterwizard.scss";
import { useSelector } from 'react-redux';
import { guid } from '../../utils/utils';
import { useNavigate } from 'react-router-dom';
import { CharacterWizardAttributes } from './wizardattributes';
import { CharacterWizardMoney } from './wizardmoney';
import { CharacterWizardItems } from './wizarditems';
import { CharacterWizardHelp } from './wizardhelp';
import { Reputation } from '../reputation';

const knownLanguages={
    "Slav":[],
    "Armenian":["Armenian","Turch"],
    "Varangian":["Norse"],
    "Fenno":["Fenno"],
    "Khazar":["Khazar","Turch"],
    "Tatar":["Armenian","Turch"],
    "Seljuk":["Seljuk","Turch"],
}

function testValue(values,value){
    let existing=values.find(v => v.field==value.field);
    if (existing) existing.value=value.value;
    else values.push(value);
}

function CharacterWizardFinalize({character,values,items,spells,onPrevious}){
    const {player}=useSelector(state => state.game);
    const [name,setName]=useState("");
    const [profession,setProfession]=useState("");
    const [ethnicity,setEthnicity]=useState('Slav');
    const [age,setAge]=useState(17);
    const [sex,setSex]=useState("Male");
    const [deities,setDeities]=useState('Rod,Perun,Devana');
    const [deitiesAvailable,setDeitiesAvailable]=useState(["Rod,Perun,Devana","Christ,Rod,Perun,Devana","Christ"]);
    let [lawful,setLawful]=useState(0);
    let [chaotic,setChaotic]=useState(1+(character.values.sorcery ? 1 :0));
    const [minLawful,setMinLawful]=useState(0);
    const [minChaotic,setMinChaotic]=useState(1+(character.values.sorcery ? 1 :0))
    let [selfish,setSelfish]=useState(0);
    let [selfless,setSelfless]=useState(0);
    const navigate=useNavigate();

    function goPrevious(){
        testValue(values,{field:'name',value:name});
        testValue(values,{field:'profession',value:profession});
        testValue(values,{field:'ethnicity',value:ethnicity});
        testValue(values,{field:'age',value:age});
        testValue(values,{field:'sex',value:sex});
        testValue(values,{field:'deities',value:deities});
        onPrevious(values);
    }

    function testLawChaos(ethnicity,deities){
        let minlawful=0,minchaotic=character.values.sorcery ? 1 : 0;
        if (deities=='Christ' || deities=='Allah' || deities=='Yahweh'){
            minlawful=1+(character.values.chant ? 1 : 0);
        }
        if (ethnicity=='Armenian') minchaotic+=1;
        let da=deities.split(',');
        if (da.length>1) minchaotic+=1;
        setMinLawful(minlawful);
        setMinChaotic(minchaotic);
        if (lawful<minLawful) lawful=minLawful;
        if (lawful>minlawful+1) lawful=minlawful+1;
        if (chaotic<minchaotic) chaotic=minchaotic;
        if (chaotic>minchaotic+1) chaotic=minChaotic+1;
        character.values.chaotic=chaotic;
        character.values.lawful=lawful;
        setLawful(lawful);
        setChaotic(chaotic);
    }

    function changeEthnicity(value){
        let deitiesAvailable=["Rod,Perun,Devana","Christ,Rod,Perun,Devana","Christ"];
        if (value=='Varangian') deitiesAvailable=['Thor,Odin','Christ,Thor,Odin','Christ'];
        if (value=='Fenno') deitiesAvailable=['Ukko,Tapio','Christ,Ukko,Tapio','Christ'];
        if (value=='Khazar') deitiesAvailable=['Kayra,Tengri','Yahweh,Kayra,Tengri','Yahweh'];
        if (value=='Tatar') deitiesAvailable=['Allah'];
        if (value=='Seljuk') deitiesAvailable=['Allah'];
        if (value=='Armenian') deitiesAvailable=['Aramazd,Mihr'];
        setDeitiesAvailable(deitiesAvailable);
        setDeities(deitiesAvailable[0]);
        testLawChaos(value,deitiesAvailable[0]);
        setEthnicity(value);
    }

    function changeDeities(value){
        testLawChaos(ethnicity,value);
        setDeities(value);
    }


    let deityOptions=deitiesAvailable.map(d => <option key={d} value={d}>{d}</option>);

    function save(){
        alert("Eipäs");
        return;
        Object.assign(character.values,{name,profession,ethnicity,deities,sex,age,lawful,chaotic,selfish,selfless});
        let rowKey=character.rowKey=guid("chr");
        character.player=character.partitionKey=player.rowKey;
        character.username=player.username;
        character.status="Completed";
        spells.forEach(spell => {
            spell.partitionKey=rowKey;
            spell.rowKey=guid('spl');
            if (spell.type=="Rune") spell.location=character.spellContainers[1].rowKey;
            else spell.location=character.spellContainers[0].rowKey;
            delete spell.type;
            character.spells.push({...spell});
        })
        items.forEach(i => {
            i.rowKey="";
            character.items.push(i);
        })
        knownLanguages[character.ethnicity].forEach(l => {character.languages.push({known:true,language:l})});
        console.log("Character",character)
        
        saveCharacter(character).then(character =>{
            console.log("Saved",character);
            navigate("/home/character/"+character.values.rowKey)
        })

    }

    function reputationHandler(item){
        console.log("Item",item)
        if (item=="selfish"){
            selfish=selfish==1 ? 0 : 1;
            character.values.selfish=selfish;
            setSelfish(selfish);
        }
        if (item=="selfless"){
            selfless=selfless==1 ? 0 : 1;
            character.values.selfless=selfless;
            setSelfless(selfless);
        }
        if (item=="lawful"){
            lawful=lawful>=minLawful+1 ? minLawful : minLawful+1;
            character.values.lawful=lawful;
            setLawful(lawful);
        }
        if (item=="chaotic"){
            chaotic=chaotic>=minChaotic+1 ? minChaotic : minChaotic+1;
            character.values.chaotic=chaotic;
            setChaotic(chaotic);
        }
    }

    character.values.chaotic=chaotic;
    character.values.lawful=lawful;
    let saveDisabled=(!name || !profession);
    return <div>
        <h3>Finalize</h3>
        <div className='wizardFinalize'>
            <div className="finalizeForm">
                <div className="formRow">
                    <div className='formField'>
                        <label>Name</label>
                        <input value={name} onChange={ev => setName(ev.target.value)} />
                    </div>
                    <div className='formField'>
                        <label>Age</label>
                        <input type="number" min={17} max={36} value={age} onChange={ev => setAge(Number(ev.target.value))} />
                    </div>
                    <div className='formField'>
                        <label>Sex</label>
                        <select value={sex} onChange={ev => setSex(ev.target.value)}>
                            <option value0="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                    </div>
                </div> 
                <div className="formRow">
                    <div className='formField'>
                        <label>Profession</label>
                        <input value={profession} onChange={ev => setProfession(ev.target.value)} />
                    </div>
                    <div className='formField'>
                        <label>Ethnicity</label>
                        <select value={ethnicity} onChange={ev => changeEthnicity(ev.target.value)}>
                            <option value="Slav">Slav</option>    
                            <option value="Varangian">Varangian</option>    
                            <option value="Fenno">Fenno</option>    
                            <option value="Khazar">Khazar</option>    
                            <option value="Armenian">Armenian</option>    
                            <option value="Tatar">Tatar</option>    
                            <option value="Seljuk">Seljuk</option>    
                        </select>
                    </div>
                    <div className='formField'>
                        <label>Deities</label>
                        <select value={deities} onChange={ev => changeDeities(ev.target.value)}>
                            {deityOptions}
                        </select>
                    </div>
                </div>
                <Reputation character={character} canTest={false} wizardHandler={reputationHandler} />
                <div className='formRow'>
                    <input type="button" value="Save" onClick={save} disabled={saveDisabled} />
                </div>
            </div>
            <div>
                <CharacterWizardHelp search="finalize" />
            </div>
        </div>
    </div>
}


function CharacterWizardHitpoints({character,values,onComplete,onPrevious}){
    const [hp,setHp]=useState(character.Con+6);
    const [rolled,setRolled]=useState(false);

    function completeClicked(){
        onComplete(values);
    }

    let hd=character.hd;
    console.log("Hitpoints",hd,character)

    function rollForHitpoints(){
        let roll=hd+"d6";
        Dice.roll(roll,hd*character.constitution).then(result => {
            let hp=character.constitution+6+result.value;
            character.hitpoints=hp;
            values.push({field:'hitpoints',value:hp});
            setRolled(true);
        })
    }

    return <div>
        <h3>Hitpoints</h3>
        <p>Your constitution: {character.constitution}</p>
        <p>All humans have 1d6+Con hitpoints to start with at zero Hit Dice.</p>
        <p>You being a hero, we grant you six for your first roll,so you'll have {6+character.constitution} hit points</p>
        {hd && !rolled ? <>
            <p>You have developed your Hit Dice to {hd} ranks so you can roll {hd}d6+{hd*character.constitution} for more hitpoints.</p>
            <input type="button" value="Roll for hit points" onClick={rollForHitpoints} />
        </> : <></>}
        {rolled || !hd ? <div>
            <p>Total hitpoints: {character.hitpoints}</p>
            {!hd ? <>
                <p>You have not developed your Hit Dice any ranks. Perhaps you should review your skill development?</p>
                <input type="button" value="Previous" onClick={() => onPrevious(values)} />
            </> : <></>}
            <input type="button" value="Continue" onClick={completeClicked} />
        </div> : <></> }
    </div>
}

export function CharacterWizard({character}){
    const [stage,setStage]=useState("attributes");
    const [values,setValues]=useState([]);
    const [items,setItems]=useState([]);
    const [spells,setSpells]=useState([]);
/*
    useEffect(function(){
        let character=createCharacter({});
        character.strength=-1;
        character.dexterity=2;
        character.constitution=3;
        character.intelligence=3;
        character.wisdom=3;
        character.charisma=4;
        setCharacter(character)
    },[])
*/
    //console.log("Wizard",spellList)

    function changeStage(stage,values,items=null,spells=null){
        console.log("Change stage",stage);
        setStage(stage);
        setValues(values);
        if (items) setItems(items);
        if (spells) setSpells(spells);
    }

    return <div className="characterWizard">
        {
            stage=='attributes' ? <CharacterWizardAttributes character={character} onComplete={values => changeStage('money',values)} /> :
            stage=='money' ? <CharacterWizardMoney character={character} values={values} onComplete={(values,items) => changeStage('items',values,items)} /> :
            stage=='items' ? <CharacterWizardItems character={character} values={values} items={items} spells={spells} onComplete={(values,items,spells) => changeStage('hitpoints',values,items,spells)} /> :
            stage=='hitpoints' ? <CharacterWizardHitpoints character={character} values={values} onComplete={values => changeStage('finalize',values)} onPrevious={(values)=>changeStage('items',values)} /> :
            <CharacterWizardFinalize character={character} values={values} items={items} spells={spells} onPrevious={values => changeStage('hitpoints',values)} />
         }
    </div>
}

export function CharacterWizardRoute(){
/*
    useEffect(function(){
        setTheme("scribe");
    },[])
*/
    return <>
        <CharacterWizard character={createCharacter({values:{}})} />
    </>
}