import React, { useEffect } from 'react';

export function BonusSelector({value,onChange}){

    useEffect(function(){
        if ((value!==0 && value<1) || value>6) onChange(0); 
    },[])

    return <div className="bonusSelector">
        <label>Bonus</label>
        <p className="bonusValues">
            <span className={'bonusValue clickable'+(value==0 ? ' bonusValueSelected' : '')} onClick={()=>onChange(0)}>0</span>
            <span className={'bonusValue clickable'+(value==1 ? ' bonusValueSelected' : '')} onClick={()=>onChange(1)}>+1</span>
            <span className={'bonusValue clickable'+(value==2 ? ' bonusValueSelected' : '')} onClick={()=>onChange(2)}>+2</span>
            <span className={'bonusValue clickable'+(value==3 ? ' bonusValueSelected' : '')} onClick={()=>onChange(3)}>+3</span>
            <span className={'bonusValue clickable'+(value==4 ? ' bonusValueSelected' : '')} onClick={()=>onChange(4)}>+4</span>
        </p>

    </div>
}