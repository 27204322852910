import React, { useEffect, useState } from 'react';

import './optionscontainer.scss'
import { MapOptions } from './mapoptions';
import { AssetOptions } from './assets/assetoptions';
import { useSelector } from 'react-redux';
import { LineOptions } from './lines/lineoptions';
import { SegmentOptions } from './lines/segmentoptions';
import { OpeningOptions } from './lines/openingoptions';
import { PointOptions } from './points/pointoptions';
import { setClicked, setClicks } from './utils/mapstore';
import { MapLayers } from './layers';


export function OptionsContainer({map,onChange,onSave}){
    let [navi,setNavi]=useState("map");
    let {clicks,clicked,current}=useSelector(state => state.maps);
    let {asset,line,point,segment,opening}=clicked;

    console.log("OptionsContainer",clicked)

    useEffect(function(){
        navi="map";
        if (clicks.length==1) navi="asset";
        if (clicks.length>=2) navi="line";
        if (asset) navi="asset";
        else if (opening) navi="opening"
        else if (line) navi="line";
        else if (segment) navi="segment";
        //console.log("Testing navi",navi)
        setNavi(navi);
    },[clicks,clicked,current])

    return <div className='optionsContainer'>
        <div className="optionsNavi">
            <a onClick={() => setNavi("map")}>Map</a>
            <a onClick={() => setNavi("layers")}>Layers</a>
            {(asset || clicks.length==1) && !segment ? <a onClick={() => setNavi("asset")}>Asset</a> : <></>}
            {line || clicks.length>1 ? <a onClick={() => setNavi("line")}>Line</a> : <></>}
            {segment && !opening ? <a onClick={() => setNavi("segment")}>Segment</a> : <></>}
            {opening ? <a onClick={() => setNavi("opening")}>Opening</a> : <></>}
        </div>
        <div>
            {navi=="map" ? <MapOptions key={map.options.rowKey} map={map} onSave={onSave}/>: <></>}
            {navi=="layers" ? <MapLayers map={map} /> : <></>}
            {navi=="point" ? <PointOptions map={map} /> : <></> }
            {navi=="asset" ? <AssetOptions map={map} />  : <></>}
            {navi=="line" ? <LineOptions map={map} /> : <></>}
            {navi=="segment" ? <SegmentOptions map={map} /> : <></>}
            {navi=="opening" ? <OpeningOptions map={map} /> : <></>}
        </div>
    </div>
}