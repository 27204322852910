import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { PlayerRolls } from '../../utils/playerrolls';
import { CharacterChat } from '../../character/characterchat';

import './realmmaster.scss'
import { Tile } from '../../utils/tile';
import { Link } from 'react-router-dom';
import { CampaignProperties } from './campaignproperties';


function RealmMasterNavigation(){

    return <Tile header="Navigation">
        <p>Import character to campaign</p>
        <p>Let player play another player's character</p>
        <p><Link to="/campaign/npcs">Manage NPCs</Link></p>
        <p><Link to="/campaign/maps">Maps and encounters</Link></p>
        <p><Link to="/campaign/images">Images</Link></p>
    </Tile>
}

function RealmMasterTools({character}){
    return <div className='realmMasterTools'>
        <CampaignProperties />
        <PlayerRolls />
        <RealmMasterNavigation />
    </div>
}

function RealmMasterView(){
    let {campaign}=useSelector(state => state.game);

    let name="Realm Master "+campaign.owner.username;
    let char={values:{name,rowKey:campaign.owner.rowKey,partitionKey:campaign.owner.rowKey,
        username:campaign.owner.username+", your almighty Realm Master"}};
    return <div className="realmMasterForPlayers">
        <CharacterChat character={char} targetGuid={campaign.owner.rowKey} />
    </div>
}

export function RealmMasterDetails(){
    let {role}=useSelector(state => state.game);

    return <div className="campaignContent subNavContent">
        {role=="Master" ? <RealmMasterTools /> : <RealmMasterView /> }
    </div>
}

