import React, { useEffect, useState } from 'react';
import { assets } from '../utils/images';
import { Checkbox } from '../../utils/formfields';

import './assetoptions.scss'
import { useSelector } from 'react-redux';
import { saveMap, setClicked, setMapAsset, setMapData } from '../utils/mapstore';
import { guid } from '../../utils/utils';
import { HTTP } from '../../utils/http';
import { useNavigate } from 'react-router-dom';
import { ColorSelector, defaultColors } from '../utils/colorselector';
import { sendToCampaign } from '../../utils/websocket';

export function AssetButton({asset, selected, onClick}) {
    let w=32;
    if (asset.sizeX==5) w=16;
    let h=w;
    if (asset.sizeY==5) h=16;
    let styles='imageButton';
    if (w==16) styles+='small';
    else if (h==16) styles+='wide';
    if (selected?.name==asset.name) styles+=' selectedAsset';
    return <button onClick={() => onClick(asset)} className='imageButton'>
        <img src={`/assets/${asset.type}/${asset.name}.svg`} width={w} height={h} /></button>
}

export function AssetGroup({title,type,selected,onClick}){
    let assetButtons=assets.filter(a=>a.type==type).map(a => <AssetButton key={a.rowKey} asset={a} selected={selected} onClick={onClick} />);
    
    return <div className="assetGroup">
        <h4>{title}</h4>
        <div className="assetGroupList">
            {assetButtons}
        </div>
    </div>
}

/*
export function AssetProperties({asset,onChange}){

    function changeRotation(ev){
        asset.rotation=Number(ev.target.value);
        onChange(asset);
    }

    return <div className="assetProperties">                    
        <h4>Decoration</h4>
        <div>
            <label>Rotate</label> 
            <input type='number' value={asset.rotation || 0} onChange={changeRotation} />
        </div>
        <input type="button" value="Delete" />
    </div>
}
*/

const defaultAssetObject={
    itemType:'asset',
    type:'master',
    name:'undefined',
    sizeX:10,
    rotation:0,
    scale:1,
    label:'',
    labelPosition:0,
    labelRotation:0,
    font:'Lora',
    fontSize:10,
    textColor:defaultColors.pen,
    visible:false,
    target:'',
    room:"",
    layer:5,
    note:'',
}

export function AssetOptions({map}){
    let {maps,clicked,clicks,mapOptions}=useSelector(state => state.maps);
    let {share,role}=useSelector(state => state.game);
    let [object,setObject]=useState(clicked.asset ? {...clicked.asset} : defaultAssetObject);
    let point=clicked.asset?.point || {...clicked.point};
    let [showAssetTypes,setShowAssetTypes]=useState(!clicked.asset)
    let [encounters,setEncounters]=useState([]);
    let [encounterTarget,setEncounterTarget]=useState("");
    const navigate=useNavigate();

    console.log("Asset options",clicked,clicks,point)

    useEffect(function(){
        map.drawLatest({...object,point});
        setShowAssetTypes(!clicked.asset)
        HTTP.get("/api/encounters").then(enc => setEncounters(enc));
    },[])

    useEffect(function(){
        setObject(clicked.asset ? {...clicked.asset} : defaultAssetObject)
    },[clicked])


    function changeProperty(property,value,isNumber=false){
        console.log("Property",property,value)
        if (isNumber) value=Number(value);
        object[property]=value;
        setObject({...object});
        map.drawLatest({...object,point});
    }

    function changeAssetType(asset){
        Object.assign(object,asset);
        object.visible=true;
        if (asset.name=="skull") object.visible=false;
        if (asset.name=="undefined") object.visible=false;
        if (!asset.sizeY) delete object.sizeY;
        setShowAssetTypes(false);
        setObject({...object});
        map.drawLatest({...object,point});
    }

    function changeFont(font){
        changeProperty('font',font);
    }

    function save(){
        let room=clicked.line;
        if (!clicked.asset) {
            map.addAsset({...object,point},room)
        }
        else {
            let existing=map.assets.find(a => a.rowKey==clicked.asset.rowKey);
            Object.assign(existing,object);
        }
        if (role=="Master" && share && share.itemRowKey==map.options.rowKey){ 
            console.log("Send map",map);
            sendToCampaign("setMapData",map);
        }
        setMapData(map);
    }

    function deleteAsset(){
        let index=map.assets.findIndex(a => a.rowKey==clicked.asset.rowKey);
        if (index>=0){
            map.assets.splice(index,1);
            setClicked();
            setMapData(map);
        }
    }


    let targetOptions=maps.map(m => <option key={m.rowKey} value={m.rowKey}>{m.name}</option>);
    let encounterOptions=encounters.map(e => <option key={e.rowKey} value={e.rowKey}>{e.name}</option>)

    function createEncounter(){
        let {rowKey,mapUnits}=mapOptions;
        let {line}=clicked;
        let encounter={name:''};
        if (mapUnits=="feet"){
            encounter.mapRowKey=rowKey;
            encounter.roomRowKey=line?.rowKey || "";
        }
        HTTP.post("/api/encounters",encounter).then(enc => {
            object.target=enc.rowKey;
            goToTarget();
        })
        console.log("Encounter",encounter)
    }

    function goToTarget(){
        let room=clicked.line;
        if (!clicked.asset) {
            map.addAsset({...object,point},room)
        }
        else {
            let existing=map.assets.find(a => a.rowKey==clicked.asset.rowKey);
            Object.assign(existing,object);
        }
        map.selectedRoom=clicked.line.rowKey;
        saveMap(map).then(map => {
            navigate("/campaign/encounter/"+object.target);
        })
    }

    return <div className='assetOptions'>
        <div className="selectedAssetType">
            <AssetButton asset={object} selected={object} onClick={() => setShowAssetTypes(!showAssetTypes)} />
            {object.name=="skull" && !object.target.startsWith("enc") ? <input type="button" value="Create encounter" onClick={createEncounter} /> : <></>}
        </div>
        {showAssetTypes ? <>
            <AssetGroup title="Realm Master" selected={object} type="master" onClick={changeAssetType} />
            <AssetGroup title="Houses" selected={object} type="houses" onClick={changeAssetType} />
            <AssetGroup title="Indoor" selected={object} type="indoor" onClick={changeAssetType} />
            <AssetGroup title="Outdoor" selected={object} type="outdoor" onClick={changeAssetType} /> 
            <AssetGroup title="Animals" selected={object} type="animals" onClick={changeAssetType} /> 
        </> : <></> }
        <div>
            <Checkbox  label="Visible to players" boxPosition='right' checked={object.visible} onChange={v => changeProperty("visible",v)} />
        </div>
        <div className='inputRow'>
            <label>Click target</label>
            <select value={object.target} onChange={ev => changeProperty('target',ev.target.value)}>
                <option value="">No click target</option>
                {object.name=="skull" ? encounterOptions : targetOptions}
            </select>
            {object.target ? <input type="button" value="Go" onClick={goToTarget} /> : <></> }
        </div>
        <div className="inputRow">
            <label>Scale {object.scale}</label>
            <input type="range" value={object.scale} min={0.2} max={4} step={0.1} onChange={ev => changeProperty('scale',ev.target.value,true)} />
        </div>
        {object.name!="text" ? <div className='inputRow'>
            <label>Rotation {object.rotation}</label>
            <input type="range" value={object.rotation} min={0} max={360} step={1} onChange={ev => changeProperty('rotation',ev.target.value,true)} />
        </div> : <></> }
        <div className="inputRow">
            <label>Label</label>
            <input value={object.label} onChange={ev => changeProperty('label',ev.target.value)} />
        </div>
        {object.label ? <>
            <div>
                <ColorSelector label="Text color" value={object.textColor} onChange={color => changeProperty("textColor",color)} />
            </div>
            {object.name!="text" ? <div className="inputRow">
                <label>Label position</label>
                <input type="range" value={object.labelPosition} min={0} max={8} onChange={ev => changeProperty('labelPosition',ev.target.value,true)} />
            </div> : <></> }
            <div className="inputRow">
                <label>Label rotation {object.labelRotaion}</label>
                <input type="range" value={object.labelRotation} min={0} max={360} onChange={ev=>changeProperty('labelRotation',ev.target.value,true)} />
            </div>
            <div className="inputRow">
                <label>Font size {object.fontSize}</label>
                <input type="range" value={object.fontSize} min={6} max={48} step={1} onChange={ev => changeProperty('fontSize',ev.target.value,true)} />
            </div>
            <div>
                <Checkbox label={object.label} checked={object.font=='Lora'} onChange={v => v ? changeFont('Lora') : 0} styles='sampleLora' />
                <Checkbox label={object.label} checked={object.font=='Roboto Slab'} onChange={v => v ? changeFont('Roboto Slab') : 0} styles='sampleRobotoSlab' />
                <Checkbox label={object.label} checked={object.font=='Quintessential'} onChange={v => v ? changeFont('Quintessential') : 0} styles='sampleQuintessential' />
                <Checkbox label={object.label} checked={object.font=='Calligraffitti'} onChange={v => v ? changeFont('Calligraffitti') : 0} styles='sampleCalligraffitti' />
                <Checkbox label={object.label} checked={object.font=='Rock Salt'} onChange={v => v ? changeFont('Rock Salt') : 0} styles='sampleRockSalt' />
                <Checkbox label={object.label} checked={object.font=='Imperial Script'} onChange={v => v ? changeFont('Imperial Script') : 0} styles='sampleImperialScript' />
                <Checkbox label={object.label} checked={object.font=='UnifrakturMaguntia'} onChange={v => v ? changeFont('UnifrakturMaguntia') : 0} styles="sampleUnifrakturMaguntia" />
                <Checkbox label={object.label} checked={object.font=='UnifrakturCook'} onChange={v => v ? changeFont('UnifrakturCook') : 0} styles="sampleUnifrakturCook" />
            </div>
        </> : <></> }
        <div className="inputRow">
            <label>Note</label>
            <textarea value={object.note} onChange={ev => changeProperty('note',ev.target.value)} />
        </div>
        <div className='inputRow'>
            <label>Layer {object.layer}</label>
            <input type="range" min={1} max={9} value={object.layer} onChange={ev => changeProperty('layer',ev.target.value,true) } />
        </div>
        <div>
            <input type="button" value="Save" onClick={save} />
            {clicked.asset ? <input type="button" value="Delete" onClick={deleteAsset} /> : <></> }
        </div>
    </div>
}