import React from 'react';
import './icons.scss';

export function Icon({type,borders,onClick,tip,style}){
    if (type==Icon.none) return <></>;
    let styles=type;
    if (style) styles+=" "+style;
    if (onClick) styles+=" clickable";
    let def=() => 0;
    let clk=onClick || def;
    return <span className={styles} title={tip} onClick={clk}> </span>
}

export function IconText({type,onClick,text}){
    let style='app-icon-text';
    if (onClick) style+=" clickable";
    else onClick=() => 0
    return <div className={style} onClick={onClick}>
        <Icon type={type} />
        <span>{text}</span>
    </div>
}

Icon.none='app-icon-none';
Icon.empty='app-bi-icon app-icon-empty';
Icon.dimmed=' app-icon-dimmed';
Icon.edit="app-bi-icon app-icon-edit";
Icon.save="app-bi-icon app-icon-save";
Icon.cancel="app-bi-icon app-icon-cancel";
Icon.accept="app-bi-icon app-icon-accept";
Icon.plus="app-bi-icon app-icon-plus";
Icon.minus="app-bi-icon app-icon-minus";
Icon.delete="app-bi-icon app-icon-delete";
Icon.editPen="app-icon-editPen";
Icon.chat="app-bi-icon app-icon-chat";
Icon.menu="app-bi-icon app-icon-menu";
Icon.home="app-bi-icon app-icon-home";
Icon.search="app-bi-icon app-icon-search";
Icon.joinMeeting="app-bi-icon app-icon-join-meeting";
Icon.leaveMeeting="app-bi-icon app-icon-leave-meeting";
Icon.mikeActive="app-bi-icon app-icon-mike-active";
Icon.mikeInactive="app-bi-icon app-icon-mike-inactive";
Icon.audioOutput="app-bi-icon app-icon-audio-output";
Icon.camera="app-bi-icon app-icon-camera";
Icon.circleChecked="app-bi-icon app-icon-circleChecked";
Icon.circleUnchecked="app-bi-icon app-icon-circleUnchecked";
Icon.boxChecked="app-bi-icon app-icon-boxChecked";
Icon.boxbUnchecked="app-bi-icon app-icon-boxUnchecked";
Icon.properties="app-bi-icon app-icon-properties";
Icon.close="app-bi-icon app-icon-close";

