import React, { useEffect, useRef, useState } from 'react';
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";


import './imageeditor.scss';
import { Page } from '../layout/page';
import { HTTP } from './http';
import { getImageUrl } from './utils';

export function InputImage({imageData,aspect=InputImage.square,onChange,setName}){
    const cropperRef = useRef(null);
    const imageRef=useRef(null);
    const maxWidth=640;
    //dth=320;
    const [wait,setWait]=React.useState(true);
    const [done,setDone]=useState(false);

    let width=320;
    let height=width;
    if (aspect==InputImage.wide){
        height=width*9/16;
    }
    if (aspect==InputImage.flat){
        height=width*3/16;
    }
    if (aspect==InputImage.narrow){
        height=320;
        width=height*9/16;
    }

    useEffect(function(){
        if (imageData) cropperRef.current.cropper.replace(imageData);
        setWait(false);
    },[imageData]);

    useEffect(function(){
        let cropper=cropperRef.current?.cropper;
        if (cropper){
            console.log("aspect",width,height)
            cropper.setAspectRatio(aspect==InputImage.free ? null : width/height);
            cropper.crop();
        }
    },[aspect])

    function preventDefault(ev){
        ev.preventDefault();
        ev.stopPropagation();
    }

    function dropImage(ev){
        ev.preventDefault();
        ev.stopPropagation();
        if (ev.dataTransfer.files.length>0){
            let file=ev.dataTransfer.files.item(0);
            console.log("File",file);
            setName(file.name)
            let fileReader = new FileReader(); 
            setWait(true);
            fileReader.onloadend = function(e) { 
                const cropper=cropperRef.current?.cropper;
                cropper.replace(fileReader.result)
                let obj=cropper.getImageData();
                console.log("Dropped",obj);
                setWait(false);
                setDone(true);
            }; 
            fileReader.onerror=function(er){
                console.log("Filereader error",er);
                setWait(false);
            }
            fileReader.readAsDataURL(file); 
        }
    }      

    function imageReady(){
        const cropper=cropperRef.current?.cropper;
        let obj=cropper.getImageData();
        if (obj.naturalWidth>maxWidth){
            let scaleX=maxWidth/obj.naturalWidth;
            cropper.scale(scaleX);
            cropper.crop();
        }
        console.log("Image ready",obj);
    }

    function onCrop() {
        const cropper = cropperRef.current?.cropper;
        let data=cropper.getCroppedCanvas().toDataURL();
        if (imageRef.current) imageRef.current.src=data;
        onChange(data);
      }
  
    return <div className="app-image-editor">
        <div className="app-image-holder" onDragEnter={preventDefault} onDragOver={preventDefault} onDrop={dropImage}>
            <img className={aspect} ref={imageRef} />
            {wait ? <div><p>Wait....</p></div> : !done ? <div><p>Drop image here</p></div> : <></> }
        </div>
        <div className="app-cropper-holder">
            <Cropper
                style={{ height: '13rem', width: '13rem' }}
                // Cropper.js options
                aspectRatio={aspect==InputImage.free ? null : width/height}
                guides={false}
                minCropBoxWidth={64}
                autoCropArea={1}
                viewMode={1}
                crop={onCrop}
                ready={imageReady}
                ref={cropperRef}
            /> 
        </div>
    </div>
}
InputImage.square="app-img-square";
InputImage.wide="app-img-wide";
InputImage.flat="app-img-flat";
InputImage.narrow="app-img-narrow";
InputImage.free="app-img-free";

export function ImageSelector({path,onClose}){
    let [imageData,setImageData]=useState(null);
    let [name,setName]=useState("");
    let [aspect,setAspect]=useState(InputImage.square);
    let [files,setFiles]=useState([]);

    useEffect(function(){
        console.log("Path",path)
        HTTP.get("/api/images/"+path).then(files => setFiles(files));
    },[]);

    function onChange(data){
        console.log("Changed")
        setImageData(data);
    }

    function save(){
        let obj={name,data:imageData};
        HTTP.post("/api/images/"+path,obj).then(files => {
            setFiles(files);
            onClose(name);
        });
    }

    let allowedFormats=['.png','jpg','jpeg','.gif','.svg'];
    files=files.filter(f => allowedFormats.some(a => f.toLowerCase().endsWith(a)));
    //files=files.filter(f => f.toLowerCase().endsWith(".png") || f.toLowerCase().endsWith(".jpg") || f.toLowerCase().endsWith(".jpeg"))

    let fileRows=files.map(f => {
        return <p onClick={() => onClose(f)} style={{backgroundImage:'url('+getImageUrl(path,f)+')'}} className="imageEditorFile" key={f}><span>{f}</span></p>
//        return <p onClick={() => onClose(f)} style={{backgroundImage:'url(/api/images/'+path+"/"+f+')'}} className="imageEditorFile" key={f}><span>{f}</span></p>
    });

    return <div className="imageSelectorBackground">
        <div className="imageSelector">
            {files.length && !imageData ? <>
                <h4>Select an existing image</h4>
                <div className='imageSelectorFileList'>
                    {fileRows}
                </div></>:<></>}
            <h4>Upload a new image</h4>
            <div className="imageSelectorUpload">
                <InputImage onChange={onChange} aspect={aspect} setName={setName} />
                <div className="imageEditorOptions">
                    {imageData ? <div>
                        <input placeholder="Filename" value={name} onChange={ev => setName(ev.target.value)} />
                        <select value={aspect} onChange={ev => setAspect(ev.target.value)}>
                            <option value={InputImage.square}>Square</option>
                            <option value={InputImage.wide}>Wide</option>
                            <option value={InputImage.narrow}>Narrow</option>
                            <option value={InputImage.free}>Free</option>
                        </select>
                    </div> : <></> }
                    <div>
                        {imageData ? <input type="button" disabled={!imageData || !name} value="Save" onClick={() => save()} /> : <> </>}
                        <input type="button" value="Cancel" onClick={() => onClose()} />
                    </div>
                    <pre>Image size (bytes) {imageData?.length}</pre>
                </div>
            </div> 
        </div>

    </div>
}

export function ImagePage({path="cmp12971714490806952"}){
    let [showPopup,setShowPopup]=useState(false);

    function selected(fn){
        console.log("Selected",fn);
        setShowPopup(false);
    }

    return <Page them="campaign">
        <input type="button" value="Select" onClick={() => setShowPopup(true)} />
        {showPopup ? <ImageSelector path="usr92771714490129495" onClose={selected} /> : <></>}
    </Page>
}