import React, { useEffect, useState } from 'react';
import { CharacterSheetCombat } from './combat/charactercombat';
import { CharacterItems } from './items/characteritems';

import './charactersheet.scss';
import { HTTP } from '../utils/http';
import { CharacterSheetSpells, CharacterSpells } from './characterspells';
import { CharacterNotesAndLanguages } from './characternotes';
import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createCharacter } from './character';
import { CharacterBasics } from './characterbasics';
import { guid } from '../utils/utils';
import { setCurrentCharacter, setCurrentCharacterValues } from '../utils/store';

export const characterSheetChildren=[
    {index:true, element:<CharacterBasics />},
    {path:'combat',element:<CharacterSheetCombat />},
    {path:'items',element:<CharacterItems />},
    {path:'spells',element:<CharacterSheetSpells />},
    {path:'notes',element:<CharacterNotesAndLanguages />},
];

export function CharacterSheet(){

    const [upd,setUpd]=useState(new Date());
    const navigate=useNavigate();
    //let [character,setCharacter]=useState(null);
    const {campaign,player,role,currentCharacter:character}=useSelector(state => state.game);
    const params=useParams();

    useEffect(function(){
        if (!params.rowKey){
            let {characters}=campaign;
            let character=characters.find(c => (c.values.rowKey || 0)==params.rowKey);
            character=createCharacter(character || {});
            //setCharacter(character);
            setCurrentCharacter(character);
            console.log("Campaign character",character,characters);
        }
        else {
            HTTP.get("/api/characters/"+params.rowKey).then(char => {
                char=createCharacter(char);
                //setCharacter(character);
                setCurrentCharacter(char);
                console.log("Player character",char)
            })
        }
    
    },[params]);

    const forceUpdate=() => setUpd(new Date());
    console.log("Sheet char",character)

    let isOwn=character?.values.partitionKey==player.rowKey;
    let rk=character?.values.rowKey || guid('xxx') ;

    return <div key={rk} className='characterSheet'>
        {character ? <>
            <div className="characterSheetNavi">
                <h2>{character.name}</h2>
                {isOwn || role=="Master" ? <>
                    <a onClick={() => navigate("./")}>Basics</a>
                   {/* <a onClick={() => navigate("./combat")}>Combat</a> */}
                    <a onClick={() => navigate("./items")}>Items</a>
                    <a onClick={() => navigate("./spells")}>Spells</a>
                    <a onClick={() => navigate("./notes")}>Notes</a> 
                </>: <></> }
            </div>
            <Outlet context={{character:createCharacter(character),forceUpdate}} />
        </> : <></> }
    </div>
}

/*

        {
            current=='skills' ? <Skills character={character} /> :
            current=='combat' ? <CharacterCombat character={character} /> :
            current=='spells' ? <CharacterSpells character={character} /> :
            current=='notes' ? <CharacterNotesAndLanguages character={character} /> :
            current=='items' ? <CharacterItems character={character} /> : <div className="characterSheetBasics">
                 <CharacterBasics character={character} canEdit={true} />
                 <Skills character={character} />
            </div>
        }
        

*/