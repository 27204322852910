import React from 'react';
import { sendToCampaign, sendToCharacter } from '../utils/websocket';

const peers={};

const iceServers = [
    { urls: "stun:stun.l.google.com:19302" },
    { urls: "stun:stun.l.google.com:5349" },
    { urls: "stun:stun1.l.google.com:3478" },
    { urls: "stun:stun1.l.google.com:5349" },
    { urls: "stun:stun2.l.google.com:19302" },
    { urls: "stun:stun2.l.google.com:5349" },
    { urls: "stun:stun3.l.google.com:3478" },
    { urls: "stun:stun3.l.google.com:5349" },
    { urls: "stun:stun4.l.google.com:19302" },
    { urls: "stun:stun4.l.google.com:5349" }
];
const servers = {iceServers}
const pcConstraints = {
    'optional': [
        {'DtlsSrtpKeyAgreement': true},
    ],
};


let userId="Eka";
let channelName="Campaign";
let initialized=false;
let localStream=null;



function logPeers(title=""){
   console.log("PEERS: "+title);
    for(let k in peers){
       console.log(k,peers[k].status)
    }
}

export function processRtcSocketMessage(message){
    let {command,data}=message;
    if (data.from==userId) return;
    if (!initialized){
       console.log("Got message, but not init",message.command,message.data.from);
        return ;
    }
    if (data.from && !peers[data.from]){
        let connection=new RTCPeerConnection(servers,pcConstraints);
        peers[data.from]={name:data.from,connection,status:"uninitialized"};
        console.log("created peer connection")
    }
    if (command=="rtc_join"){
       console.log(data.from,"joined,sending offer")
        sendOffer(data);
        //document.getElementById("offerButton").disabled=false;
    }
    if (command=="rtc_offer"){
        processOffer(data);
    }
    if (command=="rtc_answer"){
        offerAnswered(data);
    }
    if (command="rtc_candidate"){
        let connection=peers[data.from].connection;
        peers[data.from].status="ice";
        //if (data.candidate){
            connection.addIceCandidate(data.candidate).then(
                () =>console.log("ice ok"),
                err =>console.log("ice err, perhaps null")
            );
        //}
    }

}

export function joinMeeting(n){
    console.log("Join as",n)
    //const localPlayer = document.getElementById("localPlayer");
    const localPlayer=document.createElement("video");
    localPlayer.muted=true;
    localPlayer.controls=true;
    localPlayer.autoplay=true;
    localPlayer.id="localPlayer";
    userId=n;
    console.log("User",userId)
    navigator.getUserMedia({audio: true, video: true}, (stream) => {
        console.log('Received local stream');
        localStream=stream;
        localPlayer.srcObject=stream;
        document.getElementById("videocontainer").appendChild(localPlayer)
        const audioTracks = localStream.getAudioTracks();
        if (audioTracks.length > 0) {
            console.log('Using Audio device:',audioTracks[0]);
            for(let i=0;i<audioTracks.length;i++){
                console.log("Available:",audioTracks[i].label);
            }
        }
        initialized=true;
        sendToCampaign("rtc_join",{from:n,channelName});
    }, (error) => {
       console.log('getUserMedia error:', error);
    });
}        

setInterval(() => {
    for(let name in peers){
        let active=peers[name].remotePlayer?.srcObject?.active;
        console.log("isActive",name,active)
        if (peers[name].sendChannel){
            console.log("Sending to sendChannel");
            peers[name].sendChannel.send("Hello,channel")
        }
    }
}, 5000);

function sendOffer(data){
    //let {userId,channelName}=data;
    console.log("Sending offer",data)
    let connection=new RTCPeerConnection(servers,pcConstraints);
    localStream.getTracks().forEach(track => connection.addTrack(track, localStream));
    
    let remotePlayer=document.createElement("video");
    remotePlayer.muted=false;
    remotePlayer.controls=true;
    remotePlayer.autoplay=true;
    remotePlayer.addEventListener("error",ev => {
        console.log("Videoplayer error",ev)
    })
    remotePlayer.addEventListener("ended",ev => {
        console.log("Videoplayer ended",ev)
    })
    remotePlayer.addEventListener("emptied",ev => {
        console.log("Videoplayer emptied",ev)
    })
    //document.getElementById("videocontainer").appendChild(remotePlayer);
    
    //let remotePlayer=document.getElementById("remotePlayer");
    peers[data.from]={name:data.from,connection,remotePlayer,status:"offer"};
    console.log("created peer for offer",peers,data)
    connection.onicecandidate=ev => sendToUser(data.from,"rtc_candidate",{candidate:ev.candidate});
    connection.ontrack=ev => {
       console.log("Remote track",ev)
        let remotePlayer=peers[data.from].remotePlayer;
        if (remotePlayer.srcObject !== ev.streams[0]) {
            //if (!remotePlayer) remotePlayer=document.createElement("video");
            //remotePlayer.muted=false;
            //remotePlayer.controls=true;
            //remotePlayer.autoplay=true;
            remotePlayer.srcObject = ev.streams[0];
            document.getElementById("videocontainer").appendChild(remotePlayer);
            peers[data.from].status="streaming";
            ev.streams[0].addEventListener("inactive",ev => {
                console.log("Stream became inactive",ev);
            })
            ev.streams[0].addEventListener("active",ev => {
                console.log("Stream became active",ev);
            })
            console.log('Received remote stream');
        }        
    }
    connection.onaddstream=ev => {
       console.log("Local got stream",ev);
    }
    const offerOptions = {
        offerToReceiveAudio: 1,
        offerToReceiveVideo: 1,
        voiceActivityDetection: true
    };
    createDataChannel(peers[data.from]);
    connection.createOffer(offerOptions).then(desc => {
        console.log("Offer created");
        connection.setLocalDescription(desc).then(() => {
            sendToCharacter(data.from,"rtc_offer",{from:userId,sdp:desc});
            logPeers("SENT offer");
        });            
    });
};


function processOffer(offer){
    if (!peers[offer.from]) peers[offer.from]={status:"new"};
    if (!peers[offer.from].connection) peers[offer.from].connection=new RTCPeerConnection(servers,pcConstraints);
    let connection=peers[offer.from].connection;
    peers[offer.from].status="offered";
    peers[offer.from].name=offer.from;
    logPeers("Process offer");
    
    let remotePlayer=document.createElement("video");
    remotePlayer.muted=false;
    remotePlayer.controls=true;
    remotePlayer.autoplay=true;
    //document.getElementById("videocontainer").appendChild(remotePlayer);
    
    //let remotePlayer=document.getElementById("remotePlayer");
    peers[offer.from].remotePlayer=remotePlayer;

    connection.onicecandidate=ev => sendToUser(offer.from,"rtc_candidate",{candidate:ev.candidate});
    connection.ontrack=ev => {
        console.log("Remote track",ev)
        let remotePlayer=peers[offer.from].remotePlayer;
        if (remotePlayer.srcObject !== ev.streams[0]) {
            //if (!remotePlayer) remotePlayer=document.createElement("video");
            //remotePlayer.muted=false;
            //remotePlayer.controls=true;
            //remotePlayer.autoplay=true;
            remotePlayer.srcObject = ev.streams[0];
            document.getElementById("videocontainer").appendChild(remotePlayer);
            //peers[offer.from].remotePlayer=remotePlayer;
            console.log('Received remote stream');
        }        
    }
    localStream.getTracks().forEach(track => connection.addTrack(track, localStream));
    console.log('Add stream to localConnection');
    connection.addStream(localStream);

    console.log("Set remoteDescription for local connection",offer)
    createDataChannel(peers[offer.from]);
    connection.setRemoteDescription(offer.sdp).then(() => {
        connection.createAnswer().then(answer => {
            console.log('Local connection created answer, set localDescription', answer);
            connection.setLocalDescription(answer);
            sendToUser(offer.from,'rtc_answer', {from:userId,sdp:answer});
            peers[offer.from].status="answered";

        });
    })
}

function offerAnswered(answer) {
    let connection=peers[answer.from].connection;
    console.log('offer answered', answer);
    console.log("Peers",peers);
    if (!answer.sdp){
       console.log("No sdp, rejected");
        return;
    }
    //answer.sdp.sdp+="\n";
    connection.setRemoteDescription(answer.sdp).then(xxx => peers[answer.from].status="connected");
}

function createDataChannel(peer) {
    let connection=peer.connection;
    try {
        console.log('createDataChannel');
        let sendChannel=connection.createDataChannel('sendDataChannel', {reliable: true});
        sendChannel.onopen = () => {
            console.log("sendChannel open")
            peer.sendChannel=sendChannel;
        };
        sendChannel.onclose=() => {
            console.log("Send channel close")
            peer.sendChannel=null;
        };
    } catch (error) {
        console.log('createDataChannel failed', error);
    }

    connection.ondatachannel = (event) => {
        console.log('gotReceiveChannel');
        receiveChannel = event.channel;
        receiveChannel.onmessage = ev => {
            console.log('gotMessage', ev.data);
        }
        receiveChannel.onopen = () => console.log("Receivechannel state",receiveChannel.readyState);
        receiveChannel.onclose = () => console.log("Receivechannel state",receiveChannel.readyState);
    };
    
}
