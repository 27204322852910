import React from 'react';

import './formfields.scss';
import { Icon } from './icons';

export function TextInput({value,type="text",onChange,placeholder="",editable=true,onEnter=null,width=0}){
    let classes="textInput";
    if (editable) classes+=" textInputEditing";

    let len=value.length;
    if (!len) len=placeholder.length;
    if (!len) len=8;
    len+=2;
    if (width) len=width;

    let widthObject={width:len+"ex"};
    let cls="customField textInput";
    if (editable) cls+=" editable";

    function onKeyDown(ev){
        console.log("Event",ev.key,ev)
        if (ev.key=="Enter" && onEnter) onEnter(); 
    }

    function onValueChange(ev){
        if (type=="number" && !Number(ev.target.value)) return;
        onChange(ev)
    }

    return <>{editable ? <input className={cls} onKeyDown={onKeyDown} style={widthObject} placeholder={placeholder} disabled={!editable} value={value} onChange={onValueChange} />
        : <span className={cls}>{value}</span> }</>
    //return <span className={classes}  onBlur={onInput} onChange={onChange ?? (() => 0)} contentEditable={editable}>{v}</span>
    /*
    return <>
    {editable ? <input className="textInput" value={value} onChange={onChange} disabled={!editable} /> : <span className="textInput">{value}</span>}
    </>
    */
}

export function Checkbox({label,type="box", boxPosition="left", checked,onChange,editable=true, styles=""}){
 
    if (!onChange) editable=false;
    let cls="checkbox";
    if (checked) cls+=" checkboxChecked";
    if (editable) cls+=" clickable";
    let iconType=(checked ? Icon.boxChecked : Icon.boxbUnchecked);
    if (type=="circle") iconType=(checked ? Icon.circleChecked : Icon.circleUnchecked);
    if (styles) cls+=" "+styles;
    
    function clicked(){
        if (onChange && editable) onChange(!checked);
    }

    return <>{
        boxPosition=="left" ? <p className={cls} onClick={clicked}><Icon type={iconType} /><span>{label}</span></p> :   
        <p className={cls} onClick={clicked}><span>{label}</span><Icon type={iconType} /></p>}
    </>   
}