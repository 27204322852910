import React, { useState } from 'react';

import './charactercombat.scss';
import { Skill } from '../skills/skills';
import { Ranks } from '../../utils/ranks';
import { Dice } from '../../dice/dicebox';
import { useOutletContext } from 'react-router-dom';
import { clearInitiative, rolledForInitiative, saveCurrentCharacterValues, updateCharacterValues } from '../../utils/store';
import { useSelector } from 'react-redux';
import { Icon } from '../../utils/icons';
import { Checkbox, TextInput } from '../../utils/formfields';
import { createCharacter } from '../character';
import { guid } from '../../utils/utils';
import { Initiative, InitiativeButton } from '../initiative/initiative';
import { Fatigue } from '../fatigue/fatigue';
import { Weapon, Weapons } from './weapons';



function FightDefence({character,useEliminate}){
    let {initiatives}=useSelector(state => state.game);
    let myInitiative=initiatives[character.values.rowKey] || {};

    let agileAttr="dex";
    let agileBase=character.attributes.dex;
    if (character.skills.acrob>character.attributes.dex){
        agileAttr='acrob';
        agileBase=character.skills.acrob;
    }

    let ifightbonus=0,idefensebonus=0,idamagebonus=0;
    if (myInitiative){
        if (myInitiative.defensive){
            ifightbonus=-4;
            idefensebonus=4;
        }
        if (myInitiative.type=="Brutal" && myInitiative.value>=20){
            ifightbonus+=2;
            idamagebonus=2;
        }
    }

    let aStyle="combatValue"+(myInitiative.type=="Agile" || myInitiative.type=="Stealth" ? " activeCombatValue" : " inactiveCombatValue");
    let bStyle="combatValue"+(myInitiative.type=="Brutal" || myInitiative.type=="Stealth" ? " activeCombatValue" : " inactiveCombatValue");

    return <div className="fightDefence">
        <div className="titles">
            <div>
                <p>Agile</p>
            </div>
            <div>
                <p></p>
            </div>
            <div>
                <p>Brutal</p>
            </div>
        </div>
        <div className='defence'>
            <div className={aStyle}>
                <p>{character.adefence+idefensebonus}</p>
            </div>
            <div className='defenceAc'>
                <p className="defenceTitle">Defence</p>
                <p>AC: {character.ac}</p>
            </div>
            <div className={bStyle}>
                <p>{character.bdefence+idefensebonus}</p>
            </div>
        </div>
        <div className="fight">
            <div className={aStyle}>
                <p>{character.skills.afight.value}</p>
            </div>
            <div className="fightRanks">
                <p className="fightTitle">Fight</p>
                <Ranks value={character.values.fight} />
            </div>
            <div className={bStyle}>
                <p>{character.skills.bfight.value+ifightbonus}</p>
            </div>
        </div>
        <Weapons character={character} />
    </div>
}



function Hitpoints({character,edit,onChange}){
    let {role}=useSelector(state => state.game);
    let [damageTaken,setDamageTaken]=useState(0);
    let [rolling,setRolling]=useState(false);
    let [update,forceUpdate]=useState(new Date());
    //let [hp,setHp]=useState(character.hitpoints);

    function modifyDamage(add){
        if (add>0 && character.values.temporaryHitpoints){
            character.values.temporaryHitpoints-=add;
            if (character.values.temporaryHitpoints<0){
                character.damageTaken-=character.values.temporaryHitpoints;
                character.values.temporaryHitpoints=0;
            }
        }
        else character.damageTaken+=add;
        //saveCurrentCharacterValues(character);
        if (character.damageTaken<0) character.damageTaken=0;
        console.log("Current damage",character.damageTaken)
        setDamageTaken(0);
        onChange(character.hitpoints,character.damageTaken);
    }

    function rollHealing(dice){
        if (rolling) return;
        setRolling(true);
        Dice.roll(dice).then(result => {
            modifyDamage(-result.value);
            setRolling(false);
        })
    }

    function heal(value=1){
        modifyDamage(-value);
        forceUpdate(new Date());
    }

    function rollForMore(){
        Dice.roll("1d6",character.attributes.constitution.value).then(result =>{
            character.hitpointsrolled++;
            onChange(character.hitpoints+result.value,character.damageTaken);
        })
    }

    return <div className="hitpointsAndDamage">
        <div className="current">
            <div className="hitpoints">
                <p>Hitpoints</p> 
                {character.hitpointsrolled<character.hd && role!="Master" ? <>
                <span className='clickable hpDice' onClick={rollForMore}><img src="/icons/dice.png" />{character.hitpointsrolled}/{character.hd}</span></>:<></>}
                <div className='combatValue dimmed'>
                    <p>
                    {edit && role=="Master" ? <input type="number" value={character.hitpoints} onChange={ev => onChange(Number(ev.target.value),character.damageTaken)} /> :
                    <>{character.hitpoints}</>}
                    </p>
                </div>
            </div>
            {character.values.temporaryHitpoints ? <div className='temporaryHitpoints'>
                <p>Temporary</p>
                <div className='combatValue'><p>{character.values.temporaryHitpoints}</p></div>
            </div> : <></>}
            <div className='damageTaken'>
                <div>
                    <p>Damage taken</p>
                    <p><TextInput type="number"  value={damageTaken} onChange={ev => setDamageTaken(Number(ev.target.value))} onEnter={() => modifyDamage(damageTaken)} width={5}/>
                        <span className="addDamageButton" onClick={() => modifyDamage(damageTaken)}></span></p>
                </div>
                <div className='combatValue dimmed'><p>{character.damageTaken}</p></div>
            </div>
            <div className='hitpointsRemaining'>
                <p>Hitpoints remaining</p>
                <div className='combatValue'><p>{character.hitpoints-character.damageTaken}</p></div>
            </div>
        </div>
        <div className='modify'>
            <div className='heal'>
                <label>Heal</label>
                <p>
                    <span title='Heal 1 point' className='healButton heal1'onClick={() => heal(1)} ></span>
                    <span title='Heal 1d4 points' className='healButton healD4'onClick={() => rollHealing("1d4")} ></span>
                    <span title='Heal 1d8 points' className='healButton healD8'onClick={() => rollHealing("1d8")} ></span>
                </p>
            </div>
        </div>
    </div>
}

export function CharacterCombat({editable=false,title="",showFatigue=true,showInitiative=true}){
    let {campaign,currentCharacter,role}=useSelector(state => state.game);
    let character=createCharacter(currentCharacter,true);
    let [update,forceUpdate]=useState(new Date());
    let [edit,setEdit]=useState(false);
    let [original,setOriginal]=useState({...character.values})
    let [useEliminate,setUseEliminate]=useState(false);

    function save(){
        saveCurrentCharacterValues(character);
        setOriginal(character.values);
        setEdit(false);
    }

    function cancel(){
        character.values=original;
        setEdit(false);
    }

    function changeHitpoints(hitpoints,damage){
        console.log("Update hp",hitpoints,damage);
        character.hitpoints=hitpoints;
        character.damageTaken=damage;
        //updateCharacterValues(character.values);
        saveCurrentCharacterValues(character);
        setOriginal(character.values);
        //updateCharacterValues(character.values);
    }

    if (!campaign) showInitiative=false;

    console.log("Combat",useEliminate)
    return <div className="characterCombat">
        {title ? <h3>{title}
            {!edit && editable && role=="Master" ? <Icon type={Icon.edit+(edit ? Icon.dimmed : "")} onClick={() => setEdit(!edit)} style="first" /> : <></>}
                {edit ? <>
                    <Icon type={Icon.accept} onClick={save} style="first" /> 
                    <Icon type={Icon.cancel} onClick={cancel}  />
                </> : <></>}

        </h3> : <></> }
        <div className="combatContent">
            {showInitiative ? <Initiative character={character} setUseEliminate={setUseEliminate} /> : <></>}
            {showFatigue ? <Fatigue character={character} forceUpdate={() => forceUpdate(new Date())} /> : <></> }
            <FightDefence character={character} useEliminate={useEliminate} />
            <Hitpoints character={character} edit={edit} onChange={changeHitpoints} />
        </div>
    </div>
}

export function CharacterSheetCombat(){
    //const {character}=useOutletContext();
    const {currentCharacter}=useSelector(state => state.game);
    let character=createCharacter(currentCharacter,true);
    
    return <div className="characterSheetPage">
        <CharacterCombat character={character} title="Combat" showFatigue={false} />
    </div>
}