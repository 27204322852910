import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';


import './campaignproperties.scss'
import { Tile } from '../../utils/tile';
import { saveCampaignBasics } from '../../utils/store';
import { Icon } from '../../utils/icons';

export function CampaignProperties(){
    let {campaign}=useSelector(state => state.game);
    let [name,setName]=useState(campaign.name);
    let [currentDate,setCurrentDate]=useState(campaign.currentDate)
    let [note,setNote]=useState(campaign.note || "")

    function saveClicked(){
        let {partitionKey,rowKey}=campaign;
        let obj={partitionKey,rowKey,name,currentDate,note};
        saveCampaignBasics(obj)
    }

    function cancelClicked(){
        setName(campaign.name);
        setCurrentDate(campaign.currentDate);
    }

    function copySecret(){
        navigator.clipboard.writeText(campaign.rowKey);
    }

    let canSave=(name && currentDate);

    return <Tile header="Properties">
        <div className="formField">
            <label>Name</label>
            <input value={name} onChange={ev => setName(ev.target.value)} />
        </div>
        <div className="formField">
            <label>Current date</label>
            <input value={currentDate} onChange={ev => setCurrentDate(ev.target.value)} />
        </div>
        <div className="formField">
            <label>Secret</label>
            <p>{campaign.rowKey} <Icon type={Icon.copy} onClick={copySecret} /></p>
        </div>
        <div className='formField'>
            <label>Realm Master Notes</label>
            <textarea value={note} onChange={ev => setNote(ev.target.value)} />
        </div>
        <div className='formButtons'>
            <input type="button" value="Save" onClick={saveClicked} disabled={!canSave} />
            <input type="button" value="Cancel" onClick={cancelClicked}/>
        </div>
    </Tile>
}


