import React from 'react';
import { allowedLocations, titles } from './characteritems';

export function LocationSelector({type,currentLocation,onChange,onDelete,container}){

    if (!type || !allowedLocations[type]) return <p>Select item type</p>
    let locationRows=allowedLocations[type]
        .map(l => <span key={l} title={titles[l]} className={'locationValue clickable locationValue-'+l+(l==currentLocation ? " locationValueSelected":"")} onClick={() => onChange(l)}></span>)

    return <div className="locationSelector">
        <label>Save to</label>
        <p className='locationValues'>
            {locationRows}
            <span title="Delete" className="locationValue locationValue-trash clickable" onClick={onDelete}></span>
        </p>
    </div>
}