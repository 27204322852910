import { pointInShape } from "../points/pointutils";


function hitTestContour(map,object,point){
    let ht=pointInShape(point,object.points,map.options);
    if (ht){
        ht.contour=object;
        return ht;
    }
}

export function hitTestContours(map,point){
    let contours=map.objects.filter(o => o.itemType=="contour");
    for(let i=0;i<contours.length;i++){
        let contour=contours[i];
        let ht=hitTestContour(map,contour,point);
        if (ht){
            ht.contourIndex=i;
            return ht;
        }
    }
}