import { distance, pointInShape, pointNear, pointNearLine } from "../points/pointutils";

export function testForSharedPoints(map,object){
    let {lines,options}=map;
    let pointsToTest=object.points;
    for(let i=0;i<lines.length;i++){
        let line=lines[i];
        if (line.rowKey==object.rowKey) continue;
        let {points,closed}=line;
        let len=points.length-1;
        if (!closed) len-=1;
        for(let pi=0;pi<=len;pi++){
            let start=points[pi];
            let end=points[pi<points.length-1 ? pi+1 : 0];
            let inserted=0;
            for (let j=0;j<pointsToTest.length;j++){
                let point=pointsToTest[j];
                if (point.rowKey==start.rowKey || point.rowKey==end.rowKey) continue;
                if (pointNear(start,point) || pointNear(end,point)){
                    console.log("Point is near existing corner, what should we do?");
                    continue;
                }
                let pNear=pointNearLine(start,end,point,options);
                if (pNear){
                    //console.log("Found point on wall",this,wi,wall,pNear);
                    let index=(pi+inserted)+1;
                    if (index>=points.length) points.push(point);
                    else points.splice(index,0,point);
                    console.log("Added new point, line",i,"segment",pi)
                    inserted++;
                }
            }
        }
        
    }
}


function hitTestLine(map,object,point){
    let {points}=object;
    let len=points.length-1;
    if (object.closed) len+=1;
    for(let i=0;i<len;i++){
        let start=points[i];
        let end=points[i<points.length-1 ? i+1 : 0];
        let ht=pointNearLine(start,end,point,map.options);
        if (ht){
            let length=distance(start,end);
            let fromBeginning=distance(start,ht);
            return{point:ht,index:i,start,end,fromBeginning,length}
        }
    }
}

export function hitTestLines(map,point){
    for(let layer=9;layer>=1;layer--){
        let lines=map.objects.filter(o => o.itemType=="line" && o.layer==layer);
        for(let i=0;i<lines.length;i++){
            let line=lines[i];
            let segment=hitTestLine(map,line,point);
            if (segment) return {lineIndex:i,line,segment};
            if (!line.closed) continue;
            let inShape=pointInShape(point,line.points,map.options);
            if (inShape){
                return {lineIndex:i,line};
            }            
        }
    }
    return null;
}