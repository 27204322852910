import React, { useEffect, useRef } from 'react';
import { Page } from '../layout/page';
import { useSelector } from 'react-redux';
import { Login, LoginPage } from '../player/login';
import { Outlet, useNavigate } from 'react-router-dom';

import './campaign.scss'
import { Icon } from '../utils/icons';
import { PlayerHome } from '../player/playerhome';
import { getImageUrl, guid } from '../utils/utils';
import { Journal } from './journal/journal';
import { HandoutItem, HandoutsMain, handoutsMainChildren } from '../handouts/handouts';
import { CharacterSheet, characterSheetChildren } from '../character/charactersheet';
import { EncounterMain, encounterMainChildren } from '../encounter/encounter';
import { RealmMasterDetails } from './realmmaster/realmmaster';
import { NpcsMain } from '../master/npcs';
import { MapsRoute, mapsRouteChildren } from '../map/mapsroute';
import { setMarchingOrder } from '../utils/store';
import { ShareView } from './share';
import { ImageList } from './images';
import { CharacterWizard } from '../character/wizard/characterwizard';
import { createCharacter } from '../character/character';
//import { joinRtc } from '../utils/webrtc';

export const campaingHomeChildren=[
    {index:true,element: <Journal />},
    {path:'journal',element:<Journal />},
    {path:'handouts',element:<HandoutsMain />,children:handoutsMainChildren},
    {path:'character/:rowKey',element: <CharacterSheet />, children:characterSheetChildren},
    {path:'realmmaster',element:<RealmMasterDetails />},
    {path:'maps',element:<MapsRoute />,children:mapsRouteChildren},
    {path:"encounter",element: <EncounterMain />, children:encounterMainChildren},
    {path:'npcs',element: <NpcsMain />},
    {path:'share', element: <ShareView />},
    {path:'images', element: <ImageList />},
    {path:'wizard', element: <CampaingCharacterWizard />},
];

export function CampaingCharacterWizard(){
    let {campaign}=useSelector(state => state.game);
    let char=createCharacter({values:{campaign:campaign.rowKey}});

    return <div>
        <CharacterWizard character={char} />
    </div>
}

function NaviButton({character=null,title,subtitle="",imageUrl,target,styles=""}){
    const navigate=useNavigate();
    let bkr={backgroundImage:'url("'+imageUrl+'")'};
    let {newChatMessages,player,marchingOrder}=useSelector(state => state.game);

    //console.log("new chat",newChatMessages,"Listen from",character.values.rowKey)

    let cls="naviButton clickable";
    if (styles) cls+=" "+styles;
    let hasChat=false;
    if (character){
        hasChat=newChatMessages.includes(character.values.rowKey)
        if (character.values.partitionKey==player.rowKey) hasChat=false;
    }

    let canDrag=character && (character.values.rowKey != character.values.partitionKey);

    function onDragOver(ev){
        //let data=ev.dataTransfer.getData("text");
        if (!character) return;
        if (character.values.rowKey==character.values.partitionKey) return;
        let types=ev.dataTransfer.types;
        if (!types.includes(character.values.rowKey)) ev.preventDefault();
    }

    function onDrop(ev){
        if (!character) return;
        if (character.values.rowKey==character.values.partitionKey) return;
        let data=ev.dataTransfer.getData("text");
        let {x,width}=ev.target.getBoundingClientRect();
        let cx=ev.clientX-x;
        let order=[...marchingOrder];
        let otherIndex=order.indexOf(data);
        order.splice(otherIndex,1);
        let myIndex=order.indexOf(character.values.rowKey);
        if (cx>width/2) myIndex++;
        if (myIndex<order.length) order.splice(myIndex,0,data);
        else order.push(data);
        setMarchingOrder(order);
        console.log("Drop",order);
    }

    function onDragStart(ev){
        ev.dataTransfer.setData("text",character.values.rowKey);
        ev.dataTransfer.setData(character.values.rowKey,"rowKey");
    }

    return <div className={cls} style={bkr} onClick={() => navigate(target)} draggable={canDrag} onDragOver={onDragOver} onDragStart={onDragStart} onDrop={onDrop}>
        <p className="title">{title}</p>
        <p className="subTitle">{subtitle}</p>
        {hasChat ? <Icon type={Icon.chat} style="chatIcon" /> : <></>}
    </div>
}

function VideoButton(){
    let meetingWindow=document.getElementById("meetingFrame").contentWindow;
    const videoRef=useRef(null);

    useEffect(function(){
        meetingWindow.getLocalStream(videoRef.current)
    },[videoRef])

    console.log("Videobutton")
    return <div className="naviButton videoButton">
        <video ref={videoRef} />
    </div>
}

function ShareButton(){
    let {share}=useSelector(state => state.game);
    let {target,imageUrl,sharedBy,title}=share;
    const navigate=useNavigate();
    let bkr={backgroundImage:'url("'+imageUrl+'")'};

    return <div className="naviButton shareButton clickable" style={bkr} onClick={() => navigate("./share")}>
        <p className="title">{title}</p>
        <p className="subTitle">By: {sharedBy}</p>
    </div>
}

function CampaignNavi(){
    const navigate=useNavigate();
    const {campaign,player,role,socketLatest,socketHandout,marchingOrder,share}=useSelector(state => state.game);

    let chars=campaign.characters.filter(c => !c.rip).map(c => ({...c})).sort((a,b) => {
        //console.log("Marching order",marchingOrder);
        let ai=marchingOrder.indexOf(a.values.rowKey);
        let bi=marchingOrder.indexOf(b.values.rowKey);
        return ai-bi;
    })
    
    let characterRows=chars.map((c,index) => {
        let url=`./character/${c.values.player}`;
        if (c.values.rowKey) url=`./character/${c.values.rowKey}`;
        let imageUrl="/images/defaultcharacter.png";
        if (c.values.image) imageUrl=getImageUrl(c.values.partitionKey,c.values.image);
        console.log(campaign.players,c.values.partitionKey)
        let cp=campaign.players.find(p => p.rowKey==c.values.partitionKey);
        let subtitle=cp?.username || c.values.username;
        return <NaviButton key={c.values.rowKey} character={c} title={c.values.name} subtitle={subtitle} imageUrl={imageUrl} target={url} />
    });
    let char={values:{rowKey:campaign.owner.rowKey,partitionKey:campaign.owner.rowKey}};
    //console.log("Navi",campaign,characterRows)
 
    let hasChar=chars.find(c => c.values.partitionKey==player.rowKey);
    console.log("Chars",chars,hasChar)
    return <div className="campaignNavi">
            <NaviButton character={char} title="Realm Master" subtitle={campaign.owner.username} imageUrl="/images/realmmaster.jpg" target="./realmmaster" />
            {characterRows}
            {!hasChar && role=="Player" ? <NaviButton title="Wizard" imageUrl="/images/wizard.svg" target="./wizard" /> : <></>}
            {false && role=='Player' && socketLatest=="map" ? <PlayerMap small={true} onClick={() => navigate("./map")} /> : <></>}        
            {false && role=='Player' && socketLatest=="handout" ? <HandoutItem handout={socketHandout} root="./handouts/" /> : <></>}
            
            <NaviButton title="Handouts" imageUrl="/images/handout-small.png" target="./handouts" />
            {true || role=="Master" ? <NaviButton title="Maps" imageUrl="/images/empire.svg" target="./maps" /> : <></> }
            <NaviButton title="Journal" imageUrl="/images/journal.png" target="./journal" styles="naviJournal" />
            {share ? <ShareButton /> : <></>}
            {false && role=="Master" ? <NaviButton title="Encounter" imageUrl="/images/fighting-small.png" target="./encounter" styles="naviEncounter" /> : <></>}
    </div>
}

export function CampaignHome(){
    let {player,campaign,role}=useSelector(state=> state.game);

    let theme="campaign";
    //if (campaign) theme+=" meeting";
    console.log("Theme",theme,campaign)
    return <Page theme={theme}>
        {
            !player?.rowKey ? <LoginPage /> :
            !campaign ? <PlayerHome /> : <>
                <CampaignNavi />
                <Outlet />
                </>
        }
    </Page>
}