import React, { useEffect, useRef, useState } from 'react';
import { loadHtml } from '../utils/http';
import { Link, useParams } from 'react-router-dom';

import './articles.scss';
import { setTitle } from '../utils/store';
import { useSelector } from 'react-redux';

const navItem=(title,url,target,children=[]) => ({title,url,target,children});

const books=[
    navItem("Rules","rules","/articles/introduction.html",[
        navItem("Background","background",'/articles/background.html'),
        navItem("Character","character","/articles/character.html",[
            /*navItem("Character Creation","creation","/articles/character.html"),*/
            navItem("Skills","skills","/articles/skills.html"),
            navItem("Magic","magic","/articles/magic.html"),
            navItem("Items","items","/articles/items.html"),
            navItem("Ethnicity","ethnicity","/articles/ethnicity.html"),
        ]),
        navItem("Various Rules","various","/articles/rules.html"),
        navItem("Combat","combat","/articles/combat.html"),
        navItem("Spells","spells","/articles/spells.html",[
            navItem("Chant","Chant","/articles/spells.html"),
            navItem("Charm","Charm","/articles/spells.html"),
            navItem("Sorcery","Sorcery","/articles/spells.html"),
            navItem("Domination","Domination","/articles/spells.html"),
            navItem("Illusion","Illusion","/articles/spells.html"),
            navItem("Necromancy","Necromancy","/articles/spells.html")
        ])
    ]),
/*    
    navItem("Bestiary","bestiary",'/purchase/bestiary/index.html',[
        navItem("Humans","humans","/purchase/bestiary/humans.html"),
        navItem("Animals","animals","/purchase/bestiary/animals.html"),
        navItem("Mystical Skills","mystical","/purchase/bestiary/mysticalSkills.html"),
        navItem("Spirits","spirits","/purchase/bestiary/spirits.html"),
        navItem("Guardians of Nature","guardians","/purchase/bestiary/guardians.html"),
        navItem("Witches","witches","/purchase/bestiary/witches.html"),
        navItem("Dragons","dragons","/purchase/bestiary/dragons.html"),
        navItem("Undead","undead","/purchase/bestiary/undead.html"),
        navItem("Evil Ones","evil","/purchase/bestiary/evilOnes.html"),
        navItem("Shape Changers","shapechangers","/purchase/bestiary/shapeChangers.html")
    ]),
 */   
]

const adventure=[
    navItem("<div><p>Legends of the</p><p>Fortress at the Edge of the Realm</p></div>","adventure",'/purchase/adventure/index.html',[
        navItem("Introduction","introduction","/purchase/adventure/background.html"),
        navItem("Strumpili","strumpili","/purchase/adventure/strumpili.html"),
        navItem("Strumpili to Poiassa","topoiassa",'/purchase/adventure/strumpiliToPoiassa.html'),
        navItem("Poiassa","poiassa","/purchase/adventure/poiassa.html"),
        navItem("Poiassa to Cameni","tocameni","/purchase/adventure/poiassaToCameni.html"),
        navItem("Cameni","cameni","/purchase/adventure/cameni.html"),
        navItem("Cameni to Weliki Poiassa","toweliki","/purchase/adventure/cameniToWeliki.html"),
        navItem("Weliki Poiassa","weliki","/purchase/adventure/weliki.html"),
        navItem("After the Curse","aftercurse","/purchase/adventure/afterCurse.html"),
        navItem("The Staff of Domination","staffofdomination","/purchase/adventure/staff.html"),
        navItem("Finale","finale","/purchase/adventure/finale.html"),
        navItem("Some Rules","rules","/purchase/adventure/rules.html"),
        navItem("Player Handouts","handouts","/purchase/adventure/handouts.html")
    ])
];

function RulesLink({item,level=0}){
    const [showChildren,setShowChildren]=useState(true);
    let childLinks=item.children.map(c => <RulesLink key={makePath(c)} item={c} level={level+1} />);
    let path=makePath(item,books);
    return <>
        <Link to={path}>{item.title}</Link>
        {item.children.length && showChildren? <div className={`rulesNavChildren rulesNav-${level+1}`}>{childLinks}</div> : <></> }
    </>
}
//<Link to={path} onClick={() => setShowChildren(!showChildren)} >{item.title}</Link>

export function RulesNav(){

    let links=books.map(b => <RulesLink key={makePath(b)} item={b} />)

    return <div id="rulesNav">
        {links}
    </div>
}

function findArticleItem(items,url){
    for(let i=0;i<items.length;i++){
        if (items[i].url==url) return items[i];
        let item=findArticleItem(items[i].children,url);
        if (item) return item;
    }
    //console.log("Returning default");
    return null;
}

function isParent(p,i){
    let b=p.children.includes(i);
    //console.log("Is Parent",b,p.title,i.title)
    return b;
}

function findParent(item,items=books){
    for (let i=0;i<items.length;i++){
        if (isParent(items[i],item)) return items[i];
        if (!items[i].children) continue;
        let p=findParent(item,items[i].children);
        if (p) return p;
    }
    return null;
}

function makePath(item,items=books){
    let path="/"+item.url;
    let parent=findParent(item,items);
    while(parent){
        path="/"+parent.url+path;
        parent=findParent(parent);
    }
    return path;
}

function ArticleLink({item,styles}){
    let parent=findParent(item);
    let parentUrl=parent?.url || "article";
    let target='/'+parentUrl+"/"+item.url;
    //console.log("Sub",item.title,target)
    return <Link className={styles} to={target}>{item.title}</Link>
}

export function ArticleView({id,subclass=""}){
    const [article,setArticle]=useState("");
    const [item,setItem]=useState(books[0])
    const [articleStyles,setArticleStyles]=useState("");
    let {inMeeting}=useSelector(state => state.game);
    //console.log("Trying to show article",id,item)

    function changeArticle(html,item){
        let div=document.createElement("div");
        div.innerHTML=html;
        let particle=div.querySelector("article");
        let root=document.querySelector('html');
        let cls=particle.classList || "scribe";
        if (inMeeting) cls+=" meeting";
        console.log("Articleview theme",cls);
        root.classList=cls;
        //root.classList.add("articleView");
        let styles="";
        if (id=="spells" && subclass){
            div.classList.add("rulesSpellList");
            let tables=div.querySelectorAll("column>table");
            let html="";
            for(let i=0;i<tables.length;i++){
                let table=tables[i];
                //console.log("Testing for",subclass,table)
                if (!subclass || table.classList.contains(subclass)) html+=table.outerHTML;
            }
            styles="flexArticle";
            div.innerHTML=html;
        }
        setArticleStyles(styles);
        setArticle(div.innerHTML);
        setItem(item);
        setTitle(item?.title || "");
    }

    useEffect(function(){
        return () => setTitle("");
    },[])

    useEffect(function(){
        let item=findArticleItem(books,id || '');
        //console.log("Found item",item)
        if (!item) {
            item=books[0];
            console.log("Did not find article")
        }
        //else console.log("Found item",item);
        loadHtml(item.target).then(html => changeArticle(html,item));
    },[id,subclass]);

    let rules=findArticleItem(books,"rules");

    let parentItems=[];
    if (item.children.length) parentItems=[item];
    let parent=findParent(item,books);
    let cur=parent;
    while(cur && cur!=books){
        parentItems=[cur,...parentItems]
        cur=findParent(cur);
    }
    let parentRows=parentItems.map(pi => <><ArticleLink item={pi} styles="articleParent" /> &gt;</>)
    let childRows=[];

    if (item.url.startsWith(".")) childRows=parent.children.map(ci => <ArticleLink item={ci}  />);
    else if (item.children?.length==0) childRows=parent.children.map(ci => <ArticleLink item={ci} />);
    else childRows=item.children.map(ci => <ArticleLink item={ci} />)

    return <>
        {/* item?.children.length || parentItems.length ? <div className="articleSubNav">
            {parentRows}
            {childRows}
        </div> : <></> */}
        {item && false ? <h1 className='articleItemHeader'>{item.title}</h1> : <></>}
        <div id="articleContainer" className={articleStyles} dangerouslySetInnerHTML={{__html:article}}></div>
    </>
}

export function Article(){
    let params=useParams();
    return <>
        <ArticleView id={params.id || 'background'} />
    </>
}

export function RulesArticle(){
    const params=useParams();
    let id=params.id || 'rules';

    return <>
        <ArticleView id={id} subclass="" />
    </>
}

export function SpellsArticle(){
    const params=useParams();
    //console.log("Spells params",params)
    return <>
        <ArticleView id="spells" subclass={params.subclass || ""} />
    </>
}

export function CharacterArticle(){
    const params=useParams();
    let id=params.id || "character";

    return <>
        <ArticleView id={id} subclass="" />
    </>
}