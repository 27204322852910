import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './app';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter, createHashRouter } from 'react-router-dom';
import { store, testZoom } from './utils/store';
import { Provider } from 'react-redux';
import { initPins, rescaleMain } from './layout/layout';
import { Article, ArticleView, CharacterArticle, RulesArticle, SpellsArticle } from './articles/article';
import { LoginMain, loginMainChildren } from './player/login';
import { CampaignHome, campaingHomeChildren} from './campaign/campaignhome';
import { playerHomeChildren, PlayerHomeRoute } from './player/playerhome';
import { ImagePage } from './utils/imageeditor';

window.onload=function() {
    document.body.classList.add("webView");
    let view=this.localStorage["view"] || "columns";
    document.body.classList.add(view);
    initPins();
    //article.init();
    window.onresize=function(){
        console.log("Resized");
        testZoom();
        rescaleMain();
    }

    const root = ReactDOM.createRoot(document.getElementById('app'));
    const router = createBrowserRouter ([{
        path: "/", element: <App />,errorElement:<App />, children: [
            {path:"",element:<PlayerHomeRoute />, children:playerHomeChildren},
            {path:'article/:id?/subclass?',element:<Article />},
            {path:'rules/spells/:subclass?',element:<SpellsArticle />},
            {path:'rules/character/:id?',element:<CharacterArticle />},
            {path:"rules/combat",element: <ArticleView id="combat" />}, 
            {path:'rules/:id?',element:<RulesArticle />},
            {path:"images",element:<ImagePage />},

            {path:'login', element: <LoginMain />,children:loginMainChildren},
            {path:'campaign', element: <CampaignHome />,children:campaingHomeChildren},
            {path:'home',element:<PlayerHomeRoute />,children:playerHomeChildren},
        ]}
    ]);
    
    root.render(<Provider store={store}><RouterProvider router={router} /></Provider>);
    reportWebVitals();

}
