import React, { useEffect, useState } from 'react';

import './npceditor.scss';
import { HTTP } from '../utils/http';
import {fieldToShortname, weapons} from '../character/character';
import { sendTo, sendToCharacter } from '../utils/websocket';
import { Icon } from '../utils/icons';
import { Dice } from '../dice/dicebox';
import { CharacterAvatar } from '../encounter/avatars';

let currentShow=null;

export function calculateDefence(character){
    let values=character.values || character;
    let ac=values.ac;
    let hasShield=values.items?.toLowerCase().includes('shield');
    //console.log("Defence",values.ac,hasShield)
    let ag=ac+Math.max(values.dexterity,values.acrobatics)+(hasShield ? 1 : 0);
    let br=ac+values.fight+(hasShield ? 2 : 0);
    return ''+ag+"/"+br;
}

export function calculateFight(character){
    let values=character.values || character;
    let enc=false;
    if (values.type=='human' && values.ac>14) enc=true;
    let dex=enc ? 0 : values.dexterity;
    let ag=values.hd+dex+values.fight;
    let br=values.hd+values.strength+values.fight;
    return ({ag,br});
}

export function showRankOptions(newShow=null){
    console.log("Show",!!newShow)
    if (currentShow) currentShow(false);
    currentShow=newShow;
    if (currentShow) currentShow(true);
}


function RankInput({edit,title,value,onChange}){
    const [showOptions,setShowOptions]=useState(false);

    function select(v){
        setShowOptions(false);
        onChange(v);
    }

    function showOptionsPopup(ev){
        if (!edit)  return;
        ev.stopPropagation();
        showRankOptions(setShowOptions);
    }

    let cls="rankInputCurrent";
    if (edit) cls+=" .clicable";

    return <div className='rankInput'>
        <div className={cls} onClick={showOptionsPopup}>
            <span className="rankInputTitle">{title}</span><span className="rankInputValue">{value}</span></div>
        { showOptions ? <div className="rankInputOptions">
            <p className={value==-1 ? 'currentRank' : ''} onClick={() => select(-1)}>-1</p>
            <p className={value==0 ? 'currentRank' : ''} onClick={() => select(0)}>0</p>
            <p className={value==1 ? 'currentRank' : ''} onClick={() => select(1)}>1</p>
            <p className={value==2 ? 'currentRank' : ''} onClick={() => select(2)}>2</p>
            <p className={value==3 ? 'currentRank' : ''} onClick={() => select(3)}>3</p>
            <p className={value==4 ? 'currentRank' : ''} onClick={() => select(4)}>4</p>
            <p className={value==5 ? 'currentRank' : ''} onClick={() => select(5)}>5</p>
            <p className={value==6 ? 'currentRank' : ''} onClick={() => select(6)}>6</p>
        </div> : '' }
    </div>    
}

function AttributeCell({edit,character,field,forceUpdate}){
    //let [value,setValue]=useState(character[field]);
    let attr=character.values[field];
    let sn=fieldToShortname(field);
    if (!attr) console.log("Attribute",field)
    let value=character.values[field];

    function changeValue(ranks){
        let value=Number(ranks);
        if (value<0) value=0;
        if (value>6) value=6;
        character.values[field]=value;
        //setValue(value);
        //updateValue(character.id,)
        forceUpdate();
    }

    return <div className='editorCell attributeCell'>
        <RankInput edit={edit} title={sn} value={value} onChange={changeValue} />
    </div>
}

function SkillCell({edit,character,field,value='',forceUpdate}){
    let [ranks,setRanks]=useState(character[field]);
    let skill=character.skills[field];

    if (!skill){
        skill={value:-1,baseValue:-1}
    }
    if (!value) value=skill.value;

    function changeRanks(ranks){
        ranks=Number(ranks);
        if (ranks<-1) ranks=-1;
        if (ranks>6) ranks=6;
        character[field]=ranks;
        setRanks(ranks);
        if (character.rowKey) sendToCharacter(character.rowKey,'update',{field,ranks})
        forceUpdate();
    }

    function clicked(){
        if (edit) return;
        Dice.roll("1d20",value);
    }

    let sn=fieldToShortname(field);
    if (value==-1) value=''
    let cls="editorCell skillCell";
    if (!edit) cls+=" clickable";

    return <div className='editorCell skillCell' onClick={clicked}>
        <RankInput edit={edit} title={sn} value={ranks} onChange={changeRanks} />
        <div className='skillvalue'>
            {value}
        </div>
    </div>

}

function Reputation({edit,character,forceUpdate}){

    function change(rep,v){
        character[rep]=v;
        forceUpdate();
    }

    return <>
        <div className='editorCell reputationCell'>
            <RankInput edit={edit} title='Chaotic' value={character.chaotic} onChange={v => change('chaotic',v)} />
            <RankInput edit={edit} title='Lawful' value={character.lawful} onChange={v => change('lawful',v)} />
        </div>
        <div className='editorCell reputationCell'>
            <RankInput edit={edit} title='Selfish' value={character.selfish} onChange={v => change('selfish',v)} />
            <RankInput edit={edit} title='Selfless' value={character.selfless} onChange={v => change('selfless',v)} />
        </div>
    </>
}


function FightCell({character,forceUpdate,edit}){

    function change(v){
        character.values.fight=v;
        forceUpdate();
    }

    function roll(value){
        Dice.roll("1d20",value)
    }

    let {ag,br}=calculateFight(character);

    return <div className="editorCell">
        <RankInput edit={edit} title="Fight" value={character.values.fight} onChange={change} />
        <div className="fightValue">
            <span className='clickable' onClick={ev => roll(ag)}>{ag}</span><span>/</span><span className="clickable" onClick={ev => roll(br)}>{br}</span>
        </div>
    </div>
}

function AttackCell({character}){

    let attackStrings=(character.values.attacks || '').split(',');
    let attacks=[];
    attackStrings.forEach(as => {
        let parts=as.split(' ');
        let title=parts[0];
        let damage=parts[1];
        let bonus=0;
        let bonusIndex=title.indexOf("+");
        if (bonusIndex>0) bonus=parseInt(title.substring(bonusIndex+1));
        attacks.push({title,bonus,damage}); 
    })

    function rollDamage(dice,bonus){
        let x=dice.split("+");
        if (x.length>1) x=Number(x[1]);
        Dice.roll(dice,x,bonus,character.damageBonus)
    }

    let rows=attacks.map((a,i) => <div key={'attact-'+i} className="npcAttackRow">
        <span>{a.title}</span>
        {a.bonus ? <span>+{a.bonus}</span> : <></> }
        <span className='clickable' onClick={ev => rollDamage(a.damage,a.bonus)}>{a.damage}</span>
    </div>)

    return <div>
        {rows}
    </div>    
}

function DamageRow({character,number,onChange}){
    let [dmg,setDmg]=useState("");

    function addDamage(){
        let add=Number(dmg);
        if (!add) return;
        let v=character.values.damageObject[number] || 0;
        character.values.damageObject[number]=(v+add);
        setDmg("");
    }

    function keyDown(ev){
        console.log("Key",ev.key)
        if (ev.key=="Enter") addDamage();
    }

    return <p>
        <span className="opponentNumber">{number}</span>
        <span className="dmgCell">Dmg {character.values.damageObject[number] || 0}</span>
        <input value={dmg} onChange={ev => setDmg(ev.target.value)} onKeyDown={keyDown} />
        <input type="button" value="Add" onClick={addDamage}/>
        <span className='hpCell'>{character.values.hitpoints-(character.values.damageObject[number] || 0)}</span>
    </p>
}

export function NpcEditor({character,save,onClose}){
    let [edit,setEdit]=useState(false);
    let [upd,setUpd]=useState(null);
    const forceUpdate=() => setUpd(new Date());
    const {afight,bfight}=character.skills;
    const fightValue=`+${afight.value}/+${bfight.value}`;
    let [attacks,setAttacks]=useState(character.values.attacks || '');
    let [spells,setSpells]=useState(character.values.spells || '');
    let [items,setItems]=useState(character.values.items || '');

    const defence=`${character.adefence}/${character.bdefence}`;

    function changeNumber(ev,field){
        let v=Number(ev.target.value);
        character.values[field]=v;
        forceUpdate();
    }

    function changeText(ev,field){
        character.values[field]=ev.target.value;
        forceUpdate();
    }

    function doSave(){
        character.values.items=items;
        character.values.spells=spells;
        character.values.attacks=attacks;
        save(character.values);
        setEdit(false);
    }

    console.log("NPC",character)
    let type=character.values.type || 'human';

    return <div className='characterEditor'>
        <div className="editIcons">
            {!edit ? <Icon type={Icon.edit} onClick={() => setEdit(!edit)} /> :
            <><select value={character.values.type || 'human'} onChange={ev => changeText(ev,'type')}>
                <option value="human">Human</option>
                <option value="animal">Animal</option>
                <option value="undead">Undead</option>
                <option value="mystic">Mystic</option>
            </select>
                <Icon type={Icon.save} onClick={doSave} /> </>
            }
            {onClose ? <Icon type={Icon.close} onClick={onClose} /> : <></>}
        </div>
        <div className='editorRow nameRow'>
            <div><input autoComplete='off' placeholder="Name" value={character.name} onChange={ev => changeText(ev,'name')} /></div>
            {type=='human' || type=='undead' ?<>
                <div><input autoComplete='off' placeholder='Ethnicity' value={character.ethnicity} onChange={ev => changeText(ev,'ethnicity')} /></div>
                <div><input autoComplete='off' placeholder='Profession' value={character.profession} onChange={ev => changeText(ev,'profession')} /></div>
            </> : <></> }
            <div><input autoComplete='off' placeholder='Sex' value={character.sex} onChange={ev => changeText(ev,'sex')} /></div>
            <div><input type="number" autoComplete='off' placeholder='Age' value={character.age} onChange={ev => changeNumber(ev,'age')} /></div>
        </div>
        <div className='editorRow attributeRow'>
            <AttributeCell edit={edit} character={character} field='hd' forceUpdate={forceUpdate} />
            <AttributeCell edit={edit} character={character} field='dexterity' forceUpdate={forceUpdate} />
            <AttributeCell edit={edit} character={character} field='strength' forceUpdate={forceUpdate} />
            <AttributeCell edit={edit} character={character} field='constitution' forceUpdate={forceUpdate} />
            <AttributeCell edit={edit} character={character} field='intelligence' forceUpdate={forceUpdate} />
            <AttributeCell edit={edit} character={character} field='wisdom' forceUpdate={forceUpdate} />
            <AttributeCell edit={edit} character={character} field='charisma' forceUpdate={forceUpdate} />
        </div>
        {type=='mystic' ? <>
            <div className="editorRow skillRow">
                <AttributeCell edit={edit} character={character} field='awe' forceUpdate={forceUpdate} />
                <AttributeCell edit={edit} character={character} field='etherealness' forceUpdate={forceUpdate} />
                <AttributeCell edit={edit} character={character} field='mysticform' forceUpdate={forceUpdate} />
                <AttributeCell edit={edit} character={character} field='possession' forceUpdate={forceUpdate} />
                <AttributeCell edit={edit} character={character} field='regeneration' forceUpdate={forceUpdate} />
                <AttributeCell edit={edit} character={character} field='teleport' forceUpdate={forceUpdate} />
                <AttributeCell edit={edit} character={character} field='touchofdeath' forceUpdate={forceUpdate} />
            </div>
        </> : <></>}
        <div className="editorRow skillRow">
            <SkillCell edit={edit} character={character} field='awaraness' forceUpdate={forceUpdate} />
            <SkillCell edit={edit} character={character} field='bushcraft' forceUpdate={forceUpdate} />
            <SkillCell edit={edit} character={character} field='bruteforce' forceUpdate={forceUpdate} />
            <SkillCell edit={edit} character={character} field='endurance' forceUpdate={forceUpdate} />
            <SkillCell edit={edit} character={character} field='investigation' forceUpdate={forceUpdate} />
            <SkillCell edit={edit} character={character} field='stealth' forceUpdate={forceUpdate} />
            <SkillCell edit={edit} character={character} field='will' forceUpdate={forceUpdate} />
        </div>
        {type!='animal' ? <>
            <div className="editorRow skillRow">
                <SkillCell edit={edit} character={character} field='chant' forceUpdate={forceUpdate} />
                <SkillCell edit={edit} character={character} field='charm' forceUpdate={forceUpdate} />
                <SkillCell edit={edit} character={character} field='erudition' forceUpdate={forceUpdate} />
                <SkillCell edit={edit} character={character} field='medicine' forceUpdate={forceUpdate} />
                <SkillCell edit={edit} character={character} field='sleightofhand' forceUpdate={forceUpdate} />
                <SkillCell edit={edit} character={character} field='sorcery' forceUpdate={forceUpdate} />
                <SkillCell edit={edit} character={character} field='tinkering' forceUpdate={forceUpdate} />
            </div>
            <div className="editorRow skillRow">
                <SkillCell edit={edit} character={character} field='illusion' forceUpdate={forceUpdate} />
                <SkillCell edit={edit} character={character} field='necromancy' forceUpdate={forceUpdate} />
                <SkillCell edit={edit} character={character} field='domination' forceUpdate={forceUpdate} />
                <SkillCell edit={edit} character={character} field='animalempathy' forceUpdate={forceUpdate} />
                <Reputation edit={edit} character={character} forceUpdate={forceUpdate} />
            </div>
        </> : <></> }
        <div className="editorRow skillRow">
            <FightCell edit={edit} character={character} forceUpdate={forceUpdate} />
            <SkillCell edit={false} character={character} field='damageBonus' value={'+'+character.damageBonus} forceUpdate={forceUpdate} />
            <SkillCell edit={edit} character={character} field='acrobatics' forceUpdate={forceUpdate} />
            <div className="editorCell">
                <div><span>Def</span></div>
                <div><span>{calculateDefence(character)}</span></div>
            </div>
            <div className='editorCell'>
                <div><span>HP</span></div>
                <div><input type="number" value={character.hitpoints} onChange={ev => changeNumber(ev,'hitpoints')} /></div>
            </div>
            <div className='editorCell'>
                <div><span>AC</span></div>
                <div>
                    <input value={character.values.ac} onChange={ev => changeNumber(ev,'ac')} />
            </div>
            </div>
        </div>
        <div className="editorRow extraRow">
            <div className="itemsCell">
                {edit ? <input placeholder="Attacks" value={attacks} onChange={ev => setAttacks(ev.target.value)} /> :
                    <AttackCell character={character} /> }
                <input placeholder="Items" value={items} onChange={ev => setItems(ev.target.value)} />
                {type != 'animal' ? <input placeholder="spells" value={spells} onChange={ev => setSpells(ev.target.value)} /> : <></> }
            </div>
            <div className="npcAvatar">
                <CharacterAvatar number={character.values.avatar || 1} basePath='/assets/monsteravatars/monsters' maxAvatars={25} onChange={ev => changeNumber(ev,'avatar')} />
            </div>
        </div>
     </div>
}


