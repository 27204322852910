import React, { useEffect, useState } from 'react';

import './npcs.scss'
import { characterForCampaign, createCharacter, createNpc, npcForCampaign } from '../character/character';
import { useSelector } from 'react-redux';
import { NpcEditor, calculateDefence } from './npceditor';
import { HTTP } from '../utils/http';
import { Icon } from '../utils/icons';

export function NpcButton({npc,onSelect}){
    //console.log("Button",npc)
    let {initiatives}=useSelector(state => state.game);
    let attacks=npc.attacks.split(",").map(as => as.split(/[ +]+/)[0].trim()).join(",");
    let img="/assets/monsteravatars/monsters";
    let an=''+(npc.avatar || 1);
    if (an.length<2) an='0'+an;
    img+=an+".svg";
    let defences=calculateDefence(npc).split("/");
    let ag=Number(defences[0]);
    let br=Number(defences[1]);
    let def=ag > br ? ag : br;

    let myInitiative=initiatives[npc.rowKey] || {};

    return <div className="npcButton clickable" onClick={() => onSelect(npc)}>
        <div className='npcDescription'>
            <h5><span>{npc.name || "New npc"}</span>{npc.numbers ? <span className='numbers'>{npc.numbers}</span> : <></>}</h5>
            <div className="npcDetail">
                {myInitiative?.value ? <div className="initiative">
                    <p>{myInitiative.value}</p>
                </div> : <></>}
                <div className="defence">
                    <p>{def}</p>
                </div>
                <div className="wears">
                    <p>{npc.items}</p>
                    <p>{attacks}</p>
                </div>
                <img src={img} />
            </div>
        </div>
    </div>
}

export function NpcsMain(){
    let [npcs,setNpcs]=useState([]);
    let {player,campaign}=useSelector(state => state.game);
    let [selected,setSelected]=useState(null);

    useEffect(function() {
        HTTP.get("/api/npcs").then(npcs => {
            setNpcs(npcs);
        })
    },[]);

    function selectNpc(values){
        let npc=createNpc(values);
        setSelected(npc);
    }

    function save(npcValues){
        HTTP.post("/api/npcs",npcValues).then(saved => {
            let existing=npcs.find(n => n.rowKey==saved.rowKey);
            if (!existing) npcs.push(saved);
            else {
                Object.assign(existing,saved);
            }
            setNpcs([...npcs]);
        })
    }

    let npcRows=npcs.map(npc => <NpcButton key={npc.rowKey} npc={npc} onSelect={selectNpc} />)

    let newNpc=npcForCampaign(campaign,player);

    return <div className='npcsMain'>
        <h3>NPCs</h3>
        <div className="npcList">
            <NpcButton npc={newNpc} onSelect={selectNpc} />
            {npcRows}
        </div>
        {selected ? <NpcEditor key={selected.values.rowKey} character={selected} save={save} onClose={() => setSelected(null)} /> : <></>}
    </div>
}