function togglePinnedClass(cls){
    /*
    document.body.classList.toggle(cls);
    let other=cls=="navPinned" ? "asidePinned" : "navPinned";
    if (document.body.classList.contains(cls)) {
        localStorage[cls]="Yes";
        if (document.body.classList.contains("allowSinglePin")) {
            document.body.classList.remove(other);
            localStorage.removeItem(other);
        }
    }
    else localStorage[cls]="No";
    rescaleMain();
*/
}


export function toggleNavPinned() {
    togglePinnedClass("navPinned");
}

export function toggleAsidePinned() {
    console.log("Aside")
    //togglePinnedClass("asidePinned");
}


export function initPins(){
    //if (localStorage["navPinned"]=="Yes") document.body.classList.add("navPinned");
    //if (localStorage["asidePinned"]=="Yes") document.body.classList.add("asidePinned");
    if (localStorage["navPinned"]) return;
    /*
    let w=document.body.clientWidth;
    if (w<=1000) return;
    document.body.classList.add("navPinned");
    if (w<=1300) return;
    document.body.classList.add("asidePinned");
    */
}

export function rescaleMain(){
    // SCREEN
    // Less than 400, narrow aside
    // Less than 720, no pins
    // Over 1360 both pins
    let main=document.querySelector("main");
    let w=document.body.clientWidth;
    let h=document.body.clientHeight;
    w=window.innerWidth;
    h=window.innerHeight;
    let hz=h/800;
    let wz=w/1400;
    let minZoom=hz<wz ? hz : wz;
    if (minZoom<1) minZoom=1;
    //document.body.style.zoom=minZoom;
    //document.getElementById("app").style.zoom=minZoom;
    console.log("Client size",w,h)
    if (w>=940) {
        document.body.classList.add("allowBothPins");
        document.body.classList.remove("allowSinglePin");
        document.body.classList.remove("noPins");
    }
    else {
        document.body.classList.remove("allowBothPins");
        if (w>=720) {
            document.body.classList.remove("noPins");
            document.body.classList.add("allowSinglePin");
            if (document.body.classList.contains("navPinned") && document.body.classList.contains("asidePinned"))
                document.body.classList.remove("asidePinned");
        }
        else {
            document.body.classList.add("noPins");
            document.body.classList.remove("allowSinglePin");
            document.body.classList.remove("asidePinned");
            document.body.classList.remove("navPinned");
        }
    }

    // MAIN
    // columns / nocolumns 720
/*
    document.body.classList.remove("printView");
    document.body.classList.add("webView");
    */
   if (main){
        w=main.clientWidth;
        if (w<=760) {
            document.body.classList.add("nocolumns");
            document.body.classList.remove("columns");
            document.body.classList.add("noColumnsView");
        }
        else {
            document.body.classList.remove("noColumnsView")
            let view=localStorage["view"] || "columns";
            document.body.classList.add(view);
            document.body.classList.remove(view=="columns" ? "nocolumns" : "columns");
        }
    }
    
}



export function toggleColumns() {
    let cols=!document.body.classList.contains("columns");    
    document.body.classList.remove(cols ? "nocolumns" : "columns");
    document.body.classList.add(cols ? "columns" : "nocolumns");
    localStorage["view"]=cols ? "columns" : "nocolumns";
    rescaleMain();
}

function togglePreview(){
    let showWeb = !document.body.classList.contains("webView");
    document.body.classList.remove(showWeb ? "printView" : "webView");
    document.body.classList.add(showWeb ? "webView" : "printView");
    if (!showWeb){
        document.body.classList.remove("nocolumns");
        document.body.classList.add("columns");
    }
    document.getElementById("previewLink").innerText = showWeb ? "Print View" : "Web View";
    if (showWeb) article.showClass();
    else {
        let pages = document.querySelectorAll("page");
        for (let i = 0; i < pages.length; i++)
            pages[i].style.display = null;
    }
    article.rescaleMain();
    setScrollbar();

}


window.onbeforeprint=function(){
    webViewBeforPrint=document.body.classList.contains("webView");
    columnsBeforePrint=document.body.classList.contains("columns");
    document.body.classList.remove("webView");
    document.body.classList.add("printView");
    document.body.classList.remove("nocolumns");
    document.body.classList.add("columns");
    scrollBeforePrint=scrollPos;
    scrollPos=0;
    setScrollPos();
}

window.onafterprint=function(){
    if (webViewBeforPrint) {
        document.body.classList.remove("printView");
        document.body.classList.add("webView");
    }
    if (!columnsBeforePrint){
        document.body.classList.remove("columns");
        document.body.classList.add("nocolumns");
    }
    scrollPos=this.scrollBeforePrint;
    setScrollPos();
}
