import React, { useEffect, useRef, useState } from 'react';
import OrigDiceBox from "@3d-dice/dice-box";

import "./dice.scss";

export const Dice={
    Box:null,
    roll(dice,...plus){
        this.isRolling=true;
        return rollDice(this,dice,...plus);
    },
    showResult:null,
    showButtons:null,
    toggleShowButtons:null,
    isRolling:false,
}

function initDice(canvasId='dice-canvas',showResult){
    return new Promise(resolve => {
            console.log("Box init started",new Date().getTime());
            let Box = new OrigDiceBox("#"+canvasId, {
                assetPath: "/assets/",
                theme: "default",
                offscreen: true,
                scale: 5
            });
            Box.init().then(async (world) => {
                console.log("Box init complete",new Date().getTime());
                Dice.Box=Box;
                Dice.showResult=showResult;
                resolve(Dice);
            });
        //}
    });
}

function rollDice(box,dice,...plus){
    return new Promise(async (resolve,reject) => {
            box.Box.onRollComplete=(results) => {
                console.log("Dice results",results)
                let value=0;
                results.forEach(r => {
                    r.rolls.forEach(r2 => value+=r2.value)
                })
                let values=[value];

                /*
                let value=results[0].value;
                let values=results[0].rolls.map(r => r.value);
                */
                let rollString=dice;
                if (Array.isArray(dice)) rollString=dice.join("+");
                let resultString=values.join('+');
                plus.forEach(p => {
                    values.push(p);
                    value+=p;
                    if (p>0) {
                        resultString+="+"+p;
                        rollString+="+"+p;
                    }
                    else if (p<0) {
                        resultString+=p;
                        rollString+=p;
                    }
                })
                let result={value,values,resultString,rollString};
                if (box.showResult) box.showResult(result);
                Dice.isRolling=false;
                resolve(result);
            }
            box.Box.roll(dice)
    });
}


export function DiceBox({canvasId="dice-canvas",onResult=null,onInit=null}){
    const [result,setResult]=useState('');
    const [resultString,setResultString]=useState('');
    const [rollString,setRollString]=useState('');
    const [box,setBox]=useState(null);
    const [showButtons,setShowButtons]=useState(false);
    const [hasDice,setHasDice]=useState(false);

    console.log("DiceBox",showButtons)
    useEffect(() => {
        console.log("InitDice");
        initDice(canvasId,showResult).then(box => {
            setBox(box);
            if (onInit) onInit(box);
        });
    },[]);

    Dice.showButtons=setShowButtons;
    Dice.toggleShowButtons=toggleShowButtons;

    function toggleShowButtons(){
        console.log("toggle",showButtons);
        setShowButtons(!showButtons);
    }

    function showResult(result){
        setResult(result.value);
        setResultString(result.resultString);
        setRollString(result.rollString);
        setHasDice(true);
        if (onResult) onResult(result);
    }

    function clear(){
        console.log("Clear");
        box.Box.clear();
        setHasDice(false);
    }

    function clearAndHide(){
        Dice.Box.clear();
        setHasDice(false);
        setShowButtons(false);
    }

    function roll(dice,plus=0){
        rollDice(box,dice,plus).then(result => {
            console.log("Result",result);
            showResult(result);
        })
    }


    let html=document.querySelector("html");
    let w=html.clientWidth;
    let h=html.clientHeight;
    console.log("Diceholder render",w,h)
    return <>
        <canvas className='diceCanvas' id={canvasId} width={w-20} height={h-40} />
        <div className="diceButtons">
            {showButtons || hasDice ? <div>
                <h2>Dice <span onClick={clearAndHide}></span></h2>
                {showButtons ? <>
                    <button className="buttonD4" onClick={() => roll("1d4")}></button>
                    <button className="buttonD6" onClick={() => roll("1d6")}></button>
                    <button className="buttonD8" onClick={() => roll("1d8")}></button>
                    <button className="buttonD20" onClick={() => roll("1d20")}></button>
                    <button className="button2D4-2" onClick={() => roll("2d4",-2)}></button>
                </>: <></> }
            {/*hasDice*/ false ? <input type="button" value="Clear" onClick={clear}  /> : <></>}
            </div> : <></>}
            {hasDice ? <div>                 
                <p className='diceResult'><span className='rollString'>{rollString}</span><span> = </span><span className='resultString'>{resultString}</span><span> = </span><span className='resultValue'>{result}</span></p>
            </div>: <></> }
        </div>
    </>

}