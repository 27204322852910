import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { setClicks, setMapData } from '../utils/mapstore';
import { Checkbox } from '../../utils/formfields';
import { sendToCampaign } from '../../utils/websocket';

export function OpeningOptions({map}){
    let {clicked}=useSelector(state => state.maps);
    let {share,role}=useSelector(state => state.game);
    let {opening,line}=clicked;
    let [object,setObject]=useState(opening);

    useEffect(function(){
        setObject(opening);
    },[])

    function createRoom(){
        setClicks([opening.end,opening.start]);
    }

    function setType(type){
        let op=map.openings.find(o => o.rowKey==opening.rowKey);
        op.type=type;
        if (type=="full") op.width=0;
        if (type=="window") op.width=2;
        if (type=="arc"){
            op.width=10;
            op.isOpen=false;
        }
        setObject({...op});
        map.drawLatest({...line});
    }

    function setWidth(width){
        let op=map.openings.find(o => o.rowKey==opening.rowKey);
        op.width=width;
        setObject({...op});
        map.drawLatest({...line});
    }

    function setOpen(isOpen){
        let op=map.openings.find(o => o.rowKey==opening.rowKey);
        op.isOpen=isOpen;
        setObject({...op});
        map.drawLatest({...line});
    }

    function setLocked(isLocked){
        let op=map.openings.find(o => o.rowKey==opening.rowKey);
        op.isLocked=isLocked;
        if (isLocked) op.isOpen=false;
        setObject({...op});
        map.drawLatest({...line});
    }

    function deleteOpening(){

    }

    function save(){
        setMapData(map);
        if (role=="Master" && share && share.itemRowKey==map.options.rowKey){ 
            console.log("Send map",map);
            sendToCampaign("setMapData",map);
        }
    }

    console.log("Opening",object)

    return <div className="openingOptions">
        <div>
            <h3>Type</h3>
            <Checkbox label="Door" type='circle' checked={object.type=="door"} onChange={v => v ? setType("door") : 0} />
            <Checkbox label="Gate" type='circle' checked={object.type=="gate"} onChange={v => v ? setType("gate") : 0} />
            <Checkbox label="Opening" type="circle" checked={object.type=="opening"} onChange={v => v ? setType("opening") : 0} />
            <Checkbox label="Window" type="circle" checked={object.type=="window"} onChange={v => v ? setType("window") : 0} />
            <Checkbox label="Arc" type="circle" checked={object.type=="arc"} onChange={v => v ? setType("arc") : 0} />
        </div>
        {object.type=="door" || object.type=="gate" ? <div>
            <Checkbox label="Open" checked={object.isOpen} editable={!object.isLocked} onChange={v => setOpen(v)} />
            <Checkbox label="Locked" checked={object.isLocked} onChange={v => setLocked(v)} />
        </div> : <></>}
        {object.type!="window" && object.type!="arc"? <div>
            <h3>Width</h3>
            {object.type=="opening" ? <Checkbox label="Full wall" type="circle" checked={object.width==0} onChange={v => v ? setWidth(0) : 0} /> : <></> }
            <Checkbox label="Single (5')" type="circle" checked={object.width==5} onChange={v => v ? setWidth(5) : 0} />
            <Checkbox label="Double (10')" type="circle" checked={object.width==10} onChange={v => v ? setWidth(10) : 0} />
        </div> : <></> }
        <div>
            <input type="button" value="Save" onClick={save} />
            <input type="button" value="Delete" onClick={deleteOpening} />
        </div>
    </div>
}