import React from 'react';

import './ranks.scss';

export function Ranks({value,title='',onChange=null, onClick=null,min=0,max=6,styles=""}){

    function clicked(v){
        if (onClick){
            onClick()
            return;
        }
        if (!onChange) return;
        if (v>value) onChange(v);
        else onChange(v-1);
    }

    let spans=[1,2,3,4,5,6];
    spans=spans.map(v => {
        let onClick= (v>min && v<=max) ? () => clicked(v) : () => 0;
        let cls="";
        if (onChange && v>min && v<=max) cls="clickable";
        else if (onChange) cls="dimmed";
        cls+=(value>=v ? " filledrank" : " emptyrank");
        return <span key={"r-"+v} onClick={onClick} className={cls}></span>
    })

    let cls='';
    if (onChange) cls="clickable";

    return <div className={'ranks '+styles}>
        {title ? <span className='rankstitle'>{title}</span> : ''}
        {spans}
    </div>
}

/*
        <span onClick={() => clicked(1)} className={cls+(value>0 ? 'filledrank' : 'emptyrank')}></span>
        <span onClick={() => clicked(2)} className={cls+(value>1 ? 'filledrank' : 'emptyrank')}></span>
        <span onClick={() => clicked(3)} className={cls+(value>2 ? 'filledrank' : 'emptyrank')}></span>
        <span onClick={() => clicked(4)} className={cls+(value>3 ? 'filledrank' : 'emptyrank')}></span>
        <span onClick={() => clicked(5)} className={cls+(value>4 ? 'filledrank' : 'emptyrank')}></span>
        <span onClick={() => clicked(6)} className={cls+(value>5 ? 'filledrank' : 'emptyrank')}></span>
*/