import React from 'react';

import './colorselector.scss';

export const defaultColors={
    pen:'#836363',
    blue:'#6065F0',
    green:'#AFC3A5',
    black:'#000000',
    white:'#ffffff',
}



export function colorAndOpacityFromString(colorString){
    let color=colorString || defaultColors.pen;
    let opacity=255;
    if (color.length==9){
        color=colorString.substring(0,7);
        opacity=parseInt("0x"+colorString.substring(7));
    }
    console.log("colorAndOpacity",colorString,color,opacity)
    return ({color,opacity})
}

export function ColorButton({label,color,selected="",onClick}){
    let cls="colorButton";
    if (color==selected) cls+=" colorSelected";
    let style={backgroundColor:color}
    return <div className={cls} onClick={() => onClick(color)}>
        <div className="colorSample">
            <div style={style}>
            </div>
        </div>
        <div className="colorLabel">{label}</div>
    </div>
}


export function ColorSelector({label="Color",value,onChange,useOpacity=true}){
    let {color,opacity}=colorAndOpacityFromString(value);

    function changeColor(value){
        let colorString=value;
        if (useOpacity) colorString+=opacity.toString(16).padStart(2,'0');
        onChange(colorString);
    }

    function changeOpacity(value){
        let colorString=color+value.toString(16).padStart(2,'0');
        onChange(colorString);
    }

    return <div className='colorSelector'>
            <label>{label}</label>
            <div className="colorButtons">
                <div className="customColorButton">
                    <input type="color" value={color} onChange={ev=>changeColor(ev.target.value)} />
                    <div>Custom</div>
                </div>
                <ColorButton label="Default" color={defaultColors.pen} onClick={changeColor} selected={color} />
                <ColorButton label="Blue" color={defaultColors.blue} onClick={changeColor} selected={color} />
                <ColorButton label="Green" color={defaultColors.green} onClick={changeColor} selected={color} />
                <ColorButton label="Black" color={defaultColors.black} onClick={changeColor} selected={color} />
                <ColorButton label="White" color={defaultColors.white} onClick={changeColor} selected={color} />
            </div>
            {useOpacity ? <div>
                <label>Opacity</label>
                <input type="range" min={0} max={255} value={opacity} onChange={ev => changeOpacity(Number(ev.target.value))} />
            </div> : <></> }
    </div>
}