import React from 'react';
import { Link } from 'react-router-dom';
import { Page } from './page';

import "./welcome.scss"

export function Welcome(){

    return <Page theme="index light" styles="welcomePage" sectionStyles='welcomeContent'>
        <h1>Fortress at the Edge of the Realm</h1>
        <img className="smalldev noprint" src="/images/devider-1.png" />
        <div className="welcomeQuote">
            <p>You have gathered at the local inn realizing that you share a common destination:
                you are all headed to Weliki Poissa, a fortress at the slopes of Ural mountains.
            </p>
            <p>You are studying a <Link to="/article/rules">scroll</Link> one of you happens to have
                describing the land of
                Rus,
                its tribes and nations, as the door to the inn suddenly bursts open....</p>
        </div>
        <img className="smalldev noprint" src="/images/devider-1.png" />
        <div className="standard">
            <p>You are about to begin your travels in the mystical land of Kievan Rus during
                Viking-era.</p>
            <p>The first adventure of this new fantasy role-playing game takes you to the
                distant lands of
                Condora, to the slopes of Ural mountains, where you will learn ancient legends,
                meet human
                tragedies and solve mysteries of intrigue and treachery; and of course combat
                beasts of legend.
            </p>
        </div>
    </Page>
}