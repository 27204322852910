import React, { useEffect, useState } from 'react';
import { HTTP } from '../utils/http';
import { setCampaigns, setCharacters, setPlayer } from '../utils/store';
import { Page } from '../layout/page';

import './login.scss';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

export const loginMainChildren=[
    {index:true, element:<Login />},
    {path:'logout',element: <Logout />},
    {path:'reset', element: <PasswordReset />},
    {path:'register', element: <PasswordReset />},
    {path:'setpsw/:token',element: <NewPassword />},
]

export function NewPassword(){
    const[message,setMessage]=useState("");
    const[psw,setPsw]=useState("");
    const[psw2,setPsw2]=useState("");
    const params=useParams();
    const token=params.token;
    const navigate=useNavigate();

    function setPassword(){
        HTTP.post("/open/setpsw",{password:psw,token}).then(async player => {
            console.log("Response ",player);
            await setPlayer(player,false);
            console.log("Login navigate")
            navigate("/home");
        })
        .catch(err => {
            console.log("Err",err);
        })
    }

    let valid=psw.length>5 && psw==psw2;

    return <div className="login userManagement">
        <h2 id="loginTitle">New password</h2>
        <div>{message}</div>
        <img className="devider"  src="/images/devider-1.png" />
        <div className='loginInput'>
            <label htmlFor="psw">Password</label>
            <input id="psw" type="password" placeholder='Password' value={psw} onChange={ev => setPsw(ev.target.value)} />
        </div>
        <div className='loginInput'>
            <label htmlFor="psw2">Confirm</label>
            <input id="psw2" type="password" placeholder='Your password' value={psw2} onChange={ev => setPsw2(ev.target.value)} />
        </div>
        <div>
            <input disabled={!valid} type="button" value="Set password" onClick={setPassword} />
        </div>
        <img className="devider" src="/images/devider-1.png" />
    </div>

}

export function PasswordReset(){
    let [email,setEmail]=useState('');
    let [message,setMessage]=useState('');
    const navigate=useNavigate();
    const location=useLocation();
    const path=location.pathname;
    console.log("Path",path)
    let isRegister=(path.includes('/register'));
    let [isComplete,setComplete]=useState(false);

    function testRegister(){
        HTTP.post("/open/login",{email,isRegister}).then(resp => {
            console.log("repl",resp);
            setComplete(true);
        })
    }

    let title=isRegister ? "Register" : "Password reset";
    let btn=isRegister ? "Register" : "Reset";

    return <div className="login userManagement">
        <h2 id="loginTitle">{title}</h2>
        {isComplete ? <div>
            {isRegister ? 
            <p>You should shortly receive instructions on how to complete the registration to your email.</p>
            : <p>You should shortly receive an email on how to set the new password.</p>
            }
        </div> :<>
            <div>{message}</div>
            <img className='devider' src="/images/devider-1.png" />
            <div className='loginInput'>
                <label htmlFor="user">Email</label>
                <input id="user" placeholder='Your email' value={email} onChange={ev => setEmail(ev.target.value)} />
            </div>
            <div className="loginButtons">
                <input type="button" value={btn} onClick={testRegister} />
            </div>
            <img className="devider" src="/images/devider-1.png" />
            {isRegister ? <div id="resetGuide">
                <p>Enter your email and press "Register"-button. Instructions on how to complete registration
                    will be sent to your email.
                </p>
            </div> : <div id="resetGuide">
                <p>Enter your email and press "Reset"-button.  Instructions on how to complete the process
                    will be sent to your email.
                </p>

            </div>
            }
        </> }
    </div>




}

export function Logout(){
    let navigate=useNavigate();
    
    function logout(){
        HTTP.delete("/open/login").then(p => {
            setPlayer({})
            setCharacters({});
            navigate("/");
        })
    }

    return <div className='login userManagement'>
        <h2 id="loginTitle">Logout</h2>
        <div>
            <p>Do you really want to logout?</p>
        </div>
        <div>
            <input type="button" value="Logout" onClick={logout} />
            <input type="button" value="Cancel" onClick={() => navigate(-1)} />
        </div>
    </div>

}

export function Login(){
    let [email,setEmail]=useState('');
    let [password,setPassword]=useState('');
    let [message,setMessage]=useState('');
    const navigate=useNavigate();

    function testLogin(){
        //console.log("Testlogin",email,password);
        setMessage("");
        HTTP.put('/open/login',{email,password}).then(async player => {
            await setPlayer(player,false);
            //let camp=localStorage('eorcampaign');
            navigate("/");
        })
        .catch(x => {
            setMessage("Login failed");
        })
    }

    function keyDown(ev){
        if (ev.key=="Enter") testLogin();
    }

    return <div className="login userManagement">
        <div className="loginQuote">
                <p>You have gathered at the local inn realizing that you share a common destination:
                    you are all headed to Weliki Poissa, a fortress at the slopes of Ural mountains.</p>
                <p>You are studying a <Link to="/rules/background">scroll</Link> one of you happens to have describing the land of
                    Rus,
                    its tribes and nations, as the door to the inn suddenly bursts open....</p>
        </div>        
        <img className='devider' src="/images/devider-1.png" />
        <h2 id="loginTitle">Login</h2>
        <div>{message}</div>
        <div className="loginInput">
            <label htmlFor="user">Email</label>
            <input id="user" autoFocus placeholder='Your email' value={email} 
                onKeyDown={keyDown}
                onChange={ev => setEmail(ev.target.value)} />
        </div>
        <div className="loginInput">
            <label htmlFor="password">Password</label>
            <input id="psw" type="password" placeholder='Your password' value={password} 
                onKeyDown={keyDown}
                onChange={ev => setPassword(ev.target.value)} />
        </div>
        <div className="loginButtons">
            <input type="button" value="Login" onClick={testLogin} />
        </div>
        <img className='devider' src="/images/devider-1.png" />
        <div id="resetGuide">
            <p>Forgot the password? Request for <Link to="/login/reset">password reset</Link></p>
        </div>
        <div id="resetGuide">
            <p>Not registered? <Link to="/login/register">Register</Link></p>
        </div>
        <div id="whatIsThis">
            <p><Link to="">What is this?</Link></p>
        </div>
        <div>
        <p>You are about to begin your travels in the mystical land of Kievan Rus during
                                Viking-era.</p>
                            <p>The first adventure of this new fantasy role-playing game takes you to the
                                distant lands of
                                Condora, to the slopes of Ural mountains, where you will learn ancient legends,
                                meet human
                                tragedies and solve mysteries of intrigue and treachery; and of course combat
                                beasts of legend.
                            </p>

        </div>
    </div>
}

export function LoginPage(){

    return <Page theme="index light" styles="loginPage" sectionStyles='loginContent'>
            <Login />
        </Page>        
}

export function LoginMain(){

    return <Page theme="index light" styles="loginPage" sectionStyles='loginContent'>
            <Outlet />
        </Page>        
}