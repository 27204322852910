import React, { useEffect, useState } from 'react';

import './initiative.scss'
import { useSelector } from 'react-redux';
import { createCharacter } from '../character';
import { Dice } from '../../dice/dicebox';
import { clearInitiative, rolledForInitiative, saveCurrentCharacterValues, setUseEliminate } from '../../utils/store';
import { Checkbox } from '../../utils/formfields';

export function InitiativeButton({type, roll}){
    let cls="initiativeButton initiativeButton"+type;
    
    return <div className={cls} title={type} onClick={() => roll(type)}>
    </div>
}

export function DefensiveButton({enabled,onChange}){
    let cls="initiativeButton "+(enabled ? 'defensiveEnabled' : 'defensiveDisabled');
    return <div className={cls} title="Defensive mode" onClick={() => onChange(!enabled)} />
}

export function BonusButton({inUse,onClick}){
    let cls="initiativeButton ";
    cls+=(inUse ? "bonusInUseButton" : "bonusButton");
    return <div className={cls} title="Temporary bonus/penalty" onClick={onClick} />
}

export function Initiative(){
    let {initiatives,currentCharacter}=useSelector(state => state.game);
    let character=createCharacter(currentCharacter);
    let [defensive,setDefensive]=useState(false);
    let [stealthType,setStealthType]=useState(0);
    let [showBonus,setShowBonus]=useState(false);
    let [temporarySkillBonus,setTemporarySkillBonus]=useState(character.values.temporarySkillBonus);
    let [temporaryHitpoints,setTemporaryHitpoints]=useState(character.values.temporaryHitpoints);

    useEffect(function(){
        setTemporarySkillBonus(character.values.temporarySkillBonus);
        setTemporaryHitpoints(character.values.temporaryHitpoints);
    },[currentCharacter])

    function roll(type){
        let bonus=character.skills.afight.value;
        if (type=='Brutal') bonus=character.skills.bfight.value;
        if (type=='Stealth') bonus=character.skills.stealth.value;
        Dice.roll("1d20",bonus,-character.fatiguePenalty).then(result => {
            let fightBonus=0,defenseBonus=0,damageBonus=0;
            if (defensive){
                fightBonus=-4;
                defenseBonus=4;
            }
            if (type=="Brutal" && result.value>=20){
                fightBonus+=2;
                damageBonus=2;
            }
            let obj={character:character.values.rowKey,type,value:result.value,
                fightBonus,defenseBonus,defensive,damageBonus,useEliminate:false};
            rolledForInitiative(obj);
            setStealthType(0);
        })
    }

    function setUnaware(){
        let obj={character:character.values.rowKey,type:"Unaware",value:20,
            fightBonus:8,defenseBonus:0,defensive:false,damageBonus:0,useEliminate:false};
        rolledForInitiative(obj);
        setStealthType(0);
    }

    function applyBonus(){
        character.values.temporaryHitpoints=temporaryHitpoints;
        character.values.temporarySkillBonus=temporarySkillBonus;
        saveCurrentCharacterValues(character);
        setShowBonus(false);
    }

    function clearBonus(){
        character.values.temporaryHitpoints=0;
        character.values.temporarySkillBonus=0;
        saveCurrentCharacterValues(character);
        setShowBonus(false);
    }

    let myInitiative=initiatives[character.values.rowKey] || {};

    function stealthTypeClicked(value){
        setStealthType(value);
        let obj={...myInitiative,useEliminate:(value==1 || value==3)};
        setUseEliminate(obj);
    }

    let bonusInUse=(character.values.temporaryHitpoints || character.values.temporarySkillBonus)

    return <div className="initiative">
        {myInitiative.type ? <>
            <div className="latestInitiative">
                <span>{myInitiative.type}</span>
                <div className="initiativeValue">
                    <p>{myInitiative.value}</p>
                </div>
                <a onClick={() => clearInitiative(character.values.rowKey)}>Clear</a>
            </div>
            <div className="initiativeGuide">
                {(myInitiative.type=="Stealth" || myInitiative.type=="Unaware") && myInitiative.value>=20 ? <div>
                    {myInitiative.type!="Unaware" ? <>You act LAST<br /></> : <>+8 to fight<br /></>}
                    Attack against AC<br/>
                    <Checkbox label="Attack twice" type="circle" checked={stealthType==0} onChange={() => stealthTypeClicked(0)} />
                    <Checkbox label="Eliminate" type="circle" checked={stealthType==1} onChange={() => stealthTypeClicked(1)} />
                    <Checkbox label="Knockout" type="circle" checked={stealthType==2} onChange={() => stealthTypeClicked(2)} />
                    <Checkbox label="Subdue" type="circle" checked={stealthType==3} onChange={() => stealthTypeClicked(3)} />
                </div> : <></>}
                {myInitiative.type=="Stealth" && myInitiative.value<20 ? <p>Failed to sneak<br/>You attack LAST</p> : <></>}
                {myInitiative.type=="Agile" && myInitiative.value>=20 ? <p>You may attack twice</p> : <></>}
                {myInitiative.type=="Brutal" && myInitiative.value>=20 ? <p>+2 to Fight and Damage</p> : <></>}
                {myInitiative.defensive ? <p>+4 to Defence<br/>-4 to Fight</p> : <></>}
                {}
            </div>
        </> : <>
            <div className='initiativeButtons'>
                <div>
                    <InitiativeButton type="Agile" roll={roll} />
                    <InitiativeButton type="Brutal" roll={roll} />
                    <InitiativeButton type="Stealth"  roll={roll} />
                </div>
                <div>
                    <DefensiveButton enabled={defensive} onChange={setDefensive} />
                    <InitiativeButton type="Unaware" roll={setUnaware} />
                    <BonusButton inUse={bonusInUse} onClick={() => setShowBonus(!showBonus)} />
                </div>
            </div>
            {showBonus ? <div className="temporaryBonus">
                <div className="temporaryBonusField">
                    <label>Skill bonus</label>
                    <input type="number" value={temporarySkillBonus} onChange={ev => setTemporarySkillBonus(Number(ev.target.value))} />
                </div>
                <div className='temporaryBonusField'>
                    <label>Temporary hitpoints</label>
                    <input type="number" value={temporaryHitpoints} onChange={ev => setTemporaryHitpoints(Number(ev.target.value))} />
                </div>
                <div className='temporaryBonusButtons'>
                    <input type="button" value="Use" onClick={applyBonus} />
                    {bonusInUse ? <input type="button" value="Clear" onClick={clearBonus} /> : <></> }
                </div>
            </div> : <></>}
        </>
        }
    </div>
}

