import React from 'react';
import { CharacterWizardHelp } from './wizardhelp';
import { Attributes } from '../attributes';

import './wizardattributes.scss';

export function CharacterWizardAttributes({character, onComplete}){
    //character=createCharacter(character);
    //console.log("Wizard char",character.strength)
    return <div className="wizardAttributes">
        <Attributes character={character} onComplete={onComplete} />
        <CharacterWizardHelp search="Attributes" />
    </div>
}

