import { loadHtml } from "../utils/http";

const loaded={};

function loadRulesFile(fn){
    return new Promise(resolve => {
        console.log("Loading",fn,"alreadyloaded",!!loaded[fn])
        if (loaded[fn]) resolve(loaded[fn]);
        else loadHtml("/articles/"+fn).then((data) => {
            let rules=document.createElement("div");
            let helper=document.createElement("div");
            helper.innerHTML=data;
            let artTitle="";
            let h1=helper.querySelector("article > h1");
            if (h1) artTitle=h1.innerHTML;
            let cols=helper.querySelectorAll("column");
            let rule=null;
            for(let ci=0;ci<cols.length;ci++){
                let ch=cols[ci].children;
                for(let i=0;i<ch.length;i++){
                    if (ch[i].localName=="h2"){
                        if (rule) rules.appendChild(rule);
                        rule=null;
                        if (!ch[i].classList.contains("skipSearch")){
                            ch[i].innerHTML+="<span>"+artTitle+"</span>";
                            rule=document.createElement("div");
                            let search=ch[i].innerText;
                            let s=ch[i].getAttribute("search");
                            if (s) search+=","+s; 
                            //console.log(search)
                            rule.setAttribute("search",search.toLowerCase());
                            let wz=ch[i].getAttribute("wizardSearch");
                            if (wz) rule.setAttribute("wizardSearch",wz);
                        }
                    }
                    if (rule) rule.appendChild(ch[i].cloneNode(true));
                }
            }
            if (rule) rules.appendChild(rule);
            let html=rules.innerHTML;
            loaded[fn]=html;
            resolve(html);
        })
    })
}

export const spellList=[];

async function loadSpellsFile(){
    return new Promise(resolve => {
        if (loaded["/articles/spells.html"]) resolve(loaded["/articles/spells.html"])
        else loadHtml("/articles/spells.html").then(data =>{
            let spells=document.createElement("div");
            let helper=document.createElement("div");
            helper.innerHTML=data;
            let s=helper.querySelectorAll("table.spell");
            for(let i=0;i<s.length;i++){
                let d=document.createElement("div");
                let search="spell,";
                let t=s[i].querySelector("td.title");
                let title="";
                if (t) {
                    title=t.innerText;
                    search+=t.innerText;
                }
                let schools=s[i].querySelector("tr:nth-child(2) td:nth-child(2)");
                if (schools) {
                    schools=schools.innerText;
                    search+=","+schools;
                }
                let range=s[i].querySelector("tr:nth-child(3) td:nth-child(2)")?.innerText;
                let duration=s[i].querySelector("tr:nth-child(4) td:nth-child(2)")?.innerText;
                let description=s[i].querySelector("td.description")?.innerHTML;
                spellList.push({title,schools,range,duration,description});
                d.setAttribute("search",search.toLowerCase());
                d.appendChild(s[i].cloneNode(true));
                let nt=d.querySelector("td.title");
                if (nt) nt.innerHTML=t.innerHTML+"<span>Spells</span>";
                spells.appendChild(d);
            }
            loaded["/articles/spells.html"]=spells.innerHTML;
            resolve(spells.innerHTML)
        });
    })
}


export async function loadRules(){
    console.log("Starting rules")
    let char=await loadRulesFile("character.html");
    let skills=await loadRulesFile("skills.html");
    let eth=await loadRulesFile("ethnicity.html");
    let combat=await loadRulesFile("combat.html");
    let magic=await loadRulesFile("magic.html");
    let rules=await loadRulesFile("rules.html");
    let spells=await loadSpellsFile();

    return char+skills+eth+combat+magic+rules+spells;
}

