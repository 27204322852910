import React, { useEffect, useState } from 'react';
import { loadRules } from '../articles/articleutils';

export function Search({rules}){
    let [all,setAll]=useState(false);


    //console.log("RUles",rules)

    function doSearch(ev){
        console.log("Do search")
        let search = ev.target.value.toLowerCase();
        let items = document.querySelectorAll("#searchContent div");
        for (let i = 0; i < items.length; i++) {
            let b = false;
            if (!all) b = (items[i].getAttribute("search") || "").includes(search);
            else b = (items[i].innerText.toLowerCase() || "").includes(search);
            items[i].style.display = b ? "block" : "none";
        }
    }

    return <div className="rulesSearch">
        <div id="options">
            <input id="search" onKeyUp={doSearch} placeholder="Search" />
            <input id="allContent" type="checkbox" checked={all} onChange={() => setAll(!all)} /> Seach all content
        </div>
        <div id="searchContent" dangerouslySetInnerHTML={{__html:rules}} >
        </div>
    </div>
}