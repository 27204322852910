import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { showRankOptions } from '../master/npceditor';
import { useSelector } from 'react-redux';
import { Login, PasswordReset } from '../player/login';
import { joinMeting } from '../webrtc/webrtc';

import "./main.scss";

export function Main(){
    let {player,inMeeting,zoom}=useSelector(state => state.game);

    useEffect(function(){
        console.log("Main got player",player.username);
        //if (player.username) joinMeting(player.username);
    },[player])

    useEffect(function(){
        console.log("Main is testing in meeting");
        let html=document.querySelector("html");
        if (inMeeting) html.classList.add("meeting");
        else html.classList.remove("meeting");
    },[inMeeting]);

    let cls="app view";
    if (inMeeting) cls+=" meeting";

//    return <div id="center" className="app view" onClick={() => showRankOptions()}>
    return <div id="center" className="app view" style={{zoom}}>
        <main>
            <Outlet />
        </main>
    </div>
}