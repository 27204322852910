import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tile } from '../utils/tile';
import { Icon } from '../utils/icons';
import { TextInput } from '../utils/formfields';

import './playerinfo.scss';
import { changeUser, setUserEmail} from '../utils/store';
import { HTTP } from '../utils/http';
import { sendTo } from '../utils/websocket';

export function PlayerInfo(){
    const [username,changeUsername]=useState('');
    const {player,campaign}=useSelector(state => state.game);
    let [editUsername,setEditUsername]=useState(false);
    let [editPassword,setEditPassword]=useState(false);
    let [editEmail,setEditEmail]=useState(false);
    let [originalUsername,setOriginalUsername]=useState("")
    let [password,setPassword]=useState("");
    let [passwordAgain,setPasswordAgain]=useState("");
    let [newEmail,setNewEmail]=useState("");
    let [emailErr,setEmailErr]=useState("");
    let [emailState,setEmailState]=useState(0);
    let [verification,setVerification]=useState("");

    useEffect(function(){
        changeUsername(player.username);
        setOriginalUsername(player.username);
    },[player]);

    function saveUsername(){
        setEditUsername(false);
        changeUser(player.rowKey,username);
        if (campaign?.rowKey){
            let obj={campaign:campaign.rowKey,rowKey:player.rowKey,username,email:player.email};
            sendTo([campaign.rowKey],"updateCampaignPlayer",obj)
        }
    }

    function cancelEditUsername(){
        setEditUsername(false);
        changeUsername(originalUsername);
    }

    function savePassword(){
        changeUser(player.rowKey,null,password);
        cancelEditPassword();
    }

    function cancelEditPassword(){
        setEditPassword(false);
        setPassword("");
        setPasswordAgain("");
    }

    function initChangeEmail(){
        setEmailErr("");
        HTTP.post("/open/initsetemail",{newEmail}).then(resp => {
            console.log("Resp",resp);
            setNewEmail("");
            setEmailState(1);
        }).catch(err => {
            console.log("Error",err);
            setEmailErr("Cannot change email, it may be taken.")
        })
    }

    function completeChangeEmail(){
        setEmailErr("");
        HTTP.post("/open/setemail",{token:verification}).then(resp => {
            console.log("Resp",resp)
            setUserEmail(resp.email);
            setEmailErr("");
            setEmailState(0);
            setEditEmail(false);
        }).catch(err => {
            console.log("Err",err);
            setEmailErr("Cannot verify token, it may be expired");
        })
    }

    function cancelChangeEmail(){
        setEmailErr("");
        setEmailState(0);
        setEditEmail(false);
        setNewEmail("");
    }

    let usernameChanged=username!=originalUsername;
    let usernameValid=username.length>=3;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let isValidEmail=emailPattern.test(newEmail)

    return <Tile header="Profile" styles='playerInfo'>
        <div className="playerUsername">
            <h5>Nickname {!editUsername ? <Icon type={Icon.edit} onClick={() => setEditUsername(!editUsername)} /> : <></> }</h5>
            <div>
                <TextInput placeholder="Nickname" value={username} onChange={ev => changeUsername(ev.target.value)} editable={editUsername} />
                {usernameChanged && usernameValid ? <>
                    <Icon type={Icon.save} onClick={saveUsername} /> 
                    <Icon type={Icon.cancel} onClick={cancelEditUsername} /> 
                </>: <></> }
            </div>
        </div>
        <div className="playerPassword">
            <h5>Password <Icon type={Icon.edit} onClick={() => setEditPassword(!editPassword)} /></h5>
            {editPassword ? <div className='passwordFields'>
                <div className="passwordField">
                    <label>New password</label>
                    <input type="password" value={password} onChange={ev => setPassword(ev.target.value)} /> 
                </div>
                <div className='passwordField'>
                    <label>Again...</label>
                    <input type="password" value={passwordAgain} onChange={ev => setPasswordAgain(ev.target.value)} /> 
                </div>
                {password.length>5 && password==passwordAgain ? <div>
                    <input type="button" value="Change password" onClick={savePassword} />
                    <input type="button" value="Cancel" onClick={cancelEditPassword} />
                </div> : <></>}
            </div> : <></> }
        </div>
        <div className='playerEmail'>
            <h5>Email <Icon type={Icon.edit} onClick={() => setEditEmail(!editEmail)} /></h5>
            {!editEmail ? <p>{player.email}</p> : <>
                {emailErr ? <div>
                    <p>Error: {emailErr}</p>
                </div> : <></>}
                {emailState==0 ? <>
                    <div>
                        <label>Email</label>
                        <input placeholder="New email" type="email" value={newEmail} onChange={ev => setNewEmail(ev.target.value)} />
                    </div>
                    <div>
                        <input type="button" disabled={!isValidEmail} value="Change email" onClick={initChangeEmail} />
                        <input type="button" value="Cancel" onClick={cancelChangeEmail} />
                    </div>
                </> : <></>}
                {emailState==1 ? <>
                    {!emailErr ? <div>
                        <p>Verification code has been sent to {newEmail}. Enter the code below to complete the process.</p>
                    </div> : <></>}
                    <div>
                        <label>Verification</label>
                        <input value={verification} onChange={ev => setVerification(ev.target.value)} />
                    </div>
                    <div>
                        <input type="button" value="Confirm" onClick={completeChangeEmail} />
                        <input type="button" value="Cancel" onClick={cancelChangeEmail} />
                    </div>
                </> : <></>}
            </>}
        </div>
    </Tile>
}