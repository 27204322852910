import React, { useState } from 'react';
import { createCharacter, skillAttributes,skillNames } from '../character';

import './skills.scss';
import { Ranks } from '../../utils/ranks';
import { Dice } from '../../dice/dicebox';
import { Fatigue } from '../fatigue/fatigue';
import { sendToCampaign, sendToGm } from '../../utils/websocket';
import { Icon } from '../../utils/icons';
import { useSelector } from 'react-redux';
import { HTTP } from '../../utils/http';
import { saveCurrentCharacterValues, updateCharacterValues } from '../../utils/store';

export function Skill({character,field,edit}){
    let {role}=useSelector(state => state.game);
    let [canAdd,setCanAdd]=useState(false);
    let [update,forceUpdate]=useState(new Date());
    let ranks=0,name='',value='',baseAttribute='';
    if (field=='hd'){
        name="Hit dice";
        ranks=character.hd;
    }
    else if (field=='fight'){
        name="Fight";
        ranks=character.values.fight;
    }
    else {
        let skill=character.skills[field];
        name=skill.name;
        value=skill.value;
        ranks=skill.ranks;
        baseAttribute=skill.baseAttribute;
        //{name,value,ranks,baseAttribute}=character.skills[field];
    }
    let [isRolling,setIsRolling]=useState(false);
  

    function test(){
        if(Dice.isRolling) return;
        setIsRolling(true);
        if (edit){
            Dice.roll("2d4",-2).then(result => {
                let obj={characterid:character.rowKey,name:character.name,skill:name+"("+ranks+")",result};
                sendToGm('skilltest',obj)
                setIsRolling(false);
                if (result.value>ranks) setCanAdd(true);
            })
            }
        else {
            Dice.roll("1d20",value,-character.fatiguePenalty).then(result => {
                console.log("Tested",character);
                let obj={characterid:character.rowKey,name:character.name,skill:name,result};
                console.log(obj);
                sendToGm('skilltest',obj)
                setIsRolling(false);
            })
       }
    }

    function add(value=1){
        character[field]+=value;
        if (role!="Master") setCanAdd(false);
        forceUpdate(new Date());
    }

    let cls=isRolling ? "skill" : "skill clickable";
    return <div className={cls}>
            <div  onClick={test}>
                <div>{name} <span className="skillvalue">{value}</span></div>
                <Ranks value={ranks} title={baseAttribute} />
            </div>
            {canAdd || (role=="Master" && edit ) ? <div>
                {canAdd || (role=="Master" && edit && ranks<6) ? <Icon type={Icon.plus} onClick={()=>add()} /> : <></>}
                {role=="Master" && edit && ranks>0 ? <Icon type={Icon.minus} onClick={()=>add(-1)} /> : <></>}
            </div> : <></> }
    </div>
}

export function Skills(){
    const {role,currentCharacter}=useSelector(state => state.game);
    const [update,setUpdate]=useState(new Date());
    const [packingsDropped,setPackingsDropped]=useState(false);
    const [edit,setEdit]=useState(false);
    const [originalValues,setOriginalValues]=useState(Object.assign({},currentCharacter.values));
    let skills=[];
    let character=createCharacter(currentCharacter,packingsDropped)

    for(let k in character.skills){
        let skill=character.skills[k];
        //console.log(k,skill)
        if (skill.ranks<0 && (role!="Master" || !edit)) continue;
        skills.push(skill);
    }

    function save(){
        setOriginalValues(Object.assign({},character.values));
        setEdit(false);
        //updateCharacterValues(character.values);
        saveCurrentCharacterValues(character);
    }

    function cancel(){
        character.values=originalValues;
        setEdit(false)
    }

    skills.sort((a,b) => a.field.localeCompare(b.field));
    let skillRows=skills.map(s => <Skill key={s.field} character={character} field={s.field} edit={edit} />)

    let enc=character.encumbered;
    let canDrop=enc?.length==1 && enc[0]==1; //

    return <div className='skills'>
        <h3>Skills 
            {!edit ? <Icon type={Icon.edit+(edit ? Icon.dimmed : "")} onClick={() => setEdit(!edit)} /> : <></> }
            {edit ? <>
                <Icon type={Icon.accept} onClick={save} style="first" /> 
                <Icon type={Icon.cancel} onClick={cancel}  />
            </> : <></>}
        </h3>
        {!edit ? <>
            <Fatigue character={character} forceUpdate={() => setUpdate(new Date())} />
            {enc ? <div className="encumberedNote"><p>You are encumbered.
            {canDrop ? <span className="dropPackings" onClick={() => setPackingsDropped(true)}>Drop packings</span> : <>Your dexterity is effectively 0.</>}</p></div> : <></>}
        </> : <div>
            <Skill character={character} field="fight" edit={edit} />
            <Skill character={character} field="hd" edit={edit} />
        </div>}
        {skillRows}
</div>
}