import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Dice } from '../../dice/dicebox';

import './weapons.scss';
import { guid } from '../../utils/utils';
import { createCharacter } from '../character';

export function Weapon({weapon,arrow,onClick}){
    let {initiatives,currentCharacter}=useSelector(state => state.game);
    let character=createCharacter(currentCharacter,true);
    let myInitiative=initiatives[character.values.rowKey] || {fightBonus:0,defenseBonus:0,damageBonus:0,useEliminate:false};

    let charDamageBonus=character.damageBonus;
    if (weapon.category=="Crossbow") charDamageBonus=4;
    
    let isBow=(weapon.category=='Bow' || weapon.category=='Crossbow');
    let damageBonus=(weapon.bonus||0)+(myInitiative.damageBonus||0);
    if (isBow) damageBonus+=arrow?.bonus || 0;
    if (!myInitiative.useEliminate) damageBonus+=charDamageBonus;
    if (damageBonus>6) damageBonus=6;

    let damageDice=weapon.damage||'1d4';
    if (isBow) damageDice=arrow?.damage || '1d4';
    if (myInitiative.useEliminate) damageDice+="+"+charDamageBonus+"d6";

    let damage=damageDice;
    if (damageBonus) damage+="+"+damageBonus;

    let aEnabled=(myInitiative.type=="Agile") || (myInitiative.type=="Stealth");
    let bEnabled=(myInitiative.type=="Brutal") || (myInitiative.type=="Stealth");

    let aValue=character.skills.afight.value+(weapon.bonus||0);
    let bValue=character.skills.bfight.value+(weapon.bonus||0)+myInitiative.fightBonus;

    function rollFight(value){
        if (onClick) return;
        Dice.roll("1d20",value).then(result => {
            console.log("Result",result);
        })
    }

    function rollDamage(){
        if (onClick) return;
        let dice=damageDice.split("+");
        console.log("Damage dice");
        Dice.roll(dice,damageBonus).then(result => {
            console.log("Damage result",result.value);
        })
    }

    let aStyle="combatValue"+(aEnabled ? " activeCombatValue clickable" : " inactiveCombatValue");
    let bStyle="combatValue"+(bEnabled ? " activeCombatValue clickable" : " inactiveCombatValue");
    let cls="weapon";
    if (onClick) cls+=" clickable";
    return <div className={cls} onClick={() => (onClick ? onClick(weapon) : 0)}>
        <div className={aStyle} onClick={() => (aEnabled && !onClick ? rollFight(aValue) : 0)}>
            <p>{aValue}</p>
        </div>
        <div className="fightRanks">
            <p className="weaponTitle">{weapon.name}{weapon.bonus ? '+'+weapon.bonus : ''}</p>
            {isBow ? <p><span>{arrow.name}{arrow.bonus ? ' +'+arrow.bonus : ''}</span></p> : <></>}
            <p className="weaponDamage" onClick={rollDamage}>{damage}</p>
        </div>
        <div className={bStyle} onClick={() => (bEnabled && !onClick ? rollFight(bValue) : 0)}>
            <p>{bValue}</p>
        </div>
    </div>
}


export function Weapons(){
    let {currentCharacter}=useSelector(state => state.game);
    let character=createCharacter(currentCharacter,true);

    let weapons=character.items.filter(i => i.category=='Weapon');
    let arrows=character.items.filter(i => i.category=="Arrows")
        .filter(a => a.amount>0)
        .sort((a,b) => (b.damage || '1d4').localeCompare(a.damage || '1d4'));
    let bows=character.items.filter(i => (i.category=='Bow' || i.category=='Crossbow') && arrows.length);
    let dagger={rowKey:guid("wpn-dagger"),name:'Dagger',bonus:0,damage:"1d4"};
    let improvised={rowKey:guid("wpn-improvised"),name:'Improvised',bonus:0,damage:"1d3"};
    let unarmed={rowKey:guid("wpn-unarmed"),name:'Unarmed',bonus:0,damage:"1d2"};
    let allWeapons=[...weapons,...bows,dagger,improvised,unarmed].sort((a,b) => (b.damage || '1d4').localeCompare(a.damage || '1d4'));
    let [current,setCurrent]=useState(allWeapons[0]);
    let [currentArrow,setCurrentArrow]=useState(arrows.length ? arrows[0] : '');
    let [showList,setShowList]=useState(0);

    function currentSelected(w){
        setCurrent(w);
        if ((w.category=='Bow' || w.category=="Crossbow") && (arrows.length>1)) setShowList(2);
        else setShowList(0)
    }

    function arrowsSelected(a){
        setCurrentArrow(a);
        setShowList(0);
    }

    let weaponRows=allWeapons.filter(w => w.rowKey!=current.rowKey)
        .map(w => <Weapon key={w.rowKey} arrow={currentArrow} weapon={w} onClick={() => currentSelected(w)} />)
    let arrowRows=arrows.map(a => <div key={a.rowKey} className='arrowRow clickable' onClick={() => arrowsSelected(a)}>
        <p>{a.name}{a.bonus ? '(+'+a.bonus+')':''}</p><p>{a.damage}{a.bonus ? '(+'+a.bonus+')':''}</p></div>)
    return <div className="combatWeapons">
        <Weapon key={current.rowKey} arrow={currentArrow} weapon={current} />
        <div className="chooseWeapon" onClick={() => setShowList(1)}><a>Change weapon</a></div>
        {showList==1 ? <div className="combatWeaponList">{weaponRows}</div> :
        showList==2 ? <div className="combatArrowList">{arrowRows}</div> : <></> }
    </div>
}

