import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { PlayerRolls } from '../../utils/playerrolls';
import { MapEditor } from '../../map/mapeditor';
import { CharacterChat } from '../../character/characterchat';

import './realmmaster.scss'
import { Tile } from '../../utils/tile';
import { Link } from 'react-router-dom';
import { HTTP } from '../../utils/http';
import { sendToCampaign } from '../../utils/websocket';
import { saveCampaignBasics } from '../../utils/store';

function CampaignProperties(){
    let {campaign}=useSelector(state => state.game);
    let [name,setName]=useState(campaign.name);
    let [currentDate,setCurrentDate]=useState(campaign.currentDate)
    let [note,setNote]=useState(campaign.note || "")

    function saveClicked(){
        let {partitionKey,rowKey}=campaign;
        let obj={partitionKey,rowKey,name,currentDate,note};
        saveCampaignBasics(obj)
    }

    function cancelClicked(){
        setName(campaign.name);
        setCurrentDate(campaign.currentDate);
    }

    let canSave=(name && currentDate);

    return <Tile header="properties">
        <div className="formField">
            <label>Name</label>
            <input value={name} onChange={ev => setName(ev.target.value)} />
        </div>
        <div className="formField">
            <label>Current date</label>
            <input value={currentDate} onChange={ev => setCurrentDate(ev.target.value)} />
        </div>
        <div className='formField'>
            <label>Realm Master Notes</label>
            <textarea value={note} onChange={ev => setNote(ev.target.value)} />
        </div>
        <div className='formButtons'>
            <input type="button" value="Save" onClick={saveClicked} disabled={!canSave} />
            <input type="button" value="Cancel" onClick={cancelClicked}/>
        </div>
    </Tile>
}



function RealmMasterNavigation(){

    return <Tile header="Navigation">
        <p>Import character to campaign</p>
        <p>Let player play another player's character</p>
        <p><Link to="/campaign/npcs">Manage NPCs</Link></p>
        <p><Link to="/campaign/maps">Maps and encounters</Link></p>
        <p><Link to="/campaign/images">Images</Link></p>
    </Tile>
}

function RealmMasterTools({character}){
    return <div className='realmMasterTools'>
        <CampaignProperties />
        <PlayerRolls />
        <RealmMasterNavigation />
    </div>
}

function RealmMasterView(){
    let {campaign}=useSelector(state => state.game);

    let name="Realm Master "+campaign.owner.username;
    let char={values:{name,rowKey:campaign.owner.rowKey,partitionKey:campaign.owner.rowKey,
        username:campaign.owner.username+", your almighty Realm Master"}};
    return <div className="realmMasterForPlayers">
        <CharacterChat character={char} targetGuid={campaign.owner.rowKey} />
    </div>
}

export function RealmMasterDetails(){
    let {role}=useSelector(state => state.game);

    return <div className="subNavContent">
        {role=="Master" ? <RealmMasterTools /> : <RealmMasterView /> }
    </div>
}

