import React, { useState } from "react";

import './reputation.scss'
import { Dice } from "../dice/dicebox";
import { sendToGm } from "../utils/websocket";
import { useSelector } from "react-redux";
import { Icon } from "../utils/icons";

export function Reputation({character,edit,canTest=true,wizardHandler=null}){
    let {role}=useSelector(state => state.game);
    let [isRolling,setIsRolling]=useState(false);
    let [canAdd,setCanAdd]=useState("");
    let [update,forceUpdate]=useState(new Date());

    function clicked(item){
        if (wizardHandler) wizardHandler(item);
        if (!canTest) return;
        if (Dice.isRolling) return;
        setIsRolling(true);
        Dice.roll("2d4",-2).then(result => {
            console.log("Result",item,result)
            let obj={characterid:character.rowKey,name:character.name,skill:item+"("+character[item]+")",value:character[item],result};
            console.log('test',edit,result.value,character[item]);
            sendToGm('skilltest',obj);
            setIsRolling(false);
            if (edit && result.value>character[item]) setCanAdd(item);
        })
    }

    function add(item,value=1){
        character[item]+=value;
        if (role!="Master") setCanAdd("");
        forceUpdate(new Date());
    }

    let clickable=isRolling ? "" : " clickable"
    return <div className="characterReputation">
        <div className="lawChaos reputationPair">
            <div className={"reputationItem reputationLeft"+clickable} onClick={() => clicked('chaotic')}>
                <div>
                    <img src={`/images/scale${character.chaotic}.png`} />
                    <p>Chaotic</p>
                </div>
                <div className="modIcons">
                    {canAdd=="chaotic" || (role=="Master" && edit && character.chaotic<6) ? <Icon type={Icon.plus} onClick={() => add('chaotic')} /> : <></>}
                    {role=="Master" && edit && character.chaotic>0 ? <Icon type={Icon.minus} onClick={() => add('chaotic',-1)} /> : <></>}
                </div>
            </div>
            <div className={"reputationItem reputationRight"+clickable} onClick={() => clicked('lawful')}>
                <div>
                    <img src={`/images/scale${character.lawful}.png`} />
                    <p>Lawful</p>
                </div>
                <div className="modIcons">
                    {canAdd=="lawful" || (role=="Master" && edit && character.lawful<6) ? <Icon type={Icon.plus} onClick={() => add('lawful')} /> : <></>}
                    {role=="Master" && edit && character.lawful>0 ? <Icon type={Icon.minus} onClick={() => add('lawful',-1)} /> : <></>}
                </div>
            </div>
        </div>
        <div className="selflessSelfish reputationPair">
            <div className={"reputationItem reputationLeft"+clickable} onClick={() => clicked('selfish')}>
                <div>
                    <img src={`/images/scale${character.selfish}.png`} />
                    <p>Selfish</p>
                </div>
                <div className="modIcons">
                    {canAdd=="selfishs" || (role=="Master" && edit && character.selfish<6) ? <Icon type={Icon.plus} onClick={() => add('selfish')} /> : <></>}
                    {role=="Master" && edit && character.selfish>0 ? <Icon type={Icon.minus} onClick={() => add('selfish',-1)} /> : <></>}
                </div>
            </div>
            <div className={"reputationItem reputationRight"+clickable} onClick={() => clicked('selfless')}>
                <div>
                    <img src={`/images/scale${character.selfless}.png`} />
                    <p>Selfless</p>
                </div>
                <div className="modIcons">
                    {canAdd=="selfless" || (role=="Master" && edit && character.selfless<6) ? <Icon type={Icon.plus} onClick={() => add('selfless')} /> : <></>}
                    {role=="Master" && edit && character.selfless>0 ? <Icon type={Icon.minus} onClick={() => add('selfless',-1)} /> : <></>}
                </div>
            </div>
        </div>
    </div>
}