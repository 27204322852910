import React, { useEffect } from 'react';
import { PlayerEncounter } from '../encounter/encounter';
import { HandoutDetail } from '../handouts/handouts';
import { useSelector } from 'react-redux';
import { MapEditor } from '../map/mapeditor';
import { loadMap, setEncounter } from '../map/utils/mapstore';
import { HTTP } from '../utils/http';

export function ShareView(){
    let {share}=useSelector(state => state.game);
    let {mapData}=useSelector(state => state.maps);
    if (!share) share={};
    let {target,imageUrl,sharedBy,title,itemType,itemRowKey}=share;

    console.log("Share",imageUrl)

    useEffect(function(){
        if (itemType=="map"){
            loadMap(itemRowKey); /*.then(map => {
                if (!map.activeEncounter) setEncounter(null);
                else {
                    HTTP.get("/api/encounters/"+map.activeEncounter).then(enc => {
                        let opponents=enc.opponents || [];
                        let avatars=enc.avatars || [] ;
                        setEncounter({opponents,avatars});
                    })
                }
            })*/
        }
    },[share])

    return <div className="campaignContent shareView">
        {false && itemType=="encounter" ? <PlayerEncounter rowKey={itemRowKey} /> :
         itemType=="handout" ? <HandoutDetail rowKey={itemRowKey} noButtons={true} /> : 
         itemType=="map" && mapData?.options.rowKey==itemRowKey ? <MapEditor maxWidth={800} /> :
         itemType=="image" ? <div className="sharedImage"><img src={imageUrl} /></div> :
         <div className="nothingShared"><p>Nothing shared</p></div>
        }
    </div>
}