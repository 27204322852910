import React, { useEffect, useState } from 'react';

import './lineoptions.scss';
import { useSelector } from 'react-redux';
import { saveMap, setClicked, setCurrent, setMapData } from '../utils/mapstore';
import { ColorSelector, defaultColors } from '../utils/colorselector';
import { Checkbox } from '../../utils/formfields';
import { useNavigate } from 'react-router-dom';
import { sendToCampaign } from '../../utils/websocket';


const defaultLineObject={
    itemType:'line',
    visible:true,
    room:"",
    layer:5,
    lineWidth:1,
    lineType:'solid',
    rough:false,
    lineColor:defaultColors.pen,
    closed:true,
    openings:[],
    fillType:'no-fill',
    fillColor: defaultColors.pen,
    cornerRadius:20,
    image:'',
    target:'',
}

/*
    type:'fill',
    fillColor: defaultColors.pen,
    lineWidth:1,
    lineType:'solid',
    lineColor:defaultColors.pen,
    cornerRadius:20,
    layer:5,
    image:'',
    target:'',
    openings:[],    
*/

export function LineOptions({map}){
    let {clicked,clicks,maps}=useSelector(state => state.maps);
    let {share,role}=useSelector(state => state.game);
    let points=clicked.line?.points || clicks;
    let segment=clicked.segment;
    let [object,setObject]=useState(clicked.line ? {...clicked.line} : defaultLineObject);
    const navigate=useNavigate();

    useEffect(function(){
        console.log("Use effect",object);
        map.drawLatest({...object,points});
    },[])

    useEffect(function(){
        setObject(clicked.line ? {...clicked.line} : defaultLineObject)
    },[clicked])


    function changeProperty(property,value,isNumber=false){
        console.log("Property",property,value)
        if (isNumber) value=Number(value);
        object[property]=value;
        setObject({...object});
        map.drawLatest({...object,points});
    }


    function save(){
        let room=clicked.line?.rowKey;
        if (!clicked.line) {
            map.addObject({...object,points,room})
        }
        else {
            let existing=map.objects.find(l => l.rowKey==object.rowKey);
            Object.assign(existing,object);
        }
        if (role=="Master" && share && share.itemRowKey==map.options.rowKey){ 
            console.log("Send map",map);
            sendToCampaign("setMapData",map);
        }
        setMapData(map);
    }

    function createOpening(type){
        map.createOpening(type);
        setMapData(map);
    }

    function setSelected(){

    }

    function goToTarget(){
        let room=clicked.line?.rowKey;
        if (!clicked.line) {
            map.addObject({...object,points,room})
        }
        else {
            let existing=map.objects.find(l => l.rowKey==object.rowKey);
            Object.assign(existing,object);
        }
        saveMap(map).then(map => {
            navigate("/campaign/maps/"+object.target);
        })
    }

    function deleteLine(){
        let rowKey=object.rowKey;
        let index=map.objects.findIndex(o => o.rowKey==rowKey);
        if (index<0) return;
        if (!confirm("Really delete object")) return;
        map.objects.splice(index,1);
        map.assets.forEach(asset => {
            if (asset.room==rowKey) asset.room="";
        });
        setClicked();
        setMapData(map);
    }

    let targetOptions=maps.map(m => <option key={m.rowKey} value={m.rowKey}>{m.name}</option>);

    return <div className='lineOptions'>
        <h3>Line</h3>
        <div className="lineBasicOptions">
            {points.length>2 ? <div>
                <button title='Closed' className={'mapToolButton buttonDrawArea'+(object.closed ? ' selectedTool': '')} onClick={() => changeProperty('closed',true)} />
                <button title="Open" className={'mapToolButton buttonDrawLine'+(!object.closed ? ' selectedTool': '')} onClick={() => changeProperty('closed',false)} />
            </div> : <></> }
            <div>
                <button title='Solid' className={'mapToolButton buttonLineSolid'+(object.lineType=='solid' ? ' selectedTool': '')} onClick={() => changeProperty('lineType','solid')} />
                {points.length >2 ? <button title='Curve' className={'mapToolButton buttonLineCurve'+(object.lineType=='curve' ? ' selectedTool': '')} onClick={() => changeProperty('lineType','curve')} /> : <></> }
                <button title='Fence' className={'mapToolButton buttonLineFence'+(object.lineType=='fence' ? ' selectedTool': '')} onClick={() => changeProperty('lineType','fence')} />
                <button title='Solid' className={'mapToolButton buttonLineFortification'+(object.lineType=='fortification' ? ' selectedTool': '')} onClick={() => changeProperty('lineType','fortification')} />
                <button title='Log' className={'mapToolButton buttonLineLog'+(object.lineType=='log' ? ' selectedTool': '')} onClick={() => changeProperty('lineType','log')} />
                <button title='Stone' className={'mapToolButton buttonLineStone'+(object.lineType=='stone' ? ' selectedTool': '')} onClick={() => changeProperty('lineType','stone')} />
            </div>
        </div>
        {object.lineType=="solid" ? <div>
            <Checkbox label="Rough" boxPosition='right' checked={object.rough} onChange={v => changeProperty("rough",v)} />
        </div> : <></>}
        {object.lineType=="curve" ? <div>
            <label>Corner radius</label>
            <input type="range" min={1} max={30} value={object.cornerRadius} onChange={ev => changeProperty('cornerRadius',ev.target.value,true)} />
        </div> : <></> }
        <div>
            <Checkbox  label="Visible to players" boxPosition='right' checked={object.visible} onChange={v => changeProperty("visible",v)} />
            {map.options.onlyShowVisited ? <Checkbox label="Visited" boxPosition='right' checked={object.visited} onChange={v => changeProperty("visited",v)} /> : <></>}
        </div>
        <div>
            <ColorSelector label="Color" value={object.lineColor} onChange={color => changeProperty("lineColor",color)} />
        </div>
        <div>
            <label>Width</label>
            <input type="range" min={1} max={10} value={object.lineWidth} onChange={ev => changeProperty("lineWidth",ev.target.value,true)} />
        </div>
        {object.closed && points.length>2 ? <>
            <div>
                <label>Fill-type</label>
                <div>
                    <Checkbox label="Fill" type="circle" checked={object.fillType=='fill'} onChange={v => v ? changeProperty('fillType','fill'): 0} />
                    <Checkbox label="Fill on hover" type="circle" checked={object.fillType=='fill-on-hover'} onChange={v => v ? changeProperty('fillType','fill-on-hover'): 0} />
                    <Checkbox label="No fill" type="circle" checked={object.fillType=='no-fill'} onChange={v => v ? changeProperty('fillType','no-fill') : 0} />
                    <Checkbox label="Stone" type="circle" checked={object.fillType=='stone'} onChange={v => v ? changeProperty('fillType','stone'): 0} />
                    <Checkbox label="Wood" type="circle" checked={object.fillType=='wood'} onChange={v => v ? changeProperty('fillType','wood'): 0} />
                    <Checkbox label="Water" type="circle" checked={object.fillType=='water'} onChange={v => v ? changeProperty('fillType','water'): 0} />
                    <Checkbox label="Grass" type="circle" checked={object.fillType=='grass'} onChange={v => v ? changeProperty('fillType','grass'): 0} />
                </div>
            </div>
            {object.fillType != 'no-fill' ? <div>
                <ColorSelector label="Fill color" value={object.fillColor} onChange={color => changeProperty("fillColor",color)} />
            </div> : <></> }
        </>: <></>}
        <div>
            <label>Click target</label>
            <select value={object.target} onChange={ev => changeProperty('target',ev.target.value)}>
                <option value="">No click target</option>
                {targetOptions}
            </select>
            {object.target ? <input type="button" value="Go" onClick={goToTarget} /> : <></> }
        </div>
        <div>
            <label>Layer</label>
            <input type="range" min={1} max={9} value={object.layer} onChange={ev => changeProperty('layer',ev.target.value,true) } />
        </div>
        <div>
            <input type="button" value={object.rowKey ? "Save" : "Create"} onClick={save} />
            {object.rowKey ? <input type="button" value="Delete" onClick={deleteLine} /> : <></> }
        </div>
    </div>
}

