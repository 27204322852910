import React, { useEffect, useState } from 'react';
import { toggleAsidePinned } from './layout';
import { loadRules } from '../articles/articleutils';

export function Aside(){
    let [rules,setRules]=useState("");
    let [all,setAll]=useState(false);

    useEffect(function(){
        loadRules().then(rules => setRules(rules));
    },[]);

    //console.log("RUles",rules)

    function doSearch(ev){
        console.log("Do search")
        let search = ev.target.value.toLowerCase();
        let items = document.querySelectorAll("#asideContent div");
        for (let i = 0; i < items.length; i++) {
            let b = false;
            if (!all) b = (items[i].getAttribute("search") || "").includes(search);
            else b = (items[i].innerText.toLowerCase() || "").includes(search);
            items[i].style.display = b ? "block" : "none";
        }
    }

    return <aside>
        <div id="asideHeader" className="pinnableHeader">
            <span>Rules</span>
            <div id="searchIcon" className="iconButton">
                <img src="/icons/search-white.png" />
            </div>
            <div id="asideExpander" onClick={toggleAsidePinned} className="iconButton pinner extra">
                <img src="/icons/pin-white.png" class="expand" />
            </div>
        </div>
        <div id="asideOptions">
            <input id="search" onKeyUp={doSearch} placeholder="Search" />
            <input id="allContent" type="checkbox" checked={all} onChange={() => setAll(!all)} /> Seach all content
        </div>
        <div id="asideContent" dangerouslySetInnerHTML={{__html:rules}} >

        </div>
    </aside>
}
