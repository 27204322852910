import { createSlice } from "@reduxjs/toolkit"
import { HTTP } from "../../utils/http";
import { store } from "../../utils/store";
import { guid } from "../../utils/utils";
import { sendToCampaign } from "../../utils/websocket";
import { Map } from "../map";


const initial={
    maps:[],
    mapOptions:null,
    mapData:null,
    mapCustom:{
        backgroundScale:1,
        showGrid:true,
        squareFeet:5,
        rowKey:"",
        showNotesText:false,
    },
    clicked:{},
    clicks:[],
    mapNotes:[],
    mapPointer:null,
    avatars:[],
    doorInfo:null,
    current:null,
    encounter:null,
}

export const mapSlice=createSlice({
    name:'maps',
    initialState:initial,
    reducers:{
        setMaps(state,action){ 
            state.maps=action.payload;
        },
        setMap(state,action){
            console.log("setMap",action.payload)
            let options={...action.payload.options};
            if (options.rowKey==state.mapCustom.rowKey){
                Object.assign(options,state.mapCustom);
            }
            else {
                state.mapCustom.showGrid=options.showGrid;
                state.mapCustom.squareFeet=options.squareFeet;
                state.mapCustom.backgroundScale=options.backgroundScale;
                state.mapCustom.rowKey=options.rowKey;
                state.mapCustom={...state.mapCustom};
            }

            state.mapData={...action.payload};
            state.mapData.options=options;
            state.mapOptions=options;
            state.current=state.mapData.selectedRoom;
            state.clicked={};
            state.clicks=[];
            state.mapPointer=null;
            state.mapNotes=[];
            //state.avatars=state.mapData.playerAvatars || [];
        },
        setMapOptions(state,action){
            console.log("State",action.payload);
            let options={...action.payload};
            if (options.rowKey==state.mapCustom.rowKey){
                Object.assign(options,state.mapCustom);
            }
            else {
                state.mapCustom.showGrid=options.showGrid;
                state.mapCustom.squareFeet=options.squareFeet;
                state.mapCustom.backgroundScale=options.backgroundScale;
                state.mapCustom.rowKey=options.rowKey;
                state.mapCustom={...state.mapCustom};
            }
            state.mapData={...state.mapData,options};
            state.mapOptions=options;
        },
        setMapData(state,action){
            console.log("SetMapData",action.payload)
            let options={...action.payload.options};
            if (options.rowKey==state.mapCustom.rowKey){
                Object.assign(options,state.mapCustom);
            }
            else {
                state.mapCustom.showGrid=options.showGrid;
                state.mapCustom.squareFeet=options.squareFeet;
                state.mapCustom.backgroundScale=options.backgroundScale;
                state.mapCustom.rowKey=options.rowKey;
                state.mapCustom={...state.mapCustom};
            }
            state.mapData={...action.payload};
            state.mapData.options=options;
            state.mapOptions=options;
            state.clicked={};
            state.clicks=[];
        },
        setClicked(state,action){
            state.clicked=action.payload || {};
        },
        setClicks(state,action){
            state.clicks=action.payload || [];
            if (state.clicks.length>1) state.clicked={};
        },
        setMapPointer(state,action){
            let pt=action.payload;
            if (pt.map!=state.mapOptions.rowKey) return;
            if (!pt.x && !pt.y) state.mapPointer=null;
            else state.mapPointer=action.payload;
        },
        setMapNotes(state,action){
            state.mapNotes=action.payload || [];
        },
        setDoorInfo(state,action){
            let doorInfo=JSON.parse(JSON.stringify(action.payload));
            state.doorInfo=doorInfo;
            state.clicks=[doorInfo.points[1],doorInfo.points[0]];
            state.clicked={}
        },
        setCurrent(state,action){
            state.current=action.payload;
        },
        setMapCustom(state,action){
            state.mapCustom=action.payload;
            state.mapCustom.rowKey=state.mapData.options.rowKey;
            state.mapData={...state.mapData};
            state.mapData.options={...state.mapData.options,...state.mapCustom};
            state.mapOptions={...state.mapData.options};
        },
        setAvatar(state,action){
            let avatar=action.payload;
            if (avatar.type=="npc"){
                let avatars=[...state.avatars];
                let existing=avatars.find(a => a.rowKey==avatar.rowKey);
                if (existing) Object.assign(existing,avatar);
                else avatars.push(avatar);
                console.log("Changed",existing)
                state.avatars=avatars;
                state.encounter={...state.encounter,avatars};
                return;
            }
            if (avatar.map!=state.mapOptions?.rowKey) return;
            let playerAvatars=state.mapData.playerAvatars || [];
            playerAvatars=[...playerAvatars];
            let existing=playerAvatars.find(a => a.rowKey==avatar.rowKey);
            if (existing) Object.assign(existing,avatar);
            else playerAvatars.push(avatar);
            state.mapData={...state.mapData,playerAvatars};
        },
        setEncounter(state,action){
            state.encounter=action.payload;
            state.avatars=action.payload.avatars || [];
        },
    }
});

const actions=mapSlice.actions;

export function loadMaps(){
    HTTP.get("/api/maps").then(maps => {
        store.dispatch(actions.setMaps(maps));
    })
}

export async function loadMap(rowKey,selectedRoom=""){
    if (rowKey=="new"){
        let map=JSON.parse(JSON.stringify(new Map()));
        console.log("Maps",map,new Map())
        store.dispatch(actions.setMap(map));
        store.dispatch(actions.setMapNotes([]));
        return map;
    }
    else{
        let map=await HTTP.get("/api/maps/"+rowKey);
        //map.selectedRoom=selectedRoom;
        store.dispatch(actions.setMap(map));
        HTTP.get("/api/maps/"+rowKey+"/notes").then(notes => {
            store.dispatch(actions.setMapNotes(notes));
        })
        return map;
    }
}

export function setMapData(map){
    let jsonMap=JSON.parse(JSON.stringify(map));
    store.dispatch(actions.setMapData(jsonMap));
}

export function setMapOptions(options){
    store.dispatch(actions.setMapOptions(options));
}

export function setClicked(clicked){
    if (clicked) clicked=JSON.parse(JSON.stringify(clicked));
    store.dispatch(actions.setClicked(clicked));
}

export function setClicks(clicks){
    //if (clicks) clicks=clicks.map(c => JSON.parse(JSON.stringify(c)));
    store.dispatch(actions.setClicks(clicks));
}

export function setDoorInfo(doorInfo){
    store.dispatch(actions.setDoorInfo(doorInfo))
}

export async function saveMap(map){
    console.log("Saving map",map)
    let saved=await HTTP.post("/api/maps",map);
    map.options.rowKey=saved.rowKey;
    map.options.partitionKey=saved.partitionKey;
    let maps= await HTTP.get("/api/maps");
    store.dispatch(actions.setMaps(maps));
    return saved;
}

/*
export function setMapAsset(asset,room=null){
    store.dispatch(actions.setAsset({asset,room}));
}
*/
export function setMapPointer(pointer){
    store.dispatch(actions.setMapPointer(pointer));
    sendToCampaign("setMapPointer",pointer);
}

export function setCurrent(object){
    store.dispatch(actions.setCurrent(object));
}

export function addMapNote(map,note){
    HTTP.post("/api/maps/"+map+"/notes",note).then(notes => {
        let obj={map,notes};
        store.dispatch(actions.setMapNotes(notes));
        sendToCampaign("setMapNotes",obj)
    })
}

export function saveMapNote(note){
    HTTP.post("/api/maps/"+note.partitionKey+"/notes",note).then(notes => {
        let obj={map:note.partitionKey,notes};
        store.dispatch(actions.setMapNotes(notes));
        sendToCampaign("setMapNotes",obj);
    })
}

export function deleteMapNote(map,note){
    HTTP.delete("/api/maps/"+map+"/notes/"+note).then(notes => {
        let obj={map,notes};
        store.dispatch(actions.setMapNotes(notes));
        sendToCampaign("setMapNotes",obj)
    })
}

export function setMapNotes(data){
    
    let mapOptions=store.getState().maps.mapOptions;
    if (!mapOptions) return;
    if (mapOptions.rowKey!=data.map) return;
    store.dispatch(actions.setMapNotes(data.notes))
}

export function setMapCustom(custom){
    store.dispatch(actions.setMapCustom(custom));
}

export function setAvatar(avatar){
    store.dispatch(actions.setAvatar(avatar));
}

export function sendEncounter(enc){
    //enc.map=Map.fromJson(enc.map);
    sendToCampaign("setEncounter",enc);
}


export function setEncounter(encounter){
    store.dispatch(actions.setEncounter(encounter));
}

export function processMapSocketMessage(message){
    if (["setMapData","setMapPointer","setMapNotes","avatarsChanged","setEncounter"].includes(message.command))
        console.log("Map message",message);
    if (message.command=="setMapData") store.dispatch(actions.setMapData(message.data));
    else if (message.command=="setMapPointer") store.dispatch(actions.setMapPointer(message.data));
    else if (message.command=="setMapNotes") setMapNotes(message.data);
    else if (message.command=="avatarChanged") setAvatar(message.data);
    else if (message.command=="setEncounter") setEncounter(message.data);
}