import React, { useEffect } from 'react';

const titles={
    Armor:'Armor of any type',
    Weapon:'Weapon',
    Bow:'Bow',
    Crossbow:'Crossbow',
    Shield:'Shield',
    Valuable:'Valuable',
    Arrow:'Arrows or bolts',
    Other:'Any standard item',
}

function ItemTypeButton({type,selected,onClick}){

    let cls="itemType clickable itemType-"+type;
    if (selected==type) cls+=" itemTypeSelected";

    return <span className={cls} onClick={() => onClick(type)} title={titles[type]}>

    </span>
}

export function ItemTypeSelector({value, onChange,allowed}){

    useEffect(function(){
        if (!["Weapon","Bow","Crossbow","Armor","Shield","Arrows","Valuable"].includes(value)) {
            value="Other";
            onChange(value);
        }
    },[]);

    function typeClicked(type){
        console.log("Type clicked",type)
        if (onChange) onChange(type);
    }
    

    return <div className="itemTypeSelector">
        {allowed.includes("Other") ? <ItemTypeButton type="Other" selected={value} onClick={typeClicked}  /> : <></> }
        {allowed.includes("Weapon") ? <ItemTypeButton type="Weapon" selected={value} onClick={typeClicked}  /> : <></> }
        {allowed.includes("Bow") ? <ItemTypeButton type="Bow" selected={value} onClick={typeClicked}  /> : <></> }
        {allowed.includes("Crossbow") ? <ItemTypeButton type="Crossbow" selected={value} onClick={typeClicked}  /> : <></> }
        {allowed.includes("Armor") ? <ItemTypeButton type="Armor" selected={value} onClick={typeClicked}  /> : <></> }
        {allowed.includes("Shield") ? <ItemTypeButton type="Shield" selected={value} onClick={typeClicked}  /> : <></> }
        {allowed.includes("Arrows") ? <ItemTypeButton type="Arrows" selected={value} onClick={typeClicked}  /> : <></> }
        {allowed.includes("Valuable") ? <ItemTypeButton type="Valuable" selected={value} onClick={typeClicked}  /> : <></> }
    </div>

}