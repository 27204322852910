import { guid } from "../../utils/utils";
import { lineInfo, pointNear, pointNearLine, pointOnLine } from "../points/pointutils";

export class Room{

    constructor(points,options,rowKey=null){
        this.rowKey=rowKey || guid('rm');
        this.points=points;
        this.options=options;
        this.assets=[];
    }
 
    toJSON(){
        return{rowKey:this.rowKey,
            points:this.points,
            assets:this.assets,
        };
    }

    get wallCount(){
        return this.points.length;
    }

    getWall(i){
        if (i>=this.points.length) i=0;
        let ni=i+1;
        if (ni==this.points.length) ni=0;
        let info=lineInfo(this.points[i],this.points[ni]);
        return {start:this.points[i],end:this.points[ni],index:i,info};
    }

    addCornerPointAfter(index,point){
        index++;
        if (index>=this.points.length) index=0;
        //console.log("Corners before",this.points,point);
        console.log("Adding",point);
        this.points.splice(index,0,point);
        console.log("Corners after",this.points);
        return index;
    }

    addWallCorner(index, fromBeginning){
        let wall=this.getWall(index);
        let {start,end,info}=wall;
        let point=pointOnLine(start,end,fromBeginning);
        return this.points[this.addCornerPointAfter(index,point)];
    }

    get walls(){
        let w=[];
        for(let i=0;i<this.points.length;i++){
            w.push(this.getWall(i));
        }
        return w;
    }

    testNewPoints(points){
        for(let i=0;i<points.length;i++){
            let point=points[i];
            let walls=this.walls;
            for (let wi=0;wi<walls.length;wi++){
                let inserted=0;
                let wall=walls[wi];
                let {start,end}=wall;
                if ((start.rowKey==point.rowKey) || (end.rowKey==point.rowKey)){
                    //console.log("Point already on wall");
                    continue;
                }
                if (pointNear(start,point) || pointNear(end,point)){
                    console.log("Room.test new points,tested point is near the end of wall. What should we do?")
                    continue;
                }
                let pNear=pointNearLine(wall.start,wall.end,point,this.options);
                if (pNear){
                    //console.log("Found point on wall",this,wi,wall,pNear);
                    this.addCornerPointAfter(wi+inserted,point);
                    inserted++;
                }
            }
        }
    }

    get dimensions(){
        let minX=1000000,minY=1000000,maxX=-1,maxY=-1;
        let prev=this.points[this.points.length-1].feet;
        let ks=0;
        for(let i=0;i<this.points.length;i++){
            let p=this.points[i].feet;
            let {x,y}=p;
            if (x<minX) minX=x;
            if (y<minY) minY=y;
            if (x>maxX) maxX=x;
            if (y>maxY) maxY=y;
            if ((x!=prev.x) && (i!=0)) ks+=(y-prev.y)/(x-prev.x);
            prev=p;
        }
        //console.log("Kulmakertointen summa",ks);
        return {minY,maxY,minX,maxX,width:maxX-minX,height:maxY-minY};
    }

    get centerDisplacement(){
        let dim=this.dimensions;
        return {dx:this.options.mapCenterFeetX-dim.minX-dim.width/2,dy:this.options.mapCenterFeetY-dim.minY-dim.height/2}
    }

    hitTest(pt,ignore=""){
        let {x:tx,y:ty}=pt.mouse ? pt.mouse : pt.pixels;
        let crossings=0;
        if (ignore!="assets"){
            for(let i=0;i<this.assets.length;i++){
                let dec=this.assets[i];
                let {point,sizeX,sizeY}=dec;
                console.log("test decs",pt,point)
                if (!sizeY) sizeY=sizeX;
                let size=Math.min(sizeX,sizeY);
                console.log("Size",sizeX,sizeY,size);
                let radius=this.options.feetToPixelsRatio*size/2;
                let {x:centerX,y:centerY}=point.pixels;
                let dx=Math.abs(centerX-tx);
                let dy=Math.abs(centerY-ty);
                
                let cx=centerX-tx;
                let cy=centerY-ty;
                let dist=Math.sqrt(cx*cx+cy*cy);
                console.log("Decoration",dist,radius);
                if (dist<radius) {
                    //dec.clicked=true;
                    //this.clicked=true;
                    return {room:this,point,asset:dec,decorationIndex:i}
                }
                             
                //if (dx<sizeX*this.options.feetToPixelsRatio/2 && dy<sizeY*this.options.feetToPixelsRatio/2) return {room:this,point,decoration:dec,decorationIndex:i}
            }
        }
        let ht=pointInShape(pt,this.points,this.options);
        if (ht){
            ht.room=this;
            console.log("Room hittest",ht)
            return ht;
        }
        else return null;
    }
}
