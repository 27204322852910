import { useNavigate } from 'react-router-dom';
import React from 'react';
import { Tile } from '../utils/tile';

import './charactertiles.scss';
import { getImageUrl } from '../utils/utils';
import { Reputation } from '../character/reputation';
import { createCharacter } from '../character/character';
import { setCurrentCharacter } from '../utils/store';

export function CharacterTile({character}){
    let {values}=character;
    let imageUrl="/images/defaultcharacter.png";
    if (values.image) imageUrl=getImageUrl(values.partitionKey,values.image);
    const navigate=useNavigate();

    console.log("Image url",imageUrl)

    function goToCharacter(){
        let c=createCharacter(character);
        setCurrentCharacter(c);
        navigate("/home/character/"+character.values.rowKey);
    }

    return <Tile header={character.values.name || 'Unknown'} styles="characterTile" onClick={goToCharacter}>
        <div className="characterTileContent" style={{backgroundImage:"url('"+imageUrl+"')"}} >
            <div>
                <p>{values.name || 'Unknown'}, {values.ethnicity || 'Foreigner'} {values.profession || 'with no profession'}</p>
                {values.age ? <p>{values.age} years old {values.sex}</p> : <p>Newborn</p> }
            </div>
            <div>
                <Reputation character={createCharacter(character)} />
            </div>
        </div>
    </Tile>
}

export function WizardTile(){
    const navigate=useNavigate();

    return <Tile header="Character Wizard" styles="characterWizardTile" onClick={() => navigate("/home/wizard")}>
        <h5>Create a new character</h5>
        <p>Ask your Realm Master to join it to his/her campaign.</p>
        <p>Or offer the character to any Realm Master in the community (coming up)</p>
    </Tile>
}

