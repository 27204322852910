import diceBoxEs from '@3d-dice/dice-box';
import React, { useEffect, useState } from 'react';
import { Dice } from '../dice/dicebox';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


export function Footer(){
    let {zoom}=useSelector(state => state.game)
    let [showDice,setShowDice]=useState(false);
    const navigate=useNavigate();

    function toggleShowDice(){
//        setShowDice(!showDice);
        Dice.toggleShowButtons();
        //document.getElementById("diceHolder").classList.toggle("hidden");
    }

    return <footer className="view"><div className="books" style={{zoom}}>
        <a className="artnav" onClick={() => navigate('/rules/character')}><span>Character</span></a>
        <a className="artnav" onClick={() => navigate('/rules/various')}><span>Rules</span></a>
        <a className="artnav" onClick={() => navigate('/rules/combat')}><span>Combat</span></a>
        <a className="artnav" onClick={() => navigate('/rules/spells')}><span>Spells</span></a>
        <span id="diceOpener" onClick={toggleShowDice}><img src="/icons/dice.png" />
        </span>
        <span id="info" style={{color: 'white'}}> </span>
    </div></footer>
}