import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { setDoorInfo, setMapData } from '../utils/mapstore';
import { Checkbox } from '../../utils/formfields';
import { sendToCampaign } from '../../utils/websocket';

export function SegmentOptions({map}){
    let {clicked}=useSelector(state => state.maps);
    let {share,role}=useSelector(state => state.game);
    let {line,segment}=clicked;
    let [type,setType]=useState("corner");
    let [width,setWidth]=useState(5);
    let [isOpen,setIsOpen]=useState(true);
    let [isLocked,setIsLocked]=useState(false);

    function createOpening(){
        map.createOpening(type,width,isOpen,isLocked);
        setMapData(map);
        if (role=="Master" && share && share.itemRowKey==map.options.rowKey){ 
            console.log("Send map",map);
            sendToCampaign("setMapData",map);
        }
    }

    function addCorner(){
        map.addCorner();
        setMapData(map);
    }

    return <div className='openingOptions'>
        <input type="button" value="Add corner" onClick={addCorner} />
        <div>
            <h3>Add opening</h3>
            <Checkbox label="Door" type='circle' checked={type=="door"} onChange={v => v ? setType("door") : 0} />
            <Checkbox label="Gate" type='circle' checked={type=="gate"} onChange={v => v ? setType("gate") : 0} />
            <Checkbox label="Opening" type="circle" checked={type=="opening"} onChange={v => v ? setType("opening") : 0} />
            <Checkbox label="Window" type="circle" checked={type=="window"} onChange={v => v ? setType("window") : 0} />
            <Checkbox label="Arc" type="circle" checked={type=="arc"} onChange={v => v ? setType("arc") : 0} />
        </div>
        {type=="door" || type=="gate" ? <div>
            <Checkbox label="Open" checked={isOpen} editable={!isLocked} onChange={v => setIsOpen(v)} />
            <Checkbox label="Locked" checked={isLocked} onChange={v => setIsLocked(v)} />
        </div> : <></>}
        {type!="window" && type!="arc"? <div>
            <h3>Width</h3>
            {type=="opening" ? <Checkbox label="Full wall" type="circle" checked={width==0} onChange={v => v ? setWidth(0) : 0} /> : <></> }
            <Checkbox label="Single (5')" type="circle" checked={width==5} onChange={v => v ? setWidth(5) : 0} />
            <Checkbox label="Double (10')" type="circle" checked={width==10} onChange={v => v ? setWidth(10) : 0} />
        </div> : <></> }
        <div>
            <input type="button" value="Create opening" onClick={createOpening} />
        </div>
    </div>
}