import { guid } from "../../utils/utils";

export function pointOptions(mapWidth,mapHeight,squareFeet=5){
    if (!mapHeight) mapHeight=mapWidth;
    let obj={
        mapWidth,mapHeight,
        mapSquaresX:mapWidth/squareFeet ,
        mapSquaresY:mapHeight/squareFeet,
        dx:0,dy:0,
        squarePixels:16,
        squareFeet,
        nearPixels:6,
        wallThickness:2.5,
        backgroundScale:1,
        backgroundScale2:1,
        assetScale:4,
        backround2X:0,
        background2Y:0,
        backgroundImageUrl:"",
        backroundImageUrl2:"",
        showBackgroundImage:false,
        showBackroundImage2:false,
        mapUnits:'feet',
        imageWidth:0,
        imageHeight:0,
        showGrid:true,
        onlyShowVisited:false,
        /*
        get feetToPixelsRatio() { return this.squarePixels/this.squareFeet;},
        get mapSquaresX(){ return this.canvasWidth/this.squarePixels},
        get mapSquaresY() { return this.canvasHeight/this.squarePixels},
        get mapCenterFeetX() {return this.squareFeet*this.mapSquaresX/2;},
        get mapCenterFeetY() {return this.squareFeet*this.mapSquaresY/2;},
        */
    }
    calculateOptionValues(obj);
    return obj;
}

export function isImageMap(options){
    return (options.imageHeight && options.imageWidth && options.backgroundImageUrl)    
}

export function calculateOptionValues(options){
    /*
    if (options.backgroundImageUrl && options.imageWidth && options.imageHeight){
        options.canvasWidth=options.imageWidth*options.backgroundScale;
        options.canvasHeight=options.imageHeight*options.backgroundScale;
    }
    */
    let {mapWidth,mapHeight,squareFeet,backgroundScale}=options;
    options.mapSquaresX=mapWidth/squareFeet;
    options.mapSquaresY=mapHeight/squareFeet;

    if (isImageMap(options)){
        //console.log("Imagemap")
        options.mapHeight=options.mapWidth*options.imageHeight/options.imageWidth;
        options.mapSquaresY=mapHeight/squareFeet;
        options.canvasWidth=options.imageWidth*backgroundScale;
        options.canvasHeight=options.imageHeight*backgroundScale;
        options.feetToPixelsRatio=options.canvasWidth/mapWidth;
        options.squarePixels=squareFeet*options.feetToPixelsRatio;
    }
    else{
        options.canvasWidth=mapWidth*3*backgroundScale;
        options.canvasHeight=mapHeight*3*backgroundScale;
        options.feetToPixelsRatio=options.canvasWidth/mapWidth;
        options.squarePixels=squareFeet*options.feetToPixelsRatio;
    }
    options.mapCenterFeetX=options.squareFeet*options.mapSquaresX/2;
    options.mapCenterFeetY=options.squareFeet*options.mapSquaresY/2;
}

export function snapped(pixelValue,squarePixels=16){
    let value=Math.round(pixelValue);
    let square=Math.floor(value/squarePixels);
    let squareBegin=square*squarePixels;
    let over=value-squareBegin;
    if (over<squareFourth) return squareBegin;
    if (over<squareFourth*3) return squareBegin+2*squareFourth;
    return squareBegin+squarePixels;
}

export function point(x,y,options,rowKey=null){
    let pnt={
        rowKey:rowKey || guid('pnt'),
        feet:{x,y},
        pixels:{
            x:Math.round(x*options.feetToPixelsRatio),
            y:Math.round(y*options.feetToPixelsRatio)
        },

    };
    /*
    Object.defineProperty(pnt,'pixels',{
        get(){
            let px=(x+options.dx)*options.feetToPixelsRatio;
            let py=(y+options.dy)*options.feetToPixelsRatio;
            return {x:px,y:py}; 
        }
    })
    */
    //pnt.toJSON=() => ({rowKey:pnt.rowKey,feet:{x,y}})

    return pnt;
}

export function pointFromPixels(px,py,options,rowKey=null){
    let x=pixelsToFeet(px,options);
    let y=pixelsToFeet(py,options);
//    let pt=point(x,y,options,rowKey || guid("pnt"));
    let pt={};
    pt.feet={x,y};
    pt.mouse={x:px,y:py};
    pt.pixels={x:x*options.feetToPixelsRatio,y:y*options.feetToPixelsRatio}
    //console.log("pointFromPixels",px,py,dx,dy,pt)
    return pt;
}

export function calculatePixels(point,{feetToPixelsRatio}){
    point={...point};
    if (!point.feet) point.feet={x:point.x,y:point.y};
    let x=Math.round(point.feet.x*feetToPixelsRatio);
    let y=Math.round(point.feet.y*feetToPixelsRatio);
    point.pixels={x,y};
    if (!point.rowKey) point.rowKey=guid("pnt");
    return point;
}

export function pixelsToFeet(pixels,{feetToPixelsRatio,squareFeet}){
    let actual=pixels/feetToPixelsRatio;
    let rounded=Math.round(actual*2/squareFeet)/(2/squareFeet);
    // To closest multiple of 2.5??
    return rounded;
}

function pointNormalToLine(p1,p2,pt,options){
    let {x:x1,y:y1}=p1.pixels;
    let {x:x2,y:y2}=p2.pixels;
    let {x:tx,y:ty}=pt.mouse ? pt.mouse:pt.pixels;
    if (x1==x2) return pointFromPixels(x1,ty,options);
    if (y1==y2) return pointFromPixels(tx,y1,options);
    let k=(y2-y1)/(x2-x1);
    let kn=-1/k;
    //console.log("kulmakertoimet",k,kn,1/k);
    // y=k*x+b  =>  b=y-k*x =>
    let b=y1-k*x1;
    let bn=ty-kn*tx;
    // k*nx+b=kn*nx+bn =>
    let nx=(bn-b)/(k-kn);
    let ny=kn*nx+bn;
    return pointFromPixels(nx,ny,options);
}

export function pointNear(point1,point2,nearPixels=4){
    let {x:tx,y:ty}=(point1.mouse ? point1.mouse : point1.pixels);
    let {x,y}=(point2.mouse ? point2.mouse : point2.pixels);
    //console.log("Near",x,y,tx,ty)
    if (Math.abs(x-tx)>nearPixels) return false;
    if (Math.abs(y-ty)>nearPixels) return false;
    return true;
}

export function pointNearLine(p1,p2,pt,options){
    let nearPixels=options.nearPixels;
    let {x:x1,y:y1}=p1.pixels;
    let {x:x2,y:y2}=p2.pixels;
    let item=pt.mouse ? 'mouse' : 'pixels';
    let {x:tx,y:ty}=pt[item];    
    //console.log(tx,ty,x1,y1,x2,y2);    
    if (tx+nearPixels<Math.min(x1,x2)) return false;
    if (tx-nearPixels>Math.max(x1,x2)) return false;
    if (ty+nearPixels<Math.min(y1,y2)) return false;
    if (ty-nearPixels>Math.max(y1,y2)) return false;
    // Easy cases, lines are horizontal or vertical
    if ((y1==y2) && (Math.abs(ty-y1)<=nearPixels)) return pointNormalToLine(p1,p2,pt,options);
    if ((x1==x2)  && (Math.abs(tx-x1)<=nearPixels)) return pointNormalToLine(p1,p2,pt,options);
    
    let dx=x1-x2;
    let dy=y1-y2;
    let k=dy/dx;
    // dy=k*dx => (y-y1)=k*(x-x1) => x1 and y1 are known  y=k*x-k*x1+y1 => b=y1-k*x1;
    let b=y1-k*x1;
    // 0=kx-1*y+b
    let top=Math.abs(k*tx-ty+b);
    let bottom=Math.sqrt(k*k+1);
    let dist=top/bottom;
    //console.log("Distance",dist);
    if (dist>nearPixels) return null;
    return pointNormalToLine(p1,p2,pt,options);               
}

export function pointOnLine(p1,p2,fromStart,options){
    let li=lineInfo(p1,p2);
    if (!li.dx){
        console.log("HOLINE INFO, Vertical?")
        if (p1.feet.y>p2.feet.y) fromStart=-fromStart;
        let x=p1.feet.x;
        let y=p1.feet.y+fromStart;
        return point(x,y,options);
    }
    // li.dx/li.length=dx/fromStart
    let dx=fromStart*li.dx/li.length;
    let x=p1.feet.x+dx;
    let y=li.a*x+li.b;
    return point(x,y,options);
}

export function pointInRect(point,x1,y1,x2,y2){
    let minX=Math.min(x1,x2);
    let maxX=Math.max(x1,x2);
    let minY=Math.min(y1,y2);
    let maxY=Math.max(y1,y2);
    if (point.feet.x<minX) return false;
    if (point.feet.x>maxX) return false;
    if (point.feet.y<minY) return false;
    if (point.feet.y>maxY) return false;
    return true;
}

export function distance(p1,p2){
    let {x:x1,y:y1}=p1.feet;
    let {x:x2,y:y2}=p2.feet;
    let dx=x2-x1;
    let dy=y2-y1;
    return Math.sqrt(dx*dx+dy*dy);
}

export function lineInfo(p1,p2){
    let {x:x1,y:y1}=p1.feet;
    let {x:x2,y:y2}=p2.feet;
    let dx=x2-x1;
    let dy=y2-y1;
    let ang=dy > 0 ? Math.PI/2 : 2*Math.PI-Math.PI/2;
    if (x1==x2) {
        return {
            dx:0,dy:y2-y1,ang,length:Math.abs(y2-y1)
        }
    };
    let a=dy/dx;
    // y1=ax1+b  => b=y1-ax1
    let b=y1-a*x1;
    let length=distance(p1,p2);

    if (dx) ang=Math.atan(dy/dx);
    if (dx<0) ang+=Math.PI;
    if (ang<0) ang+=2*Math.PI;
    //console.log("info",dx,dy,ang);
    return {a,b,dx,dy,ang,length}; 
}

export function getAng(x,y,x1,y1){
    let dx=x1-x;
    let dy=y1-y;
    if (dx==0) return (dy > 0 ? Math.PI/2 : 2*Math.PI-Math.PI/2);
    let ang=Math.atan(dy/dx);
    if (dx<0) ang+=Math.PI;
    if (ang<0) ang+=2*Math.PI;
    return ang;
}

export function lineInfoRawPoints(p1,p2){
    p1={feet:p1};
    p2={feet:p2}
    return lineInfo(p1,p2);
}

export function pointInShape(point,points,options){
    let {x:tx,y:ty}=point.mouse ? point.mouse : point.pixels;
    let crossings=0;
    let corners=points;
    for(let i=0;i<points.length;i++){
        let prevIndex=i?i-1:points.length-1;
        let prev=points[prevIndex];
        let current=points[i];
        let {x,y}=prev.pixels;
        if (pointNear(prev,point)) {
            console.log("isnear")
            prev.index=prevIndex;
            return {cornerIndex:prevIndex,x,y,corner:prev}
        }
        let {x:x1,y:y1}=current.pixels;
        let lineClicked=pointNearLine(prev,current,point,options);
        if (lineClicked){
            let length=distance(prev,current);
            let fromBeginning=distance(prev,lineClicked);
            return {point:lineClicked,
                segmentIndex:prevIndex,
                segment:{index:prevIndex,start:prev,end:current,length,fromBeginning,point:lineClicked}};
        }

        if ((x<tx) && (x1<tx)) continue; //segment is entirely to the left of point
        if ((y<ty) && (y1<ty)) continue;  // segment is entirely above the point
        if ((y>ty) && (y1>ty)) continue;  // segment is entirely below
        
        if (y==y1) continue; // Horizontal segment
        // Calculate x for segment where it crosses horizontal line for tested point
        let dx1=x1-x;
        let dy1=y1-y;
        let dyt=ty-y;
        let dxt=dx1*dyt/dy1;
        // dxt=xcoord-x; ==> xcoord=dxt-x;
        let xcoord=dxt+x;
        //console.log("Is a crossing?",xcoord,ty)
        //console.log(dxt+x,tx);
        if ((dxt<x1) && (dxt+x>tx)) {  //Within segment but to the right of tested point
            crossings++;
            //console.log("Found crossing",i,dxt,x,y,x1,y1);
        }
    }
    //console.log("Found",crossings,!!(crossings%2));
    if (!(crossings%2)) return;
    return {isInside:true};
}

export function findCenter(points){
    let minx=9000000,miny=9000000;
    let maxx=0,maxy=0;
    points.forEach(p => {
        let {x,y}=p.feet;
        if (x<minx) minx=x;
        if (x>maxx) maxx=x;
        if (y<miny) miny=y;
        if (y>maxy) maxy=y;
    })
    return {x:(minx+maxx)/2,y:(miny+maxy)/2};
}

export function PointsCollection(){
    const points=[];

    const options={
        dx:0,dy:0,
        squarePixels:16,
        squareFeet:5,
        nearPixels:3,     
    }

    options.feetToPixelsRatio=squarePixels/squareFeet;

    return {
        pointFromPixels(mouseX,mouseY){
            return pointFromPixels(mouseX,mouseY,options);
        },
        find(point){
            return points.find(p => p.rowKey==point.rowKey);
        },
        add(point){
            if(!this.find(point)) this.points.push(point);
        }
    
    }

}