import React, { useEffect, useState } from 'react';
import { loadRules } from '../../articles/articleutils';

import './wizardhelp.scss';

export function CharacterWizardHelp({search}){
    let [cls,setCls]=useState("hidden");
    let [rules,setRules]=useState("");

    useEffect(function(){
        loadRules().then(rules => setRules(rules));
    },[])

    useEffect(function(){
        let items = document.querySelectorAll("#wizardSearchContent div");
        let found=false;
        for (let i = 0; i < items.length; i++) {
            let b=(items[i].getAttribute("wizardSearch") || "").includes(search);
            items[i].style.display = b ? "block" : "none";
            if (b) found=true;
        }
        if (found) setCls("");
    },[rules]);

    return <div className="wizardSearch">
        <div id="wizardSearchContent" dangerouslySetInnerHTML={{__html:rules}} >
        </div>
    </div>
}