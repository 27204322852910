import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toggleColumns, toggleNavPinned } from './layout';
import { Link, useNavigate } from 'react-router-dom';
import { RulesNav } from '../articles/article';

import './nav.scss';
import { Meeting } from '../webrtc/meeting';
import { clearCampaign } from '../utils/store';
import { Icon } from '../utils/icons';
import { Aside } from './aside';
import { Search } from './search';
import { loadRules } from '../articles/articleutils';
import { Checkbox } from '../utils/formfields';

function MikeOptions({onClose}){
    let {meetingOptions:options}=useSelector(state => state.game);
    let mv=document.getElementById("meetingFrame").contentWindow;

    let {echoCancellation,noiseSupression,autoGainControl}=options.audioOptions;

    console.log("options",options);
    let devices=options.devices.audioinput.map(d => <p key={d.deviceId}>
            <Checkbox label={d.label} type="circle" checked={options.selectedDevices.audioinput==d.deviceId} onChange={() => mv.selectMicrophone(d.deviceId)} />
        </p>)
    //let devices=<></>
    return <div className="meetingOptions mikeOptions">
        <h5>Devices</h5>
        <div>
            {devices}
        </div>
        <h5>Options</h5>
        <div>
            <Checkbox label="Mute" checked={options.audioMuted} onChange={ev => mv.muteAudio(!options.audioMuted)} />
        </div>
        <div>
            <Checkbox label="Echo cancellation" checked={echoCancellation} onChange={ev => mv.setAudioOption("echoCancellation",!echoCancellation)} />
        </div>
        <div>
            <Checkbox label="Noise supression" checked={noiseSupression}  onChange={ev => mv.setAudioOption("noiseSupression",!noiseSupression)} />
        </div>
        <div>
            <Checkbox label="Auto-gaincontrol" checked={autoGainControl} onChange={ev => mv.setAudioOption("autoGainControl",!autoGainControl)} />
        </div>
    </div>
}

function SpeakerOptions({onClose}){
    let {meetingOptions:options}=useSelector(state => state.game);
    let mv=document.getElementById("meetingFrame").contentWindow;

    let devices=options.devices.audiooutput.map(d => <div key={d.deviceId}>
        <Checkbox label={d.label} type="circle" checked={options.selectedDevices.audiooutput==d.deviceId} onChange={() => mv.selectSpeakers(d.deviceId)} />
    </div>)

    console.log("Meeting options",options)
    return <div className="meetingOptions speakerOptions">
        <h5>Devices</h5>
        <div>
           {devices}
        </div>
    </div>
}

function VideoOptions({onClose}){
    let {meetingOptions:options}=useSelector(state => state.game);
    let mv=document.getElementById("meetingFrame").contentWindow;

    let devices=options.devices.videoinput.map(d => <div key={d.deviceId}>
        <Checkbox label={d.label} type="circle" checked={options.selectedDevices.videoinput==d.deviceId} onChange={() => mv.selectCamera(d.deviceId)} />
    </div>)

    return <div className="meetingOptions speakerOptions">
        <h5>Devices</h5>
        <div>
            {devices}
        </div>
    </div>
}


export function Nav(){
    const {player,role,campaign,meetingOptions,inMeeting,zoom}=useSelector(state => state.game);
    let isLoggedIn=!!player.rowKey;
    const navigate=useNavigate();
    let [isSearch,setSearch]=useState(false);
    let [isRulesNav,setRulesNav]=useState(false);
    let [rules,setRules]=useState("");
    let [mikeActive,setMikeActive]=useState(true);
    let [showMeetingOptions,setShowMeetingOptions]=useState("");

    let meetingWindow=document.getElementById("meetingFrame").contentWindow;

    useEffect(function(){
        loadRules().then(rules => setRules(rules));
    },[]);


    function goHome(){
        //clearCampaign();
        navigate("/home");
    }

    function setNavMode(mode){
        if (mode==1) {
            isRulesNav=!isRulesNav;
            if (isRulesNav) isSearch=false;
        }
        if (mode==2){
            isSearch=!isSearch;
            if (isSearch) isRulesNav=false;
        }
        setSearch(isSearch);
        setRulesNav(isRulesNav);
    }


    function joinMeeting(){
        meetingWindow.joinMeeting();
    }

    function leaveMeeting(){
        meetingWindow.leaveMeeting();
    }

    function showMeetingControls(item){
        if (showMeetingOptions==item) showMeetingOptions="";
        else showMeetingOptions=item;
        setShowMeetingOptions(showMeetingOptions);
    }

    let searchStyles="icon-border icon-light";
    if (!isSearch) searchStyles+" icon-dimmed";
    return <nav style={{zoom}}>
        <div id="menuIcons" className='menuIconSet'>
            <Icon type={Icon.menu} style="icon-border icon-light" onClick={() => setNavMode(1)} />
            <Icon type={Icon.search} style={searchStyles} onClick={() => setNavMode(2)} />
            {isLoggedIn ? <Icon type={Icon.home} style="icon-border icon-light" onClick={goHome} /> : <></> }
        </div>
        {campaign ? <div className='meetingControls menuIconSet'>
            {!inMeeting ? <Icon type={Icon.joinMeeting} style="icon-border icon-light" onClick={() => joinMeeting()} />  
              : <Icon type={Icon.leaveMeeting} style="icon-border icon-light" onClick={() => leaveMeeting()} />}
            {!meetingOptions.audioMuted ? <Icon type={Icon.mikeActive} style="icon-border icon-light" onClick={() => showMeetingControls("mike")} /> :
                <Icon type={Icon.mikeInactive} style="icon-border icon-light" onClick={() => showMeetingControls("mike")} /> }
            <Icon type={Icon.audioOutput} style="icon-border icon-light" onClick={() => showMeetingControls("speakers")} />
            <Icon type={Icon.camera} style="icon-border icon-light" onClick={() => showMeetingControls("video")} />
        </div> : <></>}
        <div className="hidden" onClick={toggleColumns}>
            <img id="columns" src="/icons/columns.png" />
            <img id="nocolumns" src="/icons/nocolumns.png" />
        </div>
        <div className="loginLink">
            {!isLoggedIn ? <Link className="extra" id="loginLink" to="/login">Login</Link> : <></> }
            {isLoggedIn ? <Link className='extra' id="loginLink" to="/login/logout">Log out</Link>: <></>}
        </div>
        {isSearch ? <Search rules={rules} /> : <></>}
        {isRulesNav ? <div className="rulesNavContainer"><RulesNav /></div>: <></>}
        {showMeetingOptions=="mike" ? <MikeOptions /> : <></>}
        {showMeetingOptions=="speakers" ? <SpeakerOptions /> : <></>}
        {showMeetingOptions=="video" ? <VideoOptions /> : <></>}
    </nav>
}
