import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { HTTP } from '../utils/http';

import './handouts.scss';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getImageUrl, guid } from '../utils/utils';
import { InputImage } from '../utils/imageeditor';
import { sendTo } from '../utils/websocket';
import { Page } from '../layout/page';
import { setMapData } from '../map/utils/mapstore';
import { MapEditor } from '../map/mapeditor';
import { drawMap } from '../map/renderer';
import { Map } from '../map/map';
import { calculateOptionValues, calculatePixels } from '../map/points/pointutils';
import { setShare } from '../utils/store';

export const handoutsMainChildren=[
    {index:true,element:<HandoutList />},
    {path:':rowKey',element:<HandoutDetailRoute />},
]

function PlayerItem({name,rowKey,available,setAvailable}){
    let cls="handoutPlayerItem";
    let av=available.includes(rowKey);
    if (av) cls+=" isAvailable";

    function clicked(){
        let index=available.indexOf(rowKey);
        if (index<0) setAvailable([...available,rowKey]);
        else {
            available.splice(index,1);
            setAvailable([...available])
        }
    }

    return <div className={cls} onClick={clicked}>
        <p>{name}</p>
    </div>
}



export function HandoutDetail({rowKey,noButtons=false}){
    let {campaign,player,role,share}=useSelector(state => state.game);
    const {mapData}=useSelector(state => state.maps);
    const navigate=useNavigate();
    const location=useLocation();
    const [handout,setHandout]=useState({});
    const [map,setMap]=useState("");
    const [available,setAvailable]=useState([]);
    const [playersCanEdit,setPlayersCanEdit]=useState(false);
    const [modified,setModified]=useState(false);

    useEffect(function(){
        if (rowKey!='new'){
            HTTP.get("/api/handouts/"+rowKey).then(handout => {
                let avail=handout.availableTo.split(",");
                setAvailable(avail);
                setHandout(handout);
                setMap(handout.map || "");
                setPlayersCanEdit(!!handout.playersCanEdit)
                setModified(false);
            })
        }
        else {
            setHandout({partitionKey:campaign.rowKey,rowKey:guid('hnd'),availableTo:player.rowKey,show:'image'});
        }
    },[rowKey,share]);

    useEffect(function(){
        if (!map) return;
        HTTP.get("/api/maps/"+map).then(mapData => {
            setMapData(mapData);
        })

    },[map])

    function changeText(ev,field){
        handout[field]=ev.target.value;
        setModified(true);
        setHandout({...handout});
    }

    function save(){
        handout.availableTo=player.rowKey;
        if (available.length){
            handout.availableTo+=","+available.join(",");
        }
        if (!handout.type) handout.type="";
        handout.map=map;
        handout.playersCanEdit=playersCanEdit;
        HTTP.post("/api/handouts",handout).then(item => {
            console.log("item",item);
            sendTo(available,"handout",handout);
            if (share && share.itemRowKey==handout.rowKey){
                shareHandout();
            }
        })
    }

    let canSave=handout.name && (handout.image || handout.text || map);

    //let opts=campaign.players.map(c => <option selected={available.includes(c.rowKey)} value={c.rowKey}>{c.username}</option>)
    //opts.push(<option selected={available.includes(campaign.rowKey)} value={campaign.rowKey}>Everybody</option>);
    let opts=campaign.players.map(p => <PlayerItem key={p.rowKey} name={p.username} rowKey={p.rowKey} available={available} setAvailable={setAvailable} />)

    let img=getImageUrl(handout.partitionKey,handout.image);

    let ta=(handout.text || "").split("\n");
    ta=ta.map(t => "<p>"+t+"</p>").join("");

    function shareHandout(){
        let target=location.pathname;
        let sharedBy=player.username;
        let playerRowKey=player.rowKey;
        let itemRowKey=rowKey;
        let itemType="handout";
        let title=handout.name;
        let imageUrl=img;
        if (!handout.image){
            imageUrl="/images/texthandout.png";
            if (handout.type=="rumor") imageUrl="/images/rumorhandout.png";
            if (handout.type=="vision") imageUrl="/images/visionhandout.png";
        } 
        setShare({target,sharedBy,playerRowKey,imageUrl,title,itemRowKey,itemType});
    }

    if (noButtons) role="Player";
    let showEdit=(role=="Master");
    if ((handout.show=="text" || handout.show=="both") && handout.playersCanEdit) showEdit=true;

    return <div className="handoutDetail">
        {role=='Master' ? <div className="handoutForm">
            <div className="handoutBasics">
                <div className="formField">
                    <label>Name</label>
                    <input value={handout.name || ''} onChange={ev => changeText(ev,'name')} />        
                </div>
                <div className="formField">
                    <label>Type</label>
                    <select value={handout.type || ""} onChange={ev => changeText(ev,'type')}>
                        <option value="">Other</option>
                        <option value="rumor">Rumor</option>
                        <option value="vision">Vision</option>
                        <option value="map">Map</option>
                    </select>
                </div>
                <div className="formField">
                    <label>Found</label>
                    <input value={handout.foundDate || ''} onChange={ev => changeText(ev,'foundDate')} />        
                </div>
                {handout.show=="text" || handout.show=="both" ? <div className="formField">
                    <label>Players can edit</label>
                    <input type="checkbox" checked={playersCanEdit} onChange={() => setPlayersCanEdit(!playersCanEdit)} />
                </div> : <></>}
                {handout.type!='map' ? <>
                    <div className="formField">
                        <label>Image</label>
                        <input value={handout.image || ''} onChange={ev => changeText(ev,'image')} />
                    </div>
                    <div className="formField">
                        <label>Show</label>
                        <select value={handout.show} onChange={ev => changeText(ev,'show')}>
                            <option value="image">Image</option>
                            <option value="text">Text</option>
                            <option value="both">Both</option>
                        </select>
                    </div>
                </> : <>
                    <label>Map</label>
                    <input value={map || ''} onChange={ev => setMap(ev.target.value)} />
                </>}
            </div>
            <div className="availableTo">
                <label>Available to</label>
                <div className="availableToList">
                    {opts}
                    <PlayerItem name="Everybody" rowKey={campaign.rowKey} available={available} setAvailable={setAvailable} />
                </div>
            </div>
            <div className='buttons'>
                <input type="button" value="Save" onClick={save} disabled={!canSave} />
                <input type="button" value="Back" onClick={() => navigate(-1)} />
                <input type="button" value="Share" onClick={shareHandout} />
            </div>
        </div> : <div className="handoutHeader">
            <div>
                <h3>{handout.name}</h3>
                <p>Found: {handout.foundDate}</p>
            </div>
            <div>
                {!noButtons ? <input type="button" value="Back" onClick={() => navigate(-1)} /> : <></> }
                {role!="Master" && showEdit && modified ? <input type="button" value="Save" onClick={save} /> : <></> }
                {!noButtons ? <input type="button" value="Share" onClick={shareHandout} /> : <></> }
            </div>
        </div> }
        <div className="handoutData">
            {handout.type!='map' ? <>
                {handout.image && (handout.show=='image' || handout.show=='both') ? <img src={img} /> : <></> }
                {!showEdit && handout.text && (handout.show=='text' || handout.show=='both') ? <div className='handoutText' dangerouslySetInnerHTML={{__html:ta}} /> : <></> }
                {showEdit && handout.type!="map" ? <div className="formField">
                    {role=="Master" ? <label>Text</label> : <></> }
                    <textarea value={handout.text || ''} onChange={ev => changeText(ev,'text')} /></div> : <></>}
            </> : <>
                {mapData ? <MapEditor width={800} /> : <></>}
            </> }
        </div> 
    </div>
}

export function HandoutDetailRoute(){
    const params=useParams();
    return <HandoutDetail rowKey={params.rowKey} />
}

export function HandoutItem({handout,root='./',noClick=false}){
    let {player}=useSelector(state => state.game);
    let [map,setMap]=useState(null);
    let canvasRef=useRef(null)
    let img=getImageUrl(handout.partitionKey,handout.image);
    if (!handout.image){
        img="/images/texthandout.png";
        if (handout.type=="rumor") img="/images/rumorhandout.png";
        if (handout.type=="vision") img="/images/visionhandout.png";
    } 
    let imgStyle={backgroundImage:'url("'+img+'")'}
    const navigate=useNavigate();

    useEffect(function(){
        if (handout.type!="map") return;
        HTTP.get("/api/maps/"+handout.map).then(jmap => {
            let map=Map.fromJson(jmap);
            map.options.showGrid=false;
            map.options.backgroundScale=120/map.options.canvasWidth;
            calculateOptionValues(map.options);
            map.points.forEach(p => calculatePixels(p,map.options));
            setMap(map);
        });
    },[]);

    useEffect(function(){
        let canvas=canvasRef.current;
        if (!canvas || !map) return
        let json=JSON.stringify(map);
        let jsonMap=JSON.parse(json);
        let pmap=Map.fromJson(jsonMap);
        const ctx = canvas.getContext("2d");
        drawMap(pmap,"bw",player.rowKey,[],[]).then(c => {
            ctx.drawImage(c,0,0);
        });
    },[map]);

    return <div className="handoutItem" onClick={() => noClick ? 0 : navigate(root+handout.rowKey)}>
        {!map ? <div className="handoutImage" style={imgStyle}>
            <div className='handoutDescription'>
                <p>{handout.name}<br/>{handout.foundDate}</p>
            </div>
        </div> :<div className="handoutImage">
            <canvas ref={canvasRef} width={120} height={120} />
            <div className='handoutDescription'>
                <p>{handout.name}<br/>{handout.foundDate}</p>
            </div>
        </div>}
    </div>
}

export function HandoutList(){
    const {player,role,campaign,socketHandout}=useSelector(state => state.game);
    const [handouts,setHandouts]=useState([]);
    const navigate=useNavigate();

    useEffect(function(){
        HTTP.get("/api/handouts").then(handouts => {
            console.log("Got them",handouts);
            setHandouts(handouts);
        })
    },[])

    useEffect(function(){
        HTTP.get("/api/handouts").then(handouts => {
            console.log("Got them",handouts);
            setHandouts(handouts);
        })
    },[socketHandout]);

    console.log("Handouts",handouts);

    let hos=handouts.sort((a,b) => (b.foundDate || "").localeCompare(a.foundDate || ""))
        .map(ho => <HandoutItem key={ho.rowKey} handout={ho} />);

    return <div className='handoutListContent'>
        <h3>Handouts</h3>
        <div className="handoutList">{hos}</div>
        {role=="Master" ? <input type="button" value="Create" onClick={() => navigate('./new')} /> : <></> }
    </div>
}

export function HandoutsMain(){

    return <div className='handoutContent'>
        <Outlet />
    </div>
}