import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { clearNewChatFrom, sendChat } from '../utils/store';

import './characterchat.scss';

export function CharacterChat({character,targetGuid}){
    let [text,setText]=useState("");
    let [sendAs,setSendAs]=useState("");
    let {chat,newChatMessages,campaign,player}=useSelector(state => state.game);

    let chars=campaign.characters.filter(c => c.values.partitionKey==player.rowKey);
    console.log("Characters",chars);
    let from=player.rowKey;
    let name=player.username;
    if (chars.length) {
        from=chars[0].values.rowKey;
        name=chars[0].values.name;
    }


    let hasChat=newChatMessages.includes(character.values.rowKey)
    if (hasChat) clearNewChatFrom(character.values.rowKey)

    useEffect(function(){
        if (chars.length>1) setSendAs(chars[0].values.rowKey)
    },[]);

    function send(){
        if (sendAs) from=sendAs;
        let data={from,text};
        sendChat(targetGuid,data)
        setText("");
    }

    let messages=chat.filter(c => c.to.includes(targetGuid) || c.data.from==targetGuid);
    messages.reverse();

    let messageItems=messages.map(m => {
        let cls=m.to.includes(targetGuid) ? "messageFromMe" : "messageToMe";
        cls+=" messageItem";
        return <div className={cls}><p>{m.data.text}</p></div>
    })

    let cp=campaign.players.find(p => p.rowKey==character.values.partitionKey);
    let un=cp?.username || character.values.username;

    return <div>
        <h3>Chat with {un}</h3>
        <div className="newChatMessage">
            <textarea value={text} onChange={ev => setText(ev.target.value)} placeholder="Enter message here" />
            <button className="sendButton" onClick={send} />
        </div>
        <div className="chatList">{messageItems}</div>
    </div>
}
