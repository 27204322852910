import { HTTP } from "./http";

export function guid(prefix='map'){
    let d=prefix+(parseInt(Math.random() * 10000)).toString();
    d+=new Date().getTime();
    return d;
}

export function getImageUrl(path,file){
    let imageUrl=`https://kooditutoritstorage.blob.core.windows.net/eor/${path}/images/${file}`;
    return imageUrl;
}

export async function getCampaignFile(campaign,file){
    let url="https://kooditutoritstorage.blob.core.windows.net/eor/"+campaign+"/"+file;
    let data=null;
    try{
        let options=await HTTP.get(url);    
        console.log("Options",options)
    }
    catch(err){
        console.log("No file",file);
    }
    return data;
}