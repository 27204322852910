import React from 'react';
 
import './tile.scss';

export function Tile({children,header='',styles='', onClick=null}){
    let cls='tile';
    if (onClick) cls+=" clickable";
    let contentCls='tileContent';
    if (header) contentCls+=' withHeader'
    if (styles) cls+=' '+styles;
    return <div className={cls} onClick={() => (onClick ? onClick() : 0 )}>
        {header ? <h2 className='tileHeader'>{header}</h2> : '' }
        <div className={contentCls}>
            {children}
        </div>
    </div>
}