import React, { useEffect, useState } from 'react';

import "./mapoptions.scss";
import { calculateOptionValues } from './points/pointutils';
import { loadImage } from './utils/images';
import { ImageSelector } from '../utils/imageeditor';
import { useSelector } from 'react-redux';
import { getImageUrl } from '../utils/utils';
import { Checkbox } from '../utils/formfields';
import { setMapData, setMapOptions } from './utils/mapstore';
import { Icon } from '../utils/icons';
import { Map } from './map';

export function MapOptions({onSave=null}){
    let {mapData,mapOptions}=useSelector(state => state.maps)
    const [selectBkr,setSelectBkr]=useState(false);
    const [selectBkr2,setSelectBkr2]=useState(false);
    let {player}=useSelector(state => state.game)
    let options={...mapOptions};
    let [rescale,setRescale]=useState(1);

    async function loadBkrImage(){
        if (!options.backgroundImageUrl) return;
        let imageData=await loadImage(options.backgroundImageUrl);
        let img=imageData.img;
        if (!img) return;
        options.imageWidth=img.width;
        options.imageHeight=img.height;
        calculateOptionValues(options);
        //setOptions({...options});
        //onChange(options);
    }

    useEffect(function() {
        loadBkrImage();
    },[]);
    //useEffect(() => setOptions({...map.options}),[map]);

    function changeFieldTruly(field,value,isNumber=false){
        if (isNumber) value=Number(value);
        options[field]=value;
        calculateOptionValues(options);
        console.log("change",field,value,options[field]);
        //setOptions({...options});
        //onChange(options,true)
        console.log("Mapoptions",options)
        setMapOptions(options);
    }

    function changeField(field,ev,isNumber=false){
        console.log("Change field",field,options)
        let value=ev.target.value;
        changeFieldTruly(field,value,isNumber);
    }

    async function imageChanged(name){
        console.log("ImageChanged")
        if (name) {
            options.backgroundImageUrl=getImageUrl(player.rowKey,name);
            let imageData=await loadImage(options.backgroundImageUrl);
            let img=imageData.img;
            if (!img) return;
            console.log("loaded",img)
            options.imageWidth=img.width;
            options.imageHeight=img.height;
            calculateOptionValues(options);
            //onChange(options);
            setMapOptions(options);
        }
        setSelectBkr(false);
    }

    async function imageChanged2(name){
        if (name) {
            options.backgroundImageUrl2=getImageUrl(player.rowKey,name);
            let imageData=await loadImage(options.backgroundImageUrl2);
            let img=imageData.img;
            if (!img) return;
            console.log("loaded",img)
        }
        else{
            options.backgroundImageUrl2="";
        }
        calculateOptionValues(options);
        setMapOptions(options);
        setSelectBkr2(false);
    }

    function saveOptions(){
        //loadBkrImage();
        //onChange(options);
        if (onSave) onSave(options);
    }

    let bkrImage="Select image";
    if (options.backgroundImageUrl){
        let parts=options.backgroundImageUrl.split("/");
        bkrImage=parts[parts.length-1];
    }
    let bkrImage2="Select image";
    if (options.backgroundImageUrl2){
        let parts=options.backgroundImageUrl2.split("/");
        bkrImage2=parts[parts.length-1];
    }

    let heightDisabled=!!options.backgroundImageUrl;
    
    let maxSquareFeet=options.mapWidth/2;
    let minSquareFeet=1;
    if (options.mapUnits=="miles") minSquareFeet=0.25;
    if (options.mapUnits=="yards")minSquareFeet=0.5;
    let step=minSquareFeet;

    let mw=minSquareFeet*options.feetToPixelsRatio;
    let values=[0.25,0.5,1,2,5,10,20,50,100,200,500,1000,2000,5000,10000];
    let steps= [0.25,0.5,1,1,5,5 ,5 ,50,50 ,100,500,500 ,1000,5000,5000 ];
    while(mw<5){
        let index=values.indexOf(minSquareFeet);
        if (index<values.length-1) {
            minSquareFeet=values[index+1];
            step=steps[index+1];        
        }    
        else {
            minSquareFeet*=10;
            step*=10;
        }
        mw=minSquareFeet*options.feetToPixelsRatio;
    }

    function doRescale(){
        let map=Map.fromJson(mapData);
        map.points.forEach(p => {
            p.feet.x*=rescale;
            p.feet.y*=rescale;
        })
        map.options.mapWidth*=rescale;
        map.options.mapHeight*=rescale;
        setMapData(map);
    }

    //console.log("Rendering options",minSquareFeet,options.mapWidth)

    return <div className="mapOptions">
        <div className='formInput'>
            <label>Name</label>
            <input value={options.name} onChange={ev => changeField('name',ev)} />
        </div>
        <div className="formInput">
            <Checkbox label="Only show visited" boxPosition='right' checked={options.onlyShowVisited} onChange={v => changeFieldTruly("onlyShowVisited",v)} />
        </div>
        <div className='formInput'>
            <Checkbox label="Publish to players" boxPosition='right' checked={options.published} onChange={v => changeFieldTruly("published",v ? 1 : 0,true)} />
        </div>    
        <div className="formInput mapUnits">
            <label>Units</label>
            <div>
                <Checkbox label="Feet" type="circle" boxPosition='right' checked={options.mapUnits=='feet'} onChange={v => v ? changeFieldTruly("mapUnits","feet") : 0} />
                <Checkbox label="Yards" type="circle" boxPosition='right' checked={options.mapUnits=='yards'} onChange={v => v ? changeFieldTruly("mapUnits","yards"): 0} />
                <Checkbox label="Miles" type="circle" boxPosition='right' checked={options.mapUnits=='miles'} onChange={v => v ? changeFieldTruly("mapUnits","miles") : 0} />
            </div>
        </div>
        <div className="formInput">
            <label>Backround image</label>
            <input type="button" value={bkrImage} onClick={() => setSelectBkr(true)} />
            {options.backgroundImageUrl ? <Icon type={Icon.delete} onClick={() =>changeFieldTruly('backgroundImageUrl','')} /> : <></>}
            {selectBkr ? <ImageSelector path={player.rowKey} onClose={imageChanged} /> : <></> }
        </div>
        <div className="formInput">
            <Checkbox label="No background fill" boxPosition="right" checked={options.noBackgroundFill} onChange={v => changeFieldTruly('noBackgroundFill',v ? 1: 0,true)} />
        </div>
        {options.backgroundImageUrl ? <div className="formInput">
            <Checkbox label="Hide image" boxPosition="right" checked={options.hideBkrImage} onChange={v => changeFieldTruly('hideBkrImage',v ? 1: 0,true)} />
        </div> : <></>}
        {options.backgroundImageUrl ? <div className='formInput'>
            <label>Second layer</label>
            <input type="button" value={bkrImage2} onClick={() => setSelectBkr2(true)} />
            {options.backgroundImageUrl2 ? <Icon type={Icon.delete} onClick={() => imageChanged2("")} /> : <></>}
            {selectBkr2 ? <ImageSelector path={player.rowKey} onClose={imageChanged2} /> : <></> }
        </div> : <></>}        

        <div className="formInput">
            <label>Size (in {options.mapUnits})</label>
            <input type="number" value={options.mapWidth} onChange={ev => changeField('mapWidth',ev,true)} /> X 
            <input type="number" disabled={heightDisabled} value={options.mapHeight} onChange={ev => changeField('mapHeight',ev,true)} />
        </div>
        <div className="formInput">
            <label>Squares</label>
            <p>{Math.floor(options.mapSquaresX)} x {Math.floor(options.mapSquaresY)}</p>
        </div>
        <div className='formInput rangeInput'>
            <label>Square is {options.squareFeet} {options.mapUnits}</label>
            <input type="range" min={minSquareFeet} max={maxSquareFeet} step={step} value={options.squareFeet} onChange={ev => changeField('squareFeet',ev,true)} />
        </div>
        <div>
            <label>Asset scale</label>
            <input type="range" min={0.1} max={20} step={0.1} value={options.assetScale || 1} onChange={ev => changeField('assetScale',ev,true)} />
        </div>
        <div className='formInput rangeInput'>
            <label>Wall thickness {options.wallThickness} feet</label>
            <input type="range" min={0.5} max={10} step={0.5} value={options.wallThickness} onChange={ev => changeField('wallThickness',ev,true)} />
        </div>
        <div>
            <label>Rescale</label>
            <input type="range" min={0.5} max={10} step={0.5} value={rescale} onChange={ev => setRescale(Number(ev.target.value))} />
            <input type="button" value="Rescale" onClick={doRescale} />
        </div>
    </div>
}