import React, { useState } from 'react';
import { abbreviations, shortNameToField } from './character';
import './attributes.scss';
import { Dice} from '../dice/dicebox';
import { useSelector } from 'react-redux';


function Attribute({character,edit=false,field,enabled=true,setEnabled=null,onChange=null}){
    let {value,name}=character.attributes[field];
    let {role}=useSelector(state => state.game);
    const [displayValue,setDisplayValue]=useState(value>=0 ? value : '');

    function roll(){
        let dice='3d6';
        let plus=0;
        if (['dexterity','strength','constitution'].includes(field)){
            dice='2d6';
            plus=6;
        }
        if (setEnabled) setEnabled(false);
        Dice.roll(dice,plus).then(r => {
            let v=0;
            if (r.value>=4) v=1;
            if (r.value>=6) v=2;
            if (r.value>=9) v=3;
            if (r.value>=13) v=4;
            if (r.value>=16) v=5;
            if (r.value>=18) v=6;
            console.log(r,v);
            character.attributes[field]=v;
            setDisplayValue(v);
            //let field=shortNameToField(shortName);
            if (onChange) onChange(field,v);
            if (setEnabled) setEnabled(true);
        })
    }

    function onChangeField(ev){
        let v=Number(ev.target.value)
        character.attributes[field]=v;
        setDisplayValue(v);
        if (onChange) onChange(field,v);
    }

    return <div className="attribute">
        <p>{name}</p>
        {edit && role=="Master" ? <p><input type="number" min={0} max={6} value={value} onChange={onChangeField} /></p> : 
        <p>{
            value >= 0 ? value : enabled ? <a onClick={roll}>Roll</a> : <></>
        }</p>}
    </div>
}

export function Attributes({character,edit,onComplete=null}){
    const [completed,setCompleted]=useState(character.strength>=0 && character.dexterity>=0 && character.constitution>=0 && character.intelligence>=0 && character.wisdom>=0 && character.charisma>=0)
    const [values,setValues]=useState([]);
    const [charismaIncreased,setCharismaIncreased]=useState(false);
    const [enabled,setEnabled]=useState(true);

    console.log("Attributes",completed,character)

    function attributeHasValue(field,value){
        let newValues=[...values,{field,value}];
        setValues(newValues);
        console.log("Got value",character.Dex,character.Int,values)
        if (character.strength<0 || character.dexterity<0 || character.constitution<0) return; 
        if (character.intelligence<0 || character.wisdom<0 || character.charisma<0) return; 
        console.log("Completed")
        let {strength,dexterity,constitution,intelligence,wisdom,charisma}=character;
        if (strength<4 && dexterity<4 && constitution<4 && intelligence<4 && wisdom<4 && charisma<4){
            let vals=values.filter(v => v.field!='charisma');
            vals.push({field:'charisma',value:4});
            setValues(vals);
            character.charisma=4;
            setCharismaIncreased(true);
        }
        setCompleted(true);
    }

    function completeClicked(){
        console.log("completeClicked");
        onComplete(values);
    }


    console.log("Attr",completed,onComplete)
    return <div className="attributes">
        <div className='attributeContainer'>
            <div>
                <Attribute enabled={enabled} setEnabled={setEnabled} edit={edit} onChange={attributeHasValue} character={character} field="dexterity" />
                <Attribute enabled={enabled} setEnabled={setEnabled} edit={edit} onChange={attributeHasValue} character={character} field="strength" />
                <Attribute enabled={enabled} setEnabled={setEnabled} edit={edit} onChange={attributeHasValue} character={character} field="constitution" />
            </div>
            <div>
                <Attribute enabled={enabled} setEnabled={setEnabled} edit={edit} onChange={attributeHasValue} character={character}  field="intelligence" />
                <Attribute enabled={enabled} setEnabled={setEnabled} edit={edit} onChange={attributeHasValue} character={character}  field="wisdom" />
                <Attribute enabled={enabled} setEnabled={setEnabled} edit={edit} onChange={attributeHasValue} character={character}  field="charisma" />
            </div>
        </div>
        {completed && onComplete ? <div>
            {charismaIncreased ? <p>Your rolled no above average values for attributes. As a favor from gods your charisma is increased to four.</p> : <></>}
            <input type="button" value="Continue" onClick={completeClicked} />
            </div> : <></>}
    </div>
}