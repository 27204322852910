import React, { useState } from 'react';
import { Dice } from '../../dice/dicebox';
import { CharacterWizardHelp } from './wizardhelp';
import { initialItems } from '../character';


export function CharacterWizardMoney({character,values,onComplete}){
    const [money,setMoney]=useState(0);
    const [rations,setRations]=useState(0);
    const [healings,setHealings]=useState(0);
    const [result,setResult]=useState(0);
    const [rolling,setRolling]=useState(false);

    function rollForMoney(){
        setRolling(true);
        Dice.roll("3d6").then(result => {
            setResult(result.value);
            setMoney(1000+result.value*10);
            setRolling(false);
        })
    }

    function rollForRations(){
        setRolling(true);
        Dice.roll("1d6").then(result => {
            setRations(result.value);
            setRolling(false);
        })
    }

    function rollForHealings(){
        setRolling(true);
        Dice.roll("1d6").then(result => {
            setHealings(result.value);
            setRolling(false);
        })
    }

    function completeClicked(){
        character.values.silverpieces=money;
        character.values.healingkit=healings;
        character.values.rations=rations;
        //        values=[...values,{field:'silverpieces',value:money}];
        //let rationsItem={name:"Rations",amount:rations,price:0,location:'onperson',enc:'bulky'};
        //let healingsItem={name:"Healing Kit",amount:healings,price:0,location:'beltpouch',enc:'light'}
        onComplete(values,[...initialItems]);
    }

    return <div className='wizardMoney'>
        <div>
            <h3>Money</h3>
            {money ? <div>
                    <p>You have 1000+{result}*10 = {money}sp </p>
                </div> :
                <div>
                    <p>You will have 1000+3d6*10 silver pieces for your initial purchases.</p>
                    {!rolling ? <input type="button" value="Roll for money" onClick={rollForMoney} /> : <></> }
                </div>}
            <h3>Rations</h3>
            {rations ? <div>
                <p>You have rations for {rations > 1 ? rations+' days' : 'one day'}.</p>
            </div> : <div>
                    <p>You have rations for 1d6 days.</p> 
                    {!rolling ? <input type="button" value="Roll for rations" onClick={rollForRations} /> : <></> }
                </div>}
            <h3>Healing kit</h3>
            {healings ? <div>
                    <p>Your Healing kit contains bandages and herbs for {healings>1 ? healings+' healings' : 'one healing'}.</p>
                </div> : <div>
                    <p>Your Healing kit contains bandages and herbs for 1d6 healings.</p>
                    {!rolling ? <input type="button" value="Roll for Healing kit"  onClick={rollForHealings} /> : <></>}
                </div>}
            {money && rations && healings ? <div>
                    <input type="button" value="Continue" onClick={completeClicked} />
                </div> : <></>}
        </div>
    </div>
}

