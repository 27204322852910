import React from 'react';
import { Tile } from './tile';
import { useSelector } from 'react-redux';

export function PlayerRolls(){
    let {skillTests}=useSelector(state => state.game);

    let rolls=skillTests.map(t => <p>{t.name} tested {t.skill} {t.result.resultString}={t.result.value}</p>)

    return <Tile header="Rolls">
        {rolls}
    </Tile>
}