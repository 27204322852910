import { drawAssets } from "../assets/assetrenderer";
import { calculatePixels, getAng, lineInfo, lineInfoRawPoints, point, pointOnLine } from "../points/pointutils";
import { createShape, drawNotes } from "../renderer";
import { loadImage } from "../utils/images";

function bezierControl(point,prev,next,radius=20){
    let li1=lineInfoRawPoints(prev,point);
    let li2=lineInfoRawPoints(point,next);
    let aDif=(li2.ang-li1.ang);
    while(aDif<-Math.PI) aDif+=2*Math.PI;
    while(aDif>Math.PI) aDif-=2*Math.PI;
    let aNormal=li2.ang-aDif/2;
/*
    console.log("Angle",
        Math.round(li1.ang*180/Math.PI),
        Math.round(li2.ang*180/Math.PI),
        Math.round(aDif*180/Math.PI),
        Math.round(aNormal*180/Math.PI));
*/
    let dx=radius*Math.cos(aNormal);
    let dy=radius*Math.sin(aNormal)
    let x=point.x+dx;
    let y=point.y+dy;
    let forward={x,y};
    x=point.x-dx;
    y=point.y-dy;
    let backward={x,y};
    return({forward,backward});
}

function drawFencePosts(ctx,p1,p2,type,options,drawLast=false){
    //console.log("Fence",p1,p2)
    let li=lineInfo(p1,p2);    
    let {length,ang}=li;
    length*=options.feetToPixelsRatio;
    let pd=10;
    if (type=="fortification") pd=4;
    let numPosts=Math.round(length/(pd*options.backgroundScale));
    let postDist=length/numPosts;
    let radius=2;
    if (type=="fortification") radius=1.7;
    //console.log("Fence",length,numPosts,postDist)
    let num=numPosts-1;
    if (drawLast) num++;
    for(let i=0;i<=num;i++){
        ctx.beginPath();
        let x=p1.pixels.x+(i*postDist)*Math.cos(ang);
        let y=p1.pixels.y+(i*postDist)*Math.sin(ang);
        ctx.arc(x,y,radius*options.backgroundScale,0,2*Math.PI);
        ctx.closePath();
        ctx.fill();
    }
}

async function drawStoneWall(ctx,map,p1,p2,startOpening,endOpening){
    let {options}=map;
    let {wallThickness,feetToPixelsRatio}=options;
    let li=lineInfo(p1,p2);
    let {length,ang}=li;
    if (!startOpening) length+=options.wallThickness/2;
    if (!endOpening) length+=options.wallThickness/2;
    let pixelWidth=length*options.feetToPixelsRatio;
    let pixelHeight=wallThickness*feetToPixelsRatio;
    let canvas=document.createElement('canvas');
    //console.log("stone",startOpening,endOpening)
    canvas.width=pixelWidth+64;
    canvas.height=pixelHeight;
    let wallImage=await loadImage("/assets/walls/stone-long.svg");
    let wallContext=canvas.getContext("2d");
    wallContext.moveTo(0,0);
    wallContext.lineTo(pixelWidth,0);
    wallContext.lineTo(pixelWidth,pixelHeight);
    wallContext.lineTo(0,pixelHeight);
    wallContext.closePath();
    wallContext.clip();
    for(let i=0;i<pixelWidth;i+=pixelHeight*10){
        wallContext.drawImage(wallImage.img,i,0,i+pixelHeight*10,pixelHeight);
    }
    return new Promise(resolve => {
        let img=new Image();
        img.src=canvas.toDataURL();
        img.onload=() => {
            let dx=0,dy=0;
            if (!startOpening){
                dx=options.wallThickness*options.feetToPixelsRatio*Math.cos(ang)/2;
                dy=options.wallThickness*options.feetToPixelsRatio*Math.sin(ang)/2;
            }
            let x=p1.pixels.x-dx+pixelHeight*Math.sin(ang)/2;
            let y=p1.pixels.y-dy-pixelHeight*Math.cos(ang)/2;
            ctx.save();
            ctx.translate(x,y);
            ctx.rotate(ang);
            ctx.drawImage(img,0,0);
            ctx.restore();
            resolve(img);
        }
    })
}

async function drawLogWall(ctx,map,p1,p2,startOpening,endOpening){
    let {options}=map;
    let {wallThickness,feetToPixelsRatio}=options;
    let li=lineInfo(p1,p2);
    let {length,ang}=li;
    if (!startOpening) length+=options.wallThickness/2;
    if (!endOpening) length+=options.wallThickness/2;
    let pixelWidth=length*options.feetToPixelsRatio;
    let pixelHeight=wallThickness*feetToPixelsRatio;
    //console.log("Wall thickness",pixelHeight)
    let canvas=document.createElement('canvas');
    //console.log("Log",pixelHeight,pixelWidth)
    canvas.width=pixelWidth+64;
    canvas.height=pixelHeight;
    let wallImage=await loadImage("/assets/walls/log-long.svg");
    let wallContext=canvas.getContext("2d");
    wallContext.moveTo(0,0);
    wallContext.lineTo(pixelWidth,0);
    wallContext.lineTo(pixelWidth,pixelHeight);
    wallContext.lineTo(0,pixelHeight);
    wallContext.closePath();
    wallContext.clip();
    for(let i=0;i<pixelWidth;i+=pixelHeight*10){
        wallContext.drawImage(wallImage.img,i,0,i+pixelHeight*10,pixelHeight);
    }
    return new Promise(resolve => {
        let img=new Image();
        img.src=canvas.toDataURL();
        img.onload=() => {
            let dx=0,dy=0;
            if (!startOpening){
                dx=options.wallThickness*options.feetToPixelsRatio*Math.cos(ang)/2;
                dy=options.wallThickness*options.feetToPixelsRatio*Math.sin(ang)/2;
            }
            let x=p1.pixels.x-dx+pixelHeight*Math.sin(ang)/2;
            let y=p1.pixels.y-dy-pixelHeight*Math.cos(ang)/2;
            ctx.save();
            ctx.translate(x,y);
            ctx.rotate(ang);
            ctx.drawImage(img,0,0);
            ctx.restore();
            resolve(img);
        }
    })
}

export function findOpeningEndpoints(map,opening){
    let {options,objects}=map;
    let controllingLine=objects.find(l => l.rowKey==opening.owningLine);
    let {points}=controllingLine;
    let pointIndex=points.findIndex(p => p.rowKey==opening.rowKey);
    let openingPoint=points[pointIndex];
    let {x,y}=openingPoint.pixels;
    let prev=points[pointIndex>0 ? pointIndex-1 : points.length-1];
    let nextIndex=pointIndex<points.length-1 ? pointIndex+1 : 0;
    let next=points[pointIndex<points.length-1 ? pointIndex+1 : 0];
    //console.log("endpoints",opening.rowKey,pointIndex,nextIndex)
    if (!opening.width){
        opening.start=prev;
        opening.end=next;
        return {start:prev,end:next};
    }
    let li=lineInfo(prev,next);
    let {ang}=li;
    let len=opening.width*options.feetToPixelsRatio; //*options.backgroundScale;
    if (options.mapUnits!="feet") len=8*opening.width/5;
    let dx=len*Math.cos(ang)/2;
    let dy=len*Math.sin(ang)/2;
    let p1x=x-dx;
    let p1y=y-dy;
    let p2x=x+dx;
    let p2y=y+dy;
    let r=options.feetToPixelsRatio;
    let start={feet:{x:p1x/r,y:p1y/r},pixels:{x:p1x,y:p1y}};
    let end={feet:{x:p2x/r,y:p2y/r},pixels:{x:p2x,y:p2y}};
    //opening.endPoints={start,end};
    opening.start=start;
    opening.end=end;
    //console.log("DX,DY",dx,dy,len,x,y,start.pixels.x,start.pixels.y,end.pixels.x,end.pixels.y)
    return {start,end};
}

async function drawSegment(ctx,map,index,item,openings){
    let {lineType,points,rowKey}=item;
    let {options}=map;
    let endIndex=index<points.length-1 ? index+1 : 0; 
    //console.log("Segment",index,endIndex);
    let start=points[index];
    let end=points[endIndex];
    let startOpening=null,endOpening=null;
    if (map.options.canvasWidth>150){
        startOpening=map.openings.find(o => o.rowKey==start.rowKey);
        endOpening=map.openings.find(o => o.rowKey==end.rowKey);
    }
    if (startOpening) openings.push(startOpening);
    if (endOpening){
        end=(rowKey==endOpening.owningLine ? endOpening.start : endOpening.end);
        //console.log("Endopening",index,start.pixels,end.pixels);
    }
    if (startOpening){
        start=(rowKey==startOpening.owningLine ? startOpening.end : startOpening.start);
        //console.log("Found start opening",index,start.pixels,end.pixels)
    }
    
    ctx.beginPath();
    ctx.moveTo(start.pixels.x,start.pixels.y);
    ctx.lineTo(end.pixels.x,end.pixels.y);
    ctx.stroke();
    if (lineType=='fence' || lineType=='fortification') drawFencePosts(ctx,start,end,lineType,options,endOpening);
    if (lineType=="log") return await drawLogWall(ctx,map,start,end,startOpening,endOpening);
    if (lineType=="stone") return await drawStoneWall(ctx,map,start,end,startOpening,endOpening);
}

function drawWindowGate(ctx,x,y,x1,y1){
    ctx.beginPath();
    ctx.lineWidth=1;
    ctx.beginPath();
    ctx.moveTo(x,y);
    ctx.lineTo(x1,y1);
    ctx.stroke();
}

function drawDoor(ctx,x,y,x1,y1){
    let ang=getAng(x,y,x1,y1);
    let dx=2*Math.sin(ang);
    let dy=2*Math.cos(ang);
    ctx.lineWidth=1;
    ctx.strokeStyle="#000000";
    ctx.fillStyle="#ffffff";
    ctx.beginPath();
    ctx.moveTo(x-dx,y+dy);
    ctx.lineTo(x1-dx,y1+dy);
    ctx.lineTo(x1+dx,y1-dy);
    ctx.lineTo(x+dx,y-dy);
    ctx.closePath();
    ctx.stroke();
    ctx.fill();
}

function drawArc(ctx,line,x1,y1,x5,y5){
    console.log("draw arc")
    let {lineType,lineWidth,lineColor}=line;
    let dx=(x5-x1)/2;
    let dy=(y5-y1)/2;
    let xc=x1+dx;
    let yc=y1+dy;
    let r=Math.sqrt(dx*dx+dy*dy);
    let ang=getAng(x1,y1,x5,y5);
    let x2=x1+r*Math.cos(ang-Math.PI/2);
    let y2=y1+r*Math.sin(ang-Math.PI/2);
    let x3=xc+r*Math.cos(ang-Math.PI/2);
    let y3=yc+r*Math.sin(ang-Math.PI/2);
    ctx.arcTo(x2,y2,x3,y3,r);
    let x4=x5+r*Math.cos(ang-Math.PI/2);
    let y4=y5+r*Math.sin(ang-Math.PI/2);
    ctx.arcTo(x4,y4,x5,y5,r);

}

function drawOpening(ctx,map,opening,line){
    let {start,end,isOpen,type,width}=opening;
    if (type=="opening") return;
    let {options}=map;
    let li=lineInfo(start,end);
    let {ang,length}=li;
    let pixelLength=length*options.feetToPixelsRatio;
    if (width==10) pixelLength/=2;
    let ang1=ang-Math.PI/6;
    if (type=="window" || !isOpen) ang1=ang;
    let x1=start.pixels.x+pixelLength*Math.cos(ang1);
    let y1=start.pixels.y+pixelLength*Math.sin(ang1);
    if (type=="window" || type=="gate") drawWindowGate(ctx,start.pixels.x,start.pixels.y,x1,y1);
    if (type=="door") drawDoor(ctx,start.pixels.x,start.pixels.y,x1,y1);
    //if (type=="arc") drawArc(ctx,line,start.pixels.x,start.pixels.y,x1,y1);
    //ctx.closePath();
    if (width==10){
        let ang2=ang+Math.PI+Math.PI/6;
        if (type=="window" || !isOpen) ang2=ang+Math.PI;
        let x2=end.pixels.x+pixelLength*Math.cos(ang2);
        let y2=end.pixels.y+pixelLength*Math.sin(ang2);
        if (type=="window" || type=="gate") drawWindowGate(ctx,end.pixels.x,end.pixels.y,x2,y2);
        if (type=="door") drawDoor(ctx,end.pixels.x,end.pixels.y,x2,y2);
    }
}

async function drawFloor(ctx,map,item,file,bezierControls){
    let floorImage=await loadImage(file);
    return new Promise(async resolve => {
        let {options}=map;
        let canvas=document.createElement("canvas");
        canvas.width=options.canvasWidth;
        canvas.height=options.canvasHeight;
        let {width,height}=floorImage.img;
        width*=options.backgroundScale;
        height*=options.backgroundScale;
        let ctxFloor=canvas.getContext("2d");
        createLineShape(ctxFloor,map,item.points,true,bezierControls,item.rough);
        //createShape(ctxFloor,map.options,item.points);
        ctxFloor.clip();
        let left=canvas.width/2-width/2;
        let top=canvas.height/2-height/2;
        while(left>0) left-=width;
        while(top>0) top-=height;
        for(let x=left;x<canvas.width;x+=width){
            for(let y=top;y<canvas.height;y+=height){
                ctxFloor.drawImage(floorImage.img,x,y,width,height);
            }
        }
        let img=new Image();
        img.src=canvas.toDataURL();
        img.onload=() => {
            ctx.drawImage(img,0,0,options.canvasWidth,options.canvasHeight);
            resolve();
        }; 
    });

}

function createLineShape(ctx,map,points,closed,bezierControls,rough){
    let len=closed ? points.length : points.length-1;
    ctx.beginPath();
    ctx.moveTo(points[0].pixels.x,points[0].pixels.y);
    let lastX=points[0].pixels.x;
    let lastY=points[0].pixels.y;
    for(let i=1;i<=len;i++){
        let currentPoint=points[i<points.length ? i : 0];
        let current=currentPoint.pixels;
        let opening=map.openings.find(o => o.rowKey==currentPoint.rowKey);
        if (opening && opening.type=="arc"){
            let ang=getAng(lastX,lastY,current.x,current.y);
            let r=5*map.options.feetToPixelsRatio;
            let x1=current.x+r*Math.cos(ang-Math.PI);
            let y1=current.y+r*Math.sin(ang-Math.PI);
            let x2=x1+r*Math.cos(ang-Math.PI/2);
            let y2=y1+r*Math.sin(ang-Math.PI/2);
            let x3=current.x+r*Math.cos(ang-Math.PI/2);
            let y3=current.y+r*Math.sin(ang-Math.PI/2);
            let x4=current.x+r*Math.cos(ang);
            let y4=current.y+r*Math.sin(ang);
            let x5=x4+r*Math.cos(ang-Math.PI/2);
            let y5=y4+r*Math.sin(ang-Math.PI/2);
            
            ctx.lineTo(x1,y1);
            ctx.arcTo(x2,y2,x3,y3,r);
            ctx.arcTo(x5,y5,x4,y4,r);
        }
        else if (bezierControls){
            let startControl=bezierControls[i-1]
            let endControl=bezierControls[i<points.length ? i : 0];
            let c1=startControl.forward;
            let c2=endControl.backward;
            ctx.bezierCurveTo(c1.x,c1.y,c2.x,c2.y,current.x,current.y) 
        }
        else {
            if (rough){
                roughLine(ctx,lastX,lastY,current.x,current.y);
            }
            else ctx.lineTo(current.x,current.y);
            lastX=current.x;
            lastY=current.Y;
        }
    }
    if (closed) ctx.closePath();
}

function roughLine(ctx,x0,y0,x1,y1){
    let ang=getAng(x0,y0,x1,y1);
    let dx=(x1-x0);
    let dy=(y1-y0);
    let len=Math.sqrt(dx*dx+dy*dy);
    let pos=3;
    while(pos<len){
        let x=x0+pos*Math.cos(ang);
        let y=y0+pos*Math.sin(ang);
        let cx=((pos%5)-2)*Math.cos(ang+Math.PI/2);
        let cy=((pos%5)-2)*Math.sin(ang+Math.PI/2);
        x+=cx;
        y+=cy;
        ctx.lineTo(x,y);
        pos+=3;
    }
    ctx.lineTo(x1,y1);
}

function drawSolid(ctx,map,item,openings){
    let {points,closed,rowKey,rough}=item;
    let index=points.findIndex(p => {
        return map.openings.find(o => o.rowKey==p.rowKey);
    })
    if (index<0) index=0;
    let start=points[index];
    let opening=null;
    if (map.options.canvasWidth>150) opening=map.openings.find(o => o.rowKey==start.rowKey);
    if (opening){
        openings.push(opening)
        start=(rowKey==opening.owningLine ? opening.end : opening.start);
    }
    ctx.beginPath();
    ctx.moveTo(start.pixels.x,start.pixels.y);
    let lastX=start.pixels.x;
    let lastY=start.pixels.y;
    let endOpening=null,end=null;
    let len=points.length;
    if (closed) len+=1;
    for(let i=1;i<len;i++){
        index++;
        if (index>=points.length) index-=points.length;
        let start=points[index];
        end=points[index<points.length-1 ? index+1 : 0];
        if (map.options.canvasWidth>150) opening=map.openings.find(o => o.rowKey==start.rowKey);
        if (opening){
            openings.push(opening);
            start=(rowKey==opening.owningLine ? opening.start: opening.end);
        }
        if (!rough) ctx.lineTo(start.pixels.x,start.pixels.y);
        else roughLine(ctx,lastX,lastY,start.pixels.x,start.pixels.y);
        lastX=start.pixels.x;
        lastY=start.pixels.y;
        if (opening){
            end=(rowKey==opening.owningLine ? opening.end : opening.start);
            if (opening.type=="arc"){
                let s=start.pixels;
                let e=end.pixels;
                /*
                let {x,y}=s;
                let {x:x1,y:y1}=e;
                let dx=(x1-x);
                let dy=(y1-y);
                let r=Math.sqrt(dx*dx+dy*dy)/2;
                let ang=getAng(x,y,x1,y1);
                */
                drawArc(ctx,item,s.x,s.y,e.x,e.y)
                //ctx.arc((x+x1)/2,(y+y1)/2,r,ang-Math.PI,ang);
            }
            else{
                ctx.moveTo(end.pixels.x,end.pixels.y);
            }
            lastX=end.pixels.x;
            lastY=end.pixels.y;
        }
    }
    if (!opening && closed) ctx.closePath();
    //if (opening) ctx.lineTo(end.pixels.x,end.pixels.y);
    ctx.stroke();
}

export async function drawLine(ctx,map,item,hover=false){
    let {fillType,points,fillColor,lineWidth,lineType,lineColor,closed,cornerRadius,rough}=item;
    if (!closed) fillType="no-fill";
    if (fillType=="fill-on-hover" && !hover) return;
    let {options}=map;
    if (!points || points.length<2) return;
    if (points.length==2) closed=false;

    //console.log("Line",lineType)

    points=points.map(p => calculatePixels(p,options));
    
    let first=points[0].pixels;
    let last=points[points.length-1].pixels;
    if (lineWidth) ctx.lineWidth=lineWidth*options.backgroundScale;
    let bezierControls=null
    if (lineType=="curve") bezierControls=points.map((p,i) => {
        let prev=i==0 ? last : points[i-1].pixels
        let current=points[i].pixels;
        let next=i<=points.length-2 ? points[i+1].pixels : first;
        return bezierControl(current,prev,next,cornerRadius);
    })
//    if (closed && (lineType=="curve" || fillType=="fill"  || fillType=="fill-on-hover")){
    if (lineType=="curve" || fillType=="fill"  || fillType=="fill-on-hover"){
        createLineShape(ctx,map,points,closed,bezierControls,rough);
        if (closed && fillType!="no-fill") {
            //ctx.closePath();
            ctx.fillStyle=fillColor;
            ctx.fill();
        }
        if (lineWidth && lineType=="curve") {
            ctx.strokeStyle=lineColor;
            ctx.stroke();
        }
    }
    if (fillType=="stone"){
        await drawFloor(ctx,map,item,"/assets/floor/stonefloor-50.jpg",bezierControls);
    }
    if (fillType=="wood"){
        await drawFloor(ctx,map,item,"/assets/floor/woodfloor-50.jpg",bezierControls);
    }
    if (fillType=="water"){
        await drawFloor(ctx,map,item,"/assets/floor/water-50.jpg",bezierControls);
    }
    if (fillType=="grass"){
        await drawFloor(ctx,map,item,"/assets/floor/grass-50.jpg",bezierControls);
    }
    if (lineType=="curve") return;
    ctx.fillStyle=lineColor;
    ctx.strokeStyle=lineColor;
    let openings=[];
    if (lineType=="solid"){
        drawSolid(ctx,map,item,openings);
    }
    else{
        let len=points.length;
        if (!closed) len-=1;
        for(let i=0;i<len;i++){
            await drawSegment(ctx,map,i,item,openings);
        }
    }
    openings.forEach(o => drawOpening(ctx,map,o,item));
    await drawNotes(ctx,map,item.rowKey);
}

export async function drawLines(map,ctx,layer,player){
    //console.log("drawlines",map)
    let lines=map.objects.filter(o => o.itemType=="line");
    for(let i=0;i<lines.length;i++){
        let line=lines[i];
        //console.log("Show line",!!player,!line.visible);
        //if (i>2) continue;
        if (player && !line.visible) continue;
        if (line.layer==layer) await drawLine(ctx,map,lines[i]);
        await drawAssets(ctx,map,lines[i],player,layer)
    }
}