import { drawAssets } from "./assets/assetrenderer";
import { loadAsset, loadImage } from "./utils/images";
import { calculatePixels, lineInfo } from "./points/pointutils";
import { drawContours } from "./contours/contourrenderer";
import { drawLines } from "./lines/linerenderer";

const roomBackgroundColor="#f0f0f080";
const wallStrokeColor="#606060";
const wallFillColor="#606060";
const hilitePointColor='#2020f0';
const newPointColor='#20f020';
const selectedShapeStartColor="#f02020";

const doorFrameThickness=2; // in feet;

export function drawPoint(ctx,point,fillStyle="#2020ff"){
    ctx.fillStyle=fillStyle;    
    let {x,y}=point.pixels;
    ctx.fillRect(x-2,y-2,4,4);        
}

export function createShape(ctx,options,points){
    points=points.map(p => calculatePixels(p,options))
    ctx.moveTo(points[0].pixels.x,points[0].pixels.y);
    for (let i=1;i<points.length;i++){
        ctx.lineTo(points[i].pixels.x,points[i].pixels.y);
    }
    ctx.closePath();
}

async function drawDoor(ctx,map,opening,mode,player){
    //console.log("Opening",opening);
    let p1=opening.start;
    let p2=opening.end;
    let wt=map.options.wallThickness*map.options.feetToPixelsRatio;
    let info=lineInfo(p1,p2);
    let openingWidth=info.length*map.options.feetToPixelsRatio;
    //let rect=expandToRect(p1,p2,-2*wt,wt+6);
    //console.log("Door",rect);
    ctx.strokeStyle='green';
    ctx.fillStyle="#3080f0";
    ctx.lineWidth=1;
    //ctx.fillStyle='white';
    let {x:bx,y:by}=p1.pixels;
    let {x:cx,y:cy}=p2.pixels;
    ctx.save();
    ctx.translate(bx,by);
    ctx.rotate(info.ang);
    
    let ft=doorFrameThickness*map.options.feetToPixelsRatio;

    ctx.beginPath();
    ctx.rect(0,-wt/2+1,ft,wt-2);
    ctx.fill();
    ctx.stroke();
    ctx.rect(openingWidth-ft,-wt/2+1,ft,wt-2);
    ctx.fill();
    ctx.stroke();
    /*
    ctx.moveTo(0,0);
    ctx.lineTo(wt,0);
    ctx.moveTo(openingWidth,0);
    ctx.lineTo(openingWidth-wt,0);
    ctx.stroke();
    */
    ctx.restore();
    ctx.strokeStyle='red';
    let dt=wt-3;
    let doorWidth=openingWidth-2*ft-1;
    if (opening.type=="door2") doorWidth/=2;
    let doorCx=ft*Math.cos(info.ang);
    let doorCy=ft*Math.sin(info.ang);
    //console.log("Door1",bx+doorCx,by+doorCy)

    ctx.strokeStyle="#a0a0a0";
    ctx.lineWidth=1;
    ctx.fillStyle='#ffffff';
    ctx.save();
    ctx.translate(bx+doorCx,by+doorCy);
    let degr=opening.isDoorOpen ? Math.PI/3 : 0;
    ctx.rotate(info.ang+degr);
    ctx.beginPath();
    ctx.rect(0,-dt/2,doorWidth,dt);
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    if (opening.type=="door2"){
        ctx.save();
        ctx.translate(cx-doorCx,cy-doorCy);
        let degr=opening.isDoorOpen ? -Math.PI/3 : 0;
        ctx.rotate(info.ang+degr);
        ctx.beginPath();
        //ctx.strokeStyle='green';
        //ctx.moveTo(0,0);
        //ctx.lineTo(-doorWidth,0);
        ctx.rect(0,-dt/2,-doorWidth,dt);
        ctx.fill();
        ctx.stroke();
        ctx.restore();
    }
}

async function drawWall(worldCtx,map,room,index,openings,mode){
    let points=room.points;
    let wallImage=await loadAsset("stonewall");
    const wallImageHeight=(map.options.wallThickness || 0.5); // Thickness of wall in feet
    //console.log("Wall image height",wallImageHeight);
    const wallImageWidth=wallImageHeight*8;  // wallImage should have 8 five feet segments
    const wallPixelWidth=wallImageWidth*map.options.feetToPixelsRatio;
    const wallPixelHeight=wallImageHeight*map.options.feetToPixelsRatio;
    let p0=points[index ? index-1 : points.length-1];
    let p1=points[index];
    let p2=points[index<points.length-1 ? index+1 : 0];
    let p3=points[index<points.length-2 ? index+2 : 2+index-points.length];

    let isOpening=openings.find(op => (((op.start==p1) && (op.end==p2)) || ((op.start==p2) && (op.end==p1))))
    if (isOpening){
        console.log("wall is opening",index);
        return;
    }
    let endsToOpening=openings.find(op => ((op.start==p2) || (op.end==p2) && (op.room2==room)));
    let startsFromOpening=openings.find(op => ((op.start==p1) || (op.end==p1) && (op.room2==room)));
    
    let info0=lineInfo(p0,p1);
    let info1=lineInfo(p1,p2);
    let info2=lineInfo(p2,p3)
    //console.log("Draw wall p1",p1.pixels,p1.options);
    let {x:xPoint1,y:yPoint1}=p1.pixels;
    let {ang:angOrig0}=info0;
    let {length:len,ang:angOrig1}=info1;
    let {ang:angOrig2}=info2;
    
    let infoTest1=lineInfo(p1,p0);
    let infoTest2=lineInfo(p2,p1);

    
    let angTest1=infoTest1.ang-info1.ang;
    let angTest2=info2.ang-infoTest2.ang;

    while (angTest1<0) angTest1+=2*Math.PI;
    while (angTest1>2*Math.PI) angTest-=2*Math.PI;
    while (angTest2<0) angTest2+=2*Math.PI;
    while (angTest2>2*Math.PI) angTest2-=2*Math.PI;


    if ((angOrig1<Math.PI) && (angOrig0>Math.PI)) angOrig0-=2*Math.PI;
    if ((angOrig1>Math.PI) && (angOrig2<Math.PI)) angOrig2+=2*Math.PI;

    len*=map.options.feetToPixelsRatio;
    let ang1=(angOrig1-angOrig0);
    let ang2=(angOrig2-angOrig1);
    let angNormal1=Math.PI/2-angTest1/2;
    let angNormal2=Math.PI/2-angTest2/2;
    let expand1=Math.abs((wallPixelHeight)*Math.tan(angNormal1));
    let expand2=Math.abs((wallPixelHeight)*Math.tan(angNormal2));

    len-=(expand1/2+expand2/2);
    let x0=0,x1=len+expand1+expand2,x2=len+expand1,x3=expand1;
    if ((ang1<0) || (ang1>Math.PI)){
        //console.log("Swap alku",index)
        x0=expand1;
        x3=0;
    }
    if ((ang2<0) || (ang2>Math.PI)) {
        //console.log("Swap loppu",index)
        x1=len+expand1;
        x2=len+expand1+expand2;
    }

    if (startsFromOpening) {
        //console.log("Wall starts from opening",index);
    }
    if (endsToOpening) {
        //console.log("Wall ends to opening");
    }
    let totalLen=x1>x2 ? x1 : x2;
    let {width,height}=wallImage.img;

    //let opening=walls.openings[index];
    return new Promise(resolve => {
        let canvas=document.createElement('canvas');
        canvas.width=totalLen+64;
        canvas.height=wallPixelHeight;
        let ctx=canvas.getContext("2d");
        ctx.moveTo(x0,0);
        ctx.lineTo(x1,0);
        ctx.lineTo(x2,wallPixelHeight);
        ctx.lineTo(x3,wallPixelHeight);
        ctx.closePath();
        ctx.clip();
        if (mode=="bw"){
            ctx.fillStyle=wallFillColor;
            ctx.fillRect(0,0,totalLen+20,wallPixelHeight);
        }
        else{
            for (let i=0;i<totalLen+20;i+=wallPixelWidth){
                //console.log(i,x1,x2,totalLen)
                ctx.drawImage(wallImage.img,i,0,wallPixelWidth,wallPixelHeight);
            }
        }
        let img=new Image();
        img.src=canvas.toDataURL();
        img.onload=() => {
            worldCtx.strokeStyle="red";
            worldCtx.lineWidth=2;
            //worldCtx.moveTo(0,0);
            //worldCtx.lineTo(320,320);
            worldCtx.save();
            worldCtx.translate(xPoint1,yPoint1);
            worldCtx.rotate(angOrig1);
            worldCtx.drawImage(img,0-Math.abs(expand1)/2,-wallPixelHeight/2);
            worldCtx.strokeStyle="#80808080";
            worldCtx.lineWidth=1;
            //console.log("wall outline",x0,x1,x2,x3);
            worldCtx.beginPath();
            worldCtx.moveTo(x0-Math.abs(expand1)/2,0-wallPixelHeight/2);
            worldCtx.lineTo(x1-Math.abs(expand1)/2,0-wallPixelHeight/2);
            worldCtx.lineTo(x2-Math.abs(expand1)/2,0+wallPixelHeight/2);
            worldCtx.lineTo(x3-Math.abs(expand1)/2,0+wallPixelHeight/2);
            worldCtx.closePath();
            worldCtx.stroke();
            worldCtx.restore();
            resolve(img);
        }
    })
    
}


async function drawWalls(ctx,map,room,openings,mode){
    //console.log("drawWalls",mode)
    for(let i=0;i<room.points.length;i++){
        await drawWall(ctx,map,room,i,openings,mode)
    }
}

async function drawFloor(ctx,map,room,openings,mode){
    if (mode=="bw") {
        ctx.beginPath();
        ctx.fillStyle=roomBackgroundColor;
        createShape(ctx,map.options,room.points);
        ctx.fill();
    }
    else{
        let floorImage=await loadAsset("stonefloor");
        return new Promise(async resolve => {
            let {options}=map;
            let canvas=document.createElement("canvas");
            canvas.width=options.canvasWidth;
            canvas.height=options.canvasHeight;
            let ctxFloor=canvas.getContext("2d");
            createShape(ctxFloor,map.options,room.points);
            ctxFloor.clip();
            ctxFloor.drawImage(floorImage.img,0,0,map.options.canvasWidth,map.options.canvasHeight);
            let img=new Image();
            img.src=canvas.toDataURL();
            img.onload=() => {
                ctx.drawImage(img,0,0,options.canvasWidth,options.canvasHeight);
                resolve();
            }; 
        });
    }
}

async function drawRoom(ctx,map,room,openings,mode,player=0,drill=false){
    await drawFloor(ctx,map,room,openings,mode);
    await drawWalls(ctx,map,room,openings,mode);
    let roomOpenings=openings.filter(op => ((op.room1==room) || (op.room2==room)));
    if (player && drill){
        for (let i=0;i<roomOpenings.length;i++){
            let opening=roomOpenings[i];
            let otherRoom=opening.room1==room ? opening.room2 : opening.room1;
            if (opening.type.startsWith("door") && !opening.isDoorOpen) continue;
            await drawRoom(ctx,map,otherRoom,openings,mode,player,false);
        }
    }
    for(let i=0;i<roomOpenings.length;i++){
        let opening=roomOpenings[i];
        if (!player && (opening.room1==room)) continue;
        if (player && opening.type.startsWith('door')){
            if ((room!=map.selectedRoom) && (opening.room1==map.selectedRoom)) continue;
        }
        //if (player && (opening.room1==room) && (opening.type.startsWith('door') && opening.isDoorOpen)) continue
        if (opening.type=='open') continue;
        if (opening.type.startsWith("door")) await drawDoor(ctx,map,opening,mode,player);
    }
    let ret=await drawAssets(ctx,map,room);
}

export function hiliteClicked(ctx,map,hiliteLine=true){
    let {point,asset,line,segment,avatar}=map.clicked;
    console.log("Hilite clicked",line,asset)
    if (line && hiliteLine){
        //console.log("Clicked room or contour",object)
        //let {wall,corner}=roomOrContourClick;
        let points=line.points.map(p => calculatePixels(p,map.options));
        for(let i=0;i<points.length;i++){
            let {x,y}=points[i].pixels;
            ctx.fillStyle=hilitePointColor;
            //if (corner?.index===i) ctx.fillStyle=selectedShapeStartColor;
            if (segment?.index===i) ctx.fillStyle=selectedShapeStartColor;
            ctx.fillRect(x-2,y-2,4,4);
        }
        if (segment && segment.point?.mouse){
            //console.log("Wall mouse point");
            let {x,y}=segment.point.mouse; 
            ctx.fillStyle="#20ff20";
            ctx.fillRect(x-2,y-2,4,4);
        }
    }
    if (asset){
        let {x,y}=asset.point.pixels; 
        ctx.fillStyle="#20ff20";
        ctx.fillRect(x-2,y-2,4,4);
    }
    if (point && !asset && !segment && !avatar){
        //console.log("hilite clicked, just point")
        point=calculatePixels(point,map.options);
        let {x,y}=point.pixels; 
        ctx.fillStyle="#20ff20";
        ctx.fillRect(x-2,y-2,4,4);
    }
}

async function drawSelectedRoom(ctx,map,mode,player){
    let {options,lines,selectedRoom,clicked,openings}=map;
    //console.log("Draw room",rooms.length,selectedRoom,player);
    if (!lines.length) return ; //returnPromise();
    if (!selectedRoom) return ;//  returnPromise();
    if (!player){
        //console.log("Not player")
        for (let i=0;i<rooms.length;i++){
            await drawRoom(ctx,map,rooms[i],openings,mode);
        }
    }
    else {
        //console.log("Draw room")
        await drawRoom(ctx,map,selectedRoom,openings,mode,player,true);
    }
}

function line(ctx,x,y,x2,y2){
    ctx.beginPath();
    ctx.moveTo(~~x+0.5,~~y+0.5);
    ctx.lineTo(~~x2+0.5,~~y2+0.5);
    ctx.stroke();
}

export function drawGrid(ctx,options){
    ctx.lineWidth=1;
    ctx.strokeStyle='#a0a0a0';
    if (!options.showGrid) return;
    console.log("Grid",options)
    for(let i=1;i<=options.mapSquaresX;i++){
        let coord=i*options.squarePixels; //*options.backgroundScale;
        line(ctx,coord,0,coord,options.canvasHeight);
    }

    for(let i=1;i<=options.mapSquaresY;i++){
        let coord=i*options.squarePixels; //*options.backgroundScale;
        line(ctx,0,coord,options.canvasWidth,coord);
    }
        
}

export function drawShape(ctx,map,points,closed=true,drawLines=true){
    if ((map.clicked.wall) && (points.length==1)) return;
    ctx.fillStyle=selectedShapeStartColor;
    ctx.lineWidth=1;
    ctx.strokeStyle=newPointColor
    if (!points.length) return;
    if (points.length>=1){
        let {x,y}=points[0].pixels;
        ctx.fillRect(x-2,y-2,4,4);
        if (points.length>=2 && drawLines){
            ctx.beginPath();
            ctx.moveTo(x,y);
        }
    }
    for(let i=1;i<points.length;i++){
        ctx.fillStyle=newPointColor;
        let {x,y}=points[i].pixels;
        if (drawLines) ctx.lineTo(x,y);
        ctx.fillRect(x-2,y-2,4,4);
    }

    if (drawLines){
        if (points.length>2 && closed) ctx.closePath();
        if (points.length>=2) ctx.stroke();
    }
}

async function drawBackground(ctx,map){
    let options=map.options;
    let imageUrl=map.options.backgroundImageUrl; //await loadImage("/maps/novgorod.svg");
    ctx.fillStyle='#808080';
    let isImageMap=options.imageWidth && options.imageHeight && options.backgroundImageUrl;
    console.log("IsImageMap",!!isImageMap,options)
    if (!options.noBackgroundFill) {
        console.log("Fill",options.name)
        ctx.fillRect(0,0,options.canvasWidth,options.canvasHeight);
    }

    if (!imageUrl) return;
    if (map.options.hideBkrImage) return;
    let imageData=await loadImage(imageUrl);
    let img=imageData.img;
    let cx=map.options.canvasWidth/2;
    let cy=map.options.canvasHeight/2;
    let scale=map.options.backgroundScale;
    let w=img.width*scale;
    let h=img.height*scale;
    let x=cx-w/2;
    let y=cy-h/2;
    console.log("Bkr",img,x,y, w,h)
    ctx.drawImage(img,x,y,w,h);
    if (map.options.backgroundImageUrl2){
        imageData=await loadImage(map.options.backgroundImageUrl2);
        img=imageData.img;
        ctx.drawImage(img,cx-w/2,cy-h/2,w,h);
    }
}

export async function drawAvatars(ctx,map,avatarsOrig){
    if (!map.showAvatars) return;
    const npcScales={
        17:{w:1,h:5/3},
        19:{w:1,h:5/3},
        20:{w:2.25,h:8/3},
        21:{w:2,h:8/3},
        22:{w:2,h:4/3},
        25:{w:2.25,h:8/3},
    }
    let avatars=[...avatarsOrig].sort((a,b) => a.y-b.y);
    let clickedAvatar=map.clicked?.avatar || {rowKey:0};
    let {options}=map;
    let {feetToPixelsRatio}=options;
    for(let i=0;i<avatars.length;i++){
        let {type,visible,image,avatarId}=avatars[i];
        //console.log("avatar",avatars[i])
        if (type=="npc" && !visible) continue;
        let imageData=await loadImage(image);
         //let widthRatio=imageData.img.width/113;
        //let heightRatio=imageData.img.height/113;
        let scaleX=1,scaleY=4/3;
        if (type=="npc" && npcScales[avatarId]) {
            scaleX=npcScales[avatarId].w;
            scaleY=npcScales[avatarId].h;
        }
        //console.log("Avatar",type,scaleX);
        let imageWidth=5*feetToPixelsRatio*scaleX;
        let imageHeight=5*feetToPixelsRatio*scaleY;
        if (map.options.mapUnits!="feet"){
            imageWidth=16*scaleX;
            imageHeight=16*scaleY;
        }
        //let x=(i+1)*map.options.squarePixels;
        //let y=4*map.options.squarePixels-map.options.squarePixels*4/3;
        let {x,y}=avatars[i];
        x*=feetToPixelsRatio;
        y*=feetToPixelsRatio;
        y-=imageHeight;
        x-=imageWidth/2;
        //console.log("Draw avatar",x,y,imageWidth,imageHeight);
        ctx.drawImage(imageData.img,x,y,imageWidth,imageHeight);
        if (avatars[i].rowKey==clickedAvatar.rowKey){
            let ey=y+imageHeight;
            let ex=x+imageWidth/2;
            let r=5;
            ctx.fillStyle="#ff0000";
            ctx.beginPath();
            ctx.ellipse(ex,ey,r,r,0,0,2*Math.PI);
            ctx.fill();
            //ctx.stroke();
        }
        let number=avatars[i].number;
        if (number){
            x+=imageWidth/2;
            //y+=map.options.squarePixels*2/3;
            let r=map.options.squarePixels/2;
            ctx.font="12px serif";
            let tm=ctx.measureText(String(number));
            let width=tm.width;
            let height=tm.fontBoundingBoxAscent;
            /*
            ctx.fillStyle="#f0f0f080";
            ctx.beginPath();
            ctx.ellipse(x,y,width/2,height/2,0,0,2*Math.PI);
            ctx.fill();
            //ctx.stroke();
            */
            ctx.fillStyle="#0000ff";
            ctx.textAlign = "center";
            ctx.fillText(String(number),x,y);            
        }
    }
}

export function drawClicks(ctx,map,closed=true){
    //console.log("drawClick",drawingMode);
    drawShape(ctx,map,map.clicks,closed);
}

export function drawPointer(ctx,map,pt){
    if (!pt) return;
    if (pt.map!=map.options.rowKey) return;
    ctx.strokeStyle="#ffffff";
    let x=pt.x*map.options.feetToPixelsRatio;
    let y=pt.y*map.options.feetToPixelsRatio;
    //let r=map.options.squarePixels;
    ctx.beginPath();
    ctx.arc(x,y,10,0,2*Math.PI);
    ctx.moveTo(x-10,y);
    ctx.lineTo(x-7,y);
    ctx.moveTo(x,y-10);
    ctx.lineTo(x,y-7);
    ctx.moveTo(x+10,y);
    ctx.lineTo(x+7,y);
    ctx.moveTo(x,y+10);
    ctx.lineTo(x,y+7)
    ctx.stroke();
    ctx.fillStyle=selectedShapeStartColor;
    ctx.beginPath()
    ctx.arc(x,y,2,0,2*Math.PI);
    ctx.fill();
    if (!pt.opening) return;
    let tx=(pt.name || pt.username)+" tries to ";
    if (pt.opening.isOpen) tx+="close door";
    else {
        tx+=" open door";
        if (pt.opening.isLocked) tx+=", it's locked";
    }
    ctx.font="9px serif";    
    let tm=ctx.measureText(tx);
    let width=tm.width;
    let height=tm.fontBoundingBoxAscent;
    ctx.strokeStyle="#00000080";
    ctx.fillStyle="#faebd7";
    ctx.fillRect(x+12,y-6,width+6,12);
    ctx.strokeRect(x+12,y-6,width+6,12);
    ctx.textAlign="left";
    ctx.textBaseline="middle";
    ctx.fillStyle="#000000";
    ctx.fillText(tx,x+15,y);
}

async function drawNote(ctx,map,note,hover=false){
    return new Promise(async resolve => {
        if (map.options.showNotesText){
            let tx=note.username+":\n"+note.text;
            let arr=tx.split("\n");
            ctx.font="10px Lora";
            let w=20;
            let h=0;
            arr.forEach(t => {
                let tm=ctx.measureText(t);
                if (tm.width>w) w=tm.width;
                h=tm.fontBoundingBoxAscent;
            })
            w+=10;
            let th=h*arr.length+10;
            let x=note.x*map.options.feetToPixelsRatio;
            let y=note.y*map.options.feetToPixelsRatio;
            x-=w/2
            y=y-th;
            ctx.strokeStyle="#00000080";
            ctx.fillStyle="#faebd7";
            ctx.fillRect(x,y,w,th);
            ctx.fillStyle="#000000a0";
            ctx.textBaseline="top";
            arr.forEach((t,index) => {  
                ctx.fillText(t,x+5,y+5+index*h);
            })
            resolve();
        }
        else{
            let imgData=await loadImage("/assets/master/map-note.svg");
            let img=imgData.img;
            let {width,height}=img;
            let x=note.x*map.options.feetToPixelsRatio;
            let y=note.y*map.options.feetToPixelsRatio;
            ctx.drawImage(img,x,y-16,16,16);
            resolve();
        }
    });
}

export async function drawNotes(ctx,map,room=""){
    if (!map.notes) return;
    for(let i=0;i<map.notes.length;i++){
        let note=map.notes[i];
        let nroom=note.room || "";
        if (nroom!=room) continue;
        await drawNote(ctx,map,note)
    }
}

export function drawMap(map,player=0){  // or "bwr"
    let {options,selectedRoom}=map;
    //console.log("DRAWMAP",map,avatars)
    return new Promise(async resolve => {
        let {canvasWidth,canvasHeight}=options;
        //canvasWidth=Math.round(canvasWidth);
        //canvasHeight=Math.round(canvasHeight);
        let canvas=new OffscreenCanvas(canvasWidth+20,canvasHeight+20);
        let ctx=canvas.getContext("2d");
        ctx.fillStyle="#808080"
        if (!map.options.noBackgroundFill){
            //console.log("Bkr fill",canvasWidth,canvasHeight)
            ctx.fillRect(canvasWidth,0,20,canvasHeight+20);
            ctx.fillRect(0,canvasHeight,canvasWidth+20,20);
        }
        await drawBackground(ctx,map);
        for(let layer=1;layer<=10;layer++){
            //drawContours(map,ctx,layer);
            if (layer==3) drawGrid(ctx,options);
            await drawLines(map,ctx,layer,player);
            await drawAssets(ctx,map,null,player,layer);
        }
        await drawNotes(ctx,map);
        //await drawSelectedRoom(ctx,map,mode,player);
        console.log("Done drawing assets");
        //await drawAvatars(ctx,map,avatars)
        //if (!player) 
        if ((map.clicked.room || (map.clicked.contour) || (map.clicked.line)) && !player){
            //hiliteClicked(ctx,map);
        }
        
        resolve(canvas);
    })
}