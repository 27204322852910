import React, { useEffect, useState } from 'react';
import { Attributes } from './attributes';

import { Skills } from './skills/skills';
import { CharacterCombat } from './combat/charactercombat';

import './characterbasics.scss';
import { TextInput } from '../utils/formfields';
import { Icon } from '../utils/icons';
import { Reputation } from './reputation';
import { CharacterAvatar } from '../encounter/avatars';
import { useSelector } from 'react-redux';
import { characterAvatarUrl, createCharacter } from './character';
import { CharacterChat } from './characterchat';
import { saveCurrentCharacterValues, setCurrentCharacter, updateCharacterValues } from '../utils/store';
import { getImageUrl } from '../utils/utils';
import { ImageSelector } from '../utils/imageeditor';


export function CharacterBasics(){
    //const {character,forceUpdate}=useOutletContext();
    const {currentCharacter,player,role,campaign}=useSelector(state => state.game);
    let [character,setCharacter]=useState(createCharacter(currentCharacter));
    let [showImageSelector,setShowImageSelector]=useState(false);
    let [modified,setModified]=useState(false);
    let [edit,setEdit]=useState(false);
    let [fup,forceUpd]=useState("");
    let [original,setOriginal]=useState(Object.assign({},character.values));
    let armor=character.armor.name;
    if (character.hasShield) armor+=", shield";
    
    console.log("CharacterBasics",campaign)

    let canEdit=(role=="Master" || character.values.partitionKey==player.rowKey);
    useEffect(function(){
        console.log("Current character changed")
        setCharacter(createCharacter(currentCharacter));
    },[currentCharacter])

    console.log("basics",character);

    function changeText(ev,field,isNumber=false,prevValue){
        let value=ev.target.value
        console.log("Value",field,value)
        if (isNumber) {
            if (isNaN(prevValue)) prevValue=0;
            if (!isNaN(value)) value=Number(value);
            else value=prevValue;
        }
        character[field]=value;
        setModified(true);
        forceUpd(new Date())
    }

    function save(){
        //updateCharacterValues(character.values);
        saveCurrentCharacterValues(character);
        setEdit(false);
        setModified(false);
        setOriginal(Object.assign({},character.values));
        /*
        HTTP.post("/api/characters",character.values).then(cv => {
            console.log("Saved",cv)
            sendToCampaign("updateCharacterValues",character.values);
        })
        */
    }

    function cancel(){
        /*
        character.name=original.name;
        character.ethnicity=original.ethnicity;
        character.profession=original.profession;
        character.age=original.age;
        character.sex=original.sex;
        character.deities=original.deities;
        */
        setCharacter(createCharacter(currentCharacter));
        //character.values=original;
        setEdit(false);
        setModified(false);
        forceUpd(new Date());
    }

    function imageSelected(fn){
        console.log("Image selected",fn)
        if (fn){
            character.image=fn;
            console.log("Image changed",character);
            setModified(true);
            setCurrentCharacter(character);
            forceUpd(new Date());
        }
        setShowImageSelector(false);
    }

    let avatarUrl=characterAvatarUrl(character.avatar);
    let imageUrl="/images/defaultcharacter.png";
    if (character.values.image) imageUrl=getImageUrl(character.values.partitionKey,character.values.image);
    return <div className='characterSheetPage characterSheetBasics'>
        <div className='characterBasics'>
            <h3>Basic info 
                {!edit && canEdit ? <Icon type={Icon.edit+(edit ? Icon.dimmed : "")} onClick={() => setEdit(!edit)} style="first" /> : <></>}
                {edit ? <>
                    <Icon type={Icon.accept} onClick={save} style="first" /> 
                    <Icon type={Icon.cancel} onClick={cancel}  />
                </> : <></>}
            </h3>
            <div className="nameEtAll">
                <div>
                    <TextInput editable={edit} placeholder='Name' value={character.name} onChange={ev => changeText(ev,'name')} />
                    <TextInput editable={edit} placeholder='Ethnicity' value={character.ethnicity} onChange={ev => changeText(ev,'ethnicity')} />
                    <TextInput editable={edit} placeholder='Profession' value={character.profession} onChange={ev => changeText(ev,'profession')} />
                </div>
                <div>
                    <TextInput editable={edit} placeholder='Sex' value={character.sex} onChange={ev => changeText(ev,'sex')} />
                    <TextInput editable={edit} placeholder='Age' value={character.age} onChange={ev => changeText(ev,'age',true,character.age)} />
                </div>
                <div className="characterExtra">
                    <div className="extraText">
                        <p>Worships <TextInput editable={edit} placeholder="Deities" value={character.deities} onChange={ev => changeText(ev,'deities')} /></p>
                        <p>Wears {armor}</p>
                    </div>
                    {edit ? <CharacterAvatar number={character.avatar} onChange={ev => changeText(ev,'avatar',true)} />
                    : <img src={avatarUrl} /> }
                </div>
                <div className="characterImage">
                    <img src={imageUrl} />
                    {edit ? <input type="button" value="Select image" onClick={() => setShowImageSelector(true)} /> : <></>}
                </div>
            </div> 
            <Attributes character={character} edit={edit} />
            <Reputation character={character} edit={edit} canTest={role=="Master" || character.partitionKey==player.rowKey}/>
        </div>
        {player.rowKey==character.player || role=="Master" ? <Skills character={character} /> : <></>}
        {player.rowKey==character.player || role=="Master" ? <CharacterCombat character={character} title="Combat" editable={true} showFatigue={false} onChange={() => forceUpd(new Date())} /> : <></>}
        {campaign && player.rowKey!=character.player || role=="Master" ? <CharacterChat character={character} targetGuid={character.rowKey} /> : <></>}        
        {showImageSelector ? <ImageSelector path={character.values.partitionKey} onClose={imageSelected} /> : <></>}
    </div>
}
