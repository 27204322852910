import React, { useState } from 'react';
import { Dice } from '../../dice/dicebox';
import { spellList } from '../../articles/articleutils';
import { allItems, skillDefs, initialItems, tinkeringTools } from '../character';

import './wizarditems.scss'
import { Ranks } from '../../utils/ranks';
import { CharacterWizardHelp } from './wizardhelp';

export function ItemRow({item,onAdd,onDelete}){

    function clicked(){
        if (onAdd) onAdd(item);
        if (onDelete) onDelete(item);
    }

    let name=item.name;
    if (item.amount) name+="("+item.amount+")";
    let price=item.price ? item.price+'sp' : 'Free';
    

    return <div className='itemRow' onClick={clicked}>
        <p className='itemName'>
            {onAdd ? <span className='itemButton itemButtonAdd'></span> : <></>}
            {onDelete ? <span className='itemButton itemButtonDelete'></span> : <></>}
            <span>{name}</span>
            <span className='itemPrice'>{price}</span>
        </p>
        <p className='itemDescription'>
            <span>{item.description}</span> 
            {item.damage ? <span className='itemDamage'>Damage: {item.damage}</span> : <></>}
            {item.ac ? <span className='itemDamage'>AC: {item.ac}</span> : <></>}
        </p>
    </div>
}

export function SpellRow({spell,onAdd,onDelete}){
    let [showInfo,setShowInfo]=useState(false);

    function clicked(type){
        if (onDelete) onDelete(spell);
        if (onAdd) onAdd(spell,type);
    }

    function changeShowInfo(value){
        console.log("Changing show to",value)
        setShowInfo(value);
    }

    console.log("Show spell",showInfo)
    return <div className='spellRow'>
        <p className='spellTitle clickable' onClick={() => changeShowInfo(!showInfo)}>{spell.title}</p> 
        {onDelete ? <p className="spellDel"><span>{spell.type}</span> <span onClick={clicked}>Del</span></p> : <></> }
        {onAdd ? <p className='spellAdd'><span onClick={() => clicked('Scroll')}>Add Scroll</span><span onClick={() => clicked("Rune")}>Add Rune</span></p> : <></> }
        {showInfo ? <>            
            <p className='spellInfo'><span className="label">Schools</span><span>{spell.schools}</span></p>
            <p className='spellInfo'><span className="label">Duration</span><span>{spell.duration}</span></p>
            <p className='spellInfo'><span className="label">Range</span><span>{spell.range}</span></p>        
            <div className='spellDescription' dangerouslySetInnerHTML={{__html:spell.description}} /> 
        </> : <></> }
    </div>
}

export function SkillRow({character,skill,onSelect}){
    let charSkill=character.skills[skill.field];
    if (!charSkill) console.log("SkillRow",skill,character)
    let className='skillRow skillRow'+skill.shortname ;
    //console.log("Skill",skill.shortname,character.skills[skill.shortname]);
    let isSpellCaster=false;
    if (charSkill){
        if (charSkill.field=='sorcery' && charSkill.baseValue>3) isSpellCaster=true;
        if (charSkill.field=='chant' && charSkill.baseValue>3) isSpellCaster=true;
        if (charSkill.field=='charm' && charSkill.baseValue>3) isSpellCaster=true;
        if (isSpellCaster) className+=" spellCaster";
    }
    //console.log("IsSpellCaster",isSpellCaster,skill)
    let ranksTitle="";
    let baseAttr=skill.baseAttribute;
    if (baseAttr) ranksTitle=character.attributes[baseAttr].shortname;
    console.log("Baseattr",ranksTitle)
    return <div className={className} onClick={() => onSelect(skill)}>
        <div>
            <p className="wizardSkillName">{skill.name}</p>
            <Ranks title={ranksTitle} value={character.values[skill.field]} />
        </div>
        {skill.field=='fight' ? <div className='skillValuesFight'>
            <p><span>Agile</span><span>{character.skills.afight.value}</span></p>
            <p><span>Brutal</span><span>{character.skills.bfight.value}</span></p></div> :
        skill.field=='hd' ? <p className='skillValueHD'>Bonus for all</p>
        : <p className='skillValue'>{charSkill.value}</p>}
    </div>
}

export function SkillDevelopment({character,rollsAvailable,onRollCompleted}){
    const [selectedSkill,setSelectedSkill]=useState(null);
    const [developmentRoll,setDevelopmentRoll]=useState("");
    const [rolling,setRolling]=useState(false);

    function developmentTest(){
        setDevelopmentRoll("");
        setRolling(true);
        Dice.roll("2d4",-2).then(result => {
            console.log("RESULT",result)
            character.values.silverpieces-=100;
            let tx=result.resultString;
            if (result.value>character.values[selectedSkill.field]){
                tx+=" is greater than current rank ("+character.values[selectedSkill.field]+"). Skill development succeeded!";
                character.values[selectedSkill.field]++;
                //values[selectedSkill.field]=character.values[selectedSkill.field];
                setDevelopmentRoll(tx);
                onRollCompleted({field:selectedSkill.field,value:character.values[selectedSkill.field]});
            }
            else{
                tx+=" is less than or equal to current rank ("+character.values[selectedSkill.field]+"). Skill development fails!";
                setDevelopmentRoll(tx);
                onRollCompleted({field:selectedSkill.field,value:character.values[selectedSkill.field]});
            }
            setRolling(false);
        })
    }

    let allSkillRows=skillDefs.filter(s => s.field!='fight' && s.value>=0).map(s => <SkillRow key={s.field} character={character} skill={s} onSelect={skill => setSelectedSkill(skill)} />)
    let hdSkill={name:'Hit Dice',shortname:'HD',field:'hd'};
    let fightSkill={name:'Fight',shortname:'Fight',field:'fight'}

    return <div className='wizardSkillDevelopment'>
        <div>
            <h2>Skills</h2>
            {!rollsAvailable ? <p>You don't have any available development rolls!</p> : <></>}
            {selectedSkill && rollsAvailable ? <div>
                <p>Selected : {selectedSkill.name} ({character.values[selectedSkill.field]})</p>
                {!rolling ? <p><input type="button" value="Make development test" onClick={developmentTest} /></p> : <></> }
                {developmentRoll ? <p>{developmentRoll}</p> : <></>}
            </div> : <></>}
            <div className='hdAndFight'>
                <SkillRow character={character} skill={hdSkill} onSelect={skill => setSelectedSkill(skill)} />
                <SkillRow character={character} skill={fightSkill} onSelect={skill => setSelectedSkill(skill)} />
            </div>
            <div className='skillsContainer'>
                {allSkillRows}
            </div>
        </div>
        <div>
            <CharacterWizardHelp search="skill" />
        </div>
    </div>
}

export function CharacterWizardItems({character,values,items,spells,onComplete}){
    const [characterItems,setItems]=useState(items);
    const [characterSpells,setSpells]=useState(spells);
    const [characterValues,setCharacterValues]=useState(values);
    const [page,setPage]=useState('items');

    console.log("Items, characterValues",items,spells)

    let moneyLeft=character.silverpieces;
    characterItems.forEach(i => moneyLeft-=i.price);
    characterSpells.forEach(s => moneyLeft-=(s.type=='Rune' ? 300 : 100));
    console.log(character.silverpieces,moneyLeft)
    let devRolls=Math.floor(moneyLeft/100);

    function addItem(item){
        character.values.silverpieces-=(item.price || 0);
        if (item.name=='Rations'){
            character.values.rations+=7;
            setItems([...characterItems]);
        }
        else setItems([...characterItems,item]);
    }

    function deleteItem(item){
        character.values.silverpieces+=(item.price || 0);
        if (item.name=="Rations"){
            if (character.values.rations>6){
                character.values.rations-=7;
            }
        }
        else{
            let index=characterItems.indexOf(item);
            if (index>=0) characterItems.splice(index,1);
    
        }
        setItems([...characterItems]);
    }

    function addSpell(spell,type){
        spell.type=type;
        if (type=="Rune") character.values.silverpieces-=300;
        else character.values.silverpieces-=100;
        setSpells([...characterSpells,spell]);
    }

    function deleteSpell(spell){
        let index=characterSpells.indexOf(spell);
        if (index>=0){
            let spell=characterSpells[index];
            if (spell.type=="Rune") character.values.silverpieces+=300;
            else character.values.silverpieces+=100;
            characterSpells.splice(index,1);
        }
        setSpells([...characterSpells]);
    }

    let itemRows=characterItems.map(i => {
        let del=deleteItem;
        if (i.name=='Rations' && i.amount<7) del=null;
        if (i.name=="Healer's Kit") del=null;
        if (initialItems.includes(i)) del=null;
        return <ItemRow key={i.rowKey} item={i} onDelete={del} />
    })
    
    let hasBow=characterItems.find(i => i.category=="Bow")
    let arrowCount=0;
    characterItems.forEach(i => {
        if (i.category!="Arrows") return;
        arrowCount+=i.amount;
    })
    
    let allItemRows=allItems;
    if (!hasBow || arrowCount>=12) allItemRows=allItemRows.filter(i => i.category!="Arrows");
    allItemRows=allItemRows
        .filter(i => (!characterItems.includes(i) && i.price>=0) || i.category=='Arrows')
        .map(i => <ItemRow key={i.rowKey} item={i} onAdd={addItem} />);


    let schools=[];
    if (character.intelligence>3 && character.values.sorcery) schools.push("Sorcery");
    if (character.wisdom>3 && character.values.chant) schools.push("Chant");
    if (character.charisma>3 && character.values.charm) schools.push("Charm");    
    let isSpellCaster=!!schools.length;

    function isValidSchool(spell){
        for(let i=0;i<schools.length;i++){
            if (spell.schools.includes(schools[i])) return true;
        }
        return false;
    }

    
    let spellRows=characterSpells.map(s => <SpellRow key={s.title} spell={s} onDelete={deleteSpell} />)
    let allSpellRows=spellList           
        .filter(s => !characterSpells.includes(s) && isValidSchool(s))
        .map(s => <SpellRow key={s.title} spell={s} onAdd={addSpell}/>)

    function commitPurchases(){
        onComplete(characterValues,characterItems,characterSpells);
    }

    function addCharacterValue(characterValue){
        let {field,value}=characterValue;
        let existing=characterValues.find(v => v.field==field);
        if (existing) existing.value=value;
        else characterValues.push(characterValue);
        if ((field=='tinkering') && (value==1)){
            let x=characterItems.find(i => i.name=="Tinkering tools");
            if (!x) setItems([...characterItems,tinkeringTools]);
        }
        setCharacterValues([...characterValues]);
    }

    return <div className="wizardItems">
        <h3>Items</h3>
        <p>You have total of {character.silverpieces}sp</p>
        <p>After the purchases you have selected you will still have money for {devRolls} skill development tests.</p>
        {!isSpellCaster && character.values.wisdom>=4 ? <p>You should at least develope Chant-skill to be able to cast spells of that school.</p> : <></>}
        {!isSpellCaster && character.values.intelligence>=4 ? <p>You should at least develope Sorcery-skill to be able to cast spells of that school.</p> : <></>}
        {!isSpellCaster && character.values.charisma>=4 ? <p>You should at least develope Charm-skill to be able to cast spells of that school.</p> : <></>}
        {isSpellCaster ? <p>You also have an ability to cast spells. You should save some money to purchase scrolls (100sp) or runes (300sp)</p> : <></>}
        <p><input type="button" value="continue" onClick={commitPurchases} /></p>
        <div className="characterWizardItemsNavi">
            <a onClick={() => setPage('items')}>Items</a>
            {isSpellCaster ? <a onClick={() => setPage('spells')}>Spells</a> : <></> }
            <a onClick={() => setPage('skills')}>Skills</a>
        </div>
        {page=='items' ? <div className={'wizardItems'+(page=='items' ? '' : ' hidden')}>
            <div>
                <h2>Selected items</h2>
                <div className='selectedItems'>
                    <ItemRow item={{name:"Rations",price:5,amount:character.values.rations}} onDelete={character.values.rations>6 ? deleteItem : null} />
                    <ItemRow item={{name:"Healing Kit",amount:character.values.healingkit}} />
                    {itemRows}
                </div>
            </div>
            <div>
                <h2>Available items</h2>
                <div className='availableItems'>{allItemRows}</div>
            </div>
        </div> : <></> }
        {page=='spells' ? <div className={'wizardSpells'+(page=='spells' ? '' : ' hidden')}>
            <div>
                <h2>Chosen Spells</h2>
                <div className='spellContainer'>
                    {spellRows}
                </div>
            </div>
            <div>
                <h2>Spells available</h2>
                <div className='spellContainer'>
                    {allSpellRows}
                </div>
            </div>
        </div> : <></> }
        {page=='skills' ? <div className={'wizardSkills'+(page=='skills' ? '' : ' hidden')}>
            <SkillDevelopment character={character} rollsAvailable={devRolls} onRollCompleted={value => addCharacterValue(value)} />
        </div> : <></> }
    </div>
}
