import React, { useEffect, useState } from 'react';
import { HTTP } from '../utils/http';
import { guid } from '../utils/utils';
import { Icon } from '../utils/icons';
import { TextInput } from '../utils/formfields';
import { useOutletContext } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createCharacter } from './character';

export function NotesItem({item,itemToEdit,onClick=(() => 0) ,save,deleteNote,edit}){
    let [title,setTitle]=useState(item.title);
    let [note,setNote]=useState(item.note);

    let styles="notesItem";
    if (edit) styles+=" clickable";

    return <div className={styles} onClick={()=> (itemToEdit!=item.rowKey ? onClick(item.rowKey):0)}>
        {itemToEdit==item.rowKey && edit ? <>
            <input placeholder="Title" value={title} onChange={ev => setTitle(ev.target.value)} />
            <textarea placeholder="Note" value={note} onChange={ev => setNote(ev.target.value)} />
            <p className="iconButtons">
                <Icon type={Icon.accept} onClick={() => save({...item,title,note})} tip="Save" /> 
                {item.rowKey ? <Icon type={Icon.cancel} onClick={() => onClick("")} tip="Cancel" /> : <></> }
                {deleteNote ? <Icon type={Icon.delete} onClick={() => deleteNote(item)} tip="Delete" /> : <></> }
            </p>
        </> : <><h4>{item.title}</h4><p>{item.note}</p></>}
    </div>
}

function LanguageItem({item,save,deleteLang,onClick=(() => 0),edit}){
    let [language,setLanguage]=useState(item?.language || "");

    function doSave(){
        save({...item,language});
        setLanguage("");
    }

    return <div className="languageItem">
        <p className={item?.known ? "languageKnown" : "languageNotKnown"}>
            <TextInput value={language} onChange={ev => setLanguage(ev.target.value)} editable={edit} />
            {edit ? <Icon type={Icon.accept} onClick={doSave} /> : <></> }
            {edit && item.rowKey ? <Icon type={Icon.delete} onClick={() => deleteLang(item)} />  : <></> }
        </p>
    </div>
}

function CharacterLanguages({character}){
    let [languages,setLanguages]=useState(character.languages);
    let [edit,setEdit]=useState(false);

    function save(item){
        if (!item.rowKey) item.rowKey=guid("lng");
        if (!item.partitionKey) item.partitionKey=character.rowKey;
        HTTP.post("/api/characters/"+character.rowKey+"/languages",item).then(languages => {
            setLanguages(languages);
            character.languages=languages;
        })
    }

    function deleteLang(item){
        HTTP.delete("/api/characters/"+character.rowKey+"/languages/"+item.rowKey).then(languages => {
            setLanguages(languages);
            character.languages=languages;
        })
    }

    let known=languages.filter(l => l.known).map(item => <LanguageItem key={item.rowKey} item={item} edit={edit} save={save} deleteLang={deleteLang} />);
    let unknown=languages.filter(l => !l.known).map(item => <LanguageItem key={item.rowKey} item={item} edit={edit} save={save} deleteLang={deleteLang} />);    

    return <div className="characterLanguages">
        <h3>Languages <Icon type={Icon.edit+(edit ? Icon.dimmed : "")} onClick={() => setEdit(!edit)} /></h3>
        <div className="languagesContainer">
            <div className="known">
                <h4>Known</h4>
                <p className='languageKnown'>Slav</p>
                {known}
                {edit ? <LanguageItem  item={{language:'',known:true}} edit={edit} save={save} /> : <></>}
            </div>
            <div className="notKnown">
                <h4>Not known</h4>
                {unknown}
                {edit ? <LanguageItem item={{language:'',known:false}} edit={edit} save={save} /> : <></>}
            </div>
        </div>
    </div>

}

export function CharacterNotes({character}){
    let [notes,setNotes]=useState(character.notes);
    let [itemToEdit,setItemToEdit]=useState("");
    let [edit,setEdit]=useState(false);

    /*
    useEffect(function(){
        HTTP.get(`/api/characters/${character.rowKey}/items`).then(notes => {

        })
    },[]);
    */

    function save(item){
        item.partitionKey=character.rowKey;
        if (!item.rowKey) item.rowKey=guid("nte");
        HTTP.post(`/api/characters/${character.rowKey}/notes`,item).then(items => {
            character.notes=items;
            setNotes([...character.notes]);
            setItemToEdit("");
        })
    }

    function deleteNote(note){
        HTTP.delete("/api/characters/"+character.rowKey+"/notes/"+note.rowKey).then(items => {
            character.notes=items;
            setNotes([...character.notes]);
            setItemToEdit("");
        })
    }

    console.log("Selected note",itemToEdit)

    function setItem(rk){
        console.log("Set item",rk)
        setItemToEdit(rk);
    }

    let rows=notes.map(n => <NotesItem key={n.rowKey} itemToEdit={itemToEdit} item={n} save={save} deleteNote={deleteNote} onClick={rk => setItem(rk)} edit={edit} />)

    return <div className="characterNotes">
        <h3>Notes <Icon type={Icon.edit+(edit ? Icon.dimmed : "")} onClick={() => setEdit(!edit)} /></h3>
        {edit ? <p>Click on item to edit or add a new one</p> : <></>}
        <div className="notesContainer">
            {rows}
            {!itemToEdit && edit ? <NotesItem item={{title:'',note:''}} save={save} edit={edit} /> : <></> }
        </div>
    </div>
}

export function CharacterNotesAndLanguages(){
    //const {character}=useOutletContext();
    const {currentCharacter}=useSelector(state => state.game);
    let character=createCharacter(currentCharacter);

    return <div className="characterSheetPage characterNotesAndLanguages">
        <CharacterLanguages character={character} />
        <CharacterNotes character={character} />
    </div>
}