import React from 'react';


import './layers.scss';
import { setClicked } from './utils/mapstore';

function LayerAsset({map,asset}){

    function assetClicked(){
        setClicked({asset})
    }

    return <div className="layerAsset">
        <div onClick={assetClicked}>{asset.name}</div>
    </div>
}

function LayerObject({map,object}){
    let {lineType,closed}=object;

    function objectClicked(){
        setClicked({line:object});
    }

    let assets=map.assets.filter(a => a.room==object.rowKey);
    let rows=assets.map(a => <LayerAsset key={a.rowKey} map={map} asset={a} />)

    return <div className="layerObject">
        <div onClick={objectClicked}>{lineType} {closed ? "closed" : 'open'}</div>        
        {assets.length ? rows : <></>}
    </div>
}

function LayerObjects({layer,map}){

    let objects=map.objects.filter(o => o.layer==layer);
    let rows=objects.map(o => <LayerObject key={o.rowKey} map={map} object={o} />)    
    let assets=map.assets.filter(a => (!a.room && a.layer==layer));
    let assetRows=assets.map(a => <LayerAsset key={a.rowKey} map={map} asset={a} />)
    return <div className="layerObjects">
        <h6>Layer {layer}</h6>
        {rows}
        {assets.length ? assetRows : <></>}
    </div>
}

export function MapLayers({map}){

    let rows=[];
    for(let i=1;i<=9;i++){
        rows.push(<LayerObjects key={'layer-'+i} layer={i} map={map} />)
    }

    return <div className="mapLayers">
        {rows}
    </div>
}