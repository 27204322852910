import React, { useEffect, useRef, useState } from 'react';
import { Map } from './map';

import './maps.scss';
import { drawAvatars, drawMap } from './renderer';
import { useSelector } from 'react-redux';
import { sendToCampaign } from '../utils/websocket';
import { calculateOptionValues } from './points/pointutils';


export function PlayerMap({map,small=false,onClick,avatars=[]}){
    const playerRef=useRef(null);
    const {player,role}=useSelector(state => state.game);
    const [squarePixels,setSquarePixels]=useState(small ? 4 : 16);
    const [roomClick,setRoomClick]=useState(null);
    const [points,setPoints]=useState([])
    const [avatarClick,setAvatarClick]=useState(null);
    const [dragging,setDragging]=useState(false);
    const [latestImage,setLatestImage]=useState(null);

    let size=small ? 120 : 320 ;

    useEffect(() => {
        //setSquarePixels(map.options.squarePixels);
        map.options.squarePixels=squarePixels;
        calculateOptionValues(map.options);
        map.points.forEach(p => p.setOptions(map.options));
        draw();
    },[map])

    function draw(){
        let playerCanvas=playerRef.current;
        if (map){
            let json=JSON.stringify(map);
            let jsonMap=JSON.parse(json);
            let pmap=Map.fromJson(jsonMap);
            const ctx = playerCanvas.getContext("2d");
            drawMap(pmap,"bw",player.rowKey,points,avatars).then(c => {
                ctx.drawImage(c,0,0);
                drawAvatars(ctx,pmap,avatars);
                setLatestImage(c);
            });
        }
    }

    function changeSquarePixels(value){        
        map.options.squarePixels=squarePixels;
        calculateOptionValues(map.options);
        map.points.forEach(p => p.setOptions(map.options));
    }

    function drawLatest(){
        let playerCanvas=playerRef.current;
        if (playerCanvas){
            let ctx=playerCanvas.getContext("2d");
            ctx.drawImage(latestImage,0,0);
            drawAvatars(ctx,map,avatars);
        }
    }


    useEffect(function(){
        window.requestAnimationFrame(draw);
    },[squarePixels,points,avatarClick]);

    function scrollToSelectedRoom(){
        if (!map.selectedRoom) return;
        let dimensions=map.selectedRoom.dimensions;
        let {minX,minY,width,height}=dimensions;
        minX*=map.options.feetToPixelsRatio;
        minY*=map.options.feetToPixelsRatio;
        width*=map.options.feetToPixelsRatio;
        height*=map.options.feetToPixelsRatio;
        let cw=designRef.current.parentElement.clientWidth;
        let ch=designRef.current.parentElement.clientHeight;
        //console.log(dimensions);
        //console.log(minX,minY,width,height,cw,ch);
        let left=minX-(cw-width)/2;
        let top=minY-(ch-height)/2;
        console.log("Scrollto,Current",left,top,designRef.current.parentElement.scrollLeft,designRef.current.parentElement.scrollTop)
        playerRef.current.parentElement.scrollTo(left,top);
        setClicks([]);
    }


    function setMapSquarePixels(ev){
        let sp=Number(ev.target.value);
        map.options.squarePixels=sp;
        calculateOptionValues(map.options);
        map.points.forEach(p => p.setOptions(map.options));
        setSquarePixels(sp);
    }

    function canvasClicked(ev){
        if (onClick) {
            onClick();
            return;
        }
        let {x,y}=ev.target.getBoundingClientRect();
        let cx=ev.clientX-x;
        let cy=ev.clientY-y;
        let point=map.getPoint(cx,cy);
        let aht=map.hitTestAvatars(point,avatars,player.rowKey,role)
        if (aht?.avatar){
            console.log("Avatar clicked",aht.avatar);
            setRoomClick(null);
            avatars.forEach((a,index) => a.clicked=(index==aht.avatarIndex));
            setAvatarClick(aht);
            setPoints([]);
            return;
        }
        avatars.forEach(a => a.clicked=false);
        setAvatarClick(null);
        setPoints([point]);
        /*
        if (ht?.opening){
            ht.opening.isDoorOpen=!ht.opening.isDoorOpen;
            //setOpeningClick(ht);
            return;
        }
        if (ht?.decoration){
            map.setClicked(ht);
            setRoomClick(ht);
            return;
        }
        if (newRoom){
            map.setClicked(ht);
            setRoomClick(ht);
        }
        if (wall){
            //setClicks([]);
            return;
        }
        //if (oldRoom && (oldRoom!=newRoom)) return;
        if (oldRoom && !newRoom){
            map.setClicked(null);
            setRoomClick(null);
            return;
        }
        */
    }
    function mouseDown(ev){
        if (!avatarClick) return;
        let {x,y}=ev.target.getBoundingClientRect();
        let cx=ev.clientX-x;
        let cy=ev.clientY-y;
        let point=map.getPoint(cx,cy);
        let aht=map.hitTestAvatars(point,avatars,player.rowKey,role);
        console.log("Start drag",aht,avatarClick)
        if (aht?.avatarIndex==avatarClick?.avatarIndex) setDragging(true);
        setPoints([]);
    }

    function mouseUp(ev){
        if (!dragging) return;
        console.log("End drag")
        setDragging(false);
        avatarClick.avatar.clicked=false;
        sendToCampaign("avatarChanged",avatarClick.avatar);
        avatars.forEach(a => a.clicked=false);
        setAvatarClick(null);
        setPoints([]);
    }

    function mouseLeave(ev){

    }

    function mouseEnter(ev){
        if (!dragging) return;
        if (!(ev.buttons%2)){
            console.log("Entering not dragging")
            setDragging(false)
        }
    }


    function mouseMove(ev){
        if (!dragging) return;
        let {x,y}=ev.target.getBoundingClientRect();
        let cx=ev.clientX-x;
        let cy=ev.clientY-y;
        let point=map.getPoint(cx,cy);
        console.log("Dragging",avatarClick?.avatar)
        if (avatarClick?.avatar){
            avatarClick.avatar.x=point.feet.x;
            avatarClick.avatar.y=point.feet.y;
            drawLatest();
        }
    }

    let maxHeight=400;
    let maxWidth=400;
    let center=document.getElementById("center");
    /*
    if (center) {
        maxHeight=center.clientHeight-240;
        maxWidth=center.clientWidth-360;
    }*/
    return  <div><div className='mapArea' style={{height:maxHeight,width:maxWidth}}>
        <canvas ref={playerRef} width={map.options.canvasWidth} height={map.options.canvasHeight} 
            onMouseDown={mouseDown}
            onMouseUp={mouseUp}
            onMouseMove={mouseMove}
            onMouseLeave={mouseLeave}
            onMouseEnter={mouseEnter}
            onClick={canvasClicked} />
        </div> 
        {!small ? <div className='formInput rangeInput'>
            <label>Square is {squarePixels} pixels</label>
            <input type="range" min={4} max={64} value={squarePixels} onChange={setMapSquarePixels} />
        </div> : <></> }
    </div>

}

