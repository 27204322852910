import React, { useEffect, useState } from 'react';
import { Outlet, } from 'react-router-dom';
import { LoginPage } from './login';
import { useSelector } from 'react-redux';
import { Page } from '../layout/page';
import { clearCampaign } from '../utils/store';
import { HTTP } from '../utils/http';
import { PlayerInfo } from './playerinfo';
import { CharacterSheet, characterSheetChildren } from '../character/charactersheet';

import './players.scss'
import { CharacterWizard, CharacterWizardRoute } from '../character/wizard/characterwizard';
import { CampaignTile, CreateCampaignTile } from './campaigntiles';
import { CharacterTile, WizardTile } from './charactertiles';
import { Tile } from '../utils/tile';

export const  playerHomeChildren=[
    {index:true,element: <PlayerHome />},
    {path:'character/:rowKey',element:<CharacterSheet />,children:characterSheetChildren},
    {path:'wizard',element:<CharacterWizardRoute />,children:characterSheetChildren},
];

function RuleEnhancements(){

    return <Tile header='Rule enhancements' styles="ruleEnhancements">
        <h3>Crossbow</h3>
        <p>Crossbow damagebonus is constant 4 (earlier 1d4). Now crossbow can be used against 
            unaware opponents in the same manner as all other weapons. With damagebonus of 4d6 agains unaware.</p>
        <h3>Missile weapons</h3>
        <p>Missile weapons may be used with either Fight-skill. If the attacker uses missile weapon with
            Brutal Fight in melee he suffers -2 to his defence.</p>
        <h3>Extra-container for items</h3>
        <p>The container can be used for any items the character doesn't carry on himself. 
            It may be a beast of burden or perhaps just items stored at home.</p>
        <p>Still the old rules apply for Severely Encumbered characters, but it is assumed that the 
            situation is temporary and they are handled case by case.</p>
    </Tile>
}

export function PlayerHome(){
    const {campaigns,campaign}=useSelector(state => state.game);
    let[characters,setCharacters]=useState([]);

    useEffect(function(){
        HTTP.get("/api/playercharacters").then(chars => {
            setCharacters(chars)
        })
    },[])

    let rows=campaigns.map(c => <CampaignTile key={c.rowKey} campaign={c}  />)

    console.log("Characters",characters);

    let charRows=characters.map(c => <CharacterTile key={c.values.rowKey} character={c} />)
    console.log(charRows)

    return <div className="campaignsPage">
        <PlayerInfo />
        <RuleEnhancements />
        {rows}
        <CreateCampaignTile />
        {charRows}
        <WizardTile />
    </div>

    /*
    return <Page theme="tools">
        {rows}
        <CreateCampaign />
    </Page>
    */
}

export function PlayerHomeRoute(){
    let {player,campaign}=useSelector(state => state.game);
    console.log("PlayerHome",player)
    /*
    useEffect(function(){
        if (campaign) clearCampaign();
    },[campaign])
    */

    return <Page theme="campaign">
        { !player?.rowKey ? <LoginPage /> : <Outlet /> }
    </Page>
}
