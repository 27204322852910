import React,{useState} from 'react';

import './avatars.scss';

function AvatarButton({number,onClick,basePath="/assets/avatars/characters_"}){
    let name=''+number;
    if (name.length<2) name='0'+name;
    
    return <button className='imageButton' onClick={() => onClick(number)}>
        <img src={`${basePath}${name}.svg`} width={36} height={48} /></button>
}


export function CharacterAvatar({number=1,onChange,basePath="/assets/avatars/characters_",maxAvatars=36}){
    let [avatar,setAvatar]=useState(number);
    let [showList,setShowList]=useState(false);

    function clicked(number){
        let fake={target:{value:number}};
        onChange(fake);
        setAvatar(number);
        setShowList(false)
    }

    let rows=[];
    for(let i=1;i<=maxAvatars;i++){
        rows.push(<AvatarButton key={'av-'+i} number={i} onClick={clicked} basePath={basePath} />);
    }

    return <div className="characterAvatar">
        <AvatarButton number={avatar} onClick={() => setShowList(!showList)} basePath={basePath} />
        {showList ? <div className="avatarList">
            {rows}
        </div> : <></>}
    </div>
}

