import { calculatePixels, distance, lineInfo, lineInfoRawPoints, pointOnLine } from "../points/pointutils";

function bezierControl(point,prev,next){
    let li1=lineInfoRawPoints(prev,point);
    let li2=lineInfoRawPoints(point,next);
    let aDif=(li2.ang-li1.ang);
    while(aDif<-Math.PI) aDif+=2*Math.PI;
    while(aDif>Math.PI) aDif-=2*Math.PI;
    let aNormal=li2.ang-aDif/2;
/*
    console.log("Angle",
        Math.round(li1.ang*180/Math.PI),
        Math.round(li2.ang*180/Math.PI),
        Math.round(aDif*180/Math.PI),
        Math.round(aNormal*180/Math.PI));
*/
    let dx=20*Math.cos(aNormal);
    let dy=20*Math.sin(aNormal)
    let x=point.x+dx;
    let y=point.y+dy;
    let forward={x,y};
    x=point.x-dx;
    y=point.y-dy;
    let backward={x,y};
    return({forward,backward});
}

function drawFencePosts(ctx,p1,p2,type,options,drawLast=false){
    //console.log("Fence",p1,p2)
    let li=lineInfo(p1,p2);    
    let {length,ang}=li;
    length*=options.feetToPixelsRatio;
    let pd=10;
    if (type=="fortification") pd=4;
    let numPosts=Math.round(length/(pd*options.backgroundScale));
    let postDist=length/numPosts;
    let radius=2;
    if (type=="fortification") radius=1.7;
    //console.log("Fence",length,numPosts,postDist)
    let num=numPosts-1;
    if (drawLast) num++;
    for(let i=0;i<=num;i++){
        ctx.beginPath();
        let x=p1.pixels.x+(i*postDist)*Math.cos(ang);
        let y=p1.pixels.y+(i*postDist)*Math.sin(ang);
        ctx.arc(x,y,radius*options.backgroundScale,0,2*Math.PI);
        ctx.closePath();
        ctx.fill();
    }
}

function drawSegment(ctx,options,index,type,points,openings){
    let endIndex=index<points.length-1 ? index+1 : 0; 
    let start=points[index];
    let end=points[endIndex];
    let endOpening=openings.find(o => o.segmentIndex==index);
    let startOpening=openings.find(o => o.segmentIndex==(index>0 ? index-1: points.length-1));
    let lenSub=5;
    if (type=="fortification") lenSub*=2;
    if (options.mapUnits!='feet') lenSub=16/options.feetToPixelsRatio;
    if (endOpening){
        let li=lineInfo(start,end);
        let endOld=end;
        end=pointOnLine(start,end,li.length-lenSub/2,options);
        let dx=endOld.pixels.x-end.pixels.x;
        let dy=endOld.pixels.y-end.pixels.y;
        let dist=Math.sqrt(dx*dx+dy*dy)
        calculatePixels(end,options);
        console.log("Found end opening",lenSub,dist,end);
        endOpening.start=end;
    }
    if (startOpening){
        let li=lineInfo(start,end);
        let startOld=start;
        start=pointOnLine(start,end,lenSub/2,options);
        calculatePixels(start,options);
        let dx=startOld.pixels.x-start.pixels.x;
        let dy=startOld.pixels.y-start.pixels.y;
        let dist=Math.sqrt(dx*dx+dy*dy);
        console.log("Found start opening",lenSub,dist,startOld,start)
        startOpening.end=start;
    }
    
    ctx.beginPath();
    ctx.moveTo(start.pixels.x,start.pixels.y);
    ctx.lineTo(end.pixels.x,end.pixels.y);
    ctx.closePath();
    ctx.stroke();
    drawFencePosts(ctx,start,end,type,options,endOpening)
}

function drawOpening(ctx,options,opening,type){
    let {start,end,isOpen}=opening;
    let li=lineInfo(start,end);
    let {ang,length}=li;
    let pixelLength=length*options.feetToPixelsRatio;
    if (type=="fortification") pixelLength/=2;
    let ang1=ang-Math.PI/6;
    let x1=start.pixels.x+pixelLength*Math.cos(ang1);
    let y1=start.pixels.y+pixelLength*Math.sin(ang1);
    ctx.beginPath();
    ctx.moveTo(start.pixels.x,start.pixels.y);
    ctx.lineTo(x1,y1);
    ctx.closePath();
    ctx.stroke();
    if (type=="fortification"){
        let ang2=ang+Math.PI+Math.PI/6;
        let x2=end.pixels.x+pixelLength*Math.cos(ang2);
        let y2=end.pixels.y+pixelLength*Math.sin(ang2);
        ctx.beginPath();
        ctx.moveTo(end.pixels.x,end.pixels.y);
        ctx.lineTo(x2,y2);
        ctx.closePath();
        ctx.stroke();
    }
}

export function drawContour(ctx,options,obj,hover=false){
    console.log("Contour",obj)
    //if (obj.type=='no-fill' || obj.type=='fill-on-hover') return;
    let {type,points,fillColor,lineWidth,lineType,lineColor,openings}=obj;
    if (!points || points.length<3) return;
    let strokeStyle=lineColor;
    let fillStyle=fillColor;
    points=points.map(p => calculatePixels(p,options));
    
    let first=points[0].pixels;
    let last=points[points.length-1].pixels;
    if (lineWidth) ctx.lineWidth=lineWidth*options.backgroundScale;

    let bezierControls=points.map((p,i) => {
        let prev=i==0 ? last : points[i-1].pixels
        let current=points[i].pixels;
        let next=i<=points.length-2 ? points[i+1].pixels : first;
        return bezierControl(current,prev,next);
    })


    /* if drawHandles
    for(let i=0;i<points.length;i++){
        let b=bezierControls[i];
        let {forward:fore,backward:back}=b;
        ctx.fillStyle="#ff0000";
        ctx.font="12px serif";

        ctx.fillRect(fore.x-2,fore.y-2,4,4);
        ctx.fillText(""+i,fore.x+2,fore.y);
        ctx.fillStyle="#0000ff";
        ctx.fillRect(back.x-2,back.y-2,4,4);
        ctx.fillText(""+i,back.x+2,back.y);
    }
    */
    if (lineType=='solid' || lineType=="curve" || (type=='fill-on-hover' && hover)){
        ctx.beginPath();
        ctx.moveTo(first.x,first.y);
        let len=closed ? points.length : points.length-1;
        for(let i=1;i<=len;i++){
            let current=points[i<points.length ? i : 0].pixels;
            let startControl=bezierControls[i-1]
            let endControl=bezierControls[i<points.length ? i : 0];
            let c1=startControl.forward;
            let c2=endControl.backward;
    
            if (lineType=="curve") ctx.bezierCurveTo(c1.x,c1.y,c2.x,c2.y,current.x,current.y) 
            else {
                ctx.lineTo(current.x,current.y);
            }
        }
        ctx.closePath();
        ctx.fillStyle=fillStyle;
        if (type!="no-fill" && closed) ctx.fill();
        if (lineWidth) {
            ctx.strokeStyle=strokeStyle;
            ctx.stroke();
        }
    }

    if (lineType=="curve" || lineType=="solid") return;
    ctx.fillStyle=strokeStyle;
    ctx.strokeStyle=strokeStyle;
    for(let i=0;i<points.length;i++){
        drawSegment(ctx,options,i,lineType,points,openings)
    }
    openings.forEach(o => drawOpening(ctx,options,o,lineType));
}

export function drawContours(map,ctx,layer){
    let contours=map.objects.filter(o => o.layer==layer && o.itemType=="contour");
    contours.forEach(c => drawContour(ctx,map.options,c))
}

