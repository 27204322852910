import React from 'react';
import { guid } from '../../utils/utils';

const houses=[
    {rowKey:guid("ast"),name:'longhouse1',type:'houses',sizeX:10,sizeY:20},
    {rowKey:guid("ast"),name:'longhouse2',type:'houses',sizeX:10,sizeY:20},
    {rowKey:guid("ast"),name:'church1',type:'houses',sizeX:10,sizeY:20},
    {rowKey:guid("ast"),name:'church2',type:'houses',sizeX:10,sizeY:20},
    {rowKey:guid("ast"),name:'house1',type:'houses',sizeX:20,sizeY:20},
    {rowKey:guid("ast"),name:'house2',type:'houses',sizeX:20,sizeY:20},
    {rowKey:guid("ast"),name:'house3',type:'houses',sizeX:15,sizeY:10},
    {rowKey:guid("ast"),name:'house4',type:'houses',sizeX:10,sizeY:10},
    {rowKey:guid("ast"),name:'mill',type:'houses',sizeX:15,sizeY:10},
    {rowKey:guid("ast"),name:'boat',type:'houses',sizeX:10,sizeY:20},
    {rowKey:guid("ast"),name:'docks',type:'houses',sizeX:10,sizeY:20},
    {rowKey:guid("ast"),name:'bridge',type:'houses',sizeX:10,sizeY:20},
    {rowKey:guid("ast"),name:'bridge-short',type:'houses',sizeX:10,sizeY:15},
    {rowKey:guid("ast"),name:'house-test',type:'houses',sizeX:10,sizeY:20},
]

const indoor=[
    {rowKey:guid("ast"),name:'stairs-spiral',type:'indoor',sizeX:10},
    /*{rowKey:guid("ast"),name:'stairs-spiral-2',type:'indoor',sizeX:10},*/
    {rowKey:guid("ast"),name:'stairs-spiral-small',type:'indoor',sizeX:5},
    {rowKey:guid("ast"),name:'stairs',type:'indoor',sizeX:10},
    {rowKey:guid("ast"),name:'stairs-small',type:'indoor',sizeX:5},
    {rowKey:guid("ast"),name:'pit',type:'indoor',sizeX:5},
    /*{rowKey:guid("ast"),name:'stairs2',type:'indoor',sizeX:10},
    {rowKey:guid("ast"),name:'stairs3',type:'indoor',sizeX:10},*/
    {rowKey:guid("ast"),name:'table',type:'indoor',sizeX:10},
    {rowKey:guid("ast"),name:'table-long',type:'indoor',sizeX:10,sizeY:5},
    {rowKey:guid("ast"),name:'table2',type:'indoor',sizeX:10,sizeY:5},
    {rowKey:guid("ast"),name:'bench',type:'indoor',sizeX:10,sizeY:2.5},
    {rowKey:guid("ast"),name:'table-color-1',type:'indoor',sizeX:10,sizeY:10},
    {rowKey:guid("ast"),name:'table-small',type:'indoor',sizeX:5},
    {rowKey:guid("ast"),name:'desk',type:'indoor',sizeX:5},
    {rowKey:guid("ast"),name:'altar-christian',type:'indoor',sizeX:10,sizeY:5},
    {rowKey:guid("ast"),name:'bed',type:'indoor',sizeX:10,sizeY:5},
    {rowKey:guid("ast"),name:'bed-double',type:'indoor',sizeX:10},
    /*{rowKey:guid("ast"),name:'bed-double-color',type:'indoor',sizeX:10},*/
    {rowKey:guid("ast"),name:'chest',type:'indoor',sizeX:5},
    {rowKey:guid("ast"),name:'box',type:'indoor',sizeX:5},
    {rowKey:guid("ast"),name:'shelf',type:'indoor',sizeX:10,sizeY:2.5},
];

const outdoor=[
    {rowKey:guid("ast"),name:'tree1',type:'outdoor',sizeX:10,sizeY:10},
    {rowKey:guid("ast"),name:'tree2',type:'outdoor',sizeX:10,sizeY:10},
    {rowKey:guid("ast"),name:'stump',type:'outdoor',sizeX:10,sizeY:10},
    {rowKey:guid("ast"),name:'stone1',type:'outdoor',sizeX:10,sizeY:5},
    {rowKey:guid("ast"),name:'stone2',type:'outdoor',sizeX:10,sizeY:5},
    {rowKey:guid("ast"),name:'stone3',type:'outdoor',sizeX:10,sizeY:10},
    {rowKey:guid("ast"),name:'village',type:'outdoor',sizeX:36,sizeY:36},
    {rowKey:guid("ast"),name:'village2',type:'outdoor',sizeX:36,sizeY:36},
    {rowKey:guid("ast"),name:'village3',type:'outdoor',sizeX:36,sizeY:36},
    {rowKey:guid("ast"),name:'village4',type:'outdoor',sizeX:36,sizeY:36},
    {rowKey:guid("ast"),name:'village5',type:'outdoor',sizeX:36,sizeY:36},
    {rowKey:guid("ast"),name:'village6',type:'outdoor',sizeX:36,sizeY:36},
    {rowKey:guid("ast"),name:'village7',type:'outdoor',sizeX:36,sizeY:36},
    {rowKey:guid("ast"),name:'village8',type:'outdoor',sizeX:36,sizeY:36},
    {rowKey:guid("ast"),name:'church',type:'outdoor',sizeX:36,sizeY:54},
    {rowKey:guid("ast"),name:'fortress',type:'outdoor',sizeX:54,sizeY:54},
    {rowKey:guid("ast"),name:'hills1',type:'outdoor',sizeX:72,sizeY:12},
    {rowKey:guid("ast"),name:'hills2',type:'outdoor',sizeX:72,sizeY:72},
    {rowKey:guid("ast"),name:'pine',type:'outdoor',sizeX:22,sizeY:36},
    {rowKey:guid("ast"),name:'spruce',type:'outdoor',sizeX:26,sizeY:36},
    {rowKey:guid("ast"),name:'mountain2',type:'outdoor',sizeX:72,sizeY:30},
    {rowKey:guid("ast"),name:'mountain4',type:'outdoor',sizeX:72,sizeY:40},
    {rowKey:guid("ast"),name:'barrels',type:'outdoor',sizeX:10,sizeY:10},
    {rowKey:guid("ast"),name:'gallows',type:'outdoor',sizeX:10,sizeY:10},
    {rowKey:guid("ast"),name:'well',type:'outdoor',sizeX:10,sizeY:10},
];

const animals=[
    {rowKey:guid("ast"),name:'cow',type:'animals',sizeX:10,sizeY:10},
    {rowKey:guid("ast"),name:'dog',type:'animals',sizeX:10,sizeY:10},
    {rowKey:guid("ast"),name:'duck',type:'animals',sizeX:10,sizeY:10},
    {rowKey:guid("ast"),name:'horse',type:'animals',sizeX:10,sizeY:10},
    {rowKey:guid("ast"),name:'moose',type:'animals',sizeX:10,sizeY:10},
    {rowKey:guid("ast"),name:'pig',type:'animals',sizeX:10,sizeY:10},
    {rowKey:guid("ast"),name:'rebbit',type:'animals',sizeX:10,sizeY:10},
    {rowKey:guid("ast"),name:'rooster',type:'animals',sizeX:10,sizeY:10},

];

export const assets=[
    {rowKey:guid("ast"),name:'skull',type:'master',sizeX:10},
    {rowKey:guid("ast"),name:'undefined',type:'master',sizeX:10},
    {rowKey:guid("ast"),name:'text',type:'master',sizeX:10},
    ...indoor,
    ...outdoor,
    ...houses,
    ...animals,
]

export const images=[
    {name:"stonefloor",type:'mapimages',filename:"stonefloor-50.jpg"},
    {name:"stonewall",type:'mapimages',filename:"stonewall.svg"},
]

const loaded={};

export function loadAsset(name){ // size in feet
    let asset=assets.find(a => a.name==name); 
    if (!asset) asset=images.find(i => i.name==name);
    let filename=asset.name+".svg";
    if (asset.filename) filename=asset.filename;
    return new Promise(resolve => {
        if (loaded[name]) resolve(loaded[name]);
        else{
            let img=new Image();
            let ret={...asset};
            ret.toJSON=() => asset;
            img.onload=function(){
                ret.img=img;
                loaded[name]=ret;
                resolve(ret);
            }
            img.src=`/assets/${asset.type}/${filename}`;
        }
    })
}

export function loadImage(filename){ // size in feet
    let name=filename;
    return new Promise(resolve => {
        if (loaded[name]) resolve(loaded[name]);
        else{
            //console.log("Loading",filename)
            let img=new Image();
            let ret={name,filename};
            ret.toJSON=() => {name,filename};
            img.onload=function(){
                //console.log("Loaded",filename)
                ret.img=img;
                loaded[name]=ret;
                resolve(ret);
            }
            img.src=`${filename}`;
        }
    })
}



export function loadAllImages(){
    return new Promise(resolve => {
        let promises=[];
        assets.forEach(a => promises.push(loadAsset(a.name)));
        images.forEach(i => promises.push(loadAsset(i.name)));

    })
}

