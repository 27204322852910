import React, { useEffect, useState } from 'react';
import { allItems, createCharacter, fieldToValue } from '../character';
import { HTTP } from '../../utils/http';
import { Icon, IconText } from '../../utils/icons';
import { guid } from '../../utils/utils';
import { notifyCharacterChanged, sendToCampaign } from '../../utils/websocket';
import { setCurrentCharacter, updateCharacterValues } from '../../utils/store';

import "./characteritems.scss";
import { ItemTypeSelector } from './itemtypeselector';
import { AcSelector } from './acselector';
import { BonusSelector } from './bonusselector';
import { DamageSelector } from './damageselector';
import { LocationSelector } from './locationselector';
import { useSelector } from 'react-redux';
import { Checkbox } from '../../utils/formfields';

export const titles={
    beltpouch:'Belt Pouch',
    worn:'Worn Items',
    onperson:'Items on Person',
    packings:'Packings',
    extra:'Extra',
    valuables:'Valuables'
}

const allowedTypes={
    worn:['Other',"Valuable","Armor"],
    beltpouch:["Other","Valuable"],
    onperson:['Other',"Valuable","Armor","Shield","Bow","Crossbow","Arrows","Weapon"],
    packings:['Other',"Valuable","Armor","Shield","Bow","Crossbow","Arrows","Weapon"],
    extra:['Other',"Valuable","Armor","Shield","Bow","Crossbow","Arrows","Weapon"],
}

export const allowedLocations={
    Other:[],
    Valuable:[],
    Armor:[],
    Shield:[],
    Bow:[],
    Crossbow:[],
    Arrows:[],
    Weapon:[]
};

for(let location in allowedTypes){
    allowedTypes[location].forEach(type => allowedLocations[type].push(location));
}

const consumables={
    rations:"Rations",
    healingkit:"Healing kit",
    silverpieces:'Silver pieces',
    goldpieces:'Gold pieces',
    gems:'Gems',
}

async function saveCharacterValues(character,forceUpdate=null){
    updateCharacterValues(character.values);
    if (forceUpdate) forceUpdate(new Date());
    return character.values;
}

function ValueItem({character,item,containerHasFocus,focusItem,setFocusItem,edit=false}){
    let [name,setName]=useState(item.name)
    let [price,setPrice]=useState(item.price );
    let [modified,setModified]=useState(false);

    console.log("Valuable",containerHasFocus,focusItem);

    function changeName(n){
        setName(n);
        setModified(true);
    }

    function changePrice(p){
        setPrice(p);
        setModified(true);
    }

    function save(){
        setModified(false);
    }

    let cls=edit ? "valueItem valueItemEdit" : "valueItem";
    let hasFocus=(edit && containerHasFocus && focusItem==item.rowKey) ;
    let focusClass=hasFocus ? "itemHasFocus" : "";
    let inputClass=hasFocus ? "itemName" : "itemName inputNoFocus";
    let priceClass=hasFocus ? "itemPrice" : "itemPrice inputNoFocus";

    return <p className={cls}>
        {!edit ? <><span>{name}</span><span>{price}</span></> : <>
            <input value={name}  className={inputClass} onFocus={() => setFocusItem(item.rowKey)}   onChange={ev => changeName(ev.target.value)} />                    
            <input className={priceClass} value={price} onFocus={() => setFocusItem(item.rowKey)} onChange={ev=>changePrice(ev.target.value)} />
            {modified ? <Icon onClick={save} type={Icon.accept}  />  : <Icon type={Icon.empty} />}
        </>}
    </p>
}

function ConsumableItem({character,containerHasFocus,focusItem,setFocusItem,item,edit=false}){
    let [value,setValue]=useState(character[item] || 0);

    useEffect(function(){
        setValue(character[item] || 0)
    },[character])

    function save(plus=0){
        character[item]=value+plus;
        if (plus) setValue(value+plus);
        saveCharacterValues(character);
    }

    let hasFocus=(edit && containerHasFocus && focusItem==item.rowKey) ;
    let focusClass=hasFocus ? "itemHasFocus" : "";
    let inputClass=hasFocus ? "itemName" : "itemName inputNoFocus";

    return <p className={focusClass}>
        <span>{consumables[item]}: </span><span>
            {!edit ? <>{value} {value>0 ? <Icon type={Icon.minus} onClick={() => save(-1)} /> : <></>}</> :<>
                <input type="number" className={inputClass} min={0} onFocus={() => setFocusItem(item)} value={value} onChange={ev => setValue(Number(ev.target.value))} />
                {value!=character[item] ? <Icon type={Icon.accept} onClick={() => save()}  /> : <></>}
            </>}
        </span> 
    </p>
}

function ContainerItem({character,container,containerHasFocus,focusItem,setFocusItem,item, forceUpdate,edit=false}){
    let [name,setName]=useState(item.name);
    let [category,setCategory]=useState(item.category);
    let [ac,setAc]=useState(item.ac||0);
    let [bonus,setBonus]=useState(item.bonus || 0);
    let [damage,setDamage]=useState(item.damage || '');
    let [price,setPrice]=useState(item.price || 0);
    let [amount,setAmount]=useState(item.amount||0);
    let [description,setDescription]=useState(item.description || '');
    let [location,setLocation]=useState(container);
    let [enc,setEnc]=useState(item.enc || 'sp');

    useEffect(function(){
        setAmount(item.amount||0)
        setPrice(item.price||0);
    },[character])
  
    function saveClicked(){
        let itemToSave=Object.assign({},item);
        if (!item.rowKey) itemToSave.rowKey=guid("itm");
        if (!item.partitionKey) itemToSave.partitionKey=character.rowKey;
        itemToSave.name=name;
        itemToSave.category=category;
        itemToSave.ac=ac;
        itemToSave.bonus=bonus;
        itemToSave.damage=damage || "1d4";
        itemToSave.price=price;
        itemToSave.amount=amount;
        itemToSave.location=location;
        itemToSave.enc=enc;
        itemToSave.description=description;
        HTTP.post("/api/characters/"+character.rowKey+"/items",itemToSave).then(items => {
            character.items=items;
            if (!item.rowKey){
                setName("");
            }
            setFocusItem("x");
            //forceUpdate();
            notifyCharacterChanged(character)
            setCurrentCharacter(character)
        })
    }

    function changeLocation(newLocation){
        console.log("Setting location",newLocation)
        location=newLocation;
        item.location=newLocation;
        saveClicked();
        //changeItemLocation(newLocation);
    }

    function saveAmount(minus){
        amount+=minus;
        item.amount=amount;
        setAmount(amount);
        saveClicked();
    }

    function deleteClicked(){
        HTTP.delete("/api/characters/"+character.rowKey+"/items/"+item.rowKey).then(items=>{
            character.items=items;
            notifyCharacterChanged(character);
            setCurrentCharacter(character);
            setFocusItem("x");
            //forceUpdate();
        })
    }

    function gainedFocus(){
        setFocusItem(item.rowKey);
    }

    let hasFocus=(edit && containerHasFocus && focusItem==item.rowKey) ;
    let focusClass=hasFocus ? "itemHasFocus" : "";
    let inputClass=hasFocus ? "itemName" : "itemName inputNoFocus";
    let priceClass=hasFocus ? "itemPriceAmount" : "itemPriceAmount inputNoFocus";
    let namePlaceHolder=item.rowKey ? "Item name" : "New item";
    if (category=="Arrows" || category=="Valuable") inputClass+=" shortenName";

    let showBonus=(category=="Arrows" || category=="Bow" || category=="Crossbow" || category=="Weapon" || category=="Shield" )
    if (!bonus) showBonus=false;
    console.log("ac",ac,damage)
    return <div>
        {edit ? <div className={focusClass}>
            <div className='itemEditRow'>
                <input placeholder={namePlaceHolder} onFocus={gainedFocus} className={inputClass} value={name} onChange={ev => setName(ev.target.value)} />
                {category=="Arrows" ? <input type="number" onFocus={gainedFocus} className={priceClass} value={amount} onChange={ev => setAmount(Number(ev.target.value))}  /> : <></> }
                {category=="Valuable" ? <input onFocus={gainedFocus} className={priceClass} value={amount} onChange={ev => setAmount(Number(ev.target.value))}  /> : <></> }
            </div>
            {containerHasFocus && focusItem==item.rowKey ? <>
                <ItemTypeSelector value={category} onChange={setCategory} allowed={allowedTypes[container]} container={container}/>
                {category=='Armor' ? <div className="itemEditRow">
                    <AcSelector value={ac} onChange={setAc} />
                    <BonusSelector value={bonus} onChange={setBonus} />
                </div> : <></>}
                {category=='Bow' || category=='Crossbow' || category=="Shield" ? <div className="itemEditRow">
                    <BonusSelector value={bonus} onChange={setBonus} />
                </div> : <></>}
                {category=="Weapon" || category=="Arrows" ? <div className="itemEditRow">
                    <DamageSelector value={damage} onChange={setDamage} forArrows={category=="Arrows"} />
                    <BonusSelector value={bonus} onChange={setBonus} />
                </div> : <></>}
                {category=='Valuable' ? <div class="itemEditRow">
                    <Checkbox label="Silver" checked={enc=='sp'} onChange={v => v? setEnc('sp') : 0} />
                    <Checkbox label="Gold" checked={enc=='gp'} onChange={v => v? setEnc('gp') : 0} />
                    <Checkbox label="Gems" checked={enc=='gems'} onChange={v => v? setEnc('gems') : 0} />
                </div> : <></>}
                <div className='itemEditRow'>
                    <textarea value={description} onChange={ev => setDescription(ev.target.value)} />
                </div>
                {name ? <div>
                    <LocationSelector type={category} currentLocation={container} onChange={changeLocation} onDelete={deleteClicked} />
                </div> : <></>}
            </>: <></>}
        </div> : <div className="itemNoEdit"><p><span>{name}{showBonus ? '+'+bonus:''}</span></p>
            {category=='Valuable' ? <p className="amountCell">
                <span>{amount}{enc}</span><Icon type={Icon.minus} style="amountMinus" onClick={() => saveAmount(-1)} /></p> : 
            category=="Arrows" ? <p className="amountCell">
                <span>({amount||0})</span>{amount>0 ? <Icon type={Icon.minus} style="amountMinus" onClick={() => saveAmount(-1)} /> : <></>}</p>  
            : <></>}
        </div>}
    </div>
}

function Container({name,forceUpdate,focus,setFocus,edit=false}){
//    let [addItem,setAddItem]=useState("");
    let {currentCharacter}=useSelector(state => state.game);
    let character=createCharacter(currentCharacter);
    let items=character.items.map(i => ({...i}));
    let [focusItem,setFocusItem]=useState("");

    function changeFocusItem(rk){
        setFocusItem(rk)
        setFocus(name);
    }

    let rows=items.filter(i => i.location==name).map((i,index) => <ContainerItem 
                container={name} containerHasFocus={focus==name} 
                focusItem={focusItem} setFocusItem={changeFocusItem} 
                key={i.rowKey} character={character} item={i} 
                forceUpdate={() => forceUpdate(new Date())} edit={edit} />);

    console.log("Container being updated",name,rows.length,character.items.length);            
    return <div className={'itemContainer itemContainer-'+name}>
        <h3>{titles[name]}</h3>
        {name=='worn' ? <p>Clothing, Dagger</p> : <></>}
        {rows}
        {name=='beltpouch' ? <>
            <ConsumableItem key="sp" character={character}  containerHasFocus={focus==name} focusItem={focusItem} setFocusItem={changeFocusItem} item="silverpieces" edit={edit} />
            <ConsumableItem character={character} item="healingkit" edit={edit} container={name} containerHasFocus={focus==name} focusItem={focusItem} setFocusItem={changeFocusItem} forceUpdate={forceUpdate} />
        </> : <></>}
        {name=='onperson' ? <ConsumableItem character={character} item ="rations" edit={edit} container={name} containerHasFocus={focus==name} focusItem={focusItem} setFocusItem={changeFocusItem} forceUpdate={forceUpdate} /> : <></>}
        {rows.length<6 && edit ? <div className='newItem'>
            <ContainerItem container={name} containerHasFocus={focus==name} focusItem={focusItem} setFocusItem={changeFocusItem} 
                key={name+'new'} character={character} 
                item={{rowKey:0,name:''}} forceUpdate={() => forceUpdate(new Date())} edit={edit} />

        </div> : <></> }
    </div>
}

export function CharacterItems(){
    const {currentCharacter}=useSelector(state => state.game);
    let character=createCharacter(currentCharacter);
    //let [upd,forceUpdate]=useState("");
    let [edit,setEdit]=useState(false);
    let [focus,setFocus]=useState("");
    let [items,setItems]=useState(character.items.map(i => ({...i})))
    console.log("ITems for character",character)

    const forceUpdate=() => 0;

    return <div className='characterSheetPage items'>
        <h3>Items <Icon type={Icon.edit+(edit ? Icon.dimmed : "")} onClick={() => setEdit(!edit)} /></h3>
        <div className="containerContainer">
            <Container name='worn' items={items} focus={focus} setFocus={() => setFocus('worn')} forceUpdate={()=>forceUpdate(new Date())} character={character} edit={edit} />
            <Container name='beltpouch' items={items} focus={focus} setFocus={() => setFocus('beltpouch')} forceUpdate={()=>forceUpdate(new Date)} character={character} edit={edit} />
            <Container name='onperson' items={items} focus={focus} setFocus={() => setFocus('onperson')} forceUpdate={()=>forceUpdate(new Date())} character={character} edit={edit} />
            <Container name='packings' items={items} focus={focus} setFocus={() => setFocus('packings')} forceUpdate={()=>forceUpdate(new Date())} character={character} edit={edit} />
            <Container name='extra' items={items} focus={focus} setFocus={() => setFocus('extra')} forceUpdate={()=>forceUpdate(new Date())} character={character} edit={edit} />
            <div className="encumbranceAndMovement">
                <h3>Encumbrance</h3>
                {character.encumbered ? <div className="characterEncumbered">
                    <p>You are encumbered</p>
                    <ul>
                        {character.encumbered.includes(1) ? <li>You carry items in your packings</li> : <></>}
                        {character.encumbered.includes(2) ? <li>You carry more than 100 coins</li> : <></>}
                        {character.encumbered.includes(3) ? <li>You are wearing medium or heavy armor</li> : <></>}
                    </ul>
                </div> : <p>You are unencumbered</p>}
                <h3>Movement</h3>
                <p>Your movement rate is <span className="movementRate">{character.movement}</span></p>
                {character.movement<=30 ? <p>Your Endurance-tests are difficult (24).</p> : <></>}
            </div>
        </div>
    </div>
}