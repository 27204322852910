import { processMapSocketMessage } from "../map/utils/mapstore";
import { processSocketMessage} from "./store";

/*
let isonweb=location.hostname.endsWith(".net");
const connectionUrl=(isonweb ? "wss" : "ws")+"://"+location.hostname+":9003";
*/

let connectionUrl="/";

let socket=null;

const initData={
    playerid:0,
    campaignid:0,
    ownerid:0,
    username:''
}

export function initSocket(){
    socket=new WebSocket(connectionUrl);
    socket.addEventListener("open",ev => {
        console.log("CampaignSocket opened");
    })
    socket.addEventListener("message",ev => {
        let data=JSON.parse(ev.data);
        if (data.from==initData.playerid){
            console.log("Ignoring",data.command);
            return;
        }
        processSocketMessage(data);
        processMapSocketMessage(data);
    })
}

export function sendInitData(player,campaign,characters=[]){
    let ownerId=campaign.partitionKey;
    let playerId=player.rowKey;
    let campaignId=campaign.rowKey;
    initData.ownerid=ownerId;
    initData.campaignid=campaignId;
    initData.playerid=playerId;
    initData.username=player.username;
    let listeners=[...characters,playerId,campaignId];
    //let obj={command:"join",data:listeners};
    sendToCampaign("join",listeners);
    //socket.send(JSON.stringify(obj));
}

export function clearSocketListeners(){
    if (!initData.campaignid) return;
    sendToCampaign("bye",{});
    initData.campaignid="";
    initData.ownerid="";
}

export function sendInitCharacterData(ownerid,character){
    initData.ownerid=ownerid;
    initData.campaignid=character.campaign;
    initData.playerid=character.player;
    initData.username=character.username;
    let data={
        campaignid:character.campaign,
        playerid:character.player,
        ownerid,
        characterid:character.rowKey,
    }
    let obj={command:'init',data};
    socket.send(JSON.stringify(obj));
}

export function sendInitPlayerData(campaign){
    initData.ownerid=campaign.partitionKey;
    initData.campaignid=campaign.rowKey;
    initData.playerid=campaign.partitionKey;
    let data={
        campaignid:campaign.rowKey,
        playerid:campaign.partitionKey,
        ownerId:campaign.partitionKey
    }
    let obj={command:'init',data};
    socket.send(JSON.stringify(obj));
}

export function sendTo(to,command,data){
    let obj={to,from:initData.playerid,command,data,timestamp:new Date()};
    console.log("Send",obj)
    socket.send(JSON.stringify(obj))
}

export function sendToGm(command,data){
    sendTo([initData.ownerid],command,data);
}

export function sendToCharacter(to,command,data){
    sendTo([to],command,data);
}

export function sendToCampaign(command,data){
    sendTo([initData.campaignid],command,data);
}

export function notifyCharacterChanged(character){
    sendTo([character.values.campaign],'updateCharacter',character);
}

/*
store.subscribe(() => {
    console.log("Web socket store",store)
})
*/