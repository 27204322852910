import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { characterAvatarUrl, createCharacter, createNpc } from '../../character/character';

import './characterlist.scss';
import { HTTP } from '../../utils/http';
import { NpcButton } from '../../master/npcs';
import { NpcEditor, calculateFight } from '../../master/npceditor';
import { Dice } from '../../dice/dicebox';
import { Map } from '../map';
import { MapEditor } from '../mapeditor';
import { Link, Outlet, useParams } from 'react-router-dom';
import { rolledForInitiative, sendEncounter, setCurrentCharacter, setEncounter, setShare } from '../../utils/store';
import { PlayerMap } from '../playermap';
import { CharacterAvatar } from '../../encounter/avatars';
import { CharacterCombat } from '../../character/combat/charactercombat';
import { sendToCampaign } from '../../utils/websocket';
import { CharacterSpells } from '../../character/characterspells';
import { Skills } from '../../character/skills/skills';
import { Page } from '../../layout/page';
import { setMapData } from '../utils/mapstore';
import { Checkbox } from '../../utils/formfields';

function CharacterItem({character,onSelect}){
    let {player,role,initiatives}=useSelector(state => state.game);
    let values=character.values || character;
    let img=characterAvatarUrl(values.avatar);

    function changeAvatar(number){
        console.log("Change avatar",number);
    }

    let isOwn=(role=="Master" || values.partitionKey==player.rowKey || values.player==player.rowKey);
    
    let myInitiative=initiatives[character.values.rowKey];
    let defence=character.adefence;
    if (myInitiative){
        if (myInitiative.type=="Brutal") defence=character.bdefence;
        if (myInitiative.defensive) defence+=4;
    }

    let cls="characterButton"+(isOwn ? " clickable" : "");
    return <div className={cls} onClick={() => isOwn ? onSelect(character) : 0}>
        <div className='description'>
            <h5>{values.name}</h5>
        </div>
        <div className="values">
            <div className="avatarImage"><img src={img} /></div>
            <div className="defence">
                <p>{defence}</p>
            </div>
            {myInitiative ? <div className="initiative">
                <p>{myInitiative.value}</p>
            </div> : <></>}
        </div>
    </div>
}

export function CharacterList({encounter}){
    let {campaign,player,initiatives,currentCharacter,role}=useSelector(state => state.game);
    let selectedChar=currentCharacter ? createCharacter(currentCharacter) :null;
    //let [selected,setSelected]=useState(campaign.characters.find(c => c.values.player==player.rowKey));
    let [current,setCurrent]=useState("combat");

    console.log("list updates")

    useEffect(function(){
        if (role=="Master") return;
        //if (!selectedChar) return;
        if (selectedChar && selectedChar.values.player==player.rowKey) return;
        let char=campaign.characters.find(c => c.values.player==player.rowKey);
        setCurrentCharacter(char);
    },[currentCharacter]);

    let charsSorted=campaign.characters.filter(c => true).sort((a,b) => {
        let ca=initiatives[a.values.rowKey]?.value || 0;
        let cb=initiatives[b.values.rowKey]?.value || 0;
        //console.log("Sorting",ca,cb,initiatives,a)
        return cb-ca;
    })

    let chars=charsSorted.map(c => <CharacterItem key={c.values.rowKey} character={createCharacter(c)} onSelect={() => setCurrentCharacter(c)} />);
    /*
    let selectedChar=null;
    if (selected) {
        let campaignCharacter=campaign.characters.find(c => c.values.rowKey==selected.values.rowKey);
        console.log("Found",campaignCharacter);
        selectedChar=createCharacter(campaignCharacter);
    }
    */
    let cls="characterList";
    if (currentCharacter) cls+=" characterListWide";

    return <div className={cls}>
        <h4>Characters</h4>
        <div className="characterListContent">
            <div className="characters">
                {chars}
            </div>
            {selectedChar ? <div className="selectedCharacter">
                <div className="selectedCharacterNavi">
                    <h3>{selectedChar.name}</h3>
                    <a onClick={() => setCurrent("combat")}>Combat</a>
                    <a onClick={() => setCurrent("skills")}>Skills</a>
                    <a onClick={() => setCurrent("spells")}>Spells</a>
                </div>
                {current=="combat" ? <CharacterCombat character={selectedChar} title={""} /> :
                current=="skills" ? <Skills character={selectedChar} /> :
                <CharacterSpells character={selectedChar} noTitle={true} /> }
            </div> : <></>}
        </div>
    </div>
}

