import React, { useEffect, useRef, useState } from 'react';
import { loadAsset } from './utils/images';
import { Map } from './map';

import './maps.scss';
import { drawAvatars, drawClicks, drawMap, drawPointer, hiliteClicked } from './renderer';
import { sendToCampaign } from '../utils/websocket';
import { useSelector } from 'react-redux';
import { calculateOptionValues, calculatePixels, findCenter, point, pointFromPixels } from './points/pointutils';
import { Icon } from '../utils/icons';
import { MapHeadingControls, MapTools, PlayerTools } from './mapcontrols';
import { OptionsContainer } from './optionscontainer';
import { setClicked, setClicks, setMapData,saveMap,setMapPointer, deleteMapNote, setAvatar, saveMapNote} from './utils/mapstore';
import { findOpeningEndpoints } from './lines/linerenderer';
import { hitTestLines } from './lines/linehittest';
import { useNavigate } from 'react-router-dom';

function testPlayerAvatars(map,avatars,characters){
    let x=map.options.mapCenterFeetX;
    let y=map.options.mapCenterFeetY;
    avatars=avatars.map(a => ({...a,inUse:a.type!="player"}));
    characters.forEach((c,index) => {
        let avatar=avatars.find(a => a.rowKey==c.values.rowKey)
        //console.log("Player avatar",avatar)
        if (!avatar){
            avatars.push({
                rowKey:c.values.rowKey,
                player:c.values.player,
                map:map.options.rowKey,
                type:'player',
                inUse:true,
                image:'/assets/avatars/characters_'+String(c.values.avatar).padStart(2,'0')+".svg",
                y:y+10,
                x:x+Math.round(index-characters.length/2)*map.options.squareFeet,
            });
        }
        else avatar.inUse=true;
    });
    let index=avatars.findIndex(a => !a.inUse);
    while(index>=0){
        avatars.splice(index,1);
        index=avatars.find(a => !a.inUse);
    }
    map.playerAvatars=avatars;
}

export function MapEditor({width=640,maxWidth,maxHeight,toolsVisible=false,inEncounter=false}){
    let {campaign,player,role,zoom,share}=useSelector(state => state.game);
    let {mapData,mapNotes,mapPointer,clicked,clicks,doorInfo,current,avatars:storeAvatars,}=useSelector(state => state.maps);
    let avatars=storeAvatars?.map(a => ({...a})) || [];
    const designRef=useRef(null);
    const [dragging,setDragging]=useState(false);
    const [repaint,setRepaint]=useState(0);
    const [showNote,setShowNote]=useState(null);
    const [latestImage,setLatestImage]=useState(null);
    const [showOptions,setShowOptions]=useState(false);
    const [playerMode,setPlayerMode]=useState(false);
    const [contourHilited,setContourHilited]=useState(false);
    const forceRepaint= () => setRepaint(new Date().getTime());
    const navigate=useNavigate();

    let map=Map.fromJson(mapData);
    testPlayerAvatars(map,map.playerAvatars,campaign.characters);
    map.showAvatars=(inEncounter || (share && share.itemRowKey==map.options.rowKey && map.options.showAvatars));
    map.inEncounter=inEncounter;
    //map.objects=[];
    //map.points=[];
    //map.openings=[];
    //map.assets=[];
    if(inEncounter){
        avatars=avatars.filter(a => a.type!="player");
        let x=map.options.mapCenterFeetX;
        let y=map.options.mapCenterFeetY;
        if (map.selectedRoom){
            let room=map.objects.find(o => o.rowKey==map.selectedRoom);
            if (room){
                let center=findCenter(room.points);
                x=center.x;
                y=center.y;
            }
        }
        avatars.forEach((a,index) => {
            if (a.x<0){
                a.x=x+Math.round(index-avatars.length/2)*5;
                a.y=y-5;
            }
        })
       
    }
    else avatars=[];
    map.avatars=[...map.playerAvatars,...avatars];
    map.notes=mapNotes;
    map.mapPointer=mapPointer;
    map.openings.forEach(o => findOpeningEndpoints(map,o));
    map.clicks=clicks.map(c => calculatePixels(c,map.options))
    clicked={...clicked};
    if (clicked.point) clicked.point=calculatePixels(clicked.point,map.options);
    map.clicked=clicked;
    map.latest={image:latestImage,canvasRef:designRef};
    //console.log("Clicked,Clicks",clicked,clicks,doorInfo);


    useEffect(function(){
        designRef.current?.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'center'
        });
    },[]);

    useEffect(function(){
        console.log("Mapeditor, useEffect",share);
        window.requestAnimationFrame(draw);
    },[mapData,mapNotes,storeAvatars])

    useEffect(function(){
        scrollToSelected();
    },[current]);

    function isPlayer(){
        let p=player.rowKey;
        if (role=="Master" && !playerMode) p=0;
        return p;
    }

    function draw(){
        let designCanvas=designRef.current;
        if (designCanvas){
            let json=JSON.stringify(map);
            let jsonMap=JSON.parse(json);
            let dmap=Map.fromJson(jsonMap);
            dmap.clicked=map.clicked;
            dmap.clicks=clicks;
            let ctx=designCanvas.getContext("2d");
            dmap.avatars=avatars;
            dmap.notes=mapNotes;
            dmap.inEncounter=inEncounter;
            dmap.prepareForDraw(isPlayer());
            console.log("Mapeditor drawing",dmap);
            drawMap(dmap,isPlayer()).then(async c => {
                console.log("Got image")
                ctx.drawImage(c,0,0);
                drawClicks(ctx,map);
                hiliteClicked(ctx,map);
                drawPointer(ctx,dmap,mapPointer);
                setLatestImage(c);
                map.latest={canvasRef:designRef,image:c};
                await drawAvatars(ctx,dmap,avatars);
               // sendToCampaign('setmap',{map:jsonMap});
            });
        }
    }

    useEffect(() => {
        console.log("Use effect current",clicks,clicked)
        map.drawLatest(null,false,!isPlayer());
    },[mapPointer,mapNotes,clicks,clicked,current]);

    function canvasClicked(ev){
        if (dragging) {
            setDragging(false);
            return;
        }
        let {x,y}=ev.target.getBoundingClientRect();
        let cx=ev.clientX-x;
        let cy=ev.clientY-y;
        cx/=zoom;
        cy/=zoom;
        let point=map.getPoint(cx,cy);
        //let aht=map.hitTestAvatars(point,avatars,player.rowKey,role)
        let prevClick=map.clicked;
        let ht=map.hitTest(point,player.rowKey,role);
        let curClick=map.clicked;
        console.log("Hittest points",point,ht);
        if (ht?.point) point=ht.point;
        if (!isPlayer()) setClicked(map.clicked);
        else {
            let clicked={};
            if (map.clicked.avatar) clicked.avatar=map.clicked.avatar;
            else if (map.clicked.opening) clicked.opening=map.clicked.opening;
            //else if (map.clicked.line) clicked.line=map.clicked.line;
            setClicked(clicked);
        }
        if (map.clicked.avatar){
            clearClicks();
            setDragging(false)
            return;
        }
        if (map.clicked.note){
            let note={...map.clicked.note};
            if (showNote && showNote.rowKey==note.rowKey) setShowNote(null);
            else{
                note.mx=cx;
                note.my=cy;
                setShowNote(note);
            }
            return;
        }
        else setShowNote(null);
        if (isPlayer()) {
            if (map.clicked.line && map.clicked.line.target && !map.showAvatars){
                navigate("/campaign/maps/"+map.clicked.line.target);
            }
            else clearClicks([point],false);
            return;
        }
        /*if (map.clicked.opening){
            console.log("Opening")
            //map.clicked.opening.isDoorOpen=!map.clicked.opening.isDoorOpen;
            setClicks([point]);
        }
        else*/ if (map.clicked.asset){
            setClicks();
        }
        else {
            setClicks([...clicks,point]);
        }
    }

    function scrollToSelected(){
        console.log("Scroll to selected",current)
        let room=map.objects.find(l => l.rowKey==current);
        if (!room) return;
        let center=findCenter(room.points);
        let x=center.x*map.options.feetToPixelsRatio;
        let y=center.y*map.options.feetToPixelsRatio;
        let cw=designRef.current.parentElement.clientWidth;
        let ch=designRef.current.parentElement.clientHeight;
        let left=x-cw/2;
        let top=y-ch/2;
        console.log("Scrollto,Current",x,y,cw,ch,left,top,)
        designRef.current.parentElement.scrollTo(left,top);
    }

    function setSelectedRoom(){
        map.selectedRoom=clicked.room;
        map.selectedRoomRowKey=map.selectedRoom.rowKey;
        let dimensions=map.selectedRoom.dimensions;
        let {minX,minY,width,height}=dimensions;
        minX*=map.options.feetToPixelsRatio;
        minY*=map.options.feetToPixelsRatio;
        width*=map.options.feetToPixelsRatio;
        height*=map.options.feetToPixelsRatio;
        let cw=designRef.current.parentElement.clientWidth;
        let ch=designRef.current.parentElement.clientHeight;
        let left=minX-(cw-width)/2;
        let top=minY-(ch-height)/2;
        console.log("Scrollto,Current",left,top,designRef.current.parentElement.scrollLeft,designRef.current.parentElement.scrollTop)
        designRef.current.parentElement.scrollTo(left,top);
        clearClicks();
    }

    function clearClicks(init=[],alsoClicked=false,force=false){
       map.clearClicks(alsoClicked) ;
       init.forEach(c => map.addClick(c));
       avatars.forEach(a => a.clicked=false);
       setClicks(map.clicks);
       if (alsoClicked) setClicked()
   }

    function mouseDown(ev){
        console.log("Mouse down",dragging,clicked.avatar,clicked.asset)
        if (!clicked.avatar && !clicked.point) return;
        ev.preventDefault();
        let {x,y}=ev.target.getBoundingClientRect();
        let cx=ev.clientX-x;
        let cy=ev.clientY-y;
        cx/=zoom;
        cy/=zoom;
        let point=map.getPoint(cx,cy);
        //let aht=map.hitTestAvatars(point,avatars);
        let ht=map.hitTest(point,player.rowKey,role);
        console.log("Down",map.clicked.avatar,clicked.avatar)
        if (map.clicked.avatar && map.clicked.avatar.rowKey==clicked.avatar.rowKey) setDragging(true);
        else if (map.clicked.point?.rowKey==clicked.point?.rowKey) setDragging(true);
        /*
        if (map.clicked.avatarClick && map.clicked.avatarClick.avatar.rowKey==clicked.avatarClick?.avatar.rowKey) setDragging(true);
        else if (map.clicked.assetClick && map.clicked.assetClick.asset.rowKey==clicked.assetClick?.asset.rowKey) setDragging(true);
        */
    }

    function mouseUp(ev){
        if (!dragging) return;
        ev.preventDefault();
        if (clicked.avatar){
            //clicked.avatar.clicked=false;
            //avatars.forEach(a => a.clicked=false);
            let avatar=map.avatars.find(a => a.rowKey==clicked.avatar.rowKey);
            setAvatar(avatar);
            sendToCampaign("avatarChanged",avatar);
        }
        else if (clicked.point?.rowKey){
            setMapData(map);
            /*
            let clickedObject=clicked.line || clicked.contour || clicked.room;
            let object=map.objects.find(o => o.rowKey==clickedObject.rowKey)
            let pointRk=clicked.point.rowKey;
            point=pointFromPixels(cx,cy,map.options,pointRk);
            let mpIndex=map.points.findIndex(p => p.rowKey==pointRk);
            let obIndex=object.points.findIndex(p => p.rowKey==pointRk);
            console.log("Found",mpIndex,pointRk,point);
            let pt={...point};
            delete pt.mouse;
            //clicked.asset.point=pt;
            Object.assign(map.points[mpIndex],pt);
            //Object.assign(object.points[obIndex],pt)
            console.log("Mappoints",map.points[mpIndex].feet,object.points[obIndex].feet);
            map.drawLatest(object);
            */
        }
      }

    function mouseLeave(ev){

    }

    function mouseEnter(ev){
        if (!dragging) return;
        if (!(ev.buttons%2)){
            console.log("Entering not dragging")
            setDragging(false)
        }
        
    }

    function mouseMove(ev){
        let {x,y}=ev.target.getBoundingClientRect();
        let cx=ev.clientX-x;
        let cy=ev.clientY-y;
        cx/=zoom;
        cy/=zoom;
        let point=map.getPoint(cx,cy);
        if (!dragging) {
            let ht=hitTestLines(map,point);
            if (contourHilited && !ht?.line){
                map.drawLatest();
                setContourHilited(false);
            }
            if (ht?.line && ht.line.fillType=='fill-on-hover'){
                map.drawLatest(ht.line,true,!isPlayer());
                setContourHilited(true);
            }
            let note=map.hitTestNotes(point,mapNotes);
            if (note){
                //console.log("Show note",note)
            }
            return;
        }
        console.log("Mousemove",clicked)
        if (clicked.avatar){
            let avatar=map.avatars.find(a => a.rowKey==clicked.avatar.rowKey);
            avatar.x=point.feet.x;
            avatar.y=point.feet.y;
            map.drawLatest(null,false,!isPlayer());
        }
        else if (clicked.asset){
            let ht=map.hitTest(point,'assets');
            if (map.clicked.room?.rowKey==clicked.room?.rowKey){
                let pointRk=clicked.asset.point.rowKey;
                point=pointFromPixels(cx,cy,map.options,pointRk);
                let asset=map.assets.find(a => a.rowKey==clicked.asset.rowKey)
                let mpIndex=map.points.findIndex(p => p.rowKey==pointRk);
                console.log("Found",mpIndex,pointRk,point);
                //map.points[mpIndex]=point;
                Object.assign(map.points[mpIndex],point);
                console.log("Mappoints",map.points);
                //clearClicks([point]);
                map.drawLatest(asset,false,!isPlayer())
            }
            else console.log("Last test failed")
        }
        else if (clicked.point?.rowKey){
            let clickedObject=clicked.line || clicked.contour || clicked.room;
            let object=map.objects.find(o => o.rowKey==clickedObject.rowKey)
            let pointRk=clicked.point.rowKey;
            point=pointFromPixels(cx,cy,map.options,pointRk);
            let mpIndex=map.points.findIndex(p => p.rowKey==pointRk);
            let obIndex=object.points.findIndex(p => p.rowKey==pointRk);
            console.log("Found",mpIndex,pointRk,point);
            let pt={...point};
            delete pt.mouse;
            //clicked.asset.point=pt;
            Object.assign(map.points[mpIndex],pt);
            //Object.assign(object.points[obIndex],pt)
            //console.log("Mappoints",map.points[mpIndex].feet,object.points[obIndex].feet);
            map.drawLatest(object,false,!isPlayer());
        }
    }


    function optionsChanged(options,repaint=false){
        Object.assign(map.options,options);
        console.log("optionsChanged",map.options);
        calculateOptionValues(map.options);
        map.points.forEach(p => p.setOptions(options));
        //if (onChange) onChange(map);
        if (!repaint) draw();
        else forceRepaint();
    }


    //let maxHeight=400;
    //let maxWidth=400;

    //let width=map.options.canvasWidth+20;
    //let height=map.options.canvasHeight+20;
    //let width=640;
    let height=400;
    let ch=400;
    let center=document.getElementById("center");
    if (center){
        let ch=center.clientHeight-160;
        if (ch<300) ch=300;
        //console.log("Center height",ch);
        if (height>ch) height=ch;
    }

    function clickOptionsChanged(){
        clearClicks([],true,true);
    }

    function deleteNote(){
        if (!showNote) return;
        deleteMapNote(map.options.rowKey,showNote.rowKey);
        setShowNote(null);
    }

    function saveNote(){
        console.log("Notes",showNote,map.notes)
        delete showNote.mx;
        delete showNote.my;
        saveMapNote(showNote);
        setShowNote(null);
    }

    function toolClicked(tool){
        console.log("Tool clicked",tool)
        if (tool=="saveMap") saveMap(map);
        if (tool=="addPointer" && map.clicked.point){
            let {x,y}=map.clicked.point.feet;
            let pt={x,y,map:map.options.rowKey};
            setMapPointer(pt)
        }
        if (tool=="clearClicks") clearClicks([],true,true);
        if (tool=="setSelected") setSelectedRoom();
        if (tool=="newMap") {
            alert("Newp map")
        }
        
    }

    function showNoteEdited(ev){
        showNote.text=ev.target.value;
        setShowNote({...showNote});
    }

    if (maxWidth && width>maxWidth) width=maxWidth;
    
    let toolsStyle=toolsVisible ? "mapToolsPinned" : "mapTools"; 

    let sharedWall=(clicked.roomClick?.wall?.sharedBy);

    let bplayer=isPlayer();

    let popupStyle={};
    if (map.clicks.length && designRef.current){
        let {x,y}=map.clicks[0].pixels;
        let rect=designRef.current.getBoundingClientRect();
        console.log("Popup",x,y,rect);
        let left=Math.round(rect.left+x+10)+"px";
        let top=Math.round(rect.top+y-70)+"px";
        popupStyle={top,left}
    }
    if (showNote && designRef.current){
        let rect=designRef.current.getBoundingClientRect();
        let left=Math.round(rect.left+showNote.mx)+"px";
        let top=Math.round(rect.top+showNote.my-60)+"px";
        popupStyle={top,left};
    }

    console.log("Pointer",mapPointer?.opening)

    return <div className="mapEditor">
        <div className="mapHolder">
            { bplayer && map.clicks.length && !showNote ? <div className="mapToolsPopup" style={popupStyle}>
                <PlayerTools onClick={toolClicked} map={map} />
            </div>:<></>}
            {!bplayer && !toolsVisible ? <Icon type={Icon.properties} onClick={() => setShowOptions(!showOptions)} /> : <></> }
            {!bplayer && (showOptions || toolsVisible) ? <div className={toolsStyle}>
                { false ? <MapTools onClick={toolClicked} /> : <></> }
                <OptionsContainer map={map} clicked={map.clicked} onChange={clickOptionsChanged} onSave={() => toolClicked("saveMap")} />
                {sharedWall ? <div>
                        <h2>Shared wall</h2>
                        <input type="button" value="Create door" onClick={joinAdjacentRooms} />
                        <input type="button" value="Create window" />
                    </div> : <></>
                }
            </div> : <></> }
            <div>
                <MapHeadingControls map={map} options={map.options} playerMode={bplayer} setPlayerMode={setPlayerMode} onChange={optionsChanged} onSave={() => toolClicked("saveMap")} />
                <div id="designMap" className='mapArea' style={{height,width}}>
                    <canvas ref={designRef} width={map.options.canvasWidth+(bplayer ? 0 : 20)} height={map.options.canvasHeight+(bplayer ? 0 : 20)} 
                        onMouseDown={mouseDown}
                        onMouseUp={mouseUp}
                        onMouseMove={mouseMove}
                        onMouseLeave={mouseLeave}
                        onMouseEnter={mouseEnter}
                        onClick={canvasClicked} />
                </div>
            </div>
            {showNote ? <div className="showNote" style={popupStyle}>
                <div className="noteHeader">
                    <div>{showNote.username}</div>
                    <div>
                        {showNote.player==player.rowKey || role=="Master" ? <>
                            <Icon type={Icon.save} onClick={saveNote} /> 
                            <Icon type={Icon.delete} onClick={deleteNote} /> 
                        </>: <></> }
                        <Icon type={Icon.close} onClick={() => setShowNote(null)} />
                    </div>
                </div>
                <div className="noteText">
                    {showNote.player==player.rowKey || role=="Master" ? <textarea value={showNote.text} onChange={showNoteEdited} />
                     :showNote.text}
                </div>
            </div> : <></>}        
        </div>
        
    </div>
}
