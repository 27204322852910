import React, { useState } from 'react';

import './fatigue.scss';
import { sendToCampaign, sendToCharacter, sendToGm } from '../../utils/websocket';
import { useSelector } from 'react-redux';
import { HTTP } from '../../utils/http';
import { saveCurrentCharacterValues, updateCharacterValues } from '../../utils/store';
import { createCharacter } from '../character';

export function FatigueButton({marker,index,onClick=null}){

    let cls="fatigueMarker fatigueMarker"+marker+(onClick ? ' clickable' : '')
    return <div className={cls} onClick={() => (onClick ? onClick(index) : 0)}>

    </div>
}

export function Fatigue({forceUpdate}){
    let {role,currentCharacter}=useSelector(state => state.game);
    const [showPopup,setShowPopup]=useState(false);
    //const [fatigue,setFatigue]=useState(character.fatigue);
    console.log("Current character",currentCharacter)
    let character=createCharacter(currentCharacter);
    
    function add(m){
        let fat=[...character.fatigue,m];
        //setFatigue(fat);
        character.fatigue=fat;
        setShowPopup(false);
        if (character.rowKey) {
            saveCurrentCharacterValues(character);
            /*
            HTTP.post("/api/characters",character.values).then(cv => {
                
                if (role=="Master") sendToCharacter(character.rowKey,'updateCharacterValues',character.values);
                else sendToGm('updateCharacterValues',character.values);
                
               //sendToCampaign("updateCharacterValues",character.values);
            })
            */
        }
        if (forceUpdate) forceUpdate();
    }

    function remove(index){
        let fat=[...character.fatigue];        
        fat.splice(index,1);
        //setFatigue(fat);
        character.fatigue=fat;
        if (character.rowKey) {
            saveCurrentCharacterValues(character);
            /*
            HTTP.post("/api/characters",character.values).then(cv => {
                if (role=="Master") sendToCharacter(character.rowKey,'updateCharacterValues', character.values);
                else sendToGm('updateCharacterValues',character.values);
            })
            */
        }
        if (forceUpdate) forceUpdate();
    }

    //console.log("fatigue",character.fatigue)

    //let markers=character.fatigue.map((f,index) => <span key={'f-'+index} className="fatigueMarker clickable" onClick={ev => remove(index)}>{f}</span>)
    
    let markers=character.fatigue.map((f,index) => <FatigueButton key={'f-'+index} marker={f} index={index} onClick={remove} />);

    let tx=character.fatiguePenalty<=10 ? -character.fatiguePenalty : 'Inc';
    return <><div className="fatigue">
        <span className='fatigueTitle'>Fatigue</span>
        <span className='fatigueMarker fatiguePenalty'>{tx}</span>
        <div className='markers'>
            {markers}
        </div>
        {character.fatigue.length<5 ? <div>
            <span className='fatigueMarker addFatigue clickable' onClick={() => setShowPopup(!showPopup)}>+</span>
            {showPopup ? <div className="addMarkers">
                <div className="markerWithTitle" onClick={ev => add('M')}><FatigueButton marker="M" /><span>Mental</span></div>
                <div className="markerWithTitle" onClick={ev => add('X')}><FatigueButton marker="X" /><span>Extertion</span></div>
                <div className="markerWithTitle" onClick={ev => add('H')}><FatigueButton marker="H" /><span>Hunger</span></div>
                <div className="markerWithTitle" onClick={ev => add('W')}><FatigueButton marker="W" /><span>Warm</span></div>
                <div className="markerWithTitle" onClick={ev => add('C')}><FatigueButton marker="C" /><span>Cold</span></div>
                <div className="markerWithTitle" onClick={ev => add('S')}><FatigueButton marker="S" /><span>Sleep</span></div>
            </div> : <></> }
        </div> : <></> }
    </div>
    {character.movement<=30 ? <div className="characterMovementNote"><p>Your movement is {character.movement}, Endurance-tests are difficult</p></div> : <></>}
    </>
}