import { useSelector } from "react-redux";
import { calculatePixels } from "../points/pointutils";
import { loadAsset } from "../utils/images";
import { defaultColors } from "../utils/colorselector";

export function drawAsset(ctx,map,item){
    return new Promise(async resolve => {
        let asset=await loadAsset(item.name)
        let img=asset.img;
        let {point,sizeX,sizeY}=item;
        point=calculatePixels(point,map.options);
        if (!sizeY) sizeY=sizeX;
        let {feetToPixelsRatio,assetScale}=map.options;
        //console.log("Asset",feetToPixelsRatio,assetScale,item.scale);
        //let pixelSizeX=sizeX*feetToPixelsRatio*assetScale*item.scale;
        //let pixelSizeY=sizeY*feetToPixelsRatio*assetScale*item.scale;

        let pixelSizeX=sizeX*item.scale;
        let pixelSizeY=sizeY*item.scale;
        if (map.options.mapUnits=='feet') {
            pixelSizeX*=feetToPixelsRatio;
            pixelSizeY*=feetToPixelsRatio;
        }
        else{
            pixelSizeX*=16/10;
            pixelSizeY*=16/10;
            pixelSizeX*=map.options.backgroundScale;
            pixelSizeY*=map.options.backgroundScale;
        }

        let sx=pixelSizeX/img.width;
        let sy=pixelSizeY/img.height;
        let degr=item.rotation || 0;
        let {x,y}=point.pixels;
        if (item.name!="text"){
            ctx.save();
            //ctx.scale(sx,sx);
            ctx.translate(x,y);
            ctx.rotate(degr*Math.PI/180);
            ctx.drawImage(img,-pixelSizeX/2,-pixelSizeY/2,pixelSizeX,pixelSizeY);
            ctx.restore();
        }
        if (item.label){
            let pos=item.labelPosition;
            const align=["center","center","left","left","left","center","right","right","right"];
            const base=["middle","bottom","bottom","middle","top","top","top","middle","bottom"];
            ctx.textAlign=align[pos];
            ctx.textBaseline=base[pos];
            if (pos==8 || (pos>=1 && pos<=2)){
                y-=pixelSizeY/2;
            }
            if (pos>=4 && pos<=6){
                y+=pixelSizeY/2;
            }
            if (pos>=2 && pos<=4){
                x+=pixelSizeX/2;
            }
            if (pos>=6 && pos<=8){
                x-=pixelSizeX/2;
            }
            let fs=Math.round(item.fontSize*map.options.backgroundScale);
            let font=fs+"px "+item.font;
            ctx.font=font;
            ctx.fillStyle=item.textColor || defaultColors.pen;
            ctx.save();
            ctx.translate(x,y);
            let degr=item.labelRotation || 0;
            ctx.rotate(degr*Math.PI/180);
            ctx.fillText(item.label,0,0);
            ctx.restore();
        }
        resolve();
    })
}

 export async function drawAssets(ctx,map,room,player,layer){
    let assets=[];
    if (room) assets=map.assets.filter(a => a.room==room.rowKey && a.layer==layer);
    else assets=map.assets.filter(a => !a.room && a.layer==layer);
    for(let i=0;i<assets.length;i++){
        if (map.inEncounter && assets[i].room==map.selectedRoom && assets[i].name=="skull") continue; 
        if (player && !assets[i].visible) continue;
        await drawAsset(ctx,map,assets[i])
    }
    return assets.length
}

