import React, { useEffect, useState } from 'react';
import { HTTP } from '../utils/http';
import { setCampaign, setCampaigns } from '../utils/store';
import { useSelector } from 'react-redux';
import { Tile } from '../utils/tile';
import { Page } from '../layout/page';
import { Login } from './login';
import { Link, useNavigate } from 'react-router-dom';

import "./campaigntiles.scss"
import { formatDate } from '../campaign/journal/journal';
import { sendTo } from '../utils/websocket';

export function CampaignTile({campaign}){
    const {player,campaign:current}=useSelector(state => state.game);
    const navigate=useNavigate();
    let [title,setTitle]=useState("Finally reached Strumpili");
    let [text,setText]=useState("Studying a scroll of history of the realm with newly found comrades");

    useEffect(function(){
        if (campaign) HTTP.get("/api/journal/"+campaign.rowKey).then(journal => {
            journal=journal.filter(a => a.partitionKey==campaign.rowKey).sort((a,b) => b.date.localeCompare(a.date));
            if (journal.length==0) return;
            setTitle(journal[0].title);
            text=journal[0].text;
            setText(text)
            if (text.length>150) text=text.substring(0,150)+"...";
        })
    },[campaign]);


    function selectCampaign(){
        setCampaign(campaign);
        navigate("/campaign");
    }

    let role="Player";
    if (campaign.partitionKey==player.rowKey) role="Master";
    let dt=formatDate(campaign.currentDate);    
    if (!campaign.players) campaign.players=[];
    let players=campaign.players.map(p => <span key={p.rowKey} className="playerUsername">{p.username}</span>)
    let isCurrent=current?.rowKey==campaign?.rowKey;
    let styles="";
    if (isCurrent) styles="currentCampaign";
    return <Tile header={campaign.name} onClick={selectCampaign} styles={styles}>
        {isCurrent ? <span className="currentCampaignIcon"></span> : <></>}
        <div className='campaignTile' onClick={selectCampaign}>
            <p><span>Your role:</span><span>{role}</span>{role!="Master" ? <span>, Master: {campaign.owner.username}</span>: <></>}</p>
            <p><span>Current date:</span><span>{dt}</span></p>
            <h4>{title}</h4>
            <p>{text}</p>
            <p className="playerList">{players}</p>
        </div>
    </Tile>
}

export function CreateCampaignTile(){
    const [name,setName]=useState('');
    const [secret,setSectret]=useState('');
    const {player}=useSelector(state => state.game);
    
    function save(){
        alert("Ähäkutti");
        return;
        HTTP.post("/api/campaigns",{name}).then(camps => {
            setName('');
            setCampaigns(camps);
        })
    }

    function join(){
        HTTP.post("/api/campaigns/join",{secret}).then(camps => {
            setSectret('');
            setCampaigns(camps);
            let obj={campaign:secret,rowKey:player.rowKey,username:player.username,email:player.email};
            sendTo([secret],"updateCampaignPlayer",obj);
        })
    }

    return <Tile header="New Campaign">
        <div className='joinCampaign'>
            <h2>Have you been asked to join Campaign</h2>
            <p>Enter the campaign secret passed to you.</p>
            <div className='formInput'>
                <label htmlFor='secret'>Secret</label>
                <input value={secret} onChange={ev => setSectret(ev.target.value)} />
            </div>
            <div className='formButtons'>
                <input type="button" value="Join" onClick={join} />
            </div>
        </div>
        <div className="createCampaignTile">
            <h2>Are you going to be a Realm Master</h2>
            <p>Create a new campaign and ask players to join it</p>
            <div className='formInput'>
                <label htmlFor="name">Name</label>
                <input value={name} onChange={ev => setName(ev.target.value)} />
            </div>
            <div className='formButtons'>
                <input type="button" value="Save" onClick={save} />
            </div>
        </div>
    </Tile>
}
