import React, { useEffect, useState } from 'react';

import './encounter.scss';
import { useSelector } from 'react-redux';
import { characterAvatarUrl, createCharacter, createNpc } from '../character/character';

import './encounter.scss';
import { HTTP } from '../utils/http';
import { NpcButton } from '../master/npcs';
import { NpcEditor, calculateFight } from '../master/npceditor';
import { Dice } from '../dice/dicebox';
import { Map } from '../map/map';
import { MapEditor } from '../map/mapeditor';
import { Link, Outlet, useParams } from 'react-router-dom';
import { rolledForInitiative, setCurrentCharacter, setShare } from '../utils/store';
import { PlayerMap } from '../map/playermap';
import { CharacterAvatar } from './avatars';
import { CharacterCombat } from '../character/combat/charactercombat';
import { sendToCampaign } from '../utils/websocket';
import { CharacterSpells } from '../character/characterspells';
import { Skills } from '../character/skills/skills';
import { Page } from '../layout/page';
import { loadMap, saveMap, sendEncounter, setEncounter, setMapData, setMapNotes } from '../map/utils/mapstore';
import { Checkbox } from '../utils/formfields';
import { CharacterList } from '../map/encounter/characterlist';
import { OpponentList, recalculateOpponentNumbering } from '../map/encounter/opponentlist';
import { guid } from '../utils/utils';

export const encounterMainChildren=[
    {index:true, element:<EncounterList />},
    {path:':rowKey', element:<EncounterDetail />},
]


function EncounterMap()
{
    let {role}=useSelector(state => state.game);
    let {mapData,avatars,encounter}=useSelector(state => state.maps);

    let xa=avatars.map(a => ({...a}));
    return <div className="encounterMap">
        <div className="encounterMapArea">
            {mapData ? <>
              <MapEditor maxWidth={400} maxHeight={400} inEncounter={encounter} /> 
            </> : <></> }
        </div>
    </div>
}

function EncounterOptions(){
    let [name,setName]=useState("");
    let [publish,setPublish]=useState(false);
    let {campaign,player}=useSelector(state => state.game);
    let {mapData,mapNotes,encounter}=useSelector(state => state.maps);

    //if (encounter?.opponents.length) console.log("Opponent 0",encounter.opponents[0].name)

    useEffect(function(){
        setName(encounter?.name || "");
        setPublish(encounter?.publish || false);
        if (encounter && mapData && encounter.publish) {
            sendEncounter({...encounter,map:mapData});
            let obj={map:mapData.options.rowKey,notes:mapNotes};
            sendToCampaign("setMapNotes",obj);
            let target="/campaign/encounter";
            let sharedBy=player.username;
            let playerRowKey=player.rowKey;
            let itemRowKey=encounter.rowKey;
            let itemType="encounter";
            let title="Encounter";
            let imageUrl="/images/fighting-small.png";
               
            setShare({target,sharedBy,playerRowKey,itemRowKey,itemType,title,imageUrl});
        }
    },[encounter,mapData])

    /*
    useEffect(function(){
        let obj={map:mapData.options.rowKey,notes:mapNotes};
        sendToCampaign("setMapNotes",obj);
    },[mapNotes])
    */

    function save(){
        let mod={...encounter};
        mod.name=name;
        mod.publish=publish;
        if (!mod.mapRowKey) mod.map=mapData;
        else {
            let data={...mapData};
            data.options={...mapData.options};
            saveMap(mapData).then(s => {
                if(!mapData.options.rowKey) setMapData(data);
            })
        }
        HTTP.post("/api/encounters",mod).then(saved => {
            setEncounter(saved);
        })
    }

    function placeAvatars(){
        let playerAvatars=campaign.characters.map((c,index) => ({
            rowKey:c.values.rowKey,
            player:c.values.player,
            type:'player',
            image:'/assets/avatars/characters_'+String(c.values.avatar).padStart(2,'0')+".svg",
            y:-1,
            x:-1,
        }));
        let avatars=encounter.avatars.map(a => ({...a}));
        let opponents=encounter.opponents.map(o => ({...o}));
        recalculateOpponentNumbering(opponents,avatars);
        /*
        playerAvatars.forEach(a => {
            if (!avatars.find(ax => ax.rowKey==a.rowKey)) avatars.push(a)
        })
        */
        let modified={...encounter,avatars,opponents};

        setEncounter(modified);
    }

    function clearAvatars(){
        let avatars=[];
        let modified={...encounter,avatars};
        setEncounter(modified);
    }

    function changePublish(publish){
        setEncounter({...encounter,publish});
    }

    return <div className="encounterOptions">
        <h4>Options</h4>
        <input placeholder='Name' value={name} onChange={ev => setName(ev.target.value)} />
        <Checkbox label="Publish" checked={publish} onChange={v => changePublish(v)} />
        <input type="button" value="Save" onClick={save} />
        {encounter?.avatars?.length ? <input type="button" value="Clear avatars" onClick={clearAvatars} /> :
            <input type="button" value="Place avatars" onClick={placeAvatars} /> }
    </div>
}


function loadEncounter(rowKey){
    if (rowKey!="create") HTTP.get("/api/encounters/"+rowKey).then(enc => {
        console.log("Got encounter",enc)
        if (!enc.opponents) enc.opponents=[];
        if (!enc.avatars) enc.avatars=[];
        if (enc.mapRowKey){
            loadMap(enc.mapRowKey,enc.roomRowKey);
        }
        else{
            if (!enc.map.options.rowKey) enc.map.options.rowKey=guid("map");
            setMapData(enc.map);
            HTTP.get("/api/maps/"+enc.map.options.rowKey+"/notes").then(notes => {
                setMapNotes(notes);
            })
        }
        setEncounter(enc);
    })
    else{
        loadMap("new").then(map => {
            console.log("Loaded",map)
            map.options.rowKey=guid("map");
            let enc={name:'',avatars:[],opponents:[],map};
            setEncounter(enc);
        })
    }
}

export function EncounterDetail(){
    //let [encounter,setEncounter]=useState({name:'Encounter',opponents:[],avatars:[],map:new Map(480),mapRoom:0})
    const params=useParams();
    let {encounter}=useSelector(state => state.maps);
    
    useEffect(function(){
        loadEncounter(params.rowKey);
    },[])

    return <div className="encounterDetail">
        <h3>{encounter?.name || "Encounter"}</h3>
        <div className="encounterView">
            <CharacterList />
            <EncounterMap  />
            <div>
                <OpponentList />
                <EncounterOptions />
            </div>
        </div>
    </div>
}

export function EncounterList(){
    let [encounters,setEncounters]=useState([]);

    useEffect(function(){
        HTTP.get("/api/encounters").then(enc => setEncounters(enc));
    },[])

    let items=encounters.map(e => <p key={e.rowKey}><Link to={`./${e.rowKey}`}>{e.name}</Link></p>)

    return <div>
        <h3>List</h3>
        <div>
            <Link to="/campaign/encounter/create">Create a new Encounter</Link>
            {items}
        </div>
    </div>
}

export function PlayerEncounter({rowKey}){
    let {encounter}=useSelector(state => state.maps);
    console.log("Player Encounter",encounter)

    useEffect(function(){
        if (encounter) return;
        if (!rowKey) return;
        loadEncounter(rowKey)
    },[]);

    useEffect(function(){
        if (encounter && encounter.map) setMapData(encounter.map);
    },[encounter]);


    let cls="encounterDetail";
    if (!encounter) cls+=" noEncounter";
    let title=encounter?.name || "Encounter";

    return <div className={cls}>
        <h3>{title}</h3>
        <div className="encounterView">
            <CharacterList />
            {encounter ? <>
                <EncounterMap />
                <OpponentList />
            </> : <p>No encounter yet, wait for Realm Master to publish</p>}
        </div>
    </div>
}



export function EncounterMain(){
    let {role}=useSelector(state => state.game);

    return <div className="encounterMain">
        {role=="Master" ?  <Outlet /> : <PlayerEncounter />}
    </div>
}