import React, { useEffect } from 'react';


export function AcSelector({value,onChange}){

    useEffect(function(){
        if (value!=14 && value!=16 && value!=18) onChange(12); 
    },[])

    return <div className='acSelector'>
        <label>AC</label>
        <p className="acValues">
            <span className={'acValue clickable'+(value==12 ? ' acValueSelected' : '')} onClick={() => onChange(12)}>12</span>
            <span className={'acValue clickable'+(value==14 ? ' acValueSelected' : '')} onClick={()=>onChange(14)}>14</span>
            <span className={'acValue clickable'+(value==16 ? ' acValueSelected' : '')} onClick={()=>onChange(16)}>16</span>
            <span className={'acValue clickable'+(value==18 ? ' acValueSelected' : '')} onClick={()=>onChange(18)}>18</span>
        </p>
    </div>
}