import { configureStore, createSlice } from '@reduxjs/toolkit'
import { HTTP } from './http';
import { characterForCampaign } from '../character/character';
import { clearSocketListeners, sendInitCharacterData, sendInitData, sendInitPlayerData, sendToCampaign, sendToCharacter } from './websocket';
import { Map } from '../map/map';
import { clearMaps, mapSlice } from '../map/utils/mapstore';
import { getCampaignFile } from './utils';
 
const initial={
    role:'none',
    player:{},
    currentCharacter:null,
    title:"",
    theme:"index",
    campaigns:[],
    campaign:null,
    share:null,
    marchingOrder:[],
    characters:{},
    skillTests:[],
    handouts:[],
    chat:[],
    newChatMessages:[],
    socketMessages:[],
    socketHandout:null,
    socketLatest:null,
    requestInitiative:true,
    initiatives:{},
    meetingOptions:null,
    inMeeting:false,
    zoom:1,
}

export const gameSlice=createSlice({
    name:'game',
    initialState:initial,
    reducers:{
        setTitle(state,action){
            state.title=action.payload;
        },
        setUsername(state,action){
            if (state.player.username){
                state.player={...state.player,username:action.payload}
            }
        },
        setUserEmail(state,action){
            if (state.player.email){
                state.player={...state.player,email:action.payload};
            }
        },
        updatePlayer(state,action){
            let info=action.payload;
            console.log("Update player",info);
            if (info.campaign!=state.campaign?.rowKey) return;
            let existing=state.campaign.players.find(p => p.rowKey==info.rowKey);
            if (!existing) state.campaign.players=[...state.campaign.players,info];
            else{
                console.log("Update existing")
                existing.username=info.username;
                existing.inactive=info.inactive;
            }
            //state.campaign={...state.campaign};
        },
        setCurrentCharacter(state,action){
            state.currentCharacter={...action.payload};
        },
        setMeetingOptions(state,action){
            console.log("Set meeting options",action.payload);
            state.meetingOptions=action.payload;
        },
        setInMeeting(state,action){
            state.inMeeting=action.payload;
        },
        setTheme(state,action){
            state.theme=action.payload;
        },
        setShare(state,action){
            console.log("Setting share",action.payload)
            state.share=action.payload;
        },
        setMarchingOrder(state,action){
            console.log("Setting marching order",action.payload)
            state.marchingOrder=action.payload;  
        },
        setPlayer(state,action){
            console.log("Set player",action.payload)
            let p=action.payload;
            state.player=p;
            state.role="Player";
            if (state.campaign && p?.rowKey){
                let myChars=state.campaign.characters.filter(char => char.values.partitionKey==p.rowKey).map(c => c.values.rowKey);
                state.role=p.rowKey==state.campaign.partitionKey ? 'Master' : 'Player';
                console.log("SetPlayer-sendInitData",state.player?.rowKey,myChars);
                localStorage["eorplayer"]=p.rowKey;
                localStorage["eorusername"]=p.username;
                sendInitData(p,state.campaign,myChars);
            } 
            else if (state.campaign){
                clearSocketListeners();
            }

        },
        setPlayerAndCampaigns(state,action){
            console.log("Set player and camps",action.payload)
            let {player,campaign,campaigns}=action.payload;
            state.campaigns=campaigns;
            state.campaign=campaign;
            state.player=player;
            if (campaign) state.role=state.player.rowKey==state.campaign.partitionKey ? 'Master' : 'Player';
        },
        setCampaign(state,action){
            console.log("Set campaign",action.payload);
            let p=state.player.rowKey;
            let c=state.campaign?.rowKey;
            if (c){
                console.log("Now we should leave old campaign");
                clearSocketListeners();
            }
            let camp=action.payload;
            let pid=state.player.rowKey;
            if (camp) {
                localStorage["eorcampaign"]=camp.rowKey;
                let myChars=camp.characters.filter(char => char.values.partitionKey==pid).map(c => c.values.rowKey);
                if (state.player?.rowKey) {
                    console.log("SetCampaign-sendInitData",state.player?.rowKey,myChars);
                    sendInitData(state.player,camp,myChars);
                }
            }
            else localStorage.remove("eorcampaign");

            state.campaign=camp;
            if (camp) state.role=state.player.rowKey==camp.partitionKey ? 'Master' : 'Player';
            else state.role="Player";
        },
        setCampaignBasics(state,action){
            state.campaign={...state.campaign,...action.payload};
        },
        setCampaigns(state,action){
            console.log("camps",action.payload)
            state.campaigns=action.payload;
        },
        setCharacters(state,action){
            console.log("Set chars",action.payload)
            state.characters=action.payload;
        },
        addSkillTest(state,action){
            state.skillTests=[action.payload,...state.skillTests]
        },
        addSocketMessage(state,action){
            state.socketMessages=[...state.socketMessages,action.payload];
        },
        updateCharacterValues(state,action){
            let values={...action.payload};
            console.log("Updating character values",values,action);
            if (state.campaign){
                let char=state.campaign.characters.find(c => c.values.rowKey==values.rowKey);
                if (char) char.values=values;
            }
            if (state.currentCharacter?.values.rowKey==values.rowKey){
                state.currentCharacter.values=values;
                state.currentCharacter={...state.currentCharacter};
            }
        },
        updateCharacter(state,action){
            let character={...action.payload};
            if (state.campaign){
                let char=state.campaign.characters.find(c => c.values.rowKey==character.values.rowKey);
                if (char){
                    char.values=character.values;
                    char.items=character.items;
                    char.languages=character.languages;
                    char.notes=character.notes;
                    char.spellcontainers=character.spellcontainers;
                    char.spells=character.spells;
                }
                else if (character.campaign==state.campaign.rowKey){
                    console.log("Addin character")
                    state.campaign.characters=[...state.campaign.characters,character];
                }
            }
            if (state.currentCharacter?.values.rowKey==character.values.rowKey){
                state.currentCharacter.values=character.values;
                state.currentCharacter.items=character.items;
                state.currentCharacter.languages=character.languages;
                state.currentCharacter.notes=character.notes;
                state.currentCharacter.spellcontainers=character.spellcontainers;
                state.currentCharacter.spells=character.spells;
            }
        },
        setHandout(state,action){
            state.socketLatest="handout";
            state.socketHandout=action.payload;
            let existing=state.handouts.find(h => h.rowKey==action.payload.rowKey);
            if (existing) Object.assign(existing,action.payload);
            else state.handouts.push(action.payload);
        },
        setHandouts(state,action){
            state.handouts=action.payload;
        },
        addChat(state,action){
            state.chat=[...state.chat,action.payload];
            state.newChatMessages=[...state.newChatMessages,action.payload.data.from];
        },       
        updateHasChatMessages(state,action){
            let {add,character}=action.payload;
            if (add){
                state.newChatMessages=[...state.newChatMessages,character];
            }
            else {
                state.newChatMessages=state.newChatMessages.filter(m => m!=character);
            }
        },
        setRequestInitiative(state,action){
            state.requestInitiative=action.payload;
            state.initiatives={};
        },
        setInitiative(state,action){
            console.log("SetInitiative",action.payload)
            state.initiatives={...state.initiatives}
            state.initiatives[action.payload.character]=action.payload;
        },
        clearInitiative(state,action){
            delete state.initiatives[action.payload.character];
            state.initiatives={...state.initiatives};
        },
        setZoom(state,action){
            state.zoom=action.payload;
        },
        updateSpells(state,action){
            let {character,spells}=action.payload;
            let index=state.campaign.characters.findIndex(c => c.values.rowKey==character);
            if (index>=0){
                let char=state.campaign.characters[index];
                char.spells=spells;
            }
            if (state.currentCharacter.values.rowKey==character) state.currentCharacter={...state.currentCharacter,spells};
        }
    }
})


export const store=configureStore({
    reducer:{
        game:gameSlice.reducer,
        maps:mapSlice.reducer,
    }
})

const actions=gameSlice.actions;

export function setTitle(title){
    store.dispatch(actions.setTitle(title));
}

export function changeUser(rowKey,username,password){
    let obj={rowKey,username};
    if (password) obj.password=password;
    HTTP.post("/open/changeuser",obj).then(user => {
        store.dispatch(actions.setUsername(user.username));
    })
}

export function setUserEmail(email){
    store.dispatch(actions.setUserEmail(email));
}

export function setInMeeting(inMeeting){
    console.log("Set in meeting!!!",inMeeting);
    /*
    let html=document.querySelector("html");
    if (inMeeting) html.classList.add("meeting");
    else html.classList.remove("meeting");
    */
    store.dispatch(actions.setInMeeting(inMeeting));
}

export function setTheme(theme){
    console.log("Set theme",theme)
    let html=document.querySelector("html");
    html.classList=theme;
    store.dispatch(actions.setTheme(theme));
}

export async function setPlayer(p,dispatch=true) {
    //if (dispatch) store.dispatch(actions.setPlayer(p));
    let c=await HTTP.get("/api/campaigns");
    let camps=await setCampaigns(c);
    console.log("Camps",camps,p)
    camps.player=p;
    //if (!dispatch) store.dispatch(actions.setPlayerAndCampaigns(camps));
    store.dispatch(actions.setPlayer(p));
    console.log("Done set campaigns")
}

export async function setCampaigns(c,dispatch=true){
    if (dispatch) store.dispatch(actions.setCampaigns(c));
    let campaignId=localStorage["eorcampaign"];
    console.log("Got campaigns",campaignId);
    let index=0;
    if (campaignId) index=c.findIndex(ci => ci.rowKey==campaignId);
    if (c.length &&  index>=0) {
        let selected=await setCampaign(c[index],true);
        return {campaign:selected,campaigns:c};
    }
    
    return {campaign:null,campaigns:c};
}

export async function setCampaign(c,dispatch=true){
    clearMaps();
    let camp=await HTTP.get("/api/campaigns/"+c.rowKey);
    let handouts=await HTTP.get("/api/handouts");
    let order=await HTTP.get("/api/campaigns/file/marchingOrder.json");
    console.log("ORDER",order)
    if (!order) order=camp.characters.map(c => c.values.rowKey);
    camp.characters.forEach(c => {
        if (!order.includes(c.values.rowKey)) order.push(c.values.rowKey);
    })
    store.dispatch(actions.setMarchingOrder(order));
    console.log("Marching order",order)
    store.dispatch(actions.setHandouts(handouts));
    HTTP.get("/api/campaigns/file/share.json").then(share => {
        store.dispatch(actions.setShare(share));
    })
    if (dispatch){
        store.dispatch(actions.setCampaign(camp));
    }
    return camp;
}

/*
export function clearCampaign(){
    store.dispatch(actions.setCampaign(null));
    localStorage["eorcampaign"]="";
}
*/


export function updateValue(characterid,shortname,value){
    store.dispatch(actions.updateValue({characterid,shortname,value}));
}

export function setCharacters(pc){
    store.dispatch(actions.setCharacters(pc));
}

export function addSkillTest(data){
    store.dispatch(actions.addSkillTest(data));
}

export function sendChat(to,data){
    store.dispatch(actions.addChat({to:[to],command:'chat',data}));
    sendToCharacter(to,'chat',data);
}

export function receiveChat(message){
    console.log("Got chat message",message)
    store.dispatch(actions.addChat(message));
}

export function clearNewChatFrom(character){
    store.dispatch(actions.updateHasChatMessages({character,add:false}));
}

export function rolledForInitiative(initiative){
    store.dispatch(actions.setInitiative(initiative));
    sendToCampaign("rolledInitiative",initiative);
}

export function setUseEliminate(initiative){
    store.dispatch(actions.setInitiative(initiative));
}

export function updateCharacterValues(values){
    console.log("UpdateCharacterValues",values.name)
    store.dispatch(actions.updateCharacterValues(values));
    sendToCampaign("updateCharacterValues",values);
    HTTP.post("/api/characters",values).then(cv => {
        console.log("Saved character values",cv)
    })
}

export function setCurrentCharacter(char){
    store.dispatch(actions.setCurrentCharacter(char));
}

export function saveCurrentCharacterValues(char){
    HTTP.post("/api/characters",char.values).then(cv => {
        console.log("Saved character values",cv)
    })
    store.dispatch(actions.setCurrentCharacter(char));
    let state=store.getState();
    console.log("State",state)
    if (state.game.campaign){
        sendToCampaign("updateCharacterValues",char.values);
    }
}

export function updateCampaignPlayer(info){
    store.dispatch(actions.updatePlayer(info));
    sendToCampaign("updateCampaignPlayer",info);
}

export function clearInitiative(rowKey){
    store.dispatch(actions.clearInitiative({character:rowKey}));
}

window.setMeetingParticipantCount=function(n){
    console.log("Meeting has",n,"participants");
    if (n>=1) document.body.classList.add("meetingHasParticipants");
    else document.body.classList.remove("meetingHasParticipants");
}

window.setMeetingOptions=function(options){
    console.log("setMeetingOptions",options)
    options=JSON.parse(JSON.stringify(options))
    store.dispatch(actions.setMeetingOptions({...options}));
}

export function setShare(share){
    store.dispatch(actions.setShare(share));
    sendToCampaign("setShare",share);
    let fileName="share.json";
    HTTP.post("/api/campaigns/file",{data:share,fileName}).then(r => {
        console.log("Saved",r);
    })
}

function shareFromSocket(share){
    store.dispatch(actions.setShare(share))
}

export function testZoom(){
    let w=window.innerWidth;
    let h=window.innerHeight;
    let hz=h/800;
    let wz=w/1400;
    let minZoom=hz<wz ? hz : wz;
    if (minZoom<1) minZoom=1;
    store.dispatch(actions.setZoom(minZoom));
}

export function setMarchingOrder(order){
    store.dispatch(actions.setMarchingOrder(order));
    sendToCampaign("setMarchingOrder",order);
    let fileName="marchingOrder.json";
    HTTP.post("/api/campaigns/file",{data:order,fileName}).then(r => {
        console.log("Saved",r);
    })
}

export function saveCampaignBasics(camp){
    HTTP.put("/api/campaigns",camp).then(camp => {
        delete camp.players;
        store.dispatch(actions.setCampaignBasics(camp));
        sendToCampaign("setCampaignBasics",camp);
    })
}

export function updateSpells(spells){
    store.dispatch(actions.updateSpells(spells));
}

export function addCharacterToCampaign(character){
    sendToCampaign("updateCharacter",character);
    store.dispatch(actions.updateCharacter(character));
}

window.setInMeeting=setInMeeting;

export function processSocketMessage(message){
    //if (message.command.startsWith("rtc_")) document.getElementById("meetingFrame").contentWindow.processRtcSocketMessage(message);
    //if (message.command=="rtc_join") console.log("RTCJoin",message.data.from);
    if (message.command.startsWith("rtc_")) return;
    console.log("Processing",message.command)
    
    if (message.command=="rtc_bye") console.log("RTC_BYE",message.data.from);
    else if (message.command=="setCampaignBasics") store.dispatch(actions.setCampaignBasics(message.data));
    else if (message.command=="updateCharacter") store.dispatch(actions.updateCharacter(message.data))
    else if (message.command=="updateCampaignPlayer") store.dispatch(actions.updatePlayer(message.data))
    else if (message.command=='skilltest') addSkillTest(message.data);
    else if (message.command=="setShare") shareFromSocket(message.data);
    else if (message.command=="setMarchingOrder") store.dispatch(actions.setMarchingOrder(message.data));
    else if (message.command=="setSpells") updateSpells(message.data);
    else if (message.command=="handout") store.dispatch(actions.setHandout(message.data))
    else if (message.command=='chat') receiveChat(message)
    else if (message.command=="updateCharacterValues") store.dispatch(actions.updateCharacterValues(message.data))
    else if (message.command=="requestInitiative") store.dispatch(actions.setRequestInitiative(true))
    else if (message.command=="rolledInitiative") store.dispatch(actions.setInitiative(message.data));
    else store.dispatch(actions.addSocketMessage(message));
}