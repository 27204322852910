import React from 'react';
import { useEffect, useState } from 'react';

import './app.scss'; 
import { HTTP } from './utils/http';
import { setMap, setPlayer, testZoom } from './utils/store';
import { useSelector } from 'react-redux';
import { clearSocketListeners, initSocket, socketForPlayer } from './utils/websocket';

import { Nav } from './layout/nav';
import { Aside } from './layout/aside';
import { Main } from './layout/main';
import { Footer } from './layout/footer';
import { Header } from './layout/header';
import { DiceBox } from './dice/dicebox';
import { Welcome } from './layout/welcome';
import { joinRtc, Meeting } from './webrtc/webrtc';


function App() {
    let [completed,setCompleted]=useState(false);

    //let {campaign,player}=useSelector(state => state.game);

    useEffect(() => {
        console.log("Init app");
        initSocket();
        testZoom();
        //rescaleMain();
        
        HTTP.get("/open/login").then(async player => {
            console.log("Got player",player);
            await setPlayer(player,false);
            setCompleted(true);
        })
        return () => clearSocketListeners();
    },[])

    return <>
        <Nav />
        {completed ? <Main /> : <Welcome />}
        <Header />
        <Footer />
        <DiceBox canvasId='app-canvas' />
    </>
}

export default App;
