import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { createNpc } from '../character/character';

import { HTTP } from '../utils/http';
import { NpcButton } from '../master/npcs';
import { NpcEditor, calculateFight } from '../master/npceditor';
import { Dice } from '../dice/dicebox';
import { rolledForInitiative } from '../utils/store';
import { sendToCampaign } from '../utils/websocket';

import './opponentlist.scss'
import { Checkbox } from '../utils/formfields';
import { guid } from '../utils/utils';
import { setEncounter } from '../map/utils/mapstore';

function NpcList({npcs,onAdd}){

    let rows=npcs.map(n => <NpcButton key={n.rowKey} npc={n} onSelect={onAdd}/>)

    return <div className="addNpcDialog">
        <div className="addNpcList">
            {rows}
        </div>
    </div>

}

export function recalculateOpponentNumbering(opponents,avatars){
    avatars.forEach(a => a.inUse=(a.type=='player'));
    for(let i=0,start=1;i<opponents.length;i++){
        let op=opponents[i];
        let numbers=''+(start);
        if (op.count>1) numbers+="-"+(start+op.count-1);
        for(let j=0;j<op.count;j++){
            let number=start+j;
            let rowKey=op.rowKey+"-"+number;
            let existing=avatars.find(a => a.rowKey==rowKey);
            let visible=true;
            if (j<op.group.length) visible=op.group[j].visible;
            if (!op.visible) visible=false;
            if (existing) {
                existing.inUse=true;
                existing.visible=visible;
                existing.image='/assets/monsteravatars/monsters'+String(op.avatar).padStart(2,'0')+".svg";
            }
            else{
                let image='/assets/monsteravatars/monsters'+String(op.avatar).padStart(2,'0')+".svg";
                let avatar={rowKey,image,avatarId:op.avatar,number,x:-1,y:-1,visible,type:'npc',inUse:true}
                avatars.push(avatar);
            } 
        }
        op.countStart=start;
        op.numbers=numbers;
        start+=op.count;
    }
    let index=avatars.findIndex(a => !a.inUse);
    while(index>=0){
        avatars.splice(index,1);
        index=avatars.findIndex(a => !a.inUse);
    }
}


function OpponentDetail({opponent,onClose}){
    let {encounter}=useSelector(state => state.maps);
    let current=encounter.opponents.find(o => o.rowKey==opponent.rowKey);
    let character=createNpc(current);
    let [count,setCount]=useState(current.count || 1);
    let [group,setGroup]=useState([]);
    let [visible,setVisible]=useState(!(current.visible===false))

    useEffect(function(){
        let group=opponent.group || [];
        group=[...group];
        let start=group.length;
        console.log("XXX",group,start,current.count||1);
        for(let i=start;i<(current.count||1);i++){
            let g={visible:true,hitpoints:character.values.hitpoints};
            group.push(g);
        }
        setGroup(group)
        setCount(current.count || 1);
    },[opponent])

    console.log("Opponent",current.name)

    function saveOpponent(npcValues){
        let opponents=encounter.opponents.map(o => ({...o}));
        let avatars=encounter.avatars?.map(a => ({...a})) || [];
        let mod={...encounter,opponents,avatars};
        let existing=mod.opponents.find(o => o.rowKey==npcValues.rowKey);
        console.log("Save opponent",existing,npcValues);
        if (existing) Object.assign(existing,npcValues,{count,group,visible});
        recalculateOpponentNumbering(mod.opponents,mod.avatars);
        setEncounter(mod);
        onClose();
    }

    function deleteOpponent(){
        let opponents=encounter.opponents.map(o => ({...o}));
        let avatars=encounter.avatars?.map(a => ({...a})) || [];
        let index=opponents.findIndex(o => o.rowKey==opponent.rowKey);
        if (index>=0) opponents.splice(index,1);
        let mod={...encounter,opponents,avatars};
        recalculateOpponentNumbering(mod.opponents,mod.avatars);
        setEncounter(mod);
        onClose();
    }

    function changeGroup(index,g){
        group=[...group];
        group[index]=g;
        setGroup(group);
    }    

    function GroupRow({index,countStart,item}){

        return <div className="opponentGroupItem">
            <div>{countStart+index}</div>
            <div><label>HP</label><input type="number" value={item.hitpoints} onChange={ev => changeGroup(index,{...item,hitpoints:Number(ev.target.value)})} /></div>
            <div><Checkbox label="visible" checked={item.visible} onChange={ev => changeGroup(index,{...item,visible:ev})} /></div>
        </div>
    }

    let grows=[];
    for(let i=0;i<count;i++){
        let g={visible:true,hitpoints:character.values.hitpoints};
        if (i<group.length) g=group[i];
        grows.push(<GroupRow key={i} countStart={character.values.countStart} index={i} item={g} />);
    }

    return <div className="opponentDetail">
            <NpcEditor character={character} save={saveOpponent} onClose={onClose} />
            <div className="opponentExtra">
                <div>
                    <label>Count</label>
                    <input type="number" value={count} onChange={ev => setCount(Number(ev.target.value))} />
                </div>
                <Checkbox label="Visible" checked={visible} onChange={v => setVisible(v)} />
                <input type="button" value="Save" onClick={() => saveOpponent(character.values)} />
                <input type="button" value="Delete" onClick={deleteOpponent} />
            </div>
            <div className='opponentGroup'>
                {grows}
            </div>
    </div>
}


export function OpponentList(){
    let {role}=useSelector(state => state.game);
    let {mapData,encounter}=useSelector(state => state.maps);
    let [npcs,setNpcs]=useState([]);
    let [showNpcList,setShowNpcList]=useState(false);
    let [showNpcDetail,setShowNpcDetail]=useState(false);

    //if (encounter?.opponents.length) console.log("Opponent 0 count",encounter.opponents[0].count)

    useEffect(function(){
        HTTP.get("/api/npcs").then(npcs => {
            setNpcs(npcs);
        })
    },[])

    function addOpponent(npc){
        let opponents=encounter.opponents.map(o => ({...o}));
        let avatars=encounter.avatars?.map(a => ({...a})) || [];
        let mod={...encounter,opponents,avatars};
        //let existing=mod.opponents.find(o => o.rowKey==npc.rowKey);
        //if (existing) existing.count++;
        //else 
        let rowKey=guid("opp");
        mod.opponents.push({...npc,rowKey,count:1,visible:true,group:[{visible:true,hitpoints:npc.hitpoints}],damageObject:{}})
        recalculateOpponentNumbering(mod.opponents,mod.avatars);
        setEncounter(mod);
    }

    function opponentSelected(npc){
        if (role!="Master") return;
        console.log("Opponent selcted")
        setShowNpcList(false);
        setShowNpcDetail(npc);
    }

    function rollInitiative(){
        sendToCampaign("requestInitiative",{})
        Dice.roll("1d20").then(result => {
            let opponents=encounter.opponents.map(o => ({...o}));
            let mod={...encounter,opponents};
            mod.opponents.forEach(o => {
                let {ag,br}=calculateFight(o);
                o.initiative=result.value+(ag>br?ag:br);
                rolledForInitiative({character:o.rowKey,value:o.initiative})
            })
            setEncounter(mod);
        })
    }

    let filtered=encounter?.opponents?.filter(o => o.visible || role=="Master");
    let rows=filtered?.map((o,index) => <NpcButton key={o.rowKey+'-'+index} npc={o} onSelect={opponentSelected}/>)
    
    let cls="opponentList "+(role=="Master" ? "roleMaster" : "rolePlayer");
    return <div className={cls}>
        <h4>Opponents</h4>
        {role=="Master" ? <>
            <input type="button" value="Add" onClick={() => setShowNpcList(!showNpcList)} />
            <input type="button" value="Initiative" onClick={rollInitiative} />
        </> : <></> }
        {rows ? <div>{rows}</div> : <></> }
        {showNpcList ? <NpcList npcs={npcs} onAdd={addOpponent} /> : <></>}
        {showNpcDetail ? <OpponentDetail opponent={showNpcDetail} onClose={() => setShowNpcDetail(false)} /> : <></>}
    </div>
}
