import React, { useEffect, useRef, useState } from 'react';
import { MapEditor } from './mapeditor';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import { HTTP } from '../utils/http';
import { Map } from './map';
import { drawMap } from './renderer';

import './mapsroute.scss'
import { ImageSelector } from '../utils/imageeditor';
import { useSelector } from 'react-redux';
import { getImageUrl } from '../utils/utils';
import { loadImage } from './utils/images';
import { calculateOptionValues, calculatePixels } from './points/pointutils';
import { loadMap, loadMaps } from './utils/mapstore';
import { findOpeningEndpoints } from './lines/linerenderer';

export const mapsRouteChildren=[
    {path:'',element: <MapSelector />},
    {path:':map',element:<MapContainer />}
]


export function MapContainer(){
    let {role}=useSelector(state => state.game);
    let params=useParams();
    //let [map,setMap]=useState(null);
    let {mapData}=useSelector(state => state.maps);

    //console.log("Container",mapData)
    let rowKey=mapData?.options.rowKey;
    
    useEffect(function(){
        let mapId=params.map;
        loadMap(mapId);
    },[params])

    return <div className="mapContainer">
        {rowKey==params.map ? <MapEditor maxWidth={800} toolsVisible={true} avatars={[]}/>  :
          <p>Loading....</p>}
    </div>
}

export function CreateMapOptions({map,onChange}){
    let {player}=useSelector(state => state.game);
    let [selectBkr,setSelectBkr]=useState(false);
    let [selectBkr2,setSelectBkr2]=useState(false);
    let options=map.options;
    let [name,setName]=useState(map.options.name || "");
    const navigate=useNavigate();
    let [modified,setModified]=useState(false);

    function valueChanged(ev,name,isNumber=false){
        if (isNumber && !Number(ev.target.value)) return;
        options[name]=isNumber ? Number(ev.target.value) : ev.target.value;
        if (name!="name") onChange(options)
    }

    async function imageChanged(name){
        console.log("ImageChanged")
        if (name) {
            options.backgroundImageUrl=getImageUrl(player.rowKey,name);
            let imageData=await loadImage(options.backgroundImageUrl);
            let img=imageData.img;
            if (!img) return;
            console.log("loaded",img)
            options.imageWidth=img.width;
            options.imageHeight=img.height;
            calculateOptionValues(options);
            onChange(options);
            setModified(true);
        }
        setSelectBkr(false);
    }

    async function imageChanged2(name){
        if (name) {
            options.backgroundImageUrl2=getImageUrl(player.rowKey,name);
            let imageData=await loadImage(options.backgroundImageUrl2);
            let img=imageData.img;
            if (!img) return;
            console.log("loaded",img)
            calculateOptionValues(options);
            onChange(options);
            setModified(true);
        }
        setSelectBkr2(false);
    }

    let bkrImage="Select";
    if (options.backgroundImageUrl){
        let parts=options.backgroundImageUrl.split("/");
        bkrImage=parts[parts.length-1];
    }

    function save(){
        if (name!=options.name) modified=true;
        map.options.name=name;
        if (modified) HTTP.post("/api/maps",map).then(resp => {
            map.options.rowKey=resp.rowKey;
            map.options.partitionKey=resp.partitionKey;
            console.log("saved",map)
            navigate('./'+map.options.rowKey);
        })
        else navigate('./'+map.options.rowKey);
    }

    let saveDisabled=!name.length;
    let heightDisabled=!!options.backgroundImageUrl;
    let saveText=options.rowKey ? "Go" : "Create";
    let title=options.rowKey ? options.name : "New map";
    return <div className="newMapOptions">
        <h4>{title}</h4>
        <input value={name} onChange={ev => setName(ev.target.value)} />
        <input value={options.mapWidth} onChange={ev => valueChanged(ev,'mapWidth',true)} />
        <input value={options.mapHeight} disabled={heightDisabled} onChange={ev => valueChanged(ev,'mapHeight',true)} />
        <input type="button" value={bkrImage} onClick={() => setSelectBkr(true)} />
        {selectBkr ? <ImageSelector path={player.rowKey} onClose={imageChanged} /> : <></> }
        {options.backgroundImageUrl ? <>
            <input type="button" value="bkrImage2" onClick={() => setSelectBkr2(true)} />
            {selectBkr2 ? <ImageSelector path={player.rowKey} onClose={imageChanged2} /> : <></> }
            </> : <></> }
        <input type="button" disabled={saveDisabled} value={saveText} onClick={save} />
    </div>
}

export function MapButton({rowKey,onLoaded,onClick}){
    let {player,role}=useSelector(state => state.game);
    let [map,setMap]=useState(null);
    let canvasRef=useRef(null);
    const navigate=useNavigate();

    useEffect(function(){
        if (!rowKey) return;
        HTTP.get("/api/maps/"+rowKey).then(jmap => {
            let map=Map.fromJson(jmap);
            map.options.showGrid=false;
            let ws=120/map.options.canvasWidth;
            let hs=120/map.options.canvasHeight;
            map.options.backgroundScale=(ws>hs ? ws : hs);
            calculateOptionValues(map.options);
            map.points.forEach(p => calculatePixels(p,map.options));
            map.objects.forEach(o => {
                if (o.lineType!="curve") o.lineType="solid";
            })
            map.openings.forEach(o => findOpeningEndpoints(map,o));
            setMap(map);
        })
    },[]);

    useEffect(function(){
        draw();
    },[map])

    function draw(){
        let canvas=canvasRef.current;
        if (!canvas || !map) return
        let json=JSON.stringify(map);
        let jsonMap=JSON.parse(json);
        let pmap=Map.fromJson(jsonMap);
        pmap.prepareForDraw(role!="Master");
        const ctx = canvas.getContext("2d");
        console.log("Button",pmap.options.name,pmap)
        drawMap(pmap,"bw",player.rowKey,[],[]).then(c => {
            ctx.drawImage(c,0,0);
        });
    }

    function buttonClicked(){
        console.log("MapButton clicked",rowKey)
        navigate('./'+rowKey);
    }

    return <div className="mapButton" onClick={buttonClicked} >
            <canvas ref={canvasRef} width={120} height={120}/>
            <div className='mapName'>
                <p>{map?.options?.name || 'Unnamed map'}</p>
            </div>
    </div>
}

export function MapSelector(){
    //let [maps,setMaps]=useState([]);
    let {maps}=useSelector(state => state.maps);
    let {role}=useSelector(state => state.game);
    const canvasRef=useRef(null);
    let [selected,setSelected]=useState(new Map());
    let [upd,forceUpdate]=useState(new Date());
    const navigate=useNavigate();

    /*
    useEffect(function(){
        HTTP.get("/api/maps").then(maps => {
            setMaps(maps);
        })
    },[])
    */

    function  draw(){
        console.log("selected",selected)
        let designCanvas=canvasRef.current;
        if (designCanvas){
            let json=JSON.stringify(selected);
            let jsonMap=JSON.parse(json);
            let dmap=Map.fromJson(jsonMap);
            let ctx=designCanvas.getContext("2d");
            drawMap(dmap,"bw",0,[],[]).then(c => {
                ctx.drawImage(c,0,0);
            });
        }
    }

    useEffect(draw,[selected,upd])

    function optionsChanged(options){
        selected.options=options;
        forceUpdate(new Date());
    }

    function loadMap(m){
        HTTP.get("/api/maps/"+m.rowKey).then(jmap => {
            let map=Map.fromJson(jmap);
            console.log("loaded",map)
            setSelected(map);
        })
    }

    function createMap(){
        let map=new Map();
        map.options.name="Unnamed map";
        HTTP.post("/api/maps",map).then(resp => {
            map.options.rowKey=resp.rowKey;
            map.options.partitionKey=resp.partitionKey;
            console.log("saved",map)
            navigate('./'+map.options.rowKey);
        })

    }

    let sorted=maps.filter(m => m.name=="Garrison").sort((a,b) => a.name.localeCompare(b.name));
    let  buttons=maps.map(m => <MapButton key={m.rowKey} rowKey={m.rowKey} />); 

    return <div>
        <h3>Maps</h3>
        <div className="mapSelectorContent">
            <div className="mapSelectorList">
                {role=="Master" ? <div className="newMapButton clickable" onClick={createMap}>
                    <p>New map</p>
                </div> : <></> }
                {buttons}
            </div>
        </div>
    </div>
}

export function MapsRoute(){

    useEffect(function(){
        loadMaps();
    },[])

    return <div className='mapsRoute'>
        <Outlet />
    </div>
}