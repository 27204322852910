import React from 'react';

import './pointoptions.scss';
import { useSelector } from 'react-redux';
import { AssetOptions } from '../assets/assetoptions';
import { setMapData } from '../utils/mapstore';

export function PointOptions({map}){
    let {clicked}=useSelector(state => state.maps);
    let {line,courtour,room,segment}=clicked;
    let object=line||courtour||room;

    function addCorner(){
        map.addCorner();
        setMapData(map);
    }

    function addGate(){
        map.createGate();
        setMapData(map);
    }

    return <div className="pointOptions">
        <h3>Point</h3>
        {segment ? <div>
            <input type="button" value="Add corner to object" onClick={addCorner} />
            <input type="button" value="Add gate" onClick={addGate} />
        </div> : <></>}
        {!segment ? <AssetOptions map={map} /> :<></>}
    </div>
}
