import React from 'react';
import { useSelector } from 'react-redux';

import './header.scss';

export function Header(){
    const {player,campaign,title:subtitle,zoom}=useSelector(state => state.game);

    let title="Edge of Realm";
    if (campaign) title=campaign.name;

    return <header className="view" style={{zoom}}>
        <h1>{player.partitionKey ? player.username+" at " : ''}{title}</h1>
        {subtitle ? <h2>{subtitle}</h2> : <></>}
    </header>
}