import React, { useEffect } from 'react';
import { PlayerEncounter } from '../encounter/encounter';
import { HandoutDetail } from '../handouts/handouts';
import { useSelector } from 'react-redux';
import { MapEditor } from '../map/mapeditor';
import { loadMap } from '../map/utils/mapstore';

export function ShareView(){
    let {share}=useSelector(state => state.game);
    let {mapData}=useSelector(state => state.maps);
    if (!share) share={};
    let {target,imageUrl,sharedBy,title,itemType,itemRowKey}=share;

    useEffect(function(){
        if (itemType=="map"){
            loadMap(itemRowKey);
        }
    },[share])

    return <div className="shareView">
        {itemType=="encounter" ? <PlayerEncounter rowKey={itemRowKey} /> :
         itemType=="handout" ? <HandoutDetail rowKey={itemRowKey} noButtons={true} /> : 
         itemType=="map" && mapData?.options.rowKey==itemRowKey ? <MapEditor maxWidth={800} /> :
         itemType=="image" ? <div className="sharedImage"><img src={imageUrl} /></div> :
         <div className="nothingShared"><p>Nothing shared</p></div>
        }
    </div>
}