import React, { useEffect } from 'react';

export function DamageSelector({value,onChange,forArrows=false}){

    useEffect(function(){
        if (!['1d4','1d6','1d8','1d10'].includes(value)) onChange("1d4"); 
    },[])

    return <div className="damageSelector">
        <label>Damage</label>
        <p className="damageValues">
            <span className={'damageValue damageValueD4 clickable'+(value=='1d4' ? ' damageValueSelected' : '')} onClick={() => onChange("1d4")}></span>
            <span className={'damageValue damageValueD6 clickable'+(value=='1d6' ? ' damageValueSelected' : '')} onClick={()=>onChange("1d6")}></span>
            {!forArrows ? <>
                <span className={'damageValue damageValueD8 clickable'+(value=='1d8' ? ' damageValueSelected' : '')} onClick={()=>onChange("1d8")}></span>
                <span className={'damageValue damageValueD10 clickable'+(value=='1d10' ? ' damageValueSelected' : '')} onClick={()=>onChange("1d10")}></span>
            </> : <></> }               
        </p>
    </div>
}