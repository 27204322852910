import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HTTP } from '../utils/http';
import { getImageUrl } from '../utils/utils';

import './images.scss';
import { setShare } from '../utils/store';

function ImageItem({filename}){
    let {player}=useSelector(state => state.game);

    let src=getImageUrl(player.rowKey,filename);

    function onShare(){
        let sharedBy=player.username;
        let playerRowKey=player.rowKey;
        let itemRowKey="";
        let itemType="image";
        let title="Image";
        let imageUrl=src;
        setShare({sharedBy,playerRowKey,itemRowKey,itemType,title,imageUrl})
    }

    return <div className="imageItem">
        <img src={src} />
        <div className='imageOptionsHolder'>
            <div className="imageOptions">
                <p>{filename}</p>
                <input type="button" value="Share" onClick={onShare} />
            </div>
        </div>
    </div>
}

export function ImageList(){
    let {player}=useSelector(state => state.game);
    let [files,setFiles]=useState([]);

    useEffect(function(){
        let path=player.rowKey;
        HTTP.get("/api/images/"+path).then(files => setFiles(files));
    },[]);

    let images=files.map((f,index) => <ImageItem key={'img-'+index} filename={f} />) 

    console.log("Files",files);
    return <div className="imageListContainer">
        <h3>Images</h3>
        <div className="imageList">
            {images}
        </div>
    </div>
}