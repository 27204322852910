import { HTTP } from "../utils/http";
import { spellList } from '../articles/articleutils';
import { guid } from "../utils/utils";

const raw=[
    {type:'attribute',shortname:'Dex',name:'Dexterity',field:'dexterity',value:-1},
    {type:'attribute',shortname:'Str',name:'Strength',field:'strength',value:-1},
    {type:'attribute',shortname:'Con',name:'Constitution',field:'constitution',value:-1},
    {type:'attribute',shortname:'Int',name:'Intelligence',field:'intelligence',value:-1},
    {type:'attribute',shortname:'Wis',name:'Wisdom',field:'wisdom',value:-1},
    {type:'attribute',shortname:'Cha',name:'Charisma',field:'charisma',value:-1},
    {type:'skill',shortname:'Acrob',name:'Acrobatics',field:'acrobatics',value:0,baseAttribute:'dexterity',baseValue:0},
    {type:'skill',shortname:'Awa',name:'Awaraness',field:'awaraness',value:0,baseAttribute:'wisdom',baseValue:0},
    {type:'skill',shortname:'Bush',name:'Bushcraft',field:'bushcraft',value:0,baseAttribute:'wisdom',baseValue:0},
    {type:'skill',shortname:'Brut',name:'Brute Force',field:'bruteforce',value:0,baseAttribute:'strength',baseValue:0},
    {type:'skill',shortname:'Chnt',name:'Chant',field:'chant',value:0,baseAttribute:'wisdom',baseValue:0},
    {type:'skill',shortname:'Chrm',name:'Charm',field:'charm',value:0,baseAttribute:'charisma',baseValue:0},
    {type:'skill',shortname:'End',name:'Endurance',field:'endurance',value:0,baseAttribute:'constitution',baseValue:0},
    {type:'skill',shortname:'Erud',name:'Erudition',field:'erudition',value:0,baseAttribute:'intelligence',baseValue:0},
    {type:'skill',shortname:'Inv',name:'Investigation',field:'investigation',value:0,baseAttribute:'intelligence',baseValue:0},
    {type:'skill',shortname:'Medi',name:'Medicine',field:'medicine',value:0,baseAttribute:'wisdom',baseValue:0},
    {type:'skill',shortname:'SoH',name:'Sleight of Hand',field:'sleightofhand',value:0,baseAttribute:'dexterity',baseValue:0},
    {type:'skill',shortname:'Sorc',name:'Sorcery',field:'sorcery',value:0,baseAttribute:'intelligence',baseValue:0},
    {type:'skill',shortname:'Stlth',name:'Stealth',field:'stealth',value:0,baseAttribute:'dexterity',baseValue:0},
    {type:'skill',shortname:'Tink',name:'Tinkering',field:'tinkering',value:0,baseAttribute:'dexterity',baseValue:0},
    {type:'skill',shortname:'Will',name:'Will',field:'will',value:0,baseAttribute:'wisdom',baseValue:0},
    {type:'skill',shortname:'Illus',name:'Illusion',field:'illusion',value:-1,baseAttribute:'intelligence',baseValue:-1},
    {type:'skill',shortname:'Domin',name:'Domination',field:'domination',value:-1,baseAttribute:'charisma',baseValue:-1},
    {type:'skill',shortname:'Necr',name:'Necromancy',field:'necromancy',value:-1,baseAttribute:'wisdom',baseValue:-1},
    {type:'skill',shortname:'Anem',name:'Animal Empathy',field:'animalempathy',value:-1,baseAttribute:'charisma',baseValue:-1},
    {type:'reputation',shortname:'Chaotic',name:'Chaotic',field:'chaotic',value:0},
    {type:'reputation',shortname:'Lawful',name:'Lawful',field:'lawful',value:0},
    {type:'reputation',shortname:'Selfish',name:'Selfish',field:'selfish',value:0},
    {type:'reputation',shortname:'Selfless',name:'Selfless',field:'selfless',value:0},
    {type:'hd',shortname:'HD',name:'Hit Dice',field:'hd',value:0},
    {type:'fight',shortname:'Fight',name:'Fight',field:'fight',value:0},
    {type:'basic',shortname:'Name',name:'Name',field:'name',value:''},
    {type:'basic',shortname:'Profession',name:'Profession',field:'profession',value:''},
    {type:'basic',shortname:'Age',name:'Age',field:'age',value:0},
    {type:'basic',shortname:'Sex',name:'Sex',field:'sex',value:''},
    {type:'basic',shortname:'Deities',name:'Deities',field:'deities',value:''},
    {type:'basic',shortname:'Ethnicity',name:'Ethnicity',field:'ethnicity',value:''},
    {type:'basic',shortname:'HP',name:'Hit Points',field:'hitpoints',value:0},
    {type:'basic',shortname:'Dmg',name:'Damage taken',field:'damageTaken',value:0},
    {type:'basic',shortname:'Fat',name:'Fatigue',field:'fatigue',value:''},
    {type:'basic',shortname:'campaign',name:'Campaign',field:'campaign',value:''},
    {type:'basic',shortname:'player',name:'player',field:'player',value:''},
    {type:'basic',shortname:'username',name:'Player',field:'username',value:''},
    {type:'basic',shortname:'SP',name:'Silver pieces',field:'silverpieces',value:0},
    {type:'basic',shortname:'GP',name:'Gold pieces',field:'goldpieces',value:0},
    {type:'basic',shortname:'Gems',name:'Gems',field:'gems',value:0},
    {type:'basic',shortname:'Healing kit',name:'Healing kit',field:'healingkit',value:0},
    {type:'basic',shortname:'Rations',name:'Rations',field:'rations',value:0},
    {type:'basic',shortname:'Avatar',name:'Avatar',field:'avatar',value:1},
    {type:'basic',shortname:'type',name:'NPC type',field:'type',value:'pc'},
    {type:'basic',shortname:'Image',name:'Image',field:'image',value:''},
    {type:'basic',shortname:'Status',name:'Status',field:'status',value:'new'},
    {type:'basic',shortname:'HP rolled',name:'Hit points rolled',field:'hitpointsrolled',value:0},
    {type:'basic',shortname:'Dev rolls',name:'Develompent rolls available',field:'devrolls',value:0},
    {type:'basic',shortname:'TempHP',name:'Temporary hitpoints',field:'temporaryHitpoints',value:0},
    {type:'basic',shortname:'TempBonus',name:'Temporary skill bonus',field:'temporarySkillBonus',value:0},
    {type:'basic',shortname:'rowKey',name:'rowKey',field:'rowKey',value:''},
    {type:'basic',shortname:'partitionKey',name:'partitionKey',field:'partitionKey',value:''},
];

export const fieldToValue=field => raw.find(r => r.field==field);
export const skillDefs=raw.filter(r => r.type=='skill');

export function shortNameToField(sn){
    let val=raw.find(r => r.shortname==sn);
    if (val) return val.field;
    return '';
}

const mystic={
    awe:"Awe",
    etherealness:"Eth",
    mysticform:"Mfrm",
    possession:'Pos',
    regeneration:'Reg',
    teleport:'Tel',
    touchofdeath:'ToD',
}

export function fieldToShortname(f){
    if (f=="damageBonus") return "Dam";
    if (f=="defence") return "Def";
    let val=raw.find(r => r.field==f);
    if (val) return val.shortname;
    val=mystic[f];
    if (val) return val;
    return f.substring(0,3);
}

export const weapons=[
    {name:'Club',category:'Weapon',price:0,damage:'1d4',enc:'bulky'},
    {name:'Staff',category:'Weapon',price:0,damage:'1d4',enc:'bulky'},
    {name:'Sling',category:'Weapon',price:0,damage:'1d4',range:'30/60/120',enc:'bulky'},
    {name:"Hunter's Bow",category:'Bow',price:0,damage:'1d4',range:'40/80/160',enc:'bulky'},
    {name:'Dagger',category:'Weapon',price:5,damage:'1d4',enc:'light'},
    {name:'Enforced Staff',category:'Weapon',price:10,damage:'1d6',enc:'bulky'},
    {name:'Axe',category:'Weapon',price:30,damage:'1d6',range:'10/20/30',enc:'bulky'},
    {name:'Short Sword',category:'Weapon',price:50,damage:'1d6',enc:'bulky'},
    {name:'Spear',category:'Weapon',price:20,damage:'1d6',range:'20/40/60',enc:'bulky'},
    {name:'Short bow',category:'Bow',price:35,damage:'',range:'60/120/360',enc:'bulky'},
    {name:'Crossbow',category:'Bow',price:50,damage:'',range:'90/180/360',enc:'bulky'},
    {name:'Military Long bow',category:'Bow',price:-1,damage:'',range:'120/240/480',enc:'bulky'},
    {name:'Military Weapon 1H',category:'Weapon',price:-1,damage:'1d8',enc:'bulky'},
    {name:'Military Weapon 2H',category:'Weapon',price:-1,damage:'1d10',enc:'bulky'},
]

export const arrows=[
    {name:'Hunting arrows',category:'Arrows',amount:6,price:0,damage:'1d4',enc:'bulky',description:'You have a quiver that holds 12 arrows'},
    {name:'Strong arrows',category:'Arrows',amount:6,price:10,damage:'1d6',enc:'bulky',description:'You have a quiver that holds 12 arrows'},
]

export const items=[
    {name:'Clothing',category:'Other',price:20,location:'worn',enc:'light',description:'Includes boots,belt, some pouches and short strings'},
/*    {name:"Healer's Kit",price:0,location:'beltpouch',enc:'light'}, */
    {name:"Cloak",category:'Other',price:5,location:'worn',enc:'light',description:'Some protection against bad weather'},
    {name:"Chalk",category:'Other',price:0.25,location:'beltpouch',enc:'light'},
    {name:"Pen and Ink",category:'Other',price:1,location:'beltpouch',enc:'light'},
    {name:"Tinder box",category:'Other',price:1,location:'beltpouch',enc:'light'},
    {name:"Candle",category:'Other',price:0.25,location:'beltpouch',enc:'light'},
    {name:"Tinkering tools",category:'Other',price:50,location:'beltpouch',enc:'light',description:'Needles,pins,rasp,serrated knife, needed for lockpicking'},
    {name:"Waterskin",category:'Other',price:2,location:'onperson',enc:'bulky'},
    {name:"Rations",category:'Other',price:5,location:'onperson',enc:'bulky',description:'Preserved food for 7 days'},
    {name:"Rope 30'",category:'Other',price:3,location:'onperson',enc:'bulky'},
    {name:"Grappling hook",category:'Other',price:5,location:'onperson',enc:'bulky'},
    {name:"Tent",category:'Other',price:20,location:'packings',enc:'bulky',description:'Accommodates four characters'},
    {name:"Bedroll",category:'Other',price:2,location:'onperson',enc:'bulky',description:'Good sleep in wilderness'},
    {name:"Winter gear",category:'Other',price:20,location:'onperson',enc:'bulky',description:'Clothing and some equipment for winter-conditions'},
    {name:"Lantern",category:'Other',price:10,location:'onperson',enc:'bulky',description:'Includes oil for three hours'},
    {name:"Oil",category:'Other',price:5,location:'onperson',enc:'bulky',description:'Six refills to lantern'},
    {name:"Torch",category:'Other',price:0,location:'onperson',amount:3,enc:'bulky'},
    {name:"Shield",category:'Other',price:15,location:'onperson',enc:'bulky'},
]

export const armors=[
    {name:'Leather armor',category:'Armor',location:'worn',ac:14,price:15},
    {name:'Chain mail',category:'Armor',location:'worn',ac:16,price:-1},
    {name:'Plate mail',category:'Armor',location:'worn',ac:18,price:-1}
]

export const allItems=[...weapons, ...arrows, ...items, ...armors]
allItems.forEach(i => i.rowKey=guid("itm"));

let clothing=items.find(i => i.name=='Clothing');
let dagger=weapons.find(w => w.name=='Dagger');
let tinderBox=items.find(i => i.name=='Tinder box');
let bedroll=items.find(i => i.name=='Bedroll');
let waterskin=items.find(i => i.name=='Waterskin');
export const tinkeringTools=items.find(i => i.name=='Tinkering tools');

export const initialItems=[clothing,dagger,tinderBox,bedroll,waterskin];

weapons.forEach(w => w.type='weapon');

export const skillAttributes={
    acrobatics:'dexterity',
    awaraness:'wisdom',
    bushcraft:'wisdom',
    bruteforce:'strength',
    chant:'wisdom',
    charm:'charisma',
    endurance:'constitution',
    erudition:'intelligence',
    fight:'',
    investigation:'intelligence',
    medicine:'wisdom',
    sleightofhand:'dexterity',
    sorcery:'intelligence',
    stealth:'dexterity',
    tinkering:'dexterity',
    will:'wisdom'
};

export function createNpc(values){
    let char=createCharacter({values},false,true);
    char.values.awe=values.awe || 0;
    char.values.etherealness=values.etherealness || 0;
    char.values.mysticform=values.mysticform || 0;
    char.values.possession=values.possession || 0;
    char.values.regeneration=values.regeneration || 0;
    char.values.teleport=values.teleport || 0;
    char.values.touchofdeath=values.touchofdeath || 0;
    return char;
}

export function characterAvatarUrl(number){
    if (!number) number=1;
    if (number<1) number=1;
    if (number>32) number=1;
    let ns=String(number).padStart(2,'0');
    return `/assets/avatars/characters_${ns}.svg`;
}


export function createCharacter({values={},items=[],spellContainers=[],spells=[],notes=[],languages=[]},packingsDropped=false,isNpc=false){
    //console.log("Values",values)

    let char={values:{},attributes:{},skills:{},ranks:{},reputation:{},
            items:[],spells:[],spellContainers:[],notes:[],languages:[]}

    //items=items.sort((i,j) => i.id-j.id);
    items.forEach(item => char.items.push(item));
    
    if(!spellContainers.length){
        spellContainers=[
            {rowKey:guid('spc'),title:'Scroll Case',type:'1scrollcase',maxSpells:6,note:'Maximum of 6 spells'},
            {rowKey:guid('spc'),title:'Runes',type:'2runes',maxSpells:6,note:'Maximum of 6 spells'},
            {rowKey:guid('spc'),title:'Scrolls on Person',type:'3onperson',maxSpells:0,note:'One round to find the scroll'},
    
        ]
    }
    spellContainers.forEach(c => char.spellContainers.push(c))
    spells.forEach(s => char.spells.push(s));
    notes.forEach(n=>char.notes.push(n));
    languages.forEach(l => char.languages.push(l));

    Object.defineProperty(char,'damageBonus',{
        get:() => {
            let val=char.strength+char.fight;
            let bonus=0;
            if (val>=5) bonus=1;
            if (val>=7) bonus=2;
            if (val>=9) bonus=3;
            if (val>=11) bonus=4;
            return bonus;
        }
    })

    Object.defineProperty(char,'fatigue',{get(){
        let fat=char.values.fatigue || '';
        return fat.split(',').filter(f => f);
    },set(v){
        let fat=v.join(',');
        char.values.fatigue=fat;
    }});

    Object.defineProperty(char,'fatiguePenalty',{get:() => {
        let cnt=char.fatigue.length;
        if (cnt==0) return 0;
        if (cnt==1) return 1;
        if (cnt==2) return 3;
        if (cnt==3) return 6;
        if (cnt==4) return 10;
        return 15;
    }})

    Object.defineProperty(char,'armor',{get: () => {
        let armor=char.items.find(w => (w.ac) && (w.location=="worn"));
        if (!armor) return {category:'Armor',extra:'none',ac:12,bonus:0,name:'No armor'};
        //if (!armor.bonus) armor.bonus=0;
        //if (!armor.ac) armor.ac=12;
        return armor;     
    }});

    Object.defineProperty(char,'hasShield',{get: () => {
        let shield=char.items.find(w => (w.category=='Shield') && (w.location=="onperson"));
        return !!shield;
    }});


    Object.defineProperty(char,'ac',{get:() =>{
        let armor=char.armor;
        return (armor.ac||12)+(armor.bonus||0);
    }})

    Object.defineProperty(char,"adefence",{get(){
        let value=char.dexterity;
        if (!char.encumbered && (char.acrobatics>char.dexterity)){
            value=char.acrobatics;
        }
        if (char.hasShield) value++;
        value+=char.ac;
        return value;
    }});

    Object.defineProperty(char,'bdefence',{get(){
        let value=char.fight;
        if (char.hasShield) value+=2;
        value+=char.ac;
        return value;
    }});

    Object.defineProperty(char,'encumbered',{
        get: () => {
            let reasons=[];
            let coins=char.values.silverpieces;
            //let packingsDropped=!!char.packingsDropped;
            for(let i=0;i<char.items.length;i++){
                if (char.items[i].location=="extra") continue;
                if (char.items[i].location=="packings" && packingsDropped) continue;
                if (char.items[i].location=="packings" && !reasons.includes(1)) reasons.push(1);
                if (char.items[i].category=="Valuable" && char.items[i].location!="packings") coins+=char.items[i].amount;
            }
            if (coins>100 && char.values.silverpieces<=100) reasons.push(2);
            if (char.armor.ac==16) reasons.push(3);
            if (char.armor.ac==18) reasons.push(3);
            return reasons.length ? reasons : null;
        }
    })

    Object.defineProperty(char,'movement',{
        get(){
            let enc=char.encumbered;
            let ac=char.armor.ac;
            if (!enc && ac==12) return 50; // Not enc, no armor
            else if (!enc && ac==14) return 40; // Not enc,light armor
            else if (ac>=18) return 20; // Enc or not + Heavy armor
            return 30; // Enc or not+Medium armor
        }
    })

    Object.defineProperty(char,'schools',{
        get(){
            let schools=[];
            if (char.intelligence>3 && char.values.sorcery) schools.push("Sorcery");
            if (char.wisdom>3 && char.values.chant) schools.push("Chant");
            if (char.charisma>3 && char.values.charm) schools.push("Charm");  
            if (char.intelligence>3 && char.values.illusion) schools.push("Illusion");  
            if (char.charisma>3 && char.values.domination) schools.push("Domination");  
            if (char.wisdom>3 && char.values.necromancy) schools.push("Necromancy");  
            return schools;  
        }
    })

    Object.defineProperty(char,'isSpellCaster',{
        get(){
            return !!char.schools.length;
        }
    })

    Object.defineProperty(char,"availableSpells",{
        get(){
            function isValidSchool(spell,schools){
                for(let i=0;i<schools.length;i++){
                    if (spell.schools.includes(schools[i])) return true;
                }
                return false;
            }
            if (!char.isSpellCaster) return [];
            let available=spellList.filter(s => isValidSchool(s,char.schools));
            return available;
        }
    })

    raw.forEach(item => {
        char.values[item.field]=item.value;
        if (item.field!='fatigue') Object.defineProperty(char,item.field,{
            get: () => {
                if ((item.field=='dexterity') && char.encumbered) return 0;
                return char.values[item.field];
            },
            set: v => {
                if (!item.field) {
                    console.log("No field",item);
                }
                char.values[item.field]=v
            }
        })
        if (item.type=='attribute'){
            Object.defineProperty(char.attributes,item.field,{
                get: () => {
                    let {shortname,name,field}=item;
                    let value=char[field];
                    return {shortname,name,field,value};
                },
                set: v => char.values[item.field]=v
            });
        }
        if (item.type=='fight'){
            Object.defineProperty(char.skills,'bfight',{
                get:() => {
                    let value=char.hd+char.strength+char.fight;
                    value+=char.temporarySkillBonus;
                    let ret={
                        hd:char.hd,ranks:char.fight,
                        baseAttribute:'strength',
                        baseValue:char.strength,
                        shortName:'bfight',
                        field:'bfight',
                        name:'Brutal Fight',
                        value
                    }
                    return ret;
                }
            })
            Object.defineProperty(char.skills,'afight',{
                get:() => {
                    let value=char.hd+char.fight+char.dexterity;
                    value+=char.temporarySkillBonus;
                    let ret={
                        hd:char.hd,ranks:char.fight,
                        baseAttribute:'dexterity',
                        baseValue:char.dexterity,
                        shortName:'afight',
                        field:'afight',
                        name:'Agile fight',
                        value
                    }
                    return ret;
                }
            })
        }
        if (item.type=='skill'){
            Object.defineProperty(char.skills,item.field,{
                enumerable:true,
                get:() => {
                    let baseAttribute=item.baseAttribute;
                    let baseValue=0;
                    if (baseAttribute) baseValue=char[baseAttribute];
                    let ranks=char.values[item.field];
                    let hd=char.values.hd;
                    let value=hd+baseValue+ranks;
                    value+=char.temporarySkillBonus;
                    //if (item.field=='illusion') console.log("illusion",item)
                    if (item.baseValue<0 && (typeof(ranks)=="undefined" || ranks<0)){
                        value=-1;
                        //console.log("Item base value",item)
                    } ;
                    let ret={
                        hd,
                        baseAttribute,baseValue,
                        ranks,
                        field:item.field,
                        shortName:item.shortname,
                        name:item.name,
                        value
                    }
                    return ret;
                }
            })
        }
        
    });
    for(let k in values){
        char.values[k]=values[k];
    }
    return char;
}

export function characterForCampaign(campaign,player){
    let char={values:{
        partitionKey:player.rowKey,
        rowKey:guid('chr'),
        campaign:campaign.rowKey,
        username:player.username,
        player:player.rowKey
    },items:[]};
    return char;
}

export function npcForCampaign(campaign,player){
    let char={
        partitionKey:campaign.rowKey,
        campaign:campaign.rowKey,
        username:player.username,
        player:player.rowKey,
        attacks:'',
        items:'',
        spells:''
    };
    return char
}



export async function saveCharacter(character){
    let values=await HTTP.post("/api/characters",character.values);
    let rowKey=values.rowKey;
    for(let i=0;i<character.items.length;i++){
        await HTTP.post("/api/characters/"+rowKey+"/items",character.items[i]);
    }
    for(let i=0;i<character.spellContainers.length;i++){
        await HTTP.post("/api/characters/"+rowKey+"/spellcontainers",character.spellContainers[i]);
    }
    for(let i=0;i<character.spells.length;i++){
        await HTTP.post("/api/characters/"+rowKey+"/spells",character.spells[i]);
    }
    for(let i=0;i<character.languages.length;i++){
        await HTTP.post("/api/characters/"+rowKey+"/languages",character.languages[i]);
    }
    let chr=await HTTP.get("/api/characters/"+rowKey);
    return chr;
}

