import React from 'react';
import { joinMeeting } from './webrtc';

import './meeting.scss';

export function Meeting(){
    
    let root=document.querySelector("html");
    let username=localStorage['eorusername'];

    function join(){
        joinMeeting(username);
        html.classList.add("joinedMeeting")
    }

    function leave(){
        html.classList.remove("joinedMeeting");
    }

    console.log("MEETING RERENDER");
    return <div className="meeting">
        <div>
            <input id="joinMeetingButton" type="button" value="Join" onClick={join} />
            <input id="leaveMeetingButton" type="button" value="Leave" onClick={leave} />
            <input id="muteButton" type="button" value="Mute" />
            <input id="novideoButton" type="button" value="No video" />
        </div>
        <div id="videocontainer">
        </div>
    </div>
}