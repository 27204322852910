import React, { useState } from 'react';
import { spellList } from '../articles/articleutils';

import './characterspells.scss';
import { Icon } from '../utils/icons';
import { guid } from '../utils/utils';
import { HTTP } from '../utils/http';
import { TextInput } from '../utils/formfields';
import { useOutletContext } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createCharacter } from './character';
import { updateSpells } from '../utils/store';
import { sendToCharacter, sendToGm } from '../utils/websocket';


const titles={
    scrolls:'Scroll Case',
    runes:'Runes',
    other:'Other',
}

export function SpellItem({character,spell,onToolClicked,containers,location="allspells",type,edit=false}){
    let [showDescription,setShowDescription]=useState(false);
    let [school,setSchool]=useState(character.schools[0]);

    let toolButtons=containers.filter(c => c.rowKey!=location).map(c => <input key={c.rowKey} type="button" value={`To ${c.title}`} onClick={() => onToolClicked(spell,c.rowKey)} />)
    let headerStyles='spellHeader';
    if (spell.isCast) headerStyles+=" spellIsCast";

    let schoolOptions=character.schools.filter(s => spell.schools.includes(s)).map(s => <option value={s}>{s}</option>)
    
    return <div className="spellItem">
        <div className={headerStyles}>
            <h5 className='spellTitle'>
                <span className="clickable" onClick={() => setShowDescription(!showDescription)}>{spell.title}</span> 
                {location!='allspells' ? <span className="castCount clickable" onClick={() => onToolClicked(spell,'cast',type==1 || type==3)}>Cast{type==2 || type==4 ? <>:{spell.castCount}</> : <></>}</span> : <></>}
            </h5>
            {edit && location=="allspells" ? <div className="spellMove">
                    {false ? <select value={school} onChange={ev => setSchool(ev.target.value)}>{schoolOptions}</select> : <></>}
                    {toolButtons}
                </div> : <></> }
        </div>
        {showDescription ? <>
            <p className='spellInfo'><span className="label">Schools</span><span>{spell.schools}</span></p>
            <p className='spellInfo'><span className="label">Duration</span><span>{spell.duration}</span></p>
            <p className='spellInfo'><span className="label">Range</span><span>{spell.range}</span></p>        
            <div className='spellDescription' dangerouslySetInnerHTML={{__html:spell.description}} /> 
            <div className='spellTools'>
                {location!="allspells" ? <div className="spellOperations">
                    {edit ? <input type="button" value="Delete" onClick={() => onToolClicked(spell,'delete')} /> : <></> }
                </div>: <></>}
            </div>    
        </>: <></> }
    </div>
}

function SpellContainer({character,container,containers,onToolClicked,save,deleteContainer,spells=[],edit=false}){
    let [title,setTitle]=useState(container.title);
    let [note,setNote]=useState(container.note);

    let tpn=Number(container.type[0]);
    console.log("Spells",container.rowKey,containers,spells)
    let spellRows=spells.filter(s => s.location==container.rowKey)
    let rows=spellRows.map(s => <SpellItem key={s.rowKey} character={character} spell={s} containers={containers} onToolClicked={onToolClicked} location={container.rowKey} type={tpn} edit={edit} />)
    
    console.log("Container",title,tpn,spells.length,container.rowKey)
    return <div className="spellContainer">
        <h4><TextInput value={title} placeholder='Item name' onChange={ev => setTitle(ev.target.value)} editable={edit && tpn>3} /></h4>
        {edit ? <textarea value={note} placeholder="Description" onChange={ev => setNote(ev.target.value)}/>:<p>{container.note}</p>}
        {edit ? <p>
            <Icon type={Icon.accept} onClick={() => save({...container,title,note})} />
            {tpn>3 && spellRows.length==0 && container.rowKey ? <Icon type={Icon.delete} onClick={() => deleteContainer(container)} /> : <></>}
            </p> : <></>}
        <div className="spellItems">
            {rows}
        </div>
    </div>
}

export function CharacterSpells({character,styles="",noTitle=false}){
    // let [spells,setSpells]=useState(character.spells);
    let {role}=useSelector(state => state.game);
    let spells=character.spells;
    let [edit,setEdit]=useState(false);
    let [containers,setContainers]=useState(character.spellContainers);
    let [showAll,setShowAll]=useState(!character.isSpellCaster);
    let availableSpells=character.availableSpells;
    let allSpells=spellList;
    console.log("allSpells",character.spellContainers,character.spells)

    function setSpells(spells){
        character.spells=spells;
        let obj={character:character.values.rowKey,spells}
        updateSpells(obj);
        if (role=="Master") sendToCharacter(character.values.rowKey,"setSpells",obj);
        else sendToGm("setSpells",obj)
    }

    function addSpell(spellToAdd,location){
        console.log("add spell to",location)
        let spell={...spellToAdd,location,isCast:false,castCount:0};
        spell.partitionKey=character.rowKey;
        spell.castCount=0;
        spell.rowKey=guid("spl");
        HTTP.post("/api/characters/"+character.rowKey+"/spells",spell).then(spells=>{
            character.spells=spells;
            setSpells([...character.spells]);
        });
    }

    async function saveSpell(spell,updateCharacter=true){
        if (!spell.partitionKey) spell.partitionKey=character.rowKey;
        if (!spell.rowKey) spell.rowKey=guid("spl");
        if (!spell.castCount) spell.castCount=0;
        let spells=await HTTP.post("/api/characters/"+character.rowKey+"/spells",spell);
        if (updateCharacter){
            character.spells=spells;
            setSpells([...character.spells]);
        }
        return spells;
    }

    function saveContainer(container){
        if (!container.partitionKey) container.partitionKey=character.rowKey;
        if (!container.rowKey) container.rowKey=guid("spc");
        HTTP.post("/api/characters/"+character.rowKey+"/spellcontainers",container).then(containers => {
            //character.spellContainers.push(container);
            //setContainers([...character.spellContainers]);
            character.spellContainers=containers;
            setContainers([...character.spellContainers]);
        })
    }

    async function deleteSpell(spell){
        let spells=await HTTP.delete("/api/characters/"+character.rowKey+"/spells/"+spell.rowKey);
        character.spells=spells;
        setSpells(spells);
        return spells;
    }

    async function deleteContainer(container){
        let containers=await HTTP.delete("/api/characters/"+character.rowKey+"/spellcontainers/"+container.rowKey)
        character.spellContainers=containers;
        setContainers(containers);
        return containers;
    }

    async function toolClicked(spell,command,deleteOnCast=false){
        if (command=="cast") {
            if (deleteOnCast){
                character.spells=await deleteSpell(spell);
            }
            else{
                let item={...spell,castCount:spell.castCount+1};
                character.spells=await saveSpell(item);
                console.log("Spells",character.spells)
            }
        }
        else if (command=='delete'){
            character.spells=await deleteSpell(spell);
        }
        else{
            console.log("Location",command)
            spell.location=command; 
        }
        setSpells([...character.spells]);
    }

    async function clearCastings(){
        let sp=[];
        for(let i=0;i<spells.length;i++){
            if (!spells[i].castCount) continue;
            sp=await saveSpell({...spells[i],castCount:0},false);
        }
        console.log("Clear",sp)
        character.spells=sp;
        setSpells([...character.spells]);
    }

    let list=showAll ? allSpells : availableSpells;
    let allSpellRows=list.map(s => <SpellItem key={s.title} character={character} spell={s} containers={containers} onToolClicked={addSpell} edit={edit} />)

    if (!character.isSpellCaster) containers=containers.filter(c => c.type=="4custom");
    containers.sort((a,b) => a.type.localeCompare(b.type));
    let containerRows=containers.map(c => <SpellContainer key={c.rowKey} character={character} container={c} onToolClicked={toolClicked} containers={containers} spells={spells} edit={edit} save={saveContainer} deleteContainer={deleteContainer}/>);


    function toggleEdit(){
        
        if (edit) containers.forEach(async c => {
            //c.partitionKey=character.rowKey;
            await HTTP.post("/api/characters/"+character.rowKey+"/spellcontainers",c);
        })
        
        setEdit(!edit);
    }

    let hasCastings=spells.some(s => s.castCount);

    let cls="characterSpells "+styles;
    return <div className={cls}>
        <div>{!noTitle ? <><h3>Own spells <Icon type={Icon.edit+(edit ? Icon.dimmed : "")} onClick={toggleEdit} /></h3>
            {!character.isSpellCaster ? <p>You are not spell caster, but you may have items that hold spells</p> : <></>}
            {hasCastings ? <p>Castings can be cleared at dawn, <a onClick={clearCastings}>clear now</a></p> : <></>}
            </> : <></> }
            <div className="ownSpells">
                {containerRows}
                {edit ? <SpellContainer key={guid('xxx')} container={{title:'',note:'',type:"4custom"}} containers={containers} save={saveContainer} edit={edit} /> : <></>}
            </div>
        </div> 
        {edit ? <div>
            <h3>Available spells</h3>
            {character.isSpellCaster ? <div><a onClick={() => setShowAll(false)}>Spells of Schools known</a> <a onClick={() => setShowAll(true)}>All spells</a></div> : <></> }
            <div className="allSpells">
                {allSpellRows}
            </div>
        </div> : <></>}
    </div>
}

export function CharacterSheetSpells(){
    //const {character}=useOutletContext();
    const {currentCharacter}=useSelector(state => state.game);
    let character=createCharacter(currentCharacter);

    return <><CharacterSpells character={character} styles="characterSheetPage" /></>
}