import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HTTP } from '../../utils/http';
import { Link } from 'react-router-dom';

import './journal.scss'
import { Icon } from '../../utils/icons';
import { HandoutItem } from '../../handouts/handouts';

const months="January,February,March,April,May,June,July,August,September,October,November,December".split(",");

function appendOrdinal(day){
    day=Number(day);
    if (!day) return "";
    if ((day>=4 && day<=19) || (day>24 && day<=29)) return ""+day+"th";
    if (day%10==1) return ""+day+"st";
    if (day%10==2) return ""+day+"nd";
    if (day%10==3) return ""+day+"rd";
    return ""+day;
}

export function formatDate(date){
    if (!date) return "";
    let parts=date.split('-');
    if (parts.length!=3) return date;
    let [year,month,day]=parts;
    if (Number(day)<1 || Number(day)>31) return date;
    if (Number(month)<1 || Number(month)>12) return date;
    return `${appendOrdinal(day)} of ${months[Number(month)-1]} in the Year ${year}`;
}

function JournalItemView({item,edit,selectItem}){
    let {handouts}=useSelector(state => state.game);
    let ta=(item.text || "").split("\n");
    ta=ta.map(t => "<p>"+t+"</p>").join("");
    let ho=null;
    if (item.handout){
        ho=handouts.find(h => h.rowKey==item.handout);
    }

    return <div className="journalItem" onClick={() => selectItem(item)}>
        <div className="journalItemContent">
            <div className="journalItemTex">
                <div className="journalItemHeader">
                    <h5>{formatDate(item.date)}, {item.title}</h5>
                </div>
                <div className="journalText" dangerouslySetInnerHTML={{__html:ta}} />
            </div>
            {ho ? <div className="journalHandout"><HandoutItem handout={ho} root="/campaign/handouts/"/></div> : <></>}
        </div>
    </div>
}

function JournalItemEdit({item,save,cancel}){
    let {campaign,role,handouts}=useSelector(state => state.game);
    let [title,setTitle]=useState(item.title || "");
    let [text,setText]=useState(item.text || "");
    let [handout,setHandout]=useState(item.handout || "");
    let [date,setDate]=useState(item.date || campaign?.currentDate || '');

    function saveItem(){
        let rowKey=item.rowKey;
        save({rowKey,title,text,date,handout})
        setTitle("");
        setText("");
        setHandout("");
    }
    let selectedHandout=null;
    if (handout) selectedHandout=handouts.find(h => h.rowKey==handout);
    let hoOptions=handouts.filter(() => true).sort((a,b) => (b.foundDate || "").localeCompare(a.foundDate || "")).map(h => <option key={h.rowKey} value={h.rowKey}>{h.name}</option>)
    let saveDisabled=!date || !title || !text;

    return <div className="journalForm journalItem">
        <div className="journalItemContent">
            <div className='journalItemText'>
                <div className="formField">
                    <input placeholder="Date" value={date} onChange={ev => setDate(ev.target.value)} />
                    <input placeholder="Title" value={title} onChange={ev => setTitle(ev.target.value)} />
                </div>
                <div className="formField">
                    <textarea placeholder="Journal notes" value={text} onChange={ev => setText(ev.target.value)} />
                </div>
            </div>
            <div className='journalHandout'>
                <div className="formField">
                    <label>Handout</label>
                    <select value={handout} onChange={ev => setHandout(ev.target.value)}>
                        <option value="">None</option>
                        {hoOptions}
                    </select>
                </div> 
                <div>
                    {selectedHandout ? <HandoutItem handout={selectedHandout} noClick={true} /> : <></> }
                </div>
            </div>
        </div>   
        <div className="formButtons">
            <input type="button" value={item.rowKey ? "Save item" : "Add entry"} onClick={saveItem} disabled={saveDisabled} />
            {item.rowKey ? <input type="button" value="Cancel" onClick={cancel} /> : <></> }
        </div>
    </div>
}

function JournalItem({item,edit,selectedItem,selectItem,save}){

    return <>{
        edit && selectedItem.rowKey==item.rowKey ? <JournalItemEdit item={item} save={save} cancel={() => selectItem({})} /> :
        <JournalItemView item={item} edit={edit} selectItem={selectItem} />
    }</>
}

export function Journal(){
    let {campaign,role,handouts}=useSelector(state => state.game);
    let [journal,setJournal]=useState([]);
    let [edit,setEdit]=useState(false);
    let [selected,setSelected]=useState({})

    useEffect(function(){
    },[])

    useEffect(function(){
        if (campaign) HTTP.get("/api/journal").then(journal => {
            setJournal(journal);
        })
    },[campaign]);

    function save(item){
        HTTP.post("/api/journal",item).then(journal =>{
            setJournal(journal);
            setSelected({});
        })
    }

    function selectItem(item={}){
        setSelected(item)
    }

    console.log("Handouts",handouts);

    let items=journal.sort((a,b) => b.date.localeCompare(a.date))
        .map(i => <JournalItem key={i.rowKey} item={i} edit={edit} save={save} selectItem={selectItem} selectedItem={selected} /> );

    return <div className="subNavContent">
        <div className="journalContent">
            <div className="journalTop">
                <div>
                    <h2>Journal of {campaign.name}</h2>
                    <p>As of {formatDate(campaign?.currentDate)}</p>
                </div>
                <Icon type={Icon.editPen} onClick={() => setEdit(!edit)} />
            </div>
            <div className="journalCenter">
                {edit && !selected.rowKey ? <JournalItem item={{}} edit={edit} selectedItem={{}} save={save} selectItem={selectItem} /> : <></> }
                <div className="journalList">
                    {items}
                </div>
            </div>   
            <div className="journalBottom"></div>         
        </div>
    </div>
}