import React, { useEffect } from 'react';
import { Checkbox } from '../utils/formfields';
import { useSelector } from 'react-redux';

import './mapcontrols.scss'
import { addMapNote, saveMap, setClicked, setClicks, setMapCustom, setMapData, setMapOptions, setMapPointer } from './utils/mapstore';
import { setShare } from '../utils/store';
import { useState } from 'react';
import { sendToCampaign } from '../utils/websocket';

export function PlayerTools({onClick,map}){
    let {player,role,currentCharacter,share}=useSelector(state => state.game);
    let {clicks,clicked,mapOptions}=useSelector(state => state.maps);
    let [addNote,setAddNote]=useState(false);
    let [text,setText]=useState("");

    function saveNote(){
        let {x,y}=clicks[0].feet;
        let {username}=player;
        let name=currentCharacter?.values.name || "";
        let map=mapOptions.rowKey;
        let note={partitionKey:map,x,y,username,name,text};
        addMapNote(map,note);
        setAddNote(false);
        setText("");
        setClicks();
    }

    function addPointer(opening=null){
        let {x,y}=clicks[0].feet;
        let {username}=player;
        let name=currentCharacter?.values.name || "";
        let map=mapOptions.rowKey;
        let pt={x,y,map,username,name,player:player.rowKey};
        pt.opening=opening;
        setMapPointer(pt);
        setClicks();
    }

    function cancel(){
        setClicks();
        setClicked();
    }

    function openDoor(){
        if (role=="Master"){
            let opening=map.openings.find(o => o.rowKey==clicked.opening.rowKey);
            opening.isOpen=true;
            opening.isLocked=false;
            setMapData(map);
            if (!map.inEncounter && share && share.itemRowKey==map.options.rowKey) sendToCampaign("setMapData",map)
        }
        else{
            addPointer(clicked.opening);
        }
    }

    function closeDoor(){
        if (role=="Master"){
            let opening=map.openings.find(o => o.rowKey==clicked.opening.rowKey);
            opening.isOpen=false;
            setMapData(map);
            if (!map.inEncounter && share && share.itemRowKey==map.options.rowKey) sendToCampaign("setMapData",map)
        }
        else{
            addPointer(clicked.opening);
        }
    }

    console.log("Clicked",clicked)

    let opening=clicked?.opening;

    return <div className='playerTools'>
       <div>
            {opening && !opening.isOpen ? <button title="Open" className={'mapToolButton buttonOpenDoor'} onClick={openDoor} /> : <></>}
            {opening && opening.isOpen ? <button title="Close" className={'mapToolButton buttonCloseDoor'} onClick={closeDoor} /> : <></>}
            <button title="Point" className={'mapToolButton buttonAddPointer'} onClick={() => addPointer()} />
            {!opening ? <button title='Add note' className={'mapToolButton buttonAddNote'} onClick={() => setAddNote(true)} /> : <></> }
            <button title='Cancel' className={'mapToolButton buttonCancel'} onClick={cancel} />
        </div>
        {addNote ? <div className="playerNote">
            <textarea value={text} onChange={ev => setText(ev.target.value)} />
            <input type="button" value="Save" onClick={saveNote} />
        </div> : <></>}
     </div>
}

export function MapTools({selected,isModified,onClick}){

    return <div className="mapToolsPopup">
        <div className="toolGroup">
            <button title="Clear clicks" className={'mapToolButton buttonClearClicks'} onClick={() => onClick('clearClicks')} />
            <button title="Set selected" className={'mapToolButton buttonSetSelected'} onClick={() => onClick('setSelected')} />
        </div>
        <div className="toolGroup">
            <button title="New map" className={'mapToolButton buttonNewMap'} onClick={() => onClick('newMap')} />
            <button title="Open map" className={'mapToolButton buttonOpenMap'} onClick={() => onClick('openMap')} />
            <button title="Map properties" className={'mapToolButton buttonMapProperties'} onClick={() => onClick('mapProperties')} />
            <button title="Save map" className={'mapToolButton buttonSaveMap'} onClick={() => onClick('saveMap')} />
        </div>
    </div>
}

function MasterTools({map}){
    let {player,share}=useSelector(state => state.game);
    let {mapOptions,clicks,clicked}=useSelector(state => state.maps);
    let options={...mapOptions};

    function onSetSelected(){
        map.selectedRoom=clicked.line.rowKey;
        let line=map.objects.find(o => o.rowKey==clicked.line.rowKey);
        line.visited=true;
        setMapData(map);
        if (share && share.itemRowKey==map.options.rowKey) sendToCampaign("setMapData",map);
    }

    function onClearClicks(){
        setClicked();
        setClicks();
    }

    function onSaveMap(){
        if (share && share.itemRowKey==map.options.rowKey) sendToCampaign("setMapData",map);
        saveMap(map)
    }
    
    function onShareMap(){
        saveMap(map).then(() => {
            let itemType="map";
            let itemRowKey=options.rowKey;
            let title="Map";
            let imageUrl="/images/empire.svg";
            let sharedBy=player.username;
            let playerRowKey=player.rowKey;
            setShare({itemType,itemRowKey,title,imageUrl,sharedBy,playerRowKey})
        })
    }

    function onAddPointer(){
        let {x,y}=clicks[0].feet;
        let {username}=player;
        let map=mapOptions.rowKey;
        let pt={x,y,map,username,player:player.rowKey};
        setMapPointer(pt);
        setClicks();
    }

    function setShowAvatars(show){
        map.options.showAvatars=show;
        if (share && share.itemRowKey==map.options.rowKey) sendToCampaign("setMapData",map);
        setMapData(map);
    }

    let showSave=true;
    if (map.inEncounter && !map.inEncounter.mapRowKey) showSave=false;

    let bav=(!map.inEncounter && share && share.itemRowKey==map.options.rowKey);

    return <div className="toolGroup">
        {clicks.length==1 ? <button title="Set pointer" className="mapToolButton buttonAddPointer" onClick={onAddPointer} /> : <></>}
        {bav ? <>
            {map.options.showAvatars ? <button title="Hide avatars" className="mapToolButton buttonAvatarsShown" onClick={() => setShowAvatars(false)} /> : <></> }
            {!map.options.showAvatars ? <button title="Show avatars" className="mapToolButton buttonAvatarsHidden" onClick={() => setShowAvatars(true)} /> : <></> }
        </> : <></>}
        <button title="Clear clicks" className={'mapToolButton buttonClearClicks'} onClick={onClearClicks} />
        {clicked.line ? <button title="Set selected" className={'mapToolButton buttonSetSelected'} onClick={onSetSelected} /> : <></> }
        {showSave ? <button title="Save map" className={'mapToolButton buttonSaveMap'} onClick={onSaveMap} /> : <></> }
        {false && options.rowKey && !map.inEncounter ? <button title="Share" className="mapToolButton buttonShareMap" onClick={onShareMap} /> : <></>}
    </div>
}

export function MapHeadingControls({map,playerMode=false,setPlayerMode}){
    let {role,player,share}=useSelector(state => state.game);
    let {mapOptions,mapPointer,mapCustom}=useSelector(state => state.maps);
    let options={...mapOptions};
    let custom={...mapCustom};

    function changeShowGrid(v){
        custom.showGrid=v;
        setMapCustom(custom)
    }

    function changeScale(ev){
        //options.backgroundScale=Number(ev.target.value);
        //setMapOptions(options);
        map.clearLatest();
        custom.backgroundScale=Number(ev.target.value);
        setMapCustom(custom)
    }

    function changeSquareFeet(v){
        custom.squareFeet=v;
        setMapCustom(custom);
    }

    let maxSquareFeet=options.mapWidth/2;
    let minSquareFeet=1;
    if (options.mapUnits=="miles") minSquareFeet=0.25;
    if (options.mapUnits=="yards")minSquareFeet=0.5;
    let step=minSquareFeet;

    let mw=minSquareFeet*options.feetToPixelsRatio;
    let values=[0.25,0.5,1,2,5,10,20,50,100,200,500,1000,2000,5000,10000];
    let steps= [0.25,0.5,1,1,5,5 ,5 ,50,50 ,100,500,500 ,1000,5000,5000 ];
    while(mw<5){
        let index=values.indexOf(minSquareFeet);
        if (index<values.length-1) {
            minSquareFeet=values[index+1];
            step=steps[index+1];        
        }    
        else {
            minSquareFeet*=10;
            step*=10;
        }
        mw=minSquareFeet*options.feetToPixelsRatio;
    }

    function onShare(){
        let itemType="map";
        let itemRowKey=options.rowKey;
        let title="Map";
        let imageUrl="/images/empire.svg";
        let sharedBy=player.username;
        let playerRowKey=player.rowKey;
        setShare({itemType,itemRowKey,title,imageUrl,sharedBy,playerRowKey})
    }

    function removePointer(){
        setMapPointer({map:map.options.rowKey});
    }

    function onSave(){
        saveMap(map);
    }

    function changeShowNotesText(show){
        custom.showNotesText=show;
        setMapCustom(custom);
    }

    let showShare=true;
    if (map.inEncounter) showShare=false;
    if (share && share.itemRowKey==options.rowKey && role!="Master") showShare=false;
    if (!options.rowKey) showShare=false;

    return <div className='mapControls'>
        <h3>{options.name}</h3>
        <div>
            <label>Scale ({custom.backgroundScale})</label>
            <input type="range" min={0.2} max={4} step={0.1} value={custom.backgroundScale} onChange={changeScale} />
        </div>
        <Checkbox boxPosition='right' label="Grid" checked={custom.showGrid} onChange={v => changeShowGrid(v)} />   
        {custom.showGrid ? <div className='formInput rangeInput'>
            <label>Square is {custom.squareFeet} {options.mapUnits}</label>
            <input type="range" min={minSquareFeet} max={maxSquareFeet} step={step} value={custom.squareFeet} onChange={ev => changeSquareFeet(Number(ev.target.value))} />
        </div> : <></> }
        {mapPointer && (role=="Master" || mapPointer.player==player.rowKey) ? <button title="Remove pointer" className="mapToolButton buttonRemovePointer" onClick={removePointer} />: <></>}
        {role=="Master" ? <>
            {playerMode ? <button title="Edit mode" className={'mapToolButton buttonPlayMode'} onClick={() => setPlayerMode(false)} /> : <></> }
            {!playerMode ? <button title="Play mode" className={'mapToolButton buttonEditMode'} onClick={() => setPlayerMode(true)} /> : <></> }
            <MasterTools map={map} />
        </> : <></> }                 
        {showShare || map.notes.length ? <div className="toolGroup">
            {map.notes.length ? <>
                {mapCustom.showNotesText ? <button label="Show note markers" onClick={() => changeShowNotesText(false)} className="mapToolButton buttonShowNotesText" /> : <></>}
                {!mapCustom.showNotesText ? <button label="Show note text" onClick={() => changeShowNotesText(true)} className="mapToolButton buttonShowNotesMarkers" /> : <></>}
            </> : <></>}
            {showShare ? <button title="Share" className="mapToolButton buttonShareMap" onClick={onShare} /> : <></> }
        </div> : <></>}
    </div>
}